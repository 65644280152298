import React from "react";
import ReactTable from "react-table";
import matchSorter from "match-sorter";
import { Card, Dimmer, Loader } from "semantic-ui-react";
import enhance from "./enhance";
import styles from "../AddNewModel/AddNewModel.module.scss";
import { Alerts } from "../../../redux/constants/Values";

export const ModelsTableData = (props) => {
  const {
    modelsDeviceParams,
    toggleRow,
    toggleSelectAll,
    modelsChekedLists,
    isFetchingDeviceParams,
    pageSize,
  } = props;
  const [page, setPage] = React.useState("");
  /* istanbul ignore next */
  return Object.keys(modelsDeviceParams).length === 0 ? (
    <Dimmer active inverted>
      <Loader size="large" inverted>
        Fetching Device Parameters...
      </Loader>
    </Dimmer>
  ) : (
    <div className={styles.modelCards}>
      <Card fluid>
        <Card.Content header="All Parameters" className={styles.cardHeader} />
        <Card.Content extra className={styles.height}>
          <ReactTable
            data={modelsDeviceParams}
            loading={isFetchingDeviceParams ? true : false}
            filterable
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id]) === filter.value
            }
            style={{ background: "white" }}
            onPageChange={(pageno) => {
              if (pageSize.includes(pageno)) {
                document.getElementsByName("selectAll")[0].checked = true;
              } else {
                document.getElementsByName("selectAll")[0].checked = false;
              }
              setPage(pageno - 1);
            }}
            getTrProps={(state, rowInfo, column) => {
              return {
                style: {
                  background:
                    rowInfo &&
                    modelsChekedLists.includes(rowInfo.original.mnemonic)
                      ? "lightgreen"
                      : "",
                },
              };
            }}
            columns={[
              {
                Header: "Device Parameters",
                id: "deviceParams",
                accessor: (d) => d.mnemonic,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["deviceParams"] }),
                filterAll: true,
              },
              {
                Header: "Display Name",
                id: "displayName",
                accessor: (d) => d.displayName,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["displayName"] }),
                filterAll: true,
              },
              {
                Header: "Units of Measure",
                id: "unit",
                accessor: (d) => d.unitsOfMeasure,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["unit"] }),
                filterAll: true,
              },
              {
                Header: "Description",
                id: "description",
                accessor: (d) => d.description,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["description"] }),
                filterAll: true,
              },
              {
                Header: "Datatype",
                id: "dataType",
                accessor: (d) => d.dataType,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["dataType"] }),
                filterAll: true,
              },
              {
                id: "checkbox",
                accessor: "",
                Cell: ({ original }) => {
                  return (
                    <input
                      component="input"
                      type="checkbox"
                      name="deviceParams"
                      id="deviceParams"
                      checked={
                        modelsChekedLists.includes(original.mnemonic)
                          ? true
                          : false
                      }
                      onChange={(e) =>
                        toggleRow(
                          e,
                          original.mnemonic,
                          original.description,
                          original.displayName,
                          original.dataType,
                          original.unitsOfMeasure
                        )
                      }
                      value={
                        original.mnemonic +
                        "," +
                        original.description +
                        "," +
                        original.displayName +
                        "," +
                        original.dataType +
                        "," +
                        original.unitsOfMeasure
                      }
                    />
                  );
                },
                Header: (x) => {
                  return (
                    <input
                      type="checkbox"
                      className="checkbox"
                      name="selectAll"
                      onChange={(e) => toggleSelectAll(e, page)}
                    />
                  );
                },
                sortable: false,
                width: 45,
              },
            ]}
            defaultPageSize={5}
            minRows={5}
            multiSort={true}
            showPageJump={true}
            noDataText={Alerts.noRows}
          />
        </Card.Content>
      </Card>
    </div>
  );
};
export default enhance(ModelsTableData);
