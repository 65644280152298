import React from "react";

export const RadioGroup = props => (
  <div className="field">
    {props.options.map(o => (
      <label key={o.value}>
        <input
          type="radio"
          {...props.input}
          value={o.value}
          checked={o.value === props.input.value}
        />{" "}
        {o.title}
      </label>
    ))}
    {props.meta.submitFailed && props.meta.error && (
      <div className="ui red pointing basic label">"{props.meta.error}"</div>
    )}
  </div>
);

// TextArea.propTypes = {
//   title: React.PropTypes.string.isRequired,
//   rows: React.PropTypes.number.isRequired,
//   name: React.PropTypes.string.isRequired,
//   content: React.PropTypes.string.isRequired,
//   resize: React.PropTypes.bool,
//   placeholder: React.PropTypes.string,
//   controlFunc: React.PropTypes.func.isRequired
// };

export default RadioGroup;
