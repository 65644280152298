import { fetchLoginDetails } from "../../redux/reducers/AuthReducer";
import { connect } from "react-redux";
import { compose, withHandlers, withState,lifecycle } from "recompose";

/**
 *
 * @description {onpage load call API}
 */
  export function componentDidMount() {
    localStorage.clear();
    sessionStorage.clear();
  }

/**
 *
 * @description {clear login details}
 */
  export const queryChange = ({LoginDetails,setLoginDetails})=>e => {
    setLoginDetails({
      ...LoginDetails,
      [e.target.name]: [e.target.value]
    });
  };

  /**
 *
 * @description {setting login details}
 */
  export const handleSubmit =({LoginDetails,setloader,history}) => (e) => {
    //   alert(JSON.stringify(history));
    e.preventDefault();
    e.persist();
    setloader(true);
    fetchLoginDetails(LoginDetails.email[0], LoginDetails.password[0], history)
  };

  export default compose(
    connect(null, { fetchLoginDetails }),
    withState("LoginDetails", "setLoginDetails", {
        email: "",
        password: ""
      }),
    withState("loader", "setloader", false),
    withHandlers({ queryChange, handleSubmit }),
    lifecycle({
        componentDidMount
      }),
  );
  