import React, { useEffect } from "react";
/**
 * @description {if user dont have rights and invalid url path will be redirected to this page}
 */
export const NoRightsPage = (props) => {
  /* istanbul ignore next */
  useEffect(() => {
    if (!sessionStorage.getItem("userInfo")) {
      window.location.href = "/";
    }
  }, []);
  return (
    <>
      <div className="containerStyle">
        <h3 className="alignCenter">Page Not Found</h3>
      </div>
      )
    </>
  );
};

export default NoRightsPage;
