import React from "react";
import {
  Label,
  Table,
  Segment,
  Button,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { Grids, commonLabels } from "../../../../redux/constants/Values";

export const QueueList = (props) => {
  const {
    detailsPageButtonIsClicked,
    sampleQueueListDetailsData,
    isFetchingSampleQueueData,
  } = props;
  return (
    <>
      <Segment padded>
        <Label attached="top" size="big" className="bgBlue">
          {Grids.sampleItem + " " + commonLabels.data}
          <Button
            onClick={detailsPageButtonIsClicked.bind(this, Grids.sampleItem)}
            primary
            floated="right"
          >
            {commonLabels.viewAll}
          </Button>
        </Label>
        <Table
          className="displayTable"
          singleLine
          compact
          striped
          sortable
          celled
          textAlign="center"
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Sample ID</Table.HeaderCell>
              <Table.HeaderCell>Batch ID</Table.HeaderCell>
              <Table.HeaderCell>Timestamp</Table.HeaderCell>
              <Table.HeaderCell>Sample Status</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {isFetchingSampleQueueData ? (
            <Dimmer active inverted>
              <Loader size="mini">{commonLabels.fetching}</Loader>
            </Dimmer>
          ) : (
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  {sampleQueueListDetailsData &&
                  sampleQueueListDetailsData.length > 0
                    ? sampleQueueListDetailsData[0].sampleId
                    : ""}
                </Table.Cell>
                <Table.Cell>
                  {sampleQueueListDetailsData &&
                  sampleQueueListDetailsData.length > 0
                    ? sampleQueueListDetailsData[0].batchId
                    : ""}
                </Table.Cell>
                <Table.Cell>
                  {sampleQueueListDetailsData &&
                  sampleQueueListDetailsData.length > 0
                    ? sampleQueueListDetailsData[0].deviceTimeStamp
                    : ""}
                </Table.Cell>
                <Table.Cell>
                  {sampleQueueListDetailsData &&
                  sampleQueueListDetailsData.length > 0
                    ? sampleQueueListDetailsData[0].sampleStatus
                    : ""}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  {sampleQueueListDetailsData &&
                  sampleQueueListDetailsData.length > 1
                    ? sampleQueueListDetailsData[1].sampleId
                    : ""}
                </Table.Cell>
                <Table.Cell>
                  {sampleQueueListDetailsData &&
                  sampleQueueListDetailsData.length > 1
                    ? sampleQueueListDetailsData[1].batchId
                    : ""}
                </Table.Cell>
                <Table.Cell>
                  {sampleQueueListDetailsData &&
                  sampleQueueListDetailsData.length > 1
                    ? sampleQueueListDetailsData[1].deviceTimeStamp
                    : ""}
                </Table.Cell>
                <Table.Cell>
                  {sampleQueueListDetailsData &&
                  sampleQueueListDetailsData.length > 1
                    ? sampleQueueListDetailsData[1].sampleStatus
                    : ""}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  {sampleQueueListDetailsData &&
                  sampleQueueListDetailsData.length > 2
                    ? sampleQueueListDetailsData[2].sampleId
                    : ""}
                </Table.Cell>
                <Table.Cell>
                  {sampleQueueListDetailsData &&
                  sampleQueueListDetailsData.length > 2
                    ? sampleQueueListDetailsData[2].batchId
                    : ""}
                </Table.Cell>
                <Table.Cell>
                  {sampleQueueListDetailsData &&
                  sampleQueueListDetailsData.length > 2
                    ? sampleQueueListDetailsData[2].deviceTimeStamp
                    : ""}
                </Table.Cell>
                <Table.Cell>
                  {sampleQueueListDetailsData &&
                  sampleQueueListDetailsData.length > 2
                    ? sampleQueueListDetailsData[2].sampleStatus
                    : ""}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  {sampleQueueListDetailsData &&
                  sampleQueueListDetailsData.length > 3
                    ? sampleQueueListDetailsData[3].sampleId
                    : ""}
                </Table.Cell>
                <Table.Cell>
                  {sampleQueueListDetailsData &&
                  sampleQueueListDetailsData.length > 3
                    ? sampleQueueListDetailsData[3].batchId
                    : ""}
                </Table.Cell>
                <Table.Cell>
                  {sampleQueueListDetailsData &&
                  sampleQueueListDetailsData.length > 3
                    ? sampleQueueListDetailsData[3].deviceTimeStamp
                    : ""}
                </Table.Cell>
                <Table.Cell>
                  {sampleQueueListDetailsData &&
                  sampleQueueListDetailsData.length > 3
                    ? sampleQueueListDetailsData[3].sampleStatus
                    : ""}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  {sampleQueueListDetailsData &&
                  sampleQueueListDetailsData.length > 4
                    ? sampleQueueListDetailsData[4].sampleId
                    : ""}
                </Table.Cell>
                <Table.Cell>
                  {sampleQueueListDetailsData &&
                  sampleQueueListDetailsData.length > 4
                    ? sampleQueueListDetailsData[4].batchId
                    : ""}
                </Table.Cell>
                <Table.Cell>
                  {sampleQueueListDetailsData &&
                  sampleQueueListDetailsData.length > 4
                    ? sampleQueueListDetailsData[4].deviceTimeStamp
                    : ""}
                </Table.Cell>
                <Table.Cell>
                  {sampleQueueListDetailsData &&
                  sampleQueueListDetailsData.length > 4
                    ? sampleQueueListDetailsData[4].sampleStatus
                    : ""}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          )}
        </Table>
      </Segment>
    </>
  );
};

export default QueueList;
