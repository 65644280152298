import { connect } from "react-redux";
import { compose, withHandlers, lifecycle, withState } from "recompose";
import { reduxForm, reset } from "redux-form";
import {
  fecthDataExpressionForReview,
  routeToReviewPage,
  fetchRulesExpressionForUpdate,
  // resetUpdateFormValues,
  resetRulesPage,
  fetchParamtersList,
} from "../../../../redux/reducers/RulesListReducer";
import { rulesListPagePath } from "./../../../../utilities/pathHelpers";
import { withRouter } from "react-router";

/**
 *
 * @description {fetch values from store}
 */
export const mapStateToProps = (state) => ({
  routePageStatus: state.rulesListreducer.routePageStatus,
  IsExpressionValidated: state.rulesListreducer.IsExpressionValidated,
  isFetching: state.rulesListreducer.isFetchingRules,
  initialValues: {
    ruleName: state.rulesListreducer.fetchExpressionForUpdateList.ruleName
      ? state.rulesListreducer.fetchExpressionForUpdateList.ruleName
      : "",
    description: state.rulesListreducer.fetchExpressionForUpdateList.description
      ? state.rulesListreducer.fetchExpressionForUpdateList.description
      : "",
    severity: state.rulesListreducer.fetchExpressionForUpdateList.severity
      ? state.rulesListreducer.fetchExpressionForUpdateList.severity
      : "",
    ruleExpression: state.rulesListreducer.fetchExpressionForUpdateList
      .ruleExpression
      ? state.rulesListreducer.fetchExpressionForUpdateList.ruleExpression
      : "",
    models: state.rulesListreducer.fetchExpressionForUpdateList.models
      ? state.rulesListreducer.fetchExpressionForUpdateList.models.map(
        (item) => ({
          label: item,
          value: item,
        })
      )
      : "",
    alarmTriggerCount:
      state.rulesListreducer.fetchExpressionForUpdateList.alarmTriggerCount > 0
        ? state.rulesListreducer.fetchExpressionForUpdateList.alarmTriggerCount
        : "",
    alarmTriggerWindowPeriod:
      state.rulesListreducer.fetchExpressionForUpdateList
        .alarmTriggerWindowPeriod > 0
        ? state.rulesListreducer.fetchExpressionForUpdateList
          .alarmTriggerWindowPeriod / 60
        : "",
    timeFormat: state.rulesListreducer.fetchExpressionForUpdateList
      .alarmTriggerWindowPeriod
      ? state.rulesListreducer.fetchExpressionForUpdateList
        .alarmTriggerWindowPeriod > 0
        ? "Minutes"
        : ""
      : "",
    deleted: state.rulesListreducer.fetchExpressionForUpdateList.isDeleted
      ? state.rulesListreducer.fetchExpressionForUpdateList.isDeleted ===
        "false"
        ? true
        : false
      : "",
  },
});

/**
 *
 * @description {submit values to redux for review page}
 */
export const onSubmit = ({
  fecthDataExpressionForReview,
  routeToReviewPage,
}) => async (values) => {
  fecthDataExpressionForReview({ values });
  routeToReviewPage();
};

/**
 *
 * @description {onpage load call API}
 */
export function componentDidMount() {
  if (this.props.match.params.update === "update") {
    this.props
      .fetchRulesExpressionForUpdate(this.props.match.params.id)
      .then(() => {
        this.props.fetchParamtersList(this.props.initialValues.models);
      });
  }
}

/**
 *
 * @description {back to list page}
 */
export const backToListPage = ({ history }) => () => {
  history.push(rulesListPagePath);
};

/**
 *
 * @description {remove/unmount component values}
 */
export function componentWillUnmount() {
  this.props.resetRulesPage();
}

/**
 *
 * @description {get props on change of states in store}
 */
export function componentWillReceiveProps(prevProps) {
  if (
    this.props.match.params.id !== prevProps.match.params.id ||
    this.props.location.key !== prevProps.location.key
  ) {
    this.props.resetRulesPage();
    this.props.reset("DeviceExpressionRootForm");
    // this.setState({ unique_key: this.props.location.key });
    this.props.setFormState(this.props.location.key);
  }
}

/**
 *
 * @description {clear form data}
 */
export const setFormState = ({ setUniqueKey }) => (key) => {
  setUniqueKey(key);
};

/**
 *
 * @description {redux validation for time occurs field}
 */
export const validate = (values) => {
  const errors = {};
  if (values.alarmTriggerCount) {
    if (!values.alarmTriggerWindowPeriod) {
      errors.alarmTriggerWindowPeriod = "Required";
    }
    if (values.alarmTriggerWindowPeriod) {
      if (!values.timeFormat) {
        errors.timeFormat = "Required";
      }
    }
    if (
      values.alarmTriggerWindowPeriod >= 8785 &&
      values.timeFormat === "Hour"
    ) {
      errors.alarmTriggerWindowPeriod = "Entered hours cannot exceed 8784";
    }

    if (
      values.alarmTriggerWindowPeriod >= 527041 &&
      values.timeFormat === "Minutes"
    ) {
      errors.alarmTriggerWindowPeriod = "Entered minutes cannot exceed 527040";
    }
  }
  if (values.models) {
    if (!values.models.toString()) {
      errors.models = "Required";
    }
  }
  return errors;
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    fecthDataExpressionForReview,
    routeToReviewPage,
    fetchRulesExpressionForUpdate,
    resetRulesPage,
    reset,
    fetchParamtersList,
  }),
  withState("unique_key", "setUniqueKey", null),
  withHandlers({ setFormState }),
  withHandlers({ onSubmit, backToListPage }),
  lifecycle({
    componentDidMount,
    componentWillUnmount,
    componentWillReceiveProps,
  }),
  reduxForm({
    form: "DeviceExpressionRootForm",
    enableReinitialize: true,
    validate,
  })
);
