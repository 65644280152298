import types from "../actions/actionTypes";
import { createThunk } from "../../utilities/createThunk";
import backendAPI from "../../apis";
import { combineReducers } from "redux";

const initialState = {
  loginDetails: {},
  changePasswordDetails: {},
  forgotPasswordDetails: {},
  newPasswordDetails: {},
};

export const fetchLoginDetails = (email, password, loginProps) => {
  createThunk({
    requestActionType: types.FETCH_LOGIN_DETAILS_REQUEST,
    apiCall: backendAPI.loginAPI.signin(email, password, loginProps),
  });
};

export const fetchChangePasswordDetails = (
  email,
  oldpassword,
  newpassword,
  changeprops
) => {
  createThunk({
    requestActionType: types.FETCH_CHANGE_PASSWORD_DETAILS_REQUEST,
    apiCall: backendAPI.loginAPI.changepassword(
      email,
      oldpassword,
      newpassword,
      changeprops
    ),
  });
};

export const fetchForgotPasswordDetails = (email, loginProps) => {
  createThunk({
    requestActionType: types.FETCH_FORGOT_PASSWORD_DETAILS_REQUEST,
    apiCall: backendAPI.loginAPI.forgotPassword(email, loginProps),
  });
};

export const fetchNewPasswordDetails = (
  verificationCode,
  newpassword,
  changeprops
) => {
  createThunk({
    requestActionType: types.FETCH_NEW_PASSWORD_DETAILS_REQUEST,
    apiCall: backendAPI.loginAPI.newPassword(
      verificationCode,
      newpassword,
      changeprops
    ),
  });
};

export const LoginReducer = {
  fetchLoginDetails,
  fetchChangePasswordDetails,
};

const loginDetailsdata = (
  state = initialState.loginDetails,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_LOGIN_DETAILS_REQUEST_SUCCESS:
      return payload;
    case "DEFAULT10":
      return payload;
    case "DEFAULT11":
      return payload;
    default:
      return state;
  }
};

const changePasswordDetailsdata = (
  state = initialState.changePasswordDetails,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_CHANGE_PASSWORD_DETAILS_REQUEST_SUCCESS:
      return payload;
    case "DEFAULT12":
      return payload;
    case "DEFAULT13":
      return payload;
    default:
      return state;
  }
};

const forgotPasswordDetailsdata = (
  state = initialState.forgotPasswordDetails,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_FORGOT_PASSWORD_DETAILS_REQUEST_SUCCESS:
      return payload;
    case "DEFAULT100":
      return payload;
    case "DEFAULT110":
      return payload;
    default:
      return state;
  }
};

const newPasswordDetailsdata = (
  state = initialState.newPasswordDetails,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_NEW_PASSWORD_DETAILS_REQUEST_SUCCESS:
      return payload;
    case "DEFAULT101":
      return payload;
    case "DEFAULT112":
      return payload;
    default:
      return state;
  }
};

export default combineReducers({
  loginDetailsdata,
  changePasswordDetailsdata,
  forgotPasswordDetailsdata,
  newPasswordDetailsdata,
});
