import React from "react";

const TextArea = props => (
  <div className="field">
    <textarea
      name={props.input.name}
      rows={props.rows}
      cols={props.cols}
      value={props.content}
      //onChange={props.controlFunc}
      placeholder={props.placeholder}
      isDisabled={props.disabled ? true : false}
      className={props.className}
    ></textarea>
    {props.meta.submitFailed &&
      (props.content.length === 0 || props.content === "") &&
      // props.meta.error &&
      props.disabled === false && (
        <div className="ui red pointing basic label">Required</div>
      )}
  </div>
);

export default TextArea;
