import React from "react";
import { Field } from "redux-form";
import { Form, Grid, Button } from "semantic-ui-react";
import { InputField, TextAreaField } from "react-semantic-redux-form";
import {
  required,
  OnlyNumberHexaAllowed,
  limitCharater,
} from "./../../../../../utilities/validations";
import { ModalHeader } from "../ModalHeader";
import styles from "./../AddDeviceCodeModal.module.scss";
import { Dimmer, Loader } from "semantic-ui-react";
import enhance from "./enhance";
import { commonLabels, Alerts } from "../../../../../redux/constants/Values";

export const AddDeviceCodeModal = (props) => {
  const { handleSubmit, closeModal, isFetching } = props;

  return (
    <div>
      {isFetching && (
        <Dimmer active inverted>
          <Loader size="large" inverted>
            {commonLabels.loading}
          </Loader>
        </Dimmer>
      )}
      <ModalHeader />
      <div className={styles.formContainer}>
        <Form onSubmit={handleSubmit}>
          <Form.Field>
            <label>Device Code</label>
            <Field
              name="deviceCode"
              component={InputField}
              placeholder="Device Code"
              validate={[required]}
              normalize={OnlyNumberHexaAllowed}
            />
            <div className={styles.noteText}>{Alerts.deviceCodeName}</div>
          </Form.Field>
          <Form.Field>
            <label>Description</label>
            <Field
              name="description"
              component={TextAreaField}
              placeholder="Description"
              validate={[required]}
              normalize={limitCharater}
            />
          </Form.Field>

          <Grid>
            <Grid.Column textAlign="center">
              <Button primary type="submit">
                {commonLabels.add}
              </Button>
              <Button primary onClick={closeModal}>
                {commonLabels.cancel}
              </Button>
            </Grid.Column>
          </Grid>
        </Form>
      </div>
    </div>
  );
};

export default enhance(AddDeviceCodeModal);
