import React from "react";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import "react-table/react-table.css";
import enhance from "./enhance";
import { Alerts } from "../../../../redux/constants/Values";

export const TableData = (props) => {
  const { getMnemonicsData } = props;
  /* istanbul ignore next */
  return (
    <div>
      <ReactTable
        data={props.data}
        getTheadFilterThProps={(e) => {
          let filtredData = e.sortedData.map((eachVal) => {
            return eachVal._original;
          });
          getMnemonicsData(filtredData.length > 0 ? filtredData : []);
          return {
            style: { position: "inherit", overflow: "inherit" },
          };
        }}
        filterable
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id]) === filter.value
        }
        columns={[
          {
            Header: "Sample ID",
            id: "SID",
            accessor: (d) =>
              d.sampleId && d.sampleId.match(/^[0-9]+$/) !== null
                ? parseInt(d.sampleId)
                : d.sampleId,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["SID"] }),
            filterAll: true,
          },
          {
            Header: "Batch ID",
            id: "BID",
            accessor: (d) =>
              d.batchId && d.batchId.match(/^[0-9]+$/) !== null
                ? parseInt(d.batchId)
                : d.batchId,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["BID"] }),
            filterAll: true,
          },
          {
            Header: "Timestamp",
            id: "SST",
            accessor: (d) => d.deviceTimeStamp,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["SST"] }),
            filterAll: true,
          },
          {
            Header: "Sample Status",
            id: "SCS",
            accessor: (d) => d.sampleStatus,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["SCS"] }),
            filterAll: true,
          },
        ]}
        defaultPageSize={10}
        minRows={3}
        noDataText={Alerts.noRows}
        className="-striped -highlight"
      />
    </div>
  );
};
export default enhance(TableData);
