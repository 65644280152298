import React, { useState } from "react";
import enhance from "./enhance";
import { Form, Button, Input, Select } from "semantic-ui-react";
import { commonLabels } from "../../../redux/constants/Values";

const OperandSelectOptions = [
  { key: 2, text: "=", value: "=" },
  { key: 3, text: "<", value: "<" },
  { key: 4, text: ">", value: ">" },
  { key: 5, text: "<=", value: "<=" },
  { key: 6, text: ">=", value: ">=" },
  { key: 7, text: "!=", value: "!=" },
];

export const AdvanceSearch = (props) => {
  const {
    modelsListsdata,
    deviceParameters,
    handleSubmit,
    handleChange,
    handleReset,
    AssetForm,
  } = props;
  const [open, togglePanel] = useState(false);

  const model_params =
    modelsListsdata.length !== 0
      ? modelsListsdata.map((item) => ({
          key: item.attributes.modelName,
          value: item.attributes.modelName,
          text: item.attributes.modelName,
        }))
      : [{ key: "Loading...", value: "", text: "Loading..." }];
  const device_params =
    deviceParameters.length !== 0
      ? deviceParameters.map((item) => ({
          key: item.displayName + " (" + item.mnemonic + ")",
          value: item.mnemonic,
          text: item.displayName + " (" + item.mnemonic + ")",
        }))
      : [{ key: "Loading...", value: "", text: "Loading..." }];

  return (
    <div>
      <div onClick={() => togglePanel(!open)} className="toggleHeader">
        <i
          className={open ? "caret square up icon" : "caret square down icon"}
        ></i>
        {commonLabels.advanceSearch}
      </div>
      {open ? (
        <div className="toggleContent">
          {" "}
          <Form>
            <Form.Group widths="equal">
              <Form.Field
                control={Input}
                fluid
                label="Asset"
                placeholder="Asset"
                name="assetName"
                value={AssetForm.assetName}
                onChange={(e,data) => handleChange(e,data)}
              />
              <Form.Field
                control={Select}
                fluid
                label="Model"
                options={model_params}
                placeholder="Model"
                name="Model"
                value={AssetForm.Model}
                onChange={(e,data) => handleChange(e,data)}
                search
                clearable
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                control={Select}
                fluid
                label="Data Item"
                options={device_params}
                placeholder="Data Item"
                name="DataItem"
                value={AssetForm.DataItem}
                onChange={(e,data) => handleChange(e,data)}
                search
                clearable
              />
              <Form.Field
                fluid
                control={Select}
                label="Condition"
                options={OperandSelectOptions}
                placeholder="<="
                name="DataItemConditions"
                value={AssetForm.DataItemConditions}
                onChange={(e,data) => handleChange(e,data)}
                search
                clearable
              />

              <Form.Field
                control={Input}
                fluid
                label="Value"
                placeholder="Value"
                name="DataItemValue"
                value={AssetForm.DataItemValue}
                onChange={(e,data) => handleChange(e,data)}
              />
            </Form.Group>
            <Button.Group>
              <Button positive onClick={()=>handleSubmit()}>
                {commonLabels.search}
              </Button>
              <Button.Or />
              <Button negative  onClick={()=>handleReset()}>
                {commonLabels.clear}
              </Button>
            </Button.Group>
          </Form>
        </div>
      ) : null}
    </div>
  );
};

export default enhance(AdvanceSearch);
