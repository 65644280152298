import { connect } from 'react-redux';
import { compose, lifecycle, withHandlers } from 'recompose';
import {
  fetchAlarmsReportLists,
  fetchDeviceParameters,
} from '../../../redux/reducers/AlarmsReportListReducer';
import { fetchModelsLists } from '../../../redux/reducers/ModelsListReducer';
import { exportCSVFile } from './../../../apis/alarmsReportListAPI/normalizers';
/**
 *
 * @description {fetch values from store}
 */
export const mapStateToProps = (state) => ({
  alarmsReportListsdata:
    state.alarmsReportListReducer.alarmsReportListsdata.eventsData,
  filteredData: state.assetListReducer.AssetFiltered,
  isFetchingAlarmsReport: state.alarmsReportListReducer.isFetchingAlarmsReport,
  modelsListsdata: state.ModelsListReducer.modelsListsdata.modelsList
    ? state.ModelsListReducer.modelsListsdata.modelsList
    : [],
  deviceParameters: state.assetListReducer.assetsDeviceParameters
    ? state.assetListReducer.assetsDeviceParameters
    : [],
});

/**
 *
 * @description {remove/unmount component values}
 */
export function componentDidMount() {
  this.props.fetchModelsLists();
  this.props.fetchDeviceParameters('onload');
}

/**
 *
 * @description {export AlarmsReport data}
 */
/* istanbul ignore next */
export const btnHandler = ({ filteredData }) => {
  exportCSVFile(filteredData);
};

export default compose(
  connect(mapStateToProps, {
    fetchAlarmsReportLists,
    fetchModelsLists,
    fetchDeviceParameters,
  }),
  withHandlers({ btnHandler }),
  lifecycle({ componentDidMount })
);
