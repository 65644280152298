import { connect } from 'react-redux';
import { compose, withHandlers, withState } from 'recompose';
import { reduxForm } from 'redux-form';
import {
  fetchAlarmsByAdvanceSearch,
  fetchDeviceParameters,
  fetchAlarmsReportLists,
} from '../../../redux/reducers/AlarmsReportListReducer';
let AlarmFormInitial = {
  assetName: '',
  Model: '',
  DataItem: '',
  AlarmSeverity: '',
  AlarmDescription: '',
  startDate: '',
  startTime: '',
  endDate: '',
  endTime: '',
};

/**
 *
 * @description {submit search values}
 */
export const handleSubmit = ({
  AlarmForm,
  fetchAlarmsByAdvanceSearch,
}) => () => {
  fetchAlarmsByAdvanceSearch(AlarmForm);
};

/**
 *
 * @description {update onchange search values}
 */

export const handleChange = ({ AlarmForm, SetAlarmForm }) => (e, data) => {
  e.preventDefault();
  if (data === undefined) {
    SetAlarmForm({
      ...AlarmForm,
      [e.target.name]: e.target.value,
    });
  } else {
    SetAlarmForm({
      ...AlarmForm,
      [data.name]: data.value,
    });
  }
};

/**
 *
 * @description {reset form values and data of asset page}
 */

export const handleReset = ({ SetAlarmForm, fetchAlarmsReportLists }) => () => {
  SetAlarmForm(AlarmFormInitial);
  window.location.reload();
  // fetchAlarmsReportLists(AlarmFormInitial);
};

export default compose(
  connect(null, {
    fetchAlarmsByAdvanceSearch,
    fetchAlarmsReportLists,
    fetchDeviceParameters,
  }),
  withState('AlarmForm', 'SetAlarmForm', AlarmFormInitial),
  withHandlers({ handleSubmit, handleChange, handleReset }),
  reduxForm({
    form: 'AlarmsByAdvanceSearch',
    enableReinitialize: true,
  })
);
