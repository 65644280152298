import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import { reduxForm, reset } from "redux-form";
import {
  fetchReportsByAdvanceSearch,
  fetchReportsLists,
} from "../../../redux/reducers/ReportsListReducer";
import moment from "moment/min/moment-with-locales";
import { Alerts, pageSize } from "../../../redux/constants/Values";

/**
 *
 * @description {submission of values}
 */
export const onSubmit = ({ fetchReportsByAdvanceSearch }) => async (values) => {
  let Todaydate = moment(new Date().setHours(23, 59, 0, 0))
    .add(1, "days")
    .utc()
    .format();
  if (Object.keys(values).length !== 0) {
    if (
      values.hasOwnProperty("startDate") &&
      values.hasOwnProperty("endDate")
    ) {
      let obj = {
        startDate: moment(values.startDate)
          .add(1, "days")
          .hours(-18)
          .minutes(-30)
          .seconds(1)
          .utc()
          .format(),
        endDate: moment(values.endDate)
          .add(1, "days")
          .hours(5)
          .minutes(29)
          .seconds(59)
          .utc()
          .format(),
        pageSize: pageSize.SIZE,
        offset: 1,
      };
      if (obj.startDate <= Todaydate && obj.endDate <= Todaydate) {
        if (obj.startDate <= obj.endDate) {
          fetchReportsByAdvanceSearch(obj);
        } else {
          window.alert(Alerts.dateRange);
        }
      } else {
        window.alert(Alerts.validDate);
      }
    } else {
      window.alert(Alerts.invalidDate);
    }
  } else {
    window.alert(Alerts.invalidDate);
  }
};

/**
 *
 * @description {reset form values}
 */
export const resetAdvSearch = ({ fetchReportsLists }) => (props) => {
  fetchReportsLists();
  props.reset("ReportsAdvanceSearchForm");
};

export default compose(
  connect(null, {
    fetchReportsByAdvanceSearch,
    fetchReportsLists,
  }),
  withHandlers({ onSubmit, resetAdvSearch, reset }),
  reduxForm({
    form: "ReportsAdvanceSearchForm",
    enableReinitialize: true,
  })
);
