import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import { fetchContentsLists } from "../../../redux/reducers/ContentsListReducer";

/**
*
* @description {fetch values from store}
*/
export const mapStateToProps = (state) => ({
  contentsListsdata: state.ContentsListReducer.contentsListsdata.jobDetailsList
    ? state.ContentsListReducer.contentsListsdata.jobDetailsList
    : [],
  isFetchingContents: state.ContentsListReducer.isFetchingContents,
});

/**
 *
 * @description {onpage load call API}
 */
export function componentDidMount() {
  this.props.fetchContentsLists();
}

export default compose(
  connect(mapStateToProps, { fetchContentsLists }),
  lifecycle({ componentDidMount })
);
