import React from "react";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import { Dimmer, Loader } from "semantic-ui-react";
import styles from "./RulesTableData.module.scss";
import { Link } from "react-router-dom";
import enhance from "./enhance";
import { commonLabels, Alerts } from "../../../redux/constants/Values";
import { fetchRulesFiltered } from "../../../redux/reducers/RulesListReducer";

export const RulesTableData = (props) => {
  const { rulesListsdata, handleEnable, isFetching } = props;
  /* istanbul ignore next */
  return isFetching ? (
    <Dimmer active inverted>
      <Loader size="large" inverted>
        {commonLabels.loading}
      </Loader>
    </Dimmer>
  ) : (
      <div>
        <ReactTable
          data-test="react-table"
          data={rulesListsdata}
          getTheadFilterThProps={(e) => {
            let filtredData = e.sortedData.map((eachVal) => {
              return eachVal._original;
            });
            props.dispatch(
              fetchRulesFiltered(filtredData.length > 0 ? filtredData : [])
            );
            return {
              style: { position: "inherit", overflow: "inherit" },
            };
          }}
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          columns={[
            {
              Header: "Name",
              id: "name",
              accessor: (d) => d.ruleName,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["name"] }),
              filterAll: true,
              Cell: (row) => {
                return sessionStorage
                  .getItem("roles")
                  .split(",")
                  .indexOf("adminGroup") > -1 ? (
                    <div>
                      <Link
                        to={`/update-rule/update/${row.original.ruleName}/${row.original.ruleType}/${row.original.isDeleted}`}
                      >
                        {row.original.ruleName}
                      </Link>
                    </div>
                  ) : (
                    <div>{row.original.ruleName}</div>
                  );
              },
            },
            {
              Header: "Type",
              id: "type",
              accessor: (d) => d.ruleType,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["type"] }),
              filterAll: true,
            },
            {
              Header: "Description",
              id: "description",
              accessor: (d) => d.description,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["description"] }),
              filterAll: true,
            },
            {
              Header: "Rules",
              id: "rules",
              accessor: (d) =>
                d.ruleExpression
                  ? d.ruleExpression
                  : d.dependentRules
                    ? d.dependentRules.toString()
                    : null,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["rules"] }),
              filterAll: true,
            },
            {
              Header: "Model",
              id: "models",
              accessor: (d) => (d.models ? d.models.toString() : null),
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["models"] }),
              filterAll: true,
            },
            {
              Header: "Severity",
              id: "severity",
              accessor: (d) => (d.severity ? parseInt(d.severity) : null),
              sortMethod: (a, b) => Number(a) - Number(b),
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["severity"] }),
              filterAll: true,
            },
            {
              Header: "Enable",
              id: "deleted",
              accessor: (d) =>
                d.isDeleted ? (d.isDeleted.toString() === "false" ? "Enable" : "Disable") : null,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["deleted"] }),
              filterAll: true,
              Filter: ({ filter, onChange }) => (
                <select
                  className={styles.consecSelectWidth}
                  onChange={(event) => onChange(event.target.value)}
                  value={filter ? filter.value : ""}
                  defaultValue={filter ? filter.value : ""}
                >
                  <option value="">All</option>
                  <option value="Enable">Enable</option>
                  <option value="Disable">Disable</option>
                </select>
              ),
              Cell: (props) => handleEnable(props.value),
            },
          ]}
          defaultPageSize={10}
          minRows={3}
          noDataText={Alerts.noRows}
          className="-striped -highlight"
        />
      </div>
    );
};
export default enhance(RulesTableData);
