import React from "react";
import { Card } from "semantic-ui-react";
import { Field } from "redux-form";
import RenderSelectInput from "../../ReactMultiSelectCheckboxes";
import { required } from "./../../../../utilities/validations";
import styles from "./DeviceCodeModelsForRuleCard.module.scss";
import enhance from "./enhance";
import { CreateLabels } from "../../../../redux/constants/Values";

export const DeviceCodeModelsForRuleCard = (props) => {
  const { modelsListsdata, onChange } = props;
  let options = modelsListsdata.map((item) => ({
    label: item.attributes.modelName,
    value: item.attributes.modelName,
  }));
  return (
    <div className="cards modelCardsDeviceCode">
      <Card fluid className={styles.deviceCustomWidthFix}>
        <Card.Content
          header={CreateLabels.modelsForRules}
          className="cardHeader"
        />
        <Card.Content extra className={styles.customHeightFix}>
          <Field
            component={RenderSelectInput}
            name="models"
            label="Choose One"
            options={options}
            placeholder="Choose One"
            className="multiCheckboxFix"
            onChange={onChange}
            validate={[required]}
          />
        </Card.Content>
      </Card>
    </div>
  );
};
export default enhance(DeviceCodeModelsForRuleCard);
