import React from "react";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import { Link } from "react-router-dom";
import enhance from "./enhance";
import { Dimmer, Loader } from "semantic-ui-react";
import { Alerts,commonLabels } from "../../../redux/constants/Values";

export const ModelsTableData = (props) => {
  const { modelsListsdata, deleteModel,isFetching } = props;
  /* istanbul ignore next */
  const roles = sessionStorage.getItem("roles").split(",");
  /* istanbul ignore next */
  const isAdmin = roles.indexOf("adminGroup") > -1 ? true : false;
  /* istanbul ignore next */
  return (
    <>
    {isFetching && (
        <Dimmer active inverted>
          <Loader size="large" inverted>
            {commonLabels.loading}
          </Loader>
        </Dimmer>
    )}
    <div>
      <ReactTable
        data={modelsListsdata.modelsList}
        filterable
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id]) === filter.value
        }
        columns={[
          {
            Header: "Name",
            id: "name",
            accessor: (d) => d.attributes.modelName,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["name"] }),
            filterAll: true,
            Cell: (row) => {
              return sessionStorage
                .getItem("roles")
                .split(",")
                .indexOf("adminGroup") > -1 ? (
                <div>
                  <Link
                    to={`/update-model/update/${row.original.attributes.modelName}`}
                  >
                    {row.original.attributes.modelName}
                  </Link>
                </div>
              ) : (
                <div>{row.original.attributes.modelName}</div>
              );
            },
          },
          {
            Header: "Description",
            id: "description",
            accessor: (d) => d.attributes.Description,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["description"] }),
            filterAll: true,
          },
          {
            Header: "Action",
            id: "DeleteModel",
            show: isAdmin ? true : false,
            filterable: false,
            width: 100,
            Cell: (row) => {
              return(
                <div>
                  <button
            style={{ fontWeight: '300', fontSize: 'small',}}
            onClick={(e) => {
              deleteModel(row.original.attributes.modelName);
            }}
            >
            {commonLabels.delete}
          </button>
                  </div>
              );
            },

          },
        ]}
        defaultPageSize={10}
        minRows={3}
        noDataText={Alerts.noRows}
        className="-striped -highlight"
      />
    </div>
    </>
  );
};
export default enhance(ModelsTableData);
