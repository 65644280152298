import React from 'react';
import PropTypes from 'prop-types';
import 'bootstrap';
import AdvanceSearch from '../AdvanceSearch';
import AlarmsReportTableData from '../AlarmsTableData';
import { Dimmer, Loader, Divider } from 'semantic-ui-react';
import enhance from './enhance';
import { NavItems, commonLabels } from '../../../redux/constants/Values';

export const AlarmsReport = (props) => {
  const {
    btnHandler,
    alarmsReportListsdata,
    modelsListsdata,
    isFetchingAlarmsReport,
    deviceParameters,
  } = props;
  return (
    <div className='containerStyle'>
      <h4>
        {NavItems.alarmsReport + ' ' + commonLabels.list}
        <button className='exportButton' onClick={btnHandler}>
          {commonLabels.exportToCSV}
        </button>
      </h4>
      <Divider />
      <AdvanceSearch
        modelsListsdata={modelsListsdata}
        deviceParameters={deviceParameters}
      />
      <Divider />
      {isFetchingAlarmsReport ? (
        <Dimmer active inverted>
          <Loader size='large' inverted>
            {commonLabels.loading}
          </Loader>
        </Dimmer>
      ) : (
        <AlarmsReportTableData alarmsReportListsdata={alarmsReportListsdata} />
      )}
    </div>
  );
};

AlarmsReport.propTypes = {
  alarmsReportListsdata: PropTypes.array,
};

export default enhance(AlarmsReport);
