import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import { reduxForm } from "redux-form";
import { fecthOnBoardingListByAdvanceSearch } from "../../../redux/reducers/OnBoardingReducer";

 /**
 *
 * @description {submitting onboarding advance search values}
 */

export const onSubmit = ({
  fecthOnBoardingListByAdvanceSearch
}) => async values => {
  fecthOnBoardingListByAdvanceSearch(values);
};

export default compose(
  connect(null, { fecthOnBoardingListByAdvanceSearch }),
  withHandlers({ onSubmit }),
  reduxForm({
    form: "OnBoardingStatusAdvanceSearchForm",
    enableReinitialize: true
  })
);
