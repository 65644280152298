import React from "react";
import { Header } from "semantic-ui-react";
import { CreateLabels } from "../../../../../redux/constants/Values";

export const ModalHeader = () => (
  <div className="modalContainer">
    <Header as="h3" block className="cardHeader">
      {CreateLabels.addDeviceCode}
    </Header>
  </div>
);

export default ModalHeader;
