import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";

import { reduxForm, reset } from "redux-form";

import {
  addDeviceCodeModalDetails,
  fetchDeviceCodeList,
} from "../../../../../redux/reducers/RulesListReducer";

/**
 *
 * @description {fetch values from store}
 */
export const mapStateToProps = (state) => ({
  isFetching: state.rulesListreducer.isFetchingRules,
});

/**
 *
 * @description {clear form after the submit}
 */
export const afterSubmit = (result, dispatch) =>
  dispatch(reset("DeviceCodeRootForm"));

/**
 *
 * @description {submit the values}
 */
export const onSubmit = ({ addDeviceCodeModalDetails, afterRequest }) => async (
  values
) => {
  addDeviceCodeModalDetails(values).then(afterRequest);
};

/**
 *
 * @description {after the response of submit values}
 */
export const afterRequest = ({ closeModal, fetchDeviceCodeList }) => ({
  payload,
}) => {
  if (!(payload instanceof Error)) {
    window.alert(payload.message);
    closeModal();
    fetchDeviceCodeList();
  } else {
    window.alert(payload.message);
  }
};

export default compose(
  connect(mapStateToProps, { addDeviceCodeModalDetails, fetchDeviceCodeList }),
  withHandlers({ afterRequest }),
  withHandlers({ onSubmit }),

  reduxForm({
    form: "AddDeviceCodeModalForm",
    enableReinitialize: true,
    onSubmitSuccess: afterSubmit,
  })
);
