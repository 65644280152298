import React from 'react';
import {
  Grid,
  Container,
  Divider,
  Dimmer,
  Loader,
  Button,
} from 'semantic-ui-react';
import enhance from './enhance';
import { commonLabels, Grids } from '../../../../redux/constants/Values';

//Files
import Alarms from '../Alarms';
import AssetDetails from '../AssetDetails';
import LCData from '../LCData';
import ErrorData from '../ErrorData';
import MassSpecData from '../MassSpecData';
import QueueList from '../QueueList';
import Rules from '../Rules';
import WorkStationData from '../WorkstationData';
import TableData from '../DetailsTableData';
import HistoricalDataitems from '../HistoricalDataitems';

export const DeviceDetail = (props) => {
  const {
    assetDetailsdata,
    alarmDetailsdata,
    rulesDetailsdata,
    workstationDetailsData,
    massSpecDetailsData,
    lcDataDetailsData,
    errorDataDetailsData,
    isFetchingAssets,
    isFetchingAlarmsData,
    isFetchingRulesData,
    isFetchingWorkstationData,
    isFetchingMassspecData,
    isFetchingLCData,
    isFetchingErrorData,
    isFetchingSampleQueueData,
    sampleQueueListDetailsData,
    isDetailsPageButtonIsClicked,
    isHistoricalDataitemsPageIsClicked,
    deviceParameters,
    detailsPageStatus,
    handleSubmit,
  } = props;
  return isFetchingAssets ? (
    <Dimmer active inverted>
      <Loader size='large' inverted>
        {commonLabels.loading}
      </Loader>
    </Dimmer>
  ) : (
    <div>
      {detailsPageStatus === 'VIEW_LIST_PAGE' && (
        <div className='containerStyle'>
          <h4 className='alignCenter bgBlue historic'>
            {' '}
            {commonLabels.asset} {props.match.params.id}
            <Button
              primary
              onClick={isHistoricalDataitemsPageIsClicked.bind(
                this,
                'historic'
              )}
              floated='right'
            >
              {Grids.historicalDataitems}
            </Button>
            <Button primary floated='right' onClick={handleSubmit}>
              {commonLabels.fetchlatestdata}
            </Button>
          </h4>
          <Divider />
          <Container fluid>
            <Grid columns={2} stackable>
              <Grid.Row>
                <Grid.Column width={10}>
                  <AssetDetails
                    assetDetailsdata={assetDetailsdata}
                    deviceName={props.match.params.id}
                  />
                  <Alarms
                    detailsPageButtonIsClicked={isDetailsPageButtonIsClicked}
                    alarmDetailsdata={alarmDetailsdata.eventsData}
                    isFetchingAlarmsData={isFetchingAlarmsData}
                  />
                  <MassSpecData
                    detailsPageButtonIsClicked={isDetailsPageButtonIsClicked}
                    massSpecDetailsData={
                      massSpecDetailsData
                        ? massSpecDetailsData.deviceListValues
                        : []
                    }
                    isFetchingMassspecData={isFetchingMassspecData}
                  />
                  <QueueList
                    detailsPageButtonIsClicked={isDetailsPageButtonIsClicked}
                    sampleQueueListDetailsData={
                      sampleQueueListDetailsData
                        ? sampleQueueListDetailsData.deviceList
                        : []
                    }
                    isFetchingSampleQueueData={isFetchingSampleQueueData}
                  />
                </Grid.Column>
                <Grid.Column width={6}>
                  <Rules
                    detailsPageButtonIsClicked={isDetailsPageButtonIsClicked}
                    rulesDetailsdata={rulesDetailsdata}
                    isFetchingRulesData={isFetchingRulesData}
                  />
                  <WorkStationData
                    detailsPageButtonIsClicked={isDetailsPageButtonIsClicked}
                    workstationDetailsData={
                      workstationDetailsData
                        ? workstationDetailsData.deviceListValues
                        : []
                    }
                    isFetchingWorkstationData={isFetchingWorkstationData}
                  />
                  <LCData
                    detailsPageButtonIsClicked={isDetailsPageButtonIsClicked}
                    lcDataDetailsData={
                      lcDataDetailsData
                        ? lcDataDetailsData.deviceListValues
                        : []
                    }
                    isFetchingLCData={isFetchingLCData}
                  />
                  <ErrorData
                    detailsPageButtonIsClicked={isDetailsPageButtonIsClicked}
                    errorDataDetailsData={
                      errorDataDetailsData
                        ? errorDataDetailsData.errorDetails
                        : []
                    }
                    isFetchingErrorData={isFetchingErrorData}
                  />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </div>
      )}
      {detailsPageStatus === 'VIEW_DETAILS_PAGE' && (
        <div className='containerStyle'>
          <TableData
            deviceName={props.match.params.id}
            alarmDetailsdata={alarmDetailsdata.eventsData}
            rulesDetailsdata={rulesDetailsdata}
            workstationDetailsData={workstationDetailsData}
            massSpecDetailsData={massSpecDetailsData}
            lcDataDetailsData={lcDataDetailsData}
            errorDataDetailsData={errorDataDetailsData}
            sampleQueueListDetailsData={sampleQueueListDetailsData.deviceList}
          />
        </div>
      )}
      {detailsPageStatus === 'VIEW_HISTORICAL_DATAITEM_PAGE' && (
        <div className='containerStyle'>
          <HistoricalDataitems
            deviceName={props.match.params.id}
            deviceParameters={deviceParameters}
          />
        </div>
      )}
    </div>
  );
};

export default enhance(DeviceDetail);
