import React from 'react';
import { Field } from 'redux-form';
import { Form, Button } from 'semantic-ui-react';
import enhance from './enhance';
import { commonLabels } from '../../../../redux/constants/Values';
import RenderSelectInput from './ReactMultiSelectCheckboxes';
import styles from '../Root/DeviceDetail.module.scss';

export const HistoricalForm = (props) => {
  const {
    handleSubmit,
    deviceParameters,
    isDetailsPageBackButtonIsClicked,
  } = props;
  const device_params =
    deviceParameters.length !== 0
      ? deviceParameters.map((item) => ({
          key: item.displayName + ' (' + item.mnemonic + ')',
          value: item.mnemonic,
          label: item.displayName + ' (' + item.mnemonic + ')',
        }))
      : [{ key: 'Loading...', value: '', text: 'Loading...' }];
  return (
    <div style={{ marginTop: '16px' }}>
      <Form onSubmit={handleSubmit.bind(this, props)}>
        <Form.Group widths='equal'>
          <Field
            component={Form.Input}
            type='Date'
            label='Start Date'
            name='startDate'
          />
          <Field
            component={Form.Input}
            type='Time'
            label='Start Time'
            name='startTime'
          />
          <Field
            component={Form.Input}
            type='Date'
            label='End Date'
            placeholder='End date'
            name='endDate'
          />
          <Field
            component={Form.Input}
            type='Time'
            label='End Time'
            placeholder='End date'
            name='endTime'
          />
        </Form.Group>
        <label className={styles.dropdownLabel}>Data Item</label>
        <Field
          component={RenderSelectInput}
          className='multiCheckboxFix'
          options={device_params}
          name='dataitem'
          label='Data Item'
        />
        <br />
      </Form>
      <Button.Group>
        <Button positive onClick={handleSubmit}>
          {commonLabels.filter}
        </Button>
        <Button.Or />
        <Button negative onClick={isDetailsPageBackButtonIsClicked}>
          {commonLabels.cancel}
        </Button>
      </Button.Group>
    </div>
  );
};

export default enhance(HistoricalForm);
