import { connect } from "react-redux";
import { compose, lifecycle, withHandlers } from "recompose";
import { fetchOnBoardingLists } from "../../../redux/reducers/OnBoardingReducer";
import { exportCSVFile } from "./../../../apis/onBoardingAPI/normalizers";

/**
 *
 * @description {fetch values from store}
 */
export const mapStateToProps = (state) => ({
  onBoardingListsdata: state.onBoardingReducer.onBoardingListsdata,
  filteredData: state.onBoardingReducer.OnboardingFiltered,
  isFetchingOnBoardingStatus:
    state.onBoardingReducer.isFetchingOnBoardingStatus,
    OnBoardingRemarksdata: state.onBoardingReducer.OnBoardingRemarksdata,

});

/**
 *
 * @description {onpage load call API}
 */
export function componentDidMount() {
  this.props.fetchOnBoardingLists();
}

export const btnHandler = ({ filteredData }) => {
  exportCSVFile(filteredData);
};

export default compose(
  connect(mapStateToProps, { fetchOnBoardingLists }),
  withHandlers({ btnHandler }),
  lifecycle({ componentDidMount })
);
