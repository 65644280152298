import raiseError from "./../../utilities/raiseError";
import api from "../config_api";
import { getHeaders } from "../../utilities/helpers";

export const fetchModelsLists = async ({ getState, values }) => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };
  const response = await fetch(api.models_get_url, requestOptions);
  let responseBody;
  if (response.ok) {
    responseBody = await response.json();
    return responseBody;
  } else {
    responseBody = await response.json();
    raiseError({ response, responseBody });
  }
};

export const forceDeleteModel = async ({ getState, values }) => {
  const requestOptions = {
    method: "DELETE",
    headers: getHeaders(),
  };
  const response = await fetch(api.delete_model_url.concat(values), requestOptions);
  let responseBody;
  if (response.ok) {
    responseBody = await response.json();
    return responseBody;
  } else {
    responseBody = await response.json();
    //return responseBody;
    raiseError({ response, responseBody });
  }
};

// fetch model data

export const fetchModelsData = async ({ getState, values }) => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };
  const response = await fetch(
    api.get_models_data.concat(values),
    requestOptions
  );
  let responseBody;
  if (response.ok) {
    responseBody = await response.json();
    return responseBody;
  } else {
    responseBody = await response.json();
    raiseError({ response, responseBody });
  }
};

export const fetchModelsDeviceParams = async ({ getState, values }) => {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: "",
  };
  const response = await fetch(api.models_device_params, requestOptions);
  let responseBody;
  if (response.ok) {
    responseBody = await response.json();
    return responseBody;
  } else {
    responseBody = await response.json();
    raiseError({ response, responseBody });
  }
};

export const fetchExcelModelsDeviceParams = async ({ getState, values }) => {
  const valueBody = {
    modelName: values,
  };
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: values !== undefined ? JSON.stringify(valueBody) : "",
  };
  const response = await fetch(api.models_device_params, requestOptions);
  let responseBody;
  if (response.ok) {
    if (response.status === 204) {
      responseBody = [];
      return responseBody;
    }
    responseBody = await response.json();
    return responseBody;
  } else {
    responseBody = await response.json();
    raiseError({ response, responseBody });
  }
};

export const createModel = async ({ getState, values }) => {
  let modelsCheckedLists = getState().ModelsListReducer.modelsCheckedLists;
  let excelModelParamsOnUpdate = getState().ModelsListReducer
    .excelModelParamsOnUpdate;
  let modelData = getState().ModelsListReducer.excelModelDeviceParams;
  for (const item of excelModelParamsOnUpdate) {
    modelData.map((groupItem, key) => {
      if (groupItem.mnemonic === item) {
        let assignList = {
          [groupItem.mnemonic]: groupItem,
        };
        modelsCheckedLists.push(assignList);
      }
      return true;
    });
  }
  const mnemonicsList = Object.assign({}, ...modelsCheckedLists);
  const valueBody = {
    modelName: values.modelName,
    description: values.description,
    parameters: Array.of(mnemonicsList),
    createdBy: sessionStorage.getItem("username"),
  };
  const requestOptions = {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify(valueBody),
  };
  const response = await fetch(api.create_model_url, requestOptions);
  let responseBody;
  if (response.ok) {
    responseBody = await response.json();
    return responseBody;
  } else {
    return response.json().then((text) => {
      throw new Error(text.message);
    });
  }
};

export const updateModel = async ({ getState, values }) => {
  let modelsCheckedLists = getState().ModelsListReducer.modelsCheckedLists;
  let modelParamsOnUpdate = getState().ModelsListReducer.modelParamsOnUpdate;
  let modelData = getState().ModelsListReducer.modelData;
  let excelParams = getState().ModelsListReducer.excelModelDeviceParams;
  if (excelParams.length > 0) {
    for (const item of modelParamsOnUpdate) {
      excelParams.map((groupItem, key) => {
        if (groupItem.mnemonic === item) {
          let assignList = {
            [groupItem.mnemonic]: {
              displayName: groupItem.displayName,
              dataType: groupItem.dataType,
              mnemonic: groupItem.mnemonic,
              description: groupItem.description,
              unitsOfMeasure: groupItem.unitsOfMeasure,
            },
          };
          modelsCheckedLists.push(assignList);
        }
        return true;
      });
    }
  } else {
    for (const item of modelParamsOnUpdate) {
      modelData.paramsList.map((groupItem, key) => {
        Object.entries(groupItem.attributes).map((attritem) => {
          if (attritem[0] === item) {
            let assignList = {
              [attritem[0]]: attritem[1],
            };
            modelsCheckedLists.push(assignList);
          }
          return true;
        });
        return true;
      });
    }
  }
  const mnemonicsList = Object.assign({}, ...modelsCheckedLists);
  const valueBody = {
    modelName: values.modelName,
    description: values.description,
    parameters: Array.of(mnemonicsList),
    updatedBy: sessionStorage.getItem("username"),
  };
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(valueBody),
  };
  const response = await fetch(api.update_model_url, requestOptions);
  let responseBody;
  if (response.ok) {
    responseBody = await response.json();
    return responseBody;
  } else {
    return response.json().then((text) => {
      throw new Error(text.message);
    });
  }
};
