import React from "react";

export const useSelectedRadioValue = () => {
  const [radioValue, setRadioValue] = React.useState("model_content");
  const [modelValue, setModelValue] = React.useState("");
  const [deviceValue, setDeviceValue] = React.useState("");
  const [serialTextValue, setSerialTextValue] = React.useState("");
  const [searches, setSearches] = React.useState([]);

  const [serialFieldValue, setSerialFieldValue] = React.useState("");
  const radioChange = (event, { value }) => {
    setRadioValue(value);
  };

  const setSerialNumberValue = val => {
    setSerialTextValue(val);
    setSerialFieldValue(val);
  };

  const ClearField = () => {
    setSerialFieldValue("");
  };

  return {
    radioValue,
    setRadioValue,
    modelValue,
    setModelValue,
    deviceValue,
    setDeviceValue,
    radioChange,
    setSearches,
    searches,
    serialTextValue,
    setSerialTextValue,
    serialFieldValue,
    setSerialNumberValue,
    ClearField
  };
};
