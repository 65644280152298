import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import { fetchReportsLists } from "../../../redux/reducers/ReportsListReducer";

/**
 *
 * @description {get values from store}
 */
export const mapStateToProps = (state) => ({
  reportsListdata: state.ReportsListReducer.reportsListsdata,
  isFetching: state.ReportsListReducer.isFetchingReports,
});

/**
 *
 * @description {onpage load API calls}
 */
export function componentDidMount() {
  this.props.fetchReportsLists();
}

export default compose(
  connect(mapStateToProps, { fetchReportsLists }),
  lifecycle({ componentDidMount })
);
