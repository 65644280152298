import api from "../../config_api";
import { Alerts } from "../../../redux/constants/Values";
/**
 * @description {this calls signin api}
 */

export async function signin(email, password, loginProps) {
  const loginDetail = {
    userName: email,
    password: password,
  };
  try {
    const response = await fetch(api.login_url, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(loginDetail),
    });
    let responseBody;
    if (response.ok) {
      responseBody = await response.json();
      if (responseBody.message === null) {
        // if user already changed password response data will be stored session.
        sessionStorage.setItem("userInfo", JSON.stringify(responseBody));
        sessionStorage.setItem("username", email);
        sessionStorage.setItem("roles", responseBody.userGroupsList);
        const permission = sessionStorage
          .getItem("roles")
          .split(",")
          .includes("adminGroup"); //checking the rights of login user in session storage.
        sessionStorage.setItem("permission", permission);
        loginProps.push("/assets"); //redirect to home screen
      } else {
        // if user logging in for first time ,its been redirected to change password
        window.alert(responseBody.message);
        loginProps.push("/changePassword");
      }
    } else {
      responseBody = await response.json();
      window.alert(responseBody.message);
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/";
      // raiseError({ response });
    }
  } catch (e) {
    window.alert(e);
    console.error("Error:", e);
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/";
  }
}

/**
 *
 * @description {this function will handle the login session of application and also it will provide the access token for every api }
 */

export function authHeader() {
  if (
    Number(JSON.parse(sessionStorage.getItem("userInfo")).presentTime) + //comparing session login time with the present api call time
      Number(JSON.parse(sessionStorage.getItem("userInfo")).expriesIn) >
    new Date().getTime() / 1000
  ) {
    //if its greather than api call time returns access token
    if (sessionStorage.getItem("userInfo")) {
      return `${JSON.parse(sessionStorage.getItem("userInfo")).accessToken}`;
    } else {
      return {};
    }
  } else {
    // clear local and session storage and returns to login screen
    window.alert(Alerts.sessionOut);
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/";
    return `${JSON.parse(sessionStorage.getItem("userInfo")).accessToken}`;
  }
}

/**
 *
 * @description {call change password api }
 */

export async function changepassword(
  email,
  oldpassword,
  newpassword,
  changeprops
) {
  const changePasswordDetail = {
    userName: email,
    password: oldpassword,
    newPassword: newpassword,
  };

  try {
    const response = await fetch(api.change_password_url, {
      method: "POST", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(changePasswordDetail),
    });
    let responseBody;
    if (response.ok) {
      responseBody = await response.json();
      window.alert(responseBody.message);
      changeprops.push("/");
    } else {
      responseBody = await response.json();
      window.alert(responseBody.message);
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/";
      // raiseError({ response });
    }
  } catch (e) {
    window.alert(e);
    console.error("Error:", e);
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/";
  }
}

export async function forgotPassword(email, loginProps) {
  // const forgotPasswordDetail = {
  //   userName: email,
  // };
  try {
    const response = await fetch(api.forgot_password_url.concat(email), {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      // body: JSON.stringify(forgotPasswordDetail),
    });
    let responseBody;
    if (response.ok) {
      responseBody = await response.json();
      sessionStorage.setItem("userInfo", JSON.stringify(responseBody));
      sessionStorage.setItem("username", email);
      window.alert(responseBody.message);
      window.location.href = "/newPassword";
    } else {
      responseBody = await response.json();
      window.alert(responseBody.message);
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/forgotPassword";
    }
  } catch (e) {
    window.alert(e);
    console.error("Error:", e);
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/forgotPassword";
  }
}

/**
 *
 * @description {call new password api }
 */

export async function newPassword(verificationCode, newpassword, changeprops) {
  const newPasswordDetail = {
    userName: sessionStorage.getItem("username"),
    verificationCode: verificationCode,
    newPassword: newpassword,
  };
  try {
    const response = await fetch(api.new_password_url, {
      method: "PUT", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(newPasswordDetail),
    });
    let responseBody;
    if (response.ok) {
      responseBody = await response.json();
      window.alert(responseBody.message);
      window.location.href = "/";
    } else {
      responseBody = await response.json();
      window.alert(responseBody.message);
      window.location.href = "/newPassword";
    }
  } catch (e) {
    window.alert(e);
    console.error("Error:", e);
  }
}
