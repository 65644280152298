import React from "react";
import {
  Table,
  Label,
  Segment,
  Button,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { Grids, commonLabels } from "../../../../redux/constants/Values";

export const ErrorData = (props) => {
  const {
    detailsPageButtonIsClicked,
    errorDataDetailsData,
    isFetchingErrorData,
  } = props;
  return (
    <>
      <Segment padded>
        <Label attached="top" size="big" className="bgBlue">
          {Grids.error + " " + commonLabels.data}
          <Button
            primary
            onClick={detailsPageButtonIsClicked.bind(this, Grids.error)}
            floated="right"
          >
            {commonLabels.viewAll}
          </Button>
        </Label>
        <Table
          className="displayTable"
          singleLine
          compact
          striped
          sortable
          celled
          textAlign="center"
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Error Type</Table.HeaderCell>
              <Table.HeaderCell>Error Code</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {isFetchingErrorData ? (
            <Dimmer active inverted>
              <Loader size="mini">{commonLabels.fetching}</Loader>
            </Dimmer>
          ) : (
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  {errorDataDetailsData &&
                  errorDataDetailsData.length > 0 &&
                  errorDataDetailsData[0].errorType !== "N/A"
                    ? errorDataDetailsData[0].errorType
                    : errorDataDetailsData && errorDataDetailsData.length > 0
                    ? errorDataDetailsData[0].errorType
                    : ""}
                </Table.Cell>
                <Table.Cell>
                  {errorDataDetailsData &&
                    errorDataDetailsData.length > 0 &&
                    errorDataDetailsData[0].errorCode}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  {errorDataDetailsData &&
                  errorDataDetailsData.length > 1 &&
                  errorDataDetailsData[1].errorType !== "N/A"
                    ? errorDataDetailsData[1].errorType
                    : errorDataDetailsData && errorDataDetailsData.length > 1
                    ? errorDataDetailsData[1].mnemonicName
                    : ""}
                </Table.Cell>
                <Table.Cell>
                  {errorDataDetailsData &&
                    errorDataDetailsData.length > 1 &&
                    errorDataDetailsData[1].errorCode}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  {errorDataDetailsData &&
                  errorDataDetailsData.length > 2 &&
                  errorDataDetailsData[2].errorType !== "N/A"
                    ? errorDataDetailsData[2].errorType
                    : errorDataDetailsData && errorDataDetailsData.length > 2
                    ? errorDataDetailsData[2].mnemonicName
                    : ""}
                </Table.Cell>
                <Table.Cell>
                  {errorDataDetailsData &&
                    errorDataDetailsData.length > 2 &&
                    errorDataDetailsData[2].errorCode}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  {errorDataDetailsData &&
                  errorDataDetailsData.length > 3 &&
                  errorDataDetailsData[3].errorType !== "N/A"
                    ? errorDataDetailsData[3].errorType
                    : errorDataDetailsData && errorDataDetailsData.length > 3
                    ? errorDataDetailsData[3].mnemonicName
                    : ""}
                </Table.Cell>
                <Table.Cell>
                  {errorDataDetailsData &&
                    errorDataDetailsData.length > 3 &&
                    errorDataDetailsData[3].errorCode}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  {errorDataDetailsData &&
                  errorDataDetailsData.length > 4 &&
                  errorDataDetailsData[4].errorType !== "N/A"
                    ? errorDataDetailsData[4].errorType
                    : errorDataDetailsData && errorDataDetailsData.length > 4
                    ? errorDataDetailsData[4].mnemonicName
                    : ""}
                </Table.Cell>
                <Table.Cell>
                  {errorDataDetailsData &&
                    errorDataDetailsData.length > 4 &&
                    errorDataDetailsData[4].errorCode}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          )}
        </Table>
      </Segment>
    </>
  );
};

export default ErrorData;
