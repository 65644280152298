import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import { fetchModelsLists } from "../../../../redux/reducers/ModelsListReducer";

/**
 *
 * @description {fetch values from store}
 */
export const mapStateToProps = (state) => ({
  modelsListsdata: state.ModelsListReducer.modelsListsdata.modelsList
    ? state.ModelsListReducer.modelsListsdata.modelsList
    : [],
});
/**
 *
 * @description {onpage load call API}
 */
export function componentDidMount() {
  this.props.fetchModelsLists();
}

export default compose(
  connect(mapStateToProps, { fetchModelsLists }),
  lifecycle({ componentDidMount })
);
