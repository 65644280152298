import React from "react";
import { Form, Grid, Header, Button, Card, Icon } from "semantic-ui-react";
import DataExpressionReview from "../DataExpressionReview";
import DataExpressionDetails from "../DataExpressionDetails";
import ExpressionModelsForRuleCard from "../ExpressionModelsForRuleCard";
import ExpressionRulesCard from "../ExpressionRulesCard";
import ExpressionSeverityCard from "../ExpressionSeverityCard";
import { Dimmer, Loader } from "semantic-ui-react";
import styles from "./DataExpressionRoot.module.scss";
import enhance from "./enhance";
import {
  commonLabels,
  CreateLabels,
  UpdateLabels,
} from "../../../../redux/constants/Values";

export const DataExpressionRoot = (props) => {
  const {
    handleSubmit,
    routePageStatus,
    initialValues,
    IsExpressionValidated,
    backToListPage,
    unique_key,
  } = props;

  return (
    <div>
      {props.match.params.update === "update" && initialValues.ruleName === "" && (
        <Dimmer active inverted>
          <Loader size="large" inverted>
            {commonLabels.loading}
          </Loader>
        </Dimmer>
      )}
      <div>
        {routePageStatus === "CREATE_PAGE" && (
          <div className={styles.expressionContainer}>
            <Header as="h3" block className={styles.cardHeader}>
              {props.match.params.update === "update" ? (
                <span>{UpdateLabels.dataExpression}</span>
              ) : (
                <span>{CreateLabels.dataExpression}</span>
              )}
            </Header>

            <Form onSubmit={handleSubmit} key={unique_key}>
              <div className="fieldContainer">
                <DataExpressionDetails
                  updateProps={props.match.params.update}
                />
              </div>

              <div className={styles.cardsContainer}>
                <Card.Group itemsPerRow={3}>
                  <ExpressionModelsForRuleCard />
                  <ExpressionRulesCard initialValues={initialValues} />
                  <ExpressionSeverityCard />
                </Card.Group>
              </div>

              <Grid>
                <Grid.Column textAlign="center">
                  <Button
                    primary
                    animated
                    className={
                      IsExpressionValidated === false ||
                      IsExpressionValidated === ""
                        ? styles.inValidExpression
                        : ""
                    }
                  >
                    <Button.Content visible>{commonLabels.next}</Button.Content>
                    <Button.Content hidden>
                      <Icon name="arrow right" />
                    </Button.Content>
                  </Button>
                  <Button primary onClick={backToListPage}>
                    {commonLabels.cancel}
                  </Button>
                </Grid.Column>
              </Grid>
            </Form>
          </div>
        )}
      </div>
      {routePageStatus === "REVIEW_PAGE" && (
        <div className="addNewMainContainer">
          <DataExpressionReview
            updateProps={props.match.params.update}
            {...props}
          />
        </div>
      )}
    </div>
  );
};
export default enhance(DataExpressionRoot);
