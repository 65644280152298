import React from "react";
import PropTypes from "prop-types";
import { Dimmer, Loader } from "semantic-ui-react";
import { Divider } from "semantic-ui-react";
import enhance from "./enhance";
import UsersTableData from "./../UsersTableData";
import { NavItems, commonLabels } from "../../../redux/constants/Values";

export const Users = ({ UserListData }) => {
  return (
    <div className="containerStyle">
      <h4>{NavItems.users + " " + commonLabels.list}</h4>
      <Divider />
      {Object.keys(UserListData).length === 0 ? (
        <Dimmer active inverted>
          <Loader size="large" inverted>
            {commonLabels.loading}
          </Loader>
        </Dimmer>
      ) : (
        <UsersTableData usersListsdata={UserListData} />
      )}
    </div>
  );
};

Users.propTypes = {
  UserListData: PropTypes.object,
};

export default enhance(Users);
