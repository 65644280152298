import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import { reduxForm } from "redux-form";
import {
  rulesAddPageRoute,
  saveDeviceCodeData,
  updateDeviceCodeData,
} from "../../../../redux/reducers/RulesListReducer";
import { withRouter } from "react-router";

/**
 *
 * @description {confirmation message}
 */
export const confirmMsg =
  "Are you sure you want to leave this screen? \nEntry will be discarded. ";

/**
 *
 * @description {fetch values from store}
 */
export const mapStateToProps = (state) => ({
  deviceCodeDetailsForReview: state.rulesListreducer.deviceCodeDetailsForReview,
  isFetching: state.rulesListreducer.isFetchingRules,
});

/**
 *
 * @description {return to create page}
 */
export const returnToAddPage = ({ rulesAddPageRoute }) => () => {
  rulesAddPageRoute();
};

/**
 *
 * @description {save/update device code}
 */
export const saveDeviceCodeEvent = ({
  saveDeviceCodeData,
  updateDeviceCodeData,
  updateProps,
  redirectToRuleListpath,
  //history
}) => () => {
  if (updateProps === "update") {
    updateDeviceCodeData().then((payload) => {
      if (payload.type === "UPDATE_DEVICE_CODE_DATA_REQUEST_SUCCESS") {
        window.alert(payload.payload.message);
        redirectToRuleListpath();
      } else {
        window.alert(payload.payload.message);
      }
    });
  } else {
    saveDeviceCodeData().then((payload) => {
      if (payload.type === "SAVE_DEVICE_CODE_DATA_REQUEST_SUCCESS") {
        window.alert(payload.payload.message);
        redirectToRuleListpath();
      } else {
        window.alert(payload.payload.message);
      }
    });
  }
};

/**
 *
 * @description {redirect to rulelist page}
 */
export const redirectToRuleListpath = ({ history }) => () =>
  history.push("/rules-list");

/**
 *
 * @description {cancel button click}
 */
export const cancelEvent = ({
  resetRulesPage,
  redirectToRuleListpath,
  reset,
  updateProps,
}) => () => {
  if (window.confirm(confirmMsg)) {
    //redirectToRuleListpath();
    // window.location.reload(false);
    reset("DeviceCodeReviewForm");
    resetRulesPage();
    if (updateProps === "update") {
      redirectToRuleListpath();
    }
  }
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    rulesAddPageRoute,
    saveDeviceCodeData,
    updateDeviceCodeData,
  }),
  withHandlers({
    redirectToRuleListpath,
  }),
  withHandlers({ returnToAddPage, cancelEvent }),
  withHandlers({ saveDeviceCodeEvent }),

  reduxForm({
    form: "DeviceCodeReviewForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    destroyOnUnmount: false,
  })
);
