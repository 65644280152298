import { connect } from "react-redux";
import { compose } from "recompose";

/**
 *
 * @description {fetch values from store}
 */
export const mapStateToProps = (state) => ({
  routePageStatus: state.rulesListreducer.routePageStatus,
});

export default compose(connect(mapStateToProps, null));
