import React from "react";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import "react-table/react-table.css";
import enhance from "./enhance";
import { Alerts } from "../../../../redux/constants/Values";

export const TableData = (props) => {
  const { getMnemonicsData } = props;
  /* istanbul ignore next */
  return (
    <div>
      <ReactTable
        data={props.data}
        getTheadFilterThProps={(e) => {
          let filtredData = e.sortedData.map((eachVal) => {
            return eachVal._original;
          });
          getMnemonicsData(filtredData.length > 0 ? filtredData : []);
          return {
            style: { position: "inherit", overflow: "inherit" },
          };
        }}
        filterable
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id]) === filter.value
        }
        columns={[
          {
            Header: "Alarm Date",
            id: "alarmdate",
            accessor: (d) => d.alarmDate,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["alarmdate"] }),
            filterAll: true,
          },
          {
            Header: "Alarm Description",
            id: "description",
            accessor: (d) => d.alarmDescription,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["description"] }),
            filterAll: true,
          },
          {
            Header: "Alarm Severity",
            id: "severity",
            accessor: (d) =>
              d.alarmSeverity && d.alarmSeverity.match(/^[0-9]+$/) !== null
                ? parseInt(d.alarmSeverity)
                : d.alarmSeverity,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["severity"] }),
            filterAll: true,
          },
          {
            Header: "Mnemonic Values",
            id: "mnemonicValues",
            getProps: (state, rowInfo, column) => {
              return {
                style: {
                  overflow:
                    rowInfo &&
                    rowInfo.original.mnemonicValues &&
                    Object.keys(rowInfo.original.mnemonicValues).length > 3
                      ? "scroll"
                      : null,
                  textOverflow:
                    rowInfo &&
                    rowInfo.original.mnemonicValues &&
                    Object.keys(rowInfo.original.mnemonicValues).length > 3
                      ? "clip"
                      : null,
                },
              };
            },
            accessor: (d) =>
              d.mnemonicValues &&
              JSON.stringify(d.mnemonicValues)
                .replace(/[{"}]/g, "")
                .replace(",", ", "),
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["mnemonicValues"] }),
            filterAll: true,
          },
        ]}
        defaultPageSize={10}
        minRows={3}
        noDataText={Alerts.noRows}
        className="-striped -highlight"
      />
    </div>
  );
};
export default enhance(TableData);
