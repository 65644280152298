import React from 'react';
import { Card, Form } from 'semantic-ui-react';
import { Field } from 'redux-form';
import { required } from './../../../../utilities/validations';
import RadioGroup from './../../RadioGroup/index';
import styles from './DeviceCodeActionsForCard.module.scss';
import { CreateLabels } from '../../../../redux/constants/Values';

export const DeviceCodeSeverityForCard = () => {
  return (
    <div className='cards'>
      <Card className={styles.deviceCustomWidthFix}>
        <Card.Content
          header={CreateLabels.configureSeverity}
          className='cardHeader'
        />

        <Card.Content extra>
          <Card.Content
            header={CreateLabels.immediateSeverity}
            className={styles.cardSubHeader}
          />
          <Form.Field>
            <Field
              component={RadioGroup}
              name='severity'
              validate={[required]}
              options={[
                { title: 'Customer (121)', value: '121' },
                { title: 'Tester (161)', value: '161' },
                { title: 'TAC (181)', value: '181' },
              ]}
            />
          </Form.Field>
          <Card.Content
            header={CreateLabels.nonImmediateSeverity}
            className={styles.cardSubHeader}
          />
          <Form.Field>
            <Field
              component={RadioGroup}
              name='severity'
              validate={[required]}
              options={[
                { title: 'Customer (120)', value: '120' },
                { title: 'Tester (160)', value: '160' },
                { title: 'TAC (180)', value: '180' },
              ]}
            />
          </Form.Field>
          <Card.Content
            header={CreateLabels.weeklyOnceSeverity}
            className={styles.cardSubHeader}
          />
          <Form.Field>
            <Field
              component={RadioGroup}
              name='severity'
              validate={[required]}
              options={[
                { title: 'Customer (122)', value: '122' },
                { title: 'Tester (162)', value: '162' },
                { title: 'TAC (182)', value: '182' },
              ]}
            />
          </Form.Field>
          <Card.Content
            header={CreateLabels.monthlyOnceSeverity}
            className={styles.cardSubHeader}
          />
          <Form.Field>
            <Field
              component={RadioGroup}
              name='severity'
              validate={[required]}
              options={[
                { title: 'Customer (123)', value: '123' },
                { title: 'Tester (163)', value: '163' },
                { title: 'TAC (183)', value: '183' },
              ]}
            />
          </Form.Field>
        </Card.Content>
      </Card>
    </div>
  );
};
export default DeviceCodeSeverityForCard;
