import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import { fetchModelsLists } from "../../../redux/reducers/ModelsListReducer";
import { reduxForm, reset } from "redux-form";

/**
 *
 * @description {get values from store}
 */
export const mapStateToProps = (state) => ({
  modelsListsdata: state.ModelsListReducer.modelsListsdata,
  isFetching: state.ModelsListReducer.isFetchingModels,
});

/**
 *
 * @description {onpage load API calls}
 */
export function componentDidMount() {
  this.props.fetchModelsLists();
}

/**
 *
 * @description {clear form values after submission}
 */
/* istanbul ignore next */
export const afterSubmit = (result, dispatch) =>
  dispatch(reset("ModelReviewForm"));

export default compose(
  connect(mapStateToProps, { fetchModelsLists }),
  lifecycle({ componentDidMount }),
  reduxForm({
    form: "ModelReviewForm",
    enableReinitialize: true,
    onSubmitSuccess: afterSubmit,
  })
);
