import { connect } from "react-redux";
import { compose, lifecycle, withHandlers } from "recompose";
import { reduxForm } from "redux-form";
import {
  fetchUserData,
  updateUser,
} from "../../../redux/reducers/UsersListReducer";
import { usersListPagePath } from "./../../../utilities/pathHelpers";
import { withRouter } from "react-router";

/**
 *
 * @description {fetch values from store}
 */
export const mapStateToProps = (state) => ({
  isFetching: state.UsersListReducer.isFetchingUsers,
  initialValues: {
    userName: state.UsersListReducer.getUserData.userAttributes
      ? state.UsersListReducer.getUserData.userAttributes.userName
      : "",
    usergroups: state.UsersListReducer.getUserData.userGroupsNameList
      ? state.UsersListReducer.getUserData.userGroupsNameList[0]
      : "",
    email: state.UsersListReducer.getUserData.userAttributes
      ? state.UsersListReducer.getUserData.userAttributes.email
      : "",
    // phone_number: state.UsersListReducer.getUserData.userAttributes
    //   ? state.UsersListReducer.getUserData.userAttributes.phone_number
    //   : "",
  },
});

/**
 *
 * @description {back to users list page}
 */
export const backToListPage = ({ history }) => () => {
  history.push(usersListPagePath);
};

/**
 *
 * @description {On Submit  User Will update}
 */

export const onSubmit = ({ updateUser, history }) => async (values) => {
  let data = {
    userName: values.userName,
    userEmail: values.email,
    phoneNumber: values.phone_number,
    userGroupsList: [{ groupName: values.usergroups }],
    updatedBy: sessionStorage.getItem("username"),
  };
  updateUser(data).then((payload) => {
    if (payload.type === "FETCH_UPDATE_USER_REQUEST_SUCCESS") {
      window.alert(payload.payload.message);
      history.push("/users-list");
    } else {
      window.alert(payload.payload.message);
    }
  });
};

/**
 *
 * @description {onpage load call API}
 */
export function componentDidMount() {
  this.props.fetchUserData(this.props.match.params.id);
}

export default compose(
  withRouter,
  connect(mapStateToProps, { fetchUserData, updateUser }),
  withHandlers({ onSubmit, backToListPage }),
  lifecycle({ componentDidMount }),
  reduxForm({
    form: "fetchUpdateUser",
    enableReinitialize: true,
  })
);
