import React, { useState } from "react";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import moment from "moment/min/moment-with-locales";
import { Field } from "redux-form";
import { reduxForm } from "redux-form";
import { Form } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { Alerts } from "../../../redux/constants/Values";

export const ContentsTableData = (props) => {
  /* istanbul ignore next */
  //const roles = sessionStorage.getItem("roles").split(",");
  /* istanbul ignore next */
  // const isAdmin = roles.indexOf("adminGroup") > -1 ? true : false;
  /* istanbul ignore next */
  const [LonDateFilterVal, setLonDateFilterVal] = useState("");
  /* istanbul ignore next */
  const [FilterDatePicker, setFilterDatePicker] = useState(false);
  /* istanbul ignore next */
  const [AfterFilter, setAfterFilter] = useState([]);

  /* istanbul ignore next */
  const handleDatepicker = (input) => {
    const value = input.currentTarget.valueAsDate;
    value === null ? setFilterDatePicker(false) : setFilterDatePicker(true);
    const dateinput = moment(value).format("YYYY-MM-DD");
    const beforefilter = props.contentsListsdata;
    if (value !== null) {
      const filterresult = beforefilter.filter(
        (data) => data.updateTime.slice(0, 10) === dateinput
      );
      setFilterDatePicker(true);
      setAfterFilter(filterresult);
      setLonDateFilterVal(input);
    }
  };

  /* istanbul ignore next */
  const handleDatepickerLonTemplate = (props) => (
    <Form>
      <Field
        component={Form.Input}
        type="Date"
        name="startDate"
        onChange={(event) => {
          if (event != null) {
            handleDatepicker(event);
          } else {
            setLonDateFilterVal("");
            setFilterDatePicker(false);
          }
        }}
        className="fullWidth"
        value={LonDateFilterVal}
      />
    </Form>
  );

  /* istanbul ignore next */
  return (
    <div>
      <ReactTable
        data={FilterDatePicker ? AfterFilter : props.contentsListsdata}
        getTheadFilterThProps={(props) => {
          return {
            style: { position: "inherit", overflow: "inherit" },
          };
        }}
        filterable
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id]) === filter.value
        }
        columns={[
          {
            Header: "Job Name",
            id: "jobName",
            accessor: (d) => d.jobName,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["jobName"] }),
            filterAll: true,
            Cell: (row) => {
              return sessionStorage
                .getItem("roles")
                .split(",")
                .indexOf("adminGroup") > -1 ? (
                <div>
                  <Link to={`/update-content/update/${row.original.jobName}`}>
                    {row.original.jobName}
                  </Link>
                </div>
              ) : (
                <div>{row.original.jobName}</div>
              );
            },
          },
          {
            Header: "Description",
            id: "description",
            accessor: (d) => d.description,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["description"] }),
            filterAll: true,
          },
          {
            Header: "Version",
            id: "version",
            accessor: (d) => parseInt(d.version),
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["version"] }),
            filterAll: true,
          },
          {
            Header: "Model",
            id: "model",
            accessor: (d) => (d.models ? d.models.toString() : null),
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["model"] }),
            filterAll: true,
          },
          {
            Header: "Serial Number",
            id: "serialNumbers",
            accessor: (d) =>
              d.serialNumbers ? d.serialNumbers.toString() : null,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["serialNumbers"] }),
            filterAll: true,
          },
          {
            Header: "Last Updated By",
            id: "updatedBy",
            accessor: (d) => d.updatedBy,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["updatedBy"] }),
            filterAll: true,
          },
          {
            Header: "Last Updated On",
            id: "lastUpdatedOn",
            accessor: (d) => d.updateTime,

            Filter: (props) => handleDatepickerLonTemplate(props),
            filterAll: true,
          },
        ]}
        defaultPageSize={10}
        minRows={3}
        noDataText={Alerts.noRows}
        className="-striped -highlight"
      />
    </div>
  );
};
export default reduxForm({
  form: "ContentsTableDataForm",
  enableReinitialize: true,
})(ContentsTableData);
