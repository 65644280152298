import React, { useState } from "react";
import { Field } from "redux-form";
import enhance from "./enhance";
import { Form, Button } from "semantic-ui-react";
import { CheckboxField } from "react-semantic-redux-form";
import { commonLabels } from "../../../redux/constants/Values";

export const AdvanceSearch = (props) => {
  const { handleSubmit, clearAdvanceSearchForm } = props;
  const [open, toggleEvent] = useState(false);

  return (
    <div>
      <div>
        <div onClick={() => toggleEvent(!open)} className="toggleHeader">
          <i
            className={open ? "caret square up icon" : "caret square down icon"}
          ></i>
          {commonLabels.advanceSearch}
        </div>

        {open ? (
          <div className="toggleContent">
            <Form onSubmit={handleSubmit}>
              <Form.Group widths="equal">
                <Field
                  component={Form.Input}
                  label="Rule Name"
                  placeholder="Rule Name"
                  name="ruleName"
                />
                <Field
                  component={Form.Input}
                  label="Model"
                  placeholder="Model"
                  name="models"
                />
              </Form.Group>

              <Form.Group widths="equal">
                <Field
                  component={CheckboxField}
                  label="Enable"
                  name="deleted"
                />
              </Form.Group>

              <Button.Group>
                <Button positive>{commonLabels.search}</Button>
                <Button.Or />
                <Button
                  negative
                  onClick={(event) => clearAdvanceSearchForm(event)}
                >
                  {commonLabels.clear}
                </Button>
              </Button.Group>
            </Form>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default enhance(AdvanceSearch);
