import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import {
  userDisable,
  passwordChange,
  fetchUsersLists,
} from "../../../redux/reducers/UsersListReducer";

/**
 *
 * @description {get values from store}
 */
export const mapStateToProps = (state) => ({
  isFetchingUsers: state.UsersListReducer.isFetchingUsers,
});

/**
 *
 * @description {disable user}
 */
export const disableUser = ({ userDisable, fetchUsersLists }) => async (
  values,
  type
) => {
  let data = {
    userName: values,
    type: type,
  };
  userDisable(data).then((payload) => {
    if (payload.type === "FETCH_USER_DISABLE_REQUEST_SUCCESS") {
      window.alert(payload.payload.message);
      fetchUsersLists();
    } else {
      window.alert(payload.payload.message);
    }
  });
};

/**
 *
 * @description {password change}
 */
export const forcePasswordChange = ({
  passwordChange,
  fetchUsersLists,
}) => async (values) => {
  passwordChange(values).then((payload) => {
    if (payload.type === "FETCH_FORCE_PASSWORD_CHANGE_REQUEST_SUCCESS") {
      window.alert(payload.payload.message);
      fetchUsersLists();
    } else {
      window.alert(payload.payload.message);
    }
  });
};

export default compose(
  connect(mapStateToProps, {
    userDisable,
    passwordChange,
    fetchUsersLists,
  }),
  withHandlers({ disableUser, forcePasswordChange })
);
