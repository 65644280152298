import React from "react";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import enhance from "./enhance";
import { Link } from "react-router-dom";
import { Dimmer, Loader } from "semantic-ui-react";
import { commonLabels, Alerts } from "../../../redux/constants/Values";

export const ReportsTableData = (props) => {
  /* istanbul ignore next */
  const { btnHandler, downloadReport, isFetching } = props;
  /* istanbul ignore next */
  return (
    <>
      {isFetching && (
        <Dimmer active inverted>
          <Loader size="large" inverted>
            {commonLabels.loading}
          </Loader>
        </Dimmer>
      )}
      <div>
        <ReactTable
          id="ReactTable"
          data={props.reportsListsdata.reportList}
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          columns={[
            {
              Header: "Report Name",
              id: "report_name",
              accessor: (d) => d.attributes.reportName,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["report_name"] }),
              filterAll: true,
              Cell: (row) => {
                return sessionStorage
                  .getItem("roles")
                  .split(",")
                  .indexOf("adminGroup") > -1 ? (
                  <div>
                    <Link
                      to={`/update-report/update/${row.original.attributes.reportName}`}
                    >
                      {row.original.attributes.reportName}
                    </Link>
                  </div>
                ) : (
                  <div>{row.original.attributes.reportName}</div>
                );
              },
            },
            {
              Header: "Report Description",
              id: "report_description",
              accessor: (d) => d.attributes.reportDescription,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, {
                  keys: ["report_description"],
                }),
              filterAll: true,
            },
            {
              Header: "Query Status",
              id: "queryExecutionStatus",
              accessor: (d) => d.attributes.queryExecutionStatus,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, {
                  keys: ["queryExecutionStatus"],
                }),
              filterAll: true,
            },
            {
              Header: "Actions",
              id: "report_download",
              filterable: false,
              Cell: (row) => {
                return (
                  <div>
                    {row.original.attributes.queryExecutionStatus &&
                    row.original.attributes.queryExecutionStatus ===
                      "SUCCEEDED" ? (
                      <button
                        onClick={(e) => downloadReport(row.original.attributes)}
                      >
                        {commonLabels.download}
                      </button>
                    ) : (
                      <button
                        onClick={(e) => btnHandler(row.original.attributes)}
                      >
                        {commonLabels.execute}
                      </button>
                    )}
                  </div>
                );
              },
            },
          ]}
          defaultPageSize={10}
          minRows={3}
          noDataText={Alerts.noRows}
          className="-striped -highlight"
        />
      </div>
    </>
  );
};

export default enhance(ReportsTableData);
