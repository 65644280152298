import React from "react";
import { Form, Grid, Header, Button, Card } from "semantic-ui-react";
import RulesOfRulesDetails from "../RulesOfRulesDetails";
import RulesOfRulesSeverity from "../RulesOfRulesSeverity";
import RulesOfRulesTable from "../RulesOfRulesTable";
import { Dimmer, Loader } from "semantic-ui-react";
import styles from "./RulesOfRulesRoot.module.scss";
import {
  commonLabels,
  CreateLabels,
  UpdateLabels,
} from "../../../../redux/constants/Values";
import enhance from "./enhance";
import { Alerts } from "../../../../redux/constants/Values";

export const RulesOfRules = (props) => {
  const {
    handleSubmit,
    backToListPage,
    unique_key,
    rulesOfRulesCheckedList,
    isFetching,
  } = props;

  return (
    <div>
      {isFetching ? (
        <Dimmer active inverted>
          <Loader size="large" inverted>
            {commonLabels.loading}
          </Loader>
        </Dimmer>
      ) : (
        <div className={styles.expressionContainer}>
          <Header as="h3" block className={styles.cardHeader}>
            {props.match.params.update === "update" ? (
              <span>{UpdateLabels.rulesOfRules}</span>
            ) : (
              <span>{CreateLabels.rulesOfRules}</span>
            )}
          </Header>

          <Form onSubmit={handleSubmit} key={unique_key}>
            <div className="fieldContainer">
              <RulesOfRulesDetails updateProps={props.match.params.update} />
            </div>

            <div className={styles.cardsContainer}>
              <Card.Group centered>
                <RulesOfRulesTable />
                <RulesOfRulesSeverity />
              </Card.Group>
            </div>
            <div className={styles.noteText}>{Alerts.mandatoryRules}</div>
            <Grid>
              <Grid.Column textAlign="center">
                <Button
                  primary
                  className={
                    rulesOfRulesCheckedList.length > 1
                      ? ""
                      : styles.addBlurEffect
                  }
                >
                  {props.match.params.update === "update"
                    ? commonLabels.update
                    : commonLabels.save}
                </Button>
                <Button primary onClick={backToListPage}>
                  {commonLabels.cancel}
                </Button>
              </Grid.Column>
            </Grid>
          </Form>
        </div>
      )}
    </div>
  );
};
export default enhance(RulesOfRules);
