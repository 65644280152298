import types from '../actions/actionTypes';
import { createThunk } from '../../utilities/createThunk';
import backendAPI from '../../apis';
import { combineReducers } from 'redux';

const initialState = {
  AlarmsReportFilteredData: [],
  alarmsReportListsdata: [],
  assetDetailsData: {},
  isFetchingAlarmsReport: false,
  mnemonicsFilteredData: [],
  assetsListByAdvanceSearch: [],
  parameterLists: [],
  assetsDeviceParameters: [],
  selectedMnemonic: '',
};

export const fetchAlarmsReportLists = createThunk({
  requestActionType: types.FETCH_ALARMS_REPORT_LIST_REQUEST,
  apiCall: backendAPI.alarmsReportListAPI.fetchAlarmsReportLists,
});

export const fetchDeviceParameters = createThunk({
  requestActionType: types.FETCH_DEVICE_PARAMS_LIST_REQUEST,
  apiCall: backendAPI.assetListAPI.fetchDeviceParameters,
});

export const fetchParamtersList = createThunk({
  requestActionType: types.FETCH_MODEL_PARAMETERS_LIST_REQUEST,
  apiCall: backendAPI.assetListAPI.fetchParamtersList,
});

export const fetchAlarmsReportFiltered = (payload) => ({
  type: types.FETCH_ASSETFILTER_DATA,
  payload: payload,
});

export const fetchAssetDetails = createThunk({
  requestActionType: types.FETCH_ASSET_DETAILS_REQUEST,
  apiCall: backendAPI.assetListAPI.fetchAssetDetails,
});

export const fetchMnemonicsData = (payload) => ({
  type: types.FETCH_MNEMONICS_DATA,
  payload: payload,
});

export const fetchAlarmsByAdvanceSearch = createThunk({
  requestActionType: types.FETCH_ASSETS_LIST_BY_ADVANCESEARCH_REQUEST,
  apiCall: backendAPI.alarmsReportListAPI.fetchAlarmsByAdvanceSearch,
});

export const fetchAssetsData = createThunk({
  requestActionType: types.FETCH_ASSETS_DATA_REQUEST,
  apiCall: backendAPI.assetListAPI.fetchAssetsData,
});

export const AssetsListReducer = {
  fetchParamtersList,
  fetchAlarmsReportLists,
  fetchAssetDetails,
  fetchAlarmsByAdvanceSearch,
  fetchAssetsData,
};

const AlarmsReportFiltered = (
  state = initialState.AlarmsReportFilteredData,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_ALARMS_REPORT_FILTER_DATA:
      return payload;
    case 'DEFAULT37':
      return payload;
    default:
      return state;
  }
};
const parameterLists = (
  state = initialState.parameterLists,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_MODEL_PARAMETERS_LIST_REQUEST_SUCCESS:
      return payload;
    case 'DEFAULT':
      return payload;
    case 'DEFAULT1':
      return payload;
    default:
      return state;
  }
};

const assetsDeviceParameters = (
  state = initialState.assetsDeviceParameters,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_DEVICE_PARAMS_LIST_REQUEST_SUCCESS:
      return payload;
    case 'DEFAULT2':
      return payload;
    case 'DEFAULT3':
      return payload;
    default:
      return state;
  }
};

const alarmsReportListsdata = (
  state = initialState.alarmsReportListsdata,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_ALARMS_REPORT_LIST_REQUEST_SUCCESS:
      return payload;
    case types.FETCH_ASSETS_LIST_BY_ADVANCESEARCH_REQUEST_SUCCESS:
      return payload;
    default:
      return state;
  }
};

const assetDetailsdata = (
  state = initialState.assetDetailsData,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_ASSET_DETAILS_REQUEST_SUCCESS:
      return payload;
    case types.RESET_ASSET_DETAILS_PAGE:
      return initialState.assetDetailsData;
    default:
      return state;
  }
};

const mnemonicsFilteredData = (
  state = initialState.mnemonicsFilteredData,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_MNEMONICS_DATA:
      return payload;
    case types.RESET_ASSET_DETAILS_PAGE:
      return initialState.mnemonicsFilteredData;
    default:
      return state;
  }
};

const selectedMnemonic = (
  state = initialState.selectedMnemonic,
  { type, payload }
) => {
  switch (type) {
    case types.HISTORY_DATA_CLICKED:
      return payload.type;
    case 'DEFAULT6':
      return payload;
    case 'DEFAULT7':
      return payload;
    default:
      return state;
  }
};

const assetsListByAdvanceSearch = (
  state = initialState.assetsListByAdvanceSearch,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_ASSETS_LIST_BY_ADVANCESEARCH_REQUEST_SUCCESS:
      return null;
    case 'DEFAULT8':
      return payload;
    case 'DEFAULT9':
      return payload;
    default:
      return state;
  }
};

const isFetchingAlarmsReport = (
  state = initialState.isFetchingAlarmsReport,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_ALARMS_REPORT_LIST_REQUEST:
    case types.FETCH_ASSETS_LIST_BY_ADVANCESEARCH_REQUEST:
    case types.FETCH_ASSETS_DATA_REQUEST:
    case types.FETCH_ASSET_DETAILS_REQUEST:
      return true;
    case types.FETCH_ASSETS_LIST_BY_ADVANCESEARCH_REQUEST_SUCCESS:
    case types.FETCH_ALARMS_REPORT_LIST_REQUEST_SUCCESS:
    case types.FETCH_ASSETS_DATA_REQUEST_SUCCESS:
    case types.FETCH_ASSET_DETAILS_REQUEST_SUCCESS:
    case types.FETCH_ASSETS_LIST_BY_ADVANCESEARCH_REQUEST_FAILURE:
    case types.FETCH_ALARMS_REPORT_LIST_REQUEST_FAILURE:
    case types.FETCH_ASSETS_DATA_REQUEST_FAILURE:
    case types.FETCH_ASSET_DETAILS_REQUEST_FAILURE:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  alarmsReportListsdata,
  assetDetailsdata,
  isFetchingAlarmsReport,
  assetsDeviceParameters,
  parameterLists,
  mnemonicsFilteredData,
  selectedMnemonic,
  assetsListByAdvanceSearch,
  AlarmsReportFiltered,
});
