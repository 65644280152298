import { fetchForgotPasswordDetails } from "../../redux/reducers/AuthReducer";
import { connect } from "react-redux";
import { compose, withHandlers, withState } from "recompose";

export const queryChange = ({ LoginDetails, setLoginDetails }) => (e) => {
  setLoginDetails({
    ...LoginDetails,
    [e.target.name]: [e.target.value],
  });
};

export const handleSubmit = ({ LoginDetails, setloader, history }) => (e) => {
  //   alert(JSON.stringify(history));
  e.preventDefault();
  e.persist();
  setloader(true);
  fetchForgotPasswordDetails(LoginDetails.email[0], history);
};

export default compose(
  connect(null, { fetchForgotPasswordDetails }),
  withState("LoginDetails", "setLoginDetails", {
    email: "",
  }),
  withState("loader", "setloader", false),
  withHandlers({ queryChange, handleSubmit })
);
