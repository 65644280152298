import React from "react";
import PropTypes from "prop-types";
import OnBoardingAdvanceSearch from "../OnBoardingAdvanceSearch";
import { Dimmer, Loader, Divider } from "semantic-ui-react";
import OnBoardingTableData from "./../OnBoardingTableData";
import enhance from "./enhance";
import { NavItems, commonLabels } from "../../../redux/constants/Values";

export const OnboardingStatus = ({
  btnHandler,
  onBoardingListsdata,
  isFetchingOnBoardingStatus,
  ...props
}) => (
  <div className="containerStyle">
    <h4>
      {NavItems.onboardingStatus + " " + commonLabels.list}
      <button className="exportButton" onClick={btnHandler}>
        {commonLabels.exportToCSV}
      </button>
    </h4>
    <Divider />
    <OnBoardingAdvanceSearch />
    <Divider />
    {isFetchingOnBoardingStatus ? (
      <Dimmer active inverted>
        <Loader size="large" inverted>
          {commonLabels.loading}
        </Loader>
      </Dimmer>
    ) : (
      <OnBoardingTableData
        onBoardingListsdata={onBoardingListsdata.deviceList}
        {...props}
      />
    )}
  </div>
);

OnboardingStatus.propTypes = {
  onBoardingListsdata: PropTypes.array.isRequired,
};

export default enhance(OnboardingStatus);
