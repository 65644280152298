import { connect } from "react-redux";
import { compose, withHandlers, lifecycle, withState } from "recompose";
import { reduxForm, change } from "redux-form";
import { fetchModelsLists } from "../../../redux/reducers/ModelsListReducer";
import { fetchAssetsLists } from "../../../redux/reducers/AssetListReducer";
import { withRouter } from "react-router";
import {
  createContent,
  fetchContentsDataForUpdate,
  fetchCreateFileContent,
  updateContent,
  IscheckSerialPresent,
  fetchContentForUpdate,
  serialIsMatched,
  serialIsNotMatched,
  resetSerialMatched,
  resetSerialNumbersMatched,
  resetContentPage,
} from "../../../redux/reducers/ContentsListReducer";
import { Alerts } from "../../../redux/constants/Values";

/**
 *
 * @description {fetch values from store}
 */
export const mapStateToProps = (state) => ({
  modelsListsdata: state.ModelsListReducer.modelsListsdata.modelsList
    ? state.ModelsListReducer.modelsListsdata.modelsList
    : [],
  isFetching: state.ContentsListReducer.isFetchingContents,
  assetsListsdata: state.assetListReducer.assetsListsdata.deviceList,
  matchedSerialNumbers: [
    ...new Set(state.ContentsListReducer.matchedSerialNumbers),
  ]
    .toString()
    .replace(/[\]']+/g, ""),
  isSerialMatched: state.ContentsListReducer.isSerialMatched,

  initialValues: {
    jobName: state.ContentsListReducer.fetchContentForUpdateList.jobName
      ? state.ContentsListReducer.fetchContentForUpdateList.jobName
      : "",
    models: state.ContentsListReducer.fetchContentForUpdateList.models
      ? state.ContentsListReducer.fetchContentForUpdateList.models.toString()
      : "",
    description: state.ContentsListReducer.fetchContentForUpdateList.description
      ? state.ContentsListReducer.fetchContentForUpdateList.description
      : "",
    serialNumbers: state.ContentsListReducer.fetchContentForUpdateList
      .serialNumbers
      ? state.ContentsListReducer.fetchContentForUpdateList.serialNumbers.toString()
      : "",
    version: state.ContentsListReducer.fetchContentForUpdateList.version
      ? state.ContentsListReducer.fetchContentForUpdateList.version
      : "",
    mandatoryUpdate: state.ContentsListReducer.fetchContentForUpdateList
      .mandatoryUpdate
      ? state.ContentsListReducer.fetchContentForUpdateList.mandatoryUpdate.toString()
      : "",
    fileName: state.ContentsListReducer.fetchContentForUpdateList.fileName
      ? state.ContentsListReducer.fetchContentForUpdateList.fileName
      : "",
  },
});

/**
 *
 * @description {On Submit New Content Will Add}
 */

export const onSubmit = ({
  createContent,
  updateContent,
  match,
  backToListPage,
  setFileError,
}) => async (values) => {
  if (match.params.update === "update") {
    updateContent(values).then((payload) => {
      if (payload.type === "UPDATE_CONTENT_REQUEST_SUCCESS") {
        window.alert(JSON.parse(payload.payload.body).Message);
        backToListPage();
      } else {
        window.alert(payload.payload.message);
      }
    });
  } else {
    if (!document.getElementById("myFileInput").files.length) {
      setFileError("Please Select File Before Uploading !!");
    } else {
      setFileError("");
      createContent(values).then((payload) => {
        if (payload.type === "CREATE_CONTENT_REQUEST_SUCCESS") {
          window.alert(JSON.parse(payload.payload.body).message);
          backToListPage();
        } else {
          window.alert(payload.payload.message);
        }
      });
    }
  }
};

/**
 *
 * @description {On Change in File Attachment}
 */
export const onAttachmentChange = ({
  fetchCreateFileContent,
  setFileError,
}) => (e) => {
  e.preventDefault();
  const files = e.target.files[0];
  if (files) {
    setFileError("");
  }
  let allowedExtensions = /(\.exe|\.zip|\.7z)$/i;
  if (files !== undefined && allowedExtensions.exec(files.name) !== null) {
    fetchCreateFileContent(files);
  } else {
    window.alert(Alerts.invalidFile);
    document.getElementById("myFileInput").value = "";
  }
};

/**
 *
 * @description {onchange on models}
 */
export const reSetModelValue = ({ change }) => () => {
  change("CreateContentForm", "models", "");
};

/**
 *
 * @description {onpage load call API}
 */
export function componentDidMount() {
  this.props.fetchModelsLists();
  this.props.fetchAssetsLists();
  if (this.props.match.params.update === "update") {
    this.props.fetchContentForUpdate(this.props.match.params.id);
  }
}

/**
 *
 * @description { Checks  Serial}
 */
export const checkSerialIsPresent = ({
  IscheckSerialPresent,
  assetsListsdata,
  serialIsMatched,
  serialIsNotMatched,
  resetSerialMatched,
}) => (event, value) => {
  event.preventDefault();
  if (value) {
    var matchedArray = [];
    assetsListsdata.filter((item) =>
      item.deviceName === value ? matchedArray.push(item.deviceName) : []
    );

    if (matchedArray.length > 0) {
      serialIsMatched();
      IscheckSerialPresent(matchedArray[0]);
    } else {
      serialIsNotMatched();
    }
  } else {
    resetSerialMatched();
  }
};

/**
 *
 * @description {back to Contents list page}
 */
export const backToListPage = ({ history }) => () => {
  history.push("/contents-list");
};

export const clearSerialNumbers = ({
  resetSerialNumbersMatched,
  resetSerialMatched,
}) => (event) => {
  event.preventDefault();
  resetSerialNumbersMatched();
  resetSerialMatched();
};

/**
 *
 * @description {get props on change of states in store}
 */
export function componentWillReceiveProps(prevProps) {
  if (
    this.props.match.params.id !== prevProps.match.params.id ||
    this.props.location.key !== prevProps.location.key
  ) {
    this.props.resetContentPage();
    this.setState({ unique_key: this.props.location.key });
  }
}

/**
 *
 * @description {remove/unmount component values}
 */
export function componentWillUnmount() {
  this.props.resetContentPage();
}

/**
 *
 * @description {redux validation for time occurs field}
 */
export const validate = (values) => {
  const errors = {};
  if (values.models) {
    if (!values.models.toString()) {
      errors.models = "Required";
    }
  }
  return errors;
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    fetchContentsDataForUpdate,
    fetchCreateFileContent,
    updateContent,
    IscheckSerialPresent,
    fetchContentForUpdate,
    serialIsMatched,
    serialIsNotMatched,
    resetSerialMatched,
    createContent,
    fetchModelsLists,
    fetchAssetsLists,
    resetSerialNumbersMatched,
    resetContentPage,
    change,
  }),
  withState("fileError", "setFileError", ""),
  withHandlers({
    backToListPage,
  }),
  withHandlers({
    onSubmit,
    onAttachmentChange,
    checkSerialIsPresent,
    clearSerialNumbers,
    reSetModelValue,
  }),
  lifecycle({
    componentDidMount,
    componentWillReceiveProps,
    componentWillUnmount,
  }),
  reduxForm({
    form: "CreateContentForm",
    enableReinitialize: true,
    validate,
  })
);
