import React from "react";
import { Route, Redirect } from "react-router-dom";
export const PrivateRoute = ({ component: Component, roles, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      /* istanbul ignore next */
      if (!sessionStorage.getItem("userInfo")) {
        window.location.href = "/";
      }
      /* istanbul ignore next */
      function checkRoleRights(allowedRoles, elligibleRoles) {
        return allowedRoles.some((item) => elligibleRoles.includes(item));
      }
      /* istanbul ignore next */
      if (!checkRoleRights(roles, sessionStorage.getItem("roles").split(","))) { //if user dont have rights redirected to no rights page
        return <Redirect to={{ pathname: "/norightspage" }} />;
      }
      /* istanbul ignore next */
      return <Component {...props} />;
    }}
  />
);
