import React from "react";
import { Field } from "redux-form";
import { Form } from "semantic-ui-react";
import {
  InputField,
  SelectField,
  TextAreaField,
  CheckboxField
} from "react-semantic-redux-form";
import styles from "./RulesOfRulesDetails.module.scss";
import {
  required,
  OnlyNumberAllowed,
  limitCharater,
  ruleNameValidations,
} from "../../../../utilities/validations";

let options = [
  { key: "Hour", value: "Hour", text: "Hour" },
  { key: "Minutes", value: "Minutes", text: "Minutes" },
];

export const RulesOfRulesDetails = (props) => {
  const { updateProps } = props;
  return (
    <div>
      <Form.Group widths="equal">
        <label className={styles.fieldLable}>Name</label>
        <Field
          name="ruleName"
          component={InputField}
          placeholder="Rule Name"
          validate={[required]}
          disabled={updateProps ? true : false}
          normalize={ruleNameValidations}
        />
        <label className={styles.fieldLable}>Description</label>
        <Field
          name="description"
          component={TextAreaField}
          placeholder="Rule Description"
          validate={[required]}
          normalize={limitCharater}
        />
        <Field name="deleted" className={styles.alignWidth} component={CheckboxField} label="Enable" />
        <label className={styles.fieldLable}>Time Interval</label>
        <Field
          name="alarmTriggerWindowPeriod"
          component={InputField}
          placeholder="Time Interval"
          validate={[required]}
          normalize={OnlyNumberAllowed}
        />

        <Field
          name="timeFormat"
          component={SelectField}
          placeholder="Hrs/Mins"
          validate={[required]}
          options={options}
        />
      </Form.Group>
    </div>
  );
};

export default RulesOfRulesDetails;
