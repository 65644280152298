import React from "react";
import enhance from "./enhance";
import { Button, FormGroup, FormControl } from "react-bootstrap";
import styles from "../Login/SignIn.module.scss";
import logo from "../../images/Sciex.jpg";
import { Dimmer, Loader } from "semantic-ui-react";
import { commonLabels, CreateLabels } from "../../redux/constants/Values";

export const ChangePassword = (props) => {
  const { handleSubmit, loader, queryChange } = props;

  return (
    <>
      {loader ? (
        <Dimmer active inverted>
          <Loader size="large" inverted>
            {commonLabels.loading}
          </Loader>
        </Dimmer>
      ) : (
        ""
      )}
      <div className={styles.login}>
        <div>
          <form className="alignlogin shadow" onSubmit={handleSubmit}>
            <div className={styles.loginLogo}>
              {" "}
                
              <img src={logo} alt="Sciex" className="brand-logo" />{" "}
            </div>

            <FormGroup controlId="email">
              <h5> User Name</h5>
              <FormControl
                required
                autoFocus
                type="text"
                name="email"
                onChange={(e) => queryChange(e)}
              />
            </FormGroup>
            <FormGroup controlId="oldPassword">
              <h5> Old Password </h5>
              <FormControl
                required
                name="oldPassword"
                type="password"
                onChange={(e) => queryChange(e)}
              />
            </FormGroup>
            <FormGroup controlId="newPassword">
              <h5> New Password </h5>
              <FormControl
                required
                name="newPassword"
                type="password"
                onChange={(e) => queryChange(e)}
              />
            </FormGroup>
            <FormGroup controlId="confirmPassword">
              <h5> Confirm Password </h5>
              <FormControl
                required
                name="confirmPassword"
                type="password"
                onChange={(e) => queryChange(e)}
              />
            </FormGroup>
            <Button block className={styles.btnColor} type="submit">
              {CreateLabels.changePassword}
            </Button>
          </form>
        </div>
      </div>
    </>
  );
};

export default enhance(ChangePassword);
