export default [
  'FETCH_RULES_LIST_REQUEST',
  'FETCH_MODEL_PARAMETERS_LIST_REQUEST',
  'FETCH_MODELS_LIST_REQUEST',
  'FETCH_ACTIONS_LIST_REQUEST',
  'FETCH_ASSETFILTER_DATA',
  'FETCH_ALARMS_REPORT_FILTER_DATA',
  'FETCH_ONBOARDINGFILTER_DATA',
  'FETCH_CONTENTS_LIST_REQUEST',
  'FETCH_ASSETS_LIST_REQUEST',
  'FETCH_ALARMS_REPORT_LIST_REQUEST',
  'FETCH_ASSET_DETAILS_REQUEST',
  'FETCH_ALARM_DETAILS_REQUEST',
  'FETCH_RULES_DETAILS_REQUEST',
  'FETCH_RULES_FILTERED_DATA',
  'FETCH_MNEMONICS_DATA',
  'FETCH_DEVICE_PARAMS_LIST_REQUEST',
  'FETCH_MODEL_PARAMETERS_LIST_REQUEST',
  'RESET_REPORT_DETAIL_PAGE',
  'FETCH_WORKSTATION_DETAILS_REQUEST',
  'FETCH_MASS_SPEC_DETAILS_REQUEST',
  'FETCH_LC_DATA_DETAILS_REQUEST',
  'FETCH_ERROR_DATA_DETAILS_REQUEST',
  'FETCH_REPORT_FINAL_OBJ_REQUEST',
  'FETCH_SAMPLE_QUEUE_LIST_DETAILS_REQUEST',
  'FETCH_HISTORY_DATA_REQUEST',
  'FETCH_HISTORICAL_DATAITEMS_REQUEST',
  'FETCH_ACTIONS_DATA_FOR_UPDATE_REQUEST', //update actions
  'FETCH_CONTENTS_DATA_FOR_UPDATE_REQUEST', //update contents
  'FETCH_USERS_DATA_FOR_UPDATE_REQUEST', //update users
  'FETCH_MODELS_DATA_FOR_UPDATE_REQUEST', //update models
  'FETCH_ASSETS_LIST_BY_ADVANCESEARCH_REQUEST',
  'FECTH_RULES_BY_ADVANCESEARCH_REQUEST',
  'FETCH_ON_BOARDING_LIST_REQUEST',
  'FECTH_ON_BOARDING_STATUS_BY_ADVANCESEARCH_REQUEST',
  'FECTH_ON_BOARDING_STATUS_REQUEST',
  'FETCH_REPORTS_LIST_REQUEST',
  'UPDATE_REPORT_FINAL_REQUEST',
  'FETCH_CREATE_USERS_REQUEST',
  'FETCH_UPDATE_USER_REQUEST',
  'FETCH_USER_DISABLE_REQUEST',
  'FETCH_FORCE_PASSWORD_CHANGE_REQUEST',
  'FETCH_REPORT_PAGE_STATUS',
  'ADDNEW_REPORT_FINAL_REQUEST',
  'DISABLE_REPORT_SUBMIT_REQUEST',
  'ADD_VALUES_TO_ARRAY',
  'FETCH_USERS_LIST_REQUEST',
  'FETCH_USER_DATA_REQUEST',
  'CREATE_MODEL_REQUEST',
  'FETCH_REPORT_PAGE_STATUS',
  'FETCH_SINGLE_REPORT_REQUEST',
  'UPDATE_DEVICE_CODE_DATA_REQUEST',
  'CREATE_ACTION_REQUEST',
  'CREATE_CONTENT_REQUEST',
  'FETCH_RULES_DEVICE_CODE_FOR_UPDATE_REQUEST',
  'FETCH_REPORTS_PARAMETERS_REQUEST',
  'FETCH_CONTENTS_BY_ADVANCESEARCH_REQUEST',
  'FETCH_REPORTS_BY_ADVANCESEARCH_REQUEST',
  'FECTH_DATA_EXPRESSION_FOR_REVIEW',
  'IS_NEXT_BUTTON_CLICKED',
  'MODEL_NEXT_BUTTON_CLICKED',
  'VIEW_ALL_BUTTON_CLICKED',
  'HISTORY_DATA_CLICKED', // history data of mnemonics for longer timegap
  'HISTORICAL_DATAITEMS_CLICKED', //historical data items for shorter timegap
  'FETCH_ADHOC_REPORT_REQUEST',
  'DOWNLOAD_ADHOC_REPORT_REQUEST',
  'FETCH_ASSETS_DATA_REQUEST',
  'FETCH_RULES_DATA_REQUEST',
  'IS_CANCEL_BUTTON_CLICKED',
  'SAVE_DEVICE_CODE_MODAL_DETAILS_REQUEST', //device code
  'FECTH_DEVICE_CODE_FOR_REVIEW',
  'SAVE_DATA_EXPRESSION_DATA_REQUEST', // save rules
  'SAVE_DEVICE_CODE_DATA_REQUEST',
  'SET_DEVICE_PARAMETER', // Parameters
  'APPEND_PARAMETERS_TO_TEXT_AREA',
  'APPEND_VALUES_TO_TEXTAREA',
  'CLEAR_EXPRESSION_TEXTAREA',
  'SET_INVALID_EXPRESSION',
  'REMOVE_VALUES_FROM_TEXTAREA',
  'FETCH_RULES_EXPRESSION_DATA_FOR_UPDATE_REQUEST', //update rules
  'SET_TEXT_AREA_VALUE_TO_STORE', //set text values
  'RESET_EXPRESSION_DATA',
  'RESET_FORM_VALUES',
  'RESET_RULES_PAGE',
  'RESET_MODELS_PAGE',
  'RESET_ASSET_DETAILS_PAGE',
  'RESET_HISTORY_DATA',
  'RESET_HISTORICAL_DATAITEMS_DATA',
  'FETCH_MODELS_DATA_REQUEST', // models data
  'FETCH_MODELS_DEVICE_PARAMS_REQUEST', //model device params
  'FETCH_EXCEL_MODELS_DEVICE_PARAMS_REQUEST', //excel model device params
  'ADD_ATTRIBUTS_TO_ATTRIBUTES_CHECKED_LISTS',
  'REMOVE_ATTRIBUTS_TO_ATTRIBUTES_CHECKED_LISTS',
  'CREATE_MODEL_DATA_REQUEST',
  'SAVE_MODELS_DATA_REQUEST',
  'SAVE_MODELS_DATA_TO_STORE',
  'UPDATE_MODEL_DATA_REQUEST',
  'IS_BACK_BUTTON_CLICKED',
  'DETAILS_BACK_BUTTON_CLICKED',
  'UPDATE_TYPE',
  'ADD_CHECKED_MODELS_ON_ONCHANGE',
  'FETCH_PARAMETERS_LIST_REQUEST',
  'FETCH_DEVICE_CODE_LIST_REQUEST', // device code list
  'UPDATE_DATA_EXPRESSION_DATA_REQUEST', // rule expression update
  'UPDATE_CONTENT_REQUEST', //update content
  'CREATE_CONTENT_FILE',
  'IS_CHECKED_SERIAL_NUMBER_PRESENT', // check serial
  'SERIAL_IS_MATCHED',
  'SERIAL_IS_NOT_MATCHED',
  'RESET_SERIAL_MATCHED_STATUS',
  'RESET_SERIAL_NUMBERS_MATCHED',
  'FETCH_CONTENT_FOR_UPDATE_REQUEST', // fetch update for content
  'RESET_CONTENT_PAGE', // reset content page
  'SET_TEXT_AREA_VALUE_TO_STORE', //set text values
  'RESET_EXPRESSION_DATA',
  'ADD_MODELS_TO_CHECKED_LISTS_ON_UPDATE', // update checked lists
  'REMOVE_MODELS_TO_CHECKED_LISTS_ON_UPDATE',
  'ADD_PAGE_NUMBER',
  'REMOVE_PAGE_NUMBER',
  // file upload actions
  'UPLOAD_FILE_CONTENT',
  'FETCH_UPLOAD_FILE_REQUEST',
  'FETCH_UPLOAD_FILE_REQUEST_SUCCESS',
  'ADD_RULES_OF_RULES_CHECKED_LIST', // rules of rules
  'REMOVE_RULES_OF_RULES_CHECKED_LIST',
  'SAVE_RULES_OF_RULES_REQUEST',
  'FETCH_RULES_OF_RULES_FOR_UPDATE_REQUEST',
  'UPDATE_RULES_OF_RULES_REQUEST',
  'FETCH_RULES_OF_RULES_LIST_REQUEST',
  'RESET_FILE_UPLOAD_PAGE',
  'RESET_UPLOAD_FILE_CONTENT',
  'UPDATE_REMARK_REQUEST',
  'DELETE_MODEL_REQUEST',
].reduce((types, type) => {
  if (type.endsWith('REQUEST')) {
    const failureType = `${type}_FAILURE`;
    const successType = `${type}_SUCCESS`;
    return {
      ...types,
      [type]: type,
      [failureType]: failureType,
      [successType]: successType,
    };
  }

  return { ...types, [type]: type };
}, {});
