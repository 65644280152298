import types from "../actions/actionTypes";
import { createThunk } from "../../utilities/createThunk";
import backendAPI from "../../apis";
import { combineReducers } from "redux";

const initialState = {
  rulesListsdata: [],
  dataExpressionForReview: [],
  routePageStatus: "CREATE_PAGE",
  deviceCodeDetailsForReview: [],
  deviceExpressionsData: "NOVALUE",
  fetchExpressionForUpdateList: "",
  deviceCodeList: [],
  isFetchingRules: false,
  textAreaValues: [],
  fetchDeviceCodeForUpdateList: "",
  IsExpressionValidated: "",
  parameterLists: [],
  checkedModelsLists: [],
  textAreaValueToStore: "",
  rulesOfRulesCheckedList: [],
  rulesOfRulesListsdata: [],
  fetchRulesOfRulesForUpdateList: "",
  rulesFilteredData: [],
};

export const fetchRulesLists = createThunk({
  requestActionType: types.FETCH_RULES_LIST_REQUEST,
  apiCall: backendAPI.rulestListAPI.fetchRulesLists,
});

export const fetchRulesByAdvanceSearch = createThunk({
  requestActionType: types.FECTH_RULES_BY_ADVANCESEARCH_REQUEST,
  apiCall: backendAPI.rulestListAPI.fetchRulesByAdvanceSearch,
});

export const fetchRulesData = createThunk({
  requestActionType: types.FETCH_RULES_DATA_REQUEST,
  apiCall: backendAPI.rulestListAPI.fetchRulesData,
});

export const fecthDataExpressionForReview = ({ values }) => ({
  type: types.FECTH_DATA_EXPRESSION_FOR_REVIEW,
  payload: { values },
});

export const rulesNextPageRoute = () => ({
  type: types.IS_NEXT_BUTTON_CLICKED,
});

export const rulesAddPageRoute = () => ({
  type: types.IS_CANCEL_BUTTON_CLICKED,
});

// export const resetUpdateFormValues = () => ({
//   type: types.RESET_FORM_VALUES
// });

export const addDeviceCodeModalDetails = createThunk({
  requestActionType: types.SAVE_DEVICE_CODE_MODAL_DETAILS_REQUEST,
  apiCall: backendAPI.rulestListAPI.saveDeviceCodeModalDetails,
});

export const RulesListReducer = {
  fetchRulesLists,
  fetchRulesByAdvanceSearch,
  fetchRulesData,
  fecthDataExpressionForReview,
  rulesNextPageRoute,
  addDeviceCodeModalDetails,
};

const rulesListsdata = (
  state = initialState.rulesListsdata,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_RULES_LIST_REQUEST_SUCCESS:
      return payload.rulesList;
    case types.FECTH_RULES_BY_ADVANCESEARCH_REQUEST_SUCCESS:
      return payload.rulesList;
    default:
      return state;
  }
};

const dataExpressionForReview = (
  state = initialState.dataExpressionForReview,
  { type, payload }
) => {
  switch (type) {
    case types.FECTH_DATA_EXPRESSION_FOR_REVIEW:
      return payload.values;
    case types.RESET_RULES_PAGE:
      return initialState.dataExpressionForReview;
    default:
      return state;
  }
};

const routePageStatus = (
  state = initialState.routePageStatus,
  { type, payload }
) => {
  switch (type) {
    case types.IS_NEXT_BUTTON_CLICKED:
      return "REVIEW_PAGE";
    case types.IS_CANCEL_BUTTON_CLICKED:
    case types.SAVE_DATA_EXPRESSION_DATA_REQUEST_SUCCESS:
    case types.UPDATE_DATA_EXPRESSION_DATA_REQUEST_SUCCESS:
    case types.UPDATE_DEVICE_CODE_DATA_REQUEST_SUCCESS:
      return "CREATE_PAGE";
    case types.RESET_RULES_PAGE:
      return initialState.routePageStatus;
    default:
      return state;
  }
};

// Device Code

export const fecthDataCodeForReview = ({ values }) => ({
  type: types.FECTH_DEVICE_CODE_FOR_REVIEW,
  payload: { values },
});

export const routeToReviewPage = () => ({
  type: types.IS_NEXT_BUTTON_CLICKED,
});

const deviceCodeDetailsForReview = (
  state = initialState.deviceCodeDetailsForReview,
  { type, payload }
) => {
  switch (type) {
    case types.FECTH_DEVICE_CODE_FOR_REVIEW:
      return payload.values;
    case types.IS_CANCEL_BUTTON_CLICKED:
      return state;
    case types.RESET_RULES_PAGE:
      return initialState.deviceCodeDetailsForReview;
    default:
      return state;
  }
};

// expression parameter changes

export const appendValuesToTextArea = ({ rowID, expressionValues }) => ({
  type: types.APPEND_VALUES_TO_TEXTAREA,
  payload: { rowID, expressionValues },
});

export const removeValuesFromTextArea = ({ rowID }) => ({
  type: types.REMOVE_VALUES_FROM_TEXTAREA,
  payload: { rowID },
});

export const clearExpression = () => ({
  type: types.CLEAR_EXPRESSION_TEXTAREA,
});

// export const divOnChange = expressionValues => ({
//   type: types.DIV_ON_CHANGE,
//   payload: expressionValues
// });

const textAreaValues = (
  state = initialState.textAreaValues,
  { type, payload }
) => {
  switch (type) {
    case types.APPEND_VALUES_TO_TEXTAREA:
      return [...state, payload];
    case types.REMOVE_VALUES_FROM_TEXTAREA:
      return state.filter((item) => item.rowID !== payload.rowID);
    case types.CLEAR_EXPRESSION_TEXTAREA:
      return initialState.textAreaValues;
    case types.RESET_RULES_PAGE:
      return initialState.textAreaValues;
    default:
      return state;
  }
};

export const setDeviceParameterToStore = (ruleExpression) => ({
  type: types.SET_DEVICE_PARAMETER,
  payload: { ruleExpression },
});

export const setInvalidExpression = () => ({
  type: types.SET_INVALID_EXPRESSION,
});

export const reSetDeviceParameters = () => ({
  type: types.RESET_EXPRESSION_DATA,
});

const deviceExpressionsData = (
  state = initialState.deviceExpressionsData,
  { type, payload }
) => {
  switch (type) {
    case types.SET_DEVICE_PARAMETER:
      return payload;
    case types.IS_CANCEL_BUTTON_CLICKED:
      return state;
    case types.SET_INVALID_EXPRESSION:
      return "InvalidExpression";
    case types.RESET_EXPRESSION_DATA:
      return "NOVALUE";
    case types.RESET_RULES_PAGE:
      return initialState.deviceExpressionsData;
    default:
      return state;
  }
};

const IsExpressionValidated = (
  state = initialState.IsExpressionValidated,
  { type, payload }
) => {
  switch (type) {
    case types.SET_DEVICE_PARAMETER:
      return true;
    case types.SET_INVALID_EXPRESSION:
      return false;

    case types.RESET_RULES_PAGE:
    case types.CLEAR_EXPRESSION_TEXTAREA:
    case types.REMOVE_VALUES_FROM_TEXTAREA:
      //case types.IS_CANCEL_BUTTON_CLICKED:
      return initialState.IsExpressionValidated;
    default:
      return state;
  }
};

export const setTextAreaValueToStore = (value) => ({
  type: types.SET_TEXT_AREA_VALUE_TO_STORE,
  payload: value,
});

const textAreaValueToStore = (
  state = initialState.textAreaValueToStore,
  { type, payload }
) => {
  switch (type) {
    case types.SET_TEXT_AREA_VALUE_TO_STORE:
      return payload;
    case types.FETCH_RULES_EXPRESSION_DATA_FOR_UPDATE_REQUEST_SUCCESS:
      return payload.rulesList[0].ruleExpression;
    case types.RESET_RULES_PAGE:
    case types.CLEAR_EXPRESSION_TEXTAREA:
      return initialState.parameterLists;
    default:
      return state;
  }
};

// save rules

export const saveDataExpressionData = createThunk({
  requestActionType: types.SAVE_DATA_EXPRESSION_DATA_REQUEST,
  apiCall: backendAPI.rulestListAPI.saveDataExpressionData,
});

export const saveDeviceCodeData = createThunk({
  requestActionType: types.SAVE_DEVICE_CODE_DATA_REQUEST,
  apiCall: backendAPI.rulestListAPI.saveDeviceCodeData,
});

//update rules

export const fetchRulesExpressionForUpdate = createThunk({
  requestActionType: types.FETCH_RULES_EXPRESSION_DATA_FOR_UPDATE_REQUEST,
  apiCall: backendAPI.rulestListAPI.fetchRulesExpressionForUpdate,
});

export const fetchRulesDeviceCodeForUpdate = createThunk({
  requestActionType: types.FETCH_RULES_DEVICE_CODE_FOR_UPDATE_REQUEST,
  apiCall: backendAPI.rulestListAPI.fetchRulesDeviceCodeForUpdate,
});

const fetchExpressionForUpdateList = (
  state = initialState.fetchExpressionForUpdateList,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_RULES_EXPRESSION_DATA_FOR_UPDATE_REQUEST_SUCCESS:
      return payload.rulesList[0];
    case types.RESET_RULES_PAGE:
      return initialState.fetchExpressionForUpdateList;
    default:
      return state;
  }
};

const fetchDeviceCodeForUpdateList = (
  state = initialState.fetchDeviceCodeForUpdateList,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_RULES_DEVICE_CODE_FOR_UPDATE_REQUEST_SUCCESS:
      return payload.rulesList[0];
    case types.RESET_RULES_PAGE:
      return initialState.fetchDeviceCodeForUpdateList;
    default:
      return state;
  }
};

export const updateDataExpressionData = createThunk({
  requestActionType: types.UPDATE_DATA_EXPRESSION_DATA_REQUEST,
  apiCall: backendAPI.rulestListAPI.updateDataExpressionData,
});

export const updateDeviceCodeData = createThunk({
  requestActionType: types.UPDATE_DEVICE_CODE_DATA_REQUEST,
  apiCall: backendAPI.rulestListAPI.updateDeviceCodeData,
});

export const resetRulesPage = () => ({
  type: types.RESET_RULES_PAGE,
});

// device code list

export const fetchDeviceCodeList = createThunk({
  requestActionType: types.FETCH_DEVICE_CODE_LIST_REQUEST,
  apiCall: backendAPI.rulestListAPI.fetchDeviceCodeList,
});

const deviceCodeList = (
  state = initialState.deviceCodeList,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_DEVICE_CODE_LIST_REQUEST_SUCCESS:
      return payload.deviceCodeList;
    case types.RESET_RULES_PAGE:
      return initialState.deviceCodeList;
    default:
      return state;
  }
};

const isFetchingRules = (state = initialState.isFetchingRules, { type }) => {
  switch (type) {
    case types.UPDATE_DATA_EXPRESSION_DATA_REQUEST:
    case types.UPDATE_DEVICE_CODE_DATA_REQUEST:
    case types.SAVE_DATA_EXPRESSION_DATA_REQUEST:
    case types.SAVE_DEVICE_CODE_MODAL_DETAILS_REQUEST:
    case types.SAVE_DEVICE_CODE_DATA_REQUEST:
    case types.FECTH_RULES_BY_ADVANCESEARCH_REQUEST:
    case types.FETCH_RULES_LIST_REQUEST:
    case types.FETCH_RULES_DATA_REQUEST:
    case types.FETCH_RULES_OF_RULES_FOR_UPDATE_REQUEST:
    case types.SAVE_RULES_OF_RULES_REQUEST:
    case types.UPDATE_RULES_OF_RULES_REQUEST:
    case types.FETCH_PARAMETERS_LIST_REQUEST:
      return true;
    case types.UPDATE_DATA_EXPRESSION_DATA_REQUEST_SUCCESS:
    case types.UPDATE_DEVICE_CODE_DATA_REQUEST_SUCCESS:
    case types.SAVE_DATA_EXPRESSION_DATA_REQUEST_SUCCESS:
    case types.SAVE_DEVICE_CODE_MODAL_DETAILS_REQUEST_SUCCESS:
    case types.SAVE_DEVICE_CODE_DATA_REQUEST_SUCCESS:
    case types.FECTH_RULES_BY_ADVANCESEARCH_REQUEST_SUCCESS:
    case types.FETCH_RULES_LIST_REQUEST_SUCCESS:
    case types.FETCH_RULES_DATA_REQUEST_SUCCESS:
    case types.FETCH_RULES_OF_RULES_FOR_UPDATE_REQUEST_SUCCESS:
    case types.SAVE_RULES_OF_RULES_REQUEST_SUCCESS:
    case types.UPDATE_RULES_OF_RULES_REQUEST_SUCCESS:
    case types.FETCH_PARAMETERS_LIST_REQUEST_SUCCESS:
      return false;
    case types.UPDATE_DATA_EXPRESSION_DATA_REQUEST_FAILURE:
    case types.UPDATE_DEVICE_CODE_DATA_REQUEST_FAILURE:
    case types.SAVE_DATA_EXPRESSION_DATA_REQUEST_FAILURE:
    case types.SAVE_DEVICE_CODE_MODAL_DETAILS_REQUEST_FAILURE:
    case types.SAVE_DEVICE_CODE_DATA_REQUEST_FAILURE:
    case types.FECTH_RULES_BY_ADVANCESEARCH_REQUEST_FAILURE:
    case types.FETCH_RULES_LIST_REQUEST_FAILURE:
    case types.FETCH_RULES_DATA_REQUEST_FAILURE:
    case types.FETCH_RULES_OF_RULES_FOR_UPDATE_REQUEST_FAILURE:
    case types.SAVE_RULES_OF_RULES_REQUEST_FAILURE:
    case types.UPDATE_RULES_OF_RULES_REQUEST_FAILURE:
    case types.FETCH_PARAMETERS_LIST_REQUEST_FAILURE:
      return false;
    default:
      return state;
  }
};

// paramerlist

export const addCheckedModelsOnChange = (values) => ({
  type: types.ADD_CHECKED_MODELS_ON_ONCHANGE,
  payload: values,
});

const checkedModelsLists = (
  state = initialState.checkedModelsLists,
  { type, payload }
) => {
  switch (type) {
    case types.ADD_CHECKED_MODELS_ON_ONCHANGE:
      return payload.map((a) => a.value);
    case types.RESET_RULES_PAGE:
      return initialState.checkedModelsLists;
    default:
      return state;
  }
};

export const fetchParamtersList = createThunk({
  requestActionType: types.FETCH_PARAMETERS_LIST_REQUEST,
  apiCall: backendAPI.rulestListAPI.fetchParamtersList,
});

const parameterLists = (
  state = initialState.parameterLists,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_PARAMETERS_LIST_REQUEST_SUCCESS:
      return Object.keys(payload).length
        ? payload.modelList.parametersList
        : [];
    case types.FETCH_PARAMETERS_LIST_REQUEST_FAILURE:
      return [];
    case types.RESET_RULES_PAGE:
      return initialState.parameterLists;
    default:
      return state;
  }
};

export const fetchRulesOfRulesLists = createThunk({
  requestActionType: types.FETCH_RULES_OF_RULES_LIST_REQUEST,
  apiCall: backendAPI.rulestListAPI.fetchRulesOfRulesLists,
});

const rulesOfRulesListsdata = (
  state = initialState.rulesOfRulesListsdata,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_RULES_OF_RULES_LIST_REQUEST_SUCCESS:
      return payload.rulesList;
    // case types.RESET_RULES_PAGE:
    //   return initialState.rulesOfRulesListsdata;
    case "DEFAULT16":
      return payload;
    case "DEFAULT17":
      return payload;
    default:
      return state;
  }
};

export const addRulesOfRulesToRulesCheckedList = (values) => ({
  type: types.ADD_RULES_OF_RULES_CHECKED_LIST,
  payload: values,
});

export const removeRulesOfRulesToRulesCheckedList = (values) => ({
  type: types.REMOVE_RULES_OF_RULES_CHECKED_LIST,
  payload: values,
});

const rulesOfRulesCheckedList = (
  state = initialState.rulesOfRulesCheckedList,
  { type, payload }
) => {
  switch (type) {
    case types.ADD_RULES_OF_RULES_CHECKED_LIST:
      return [...state, payload];
    case types.REMOVE_RULES_OF_RULES_CHECKED_LIST:
      return state.filter((item) => item.ruleName !== payload.ruleName);
    case types.FETCH_RULES_OF_RULES_FOR_UPDATE_REQUEST_SUCCESS:
      var arr = [];
      for (var i = 0; i < payload.rulesList[0].dependentRules.length; i++) {
        arr.push({
          ruleName: payload.rulesList[0].dependentRules[i],
        });
      }
      return arr;
    case types.RESET_RULES_PAGE:
      return initialState.rulesOfRulesCheckedList;
    default:
      return state;
  }
};

export const saveRuleofRules = createThunk({
  requestActionType: types.SAVE_RULES_OF_RULES_REQUEST,
  apiCall: backendAPI.rulestListAPI.saveRuleofRules,
});

export const fetchRulesofRulesForUpdate = createThunk({
  requestActionType: types.FETCH_RULES_OF_RULES_FOR_UPDATE_REQUEST,
  apiCall: backendAPI.rulestListAPI.fetchRulesofRulesForUpdate,
});

const fetchRulesOfRulesForUpdateList = (
  state = initialState.fetchRulesOfRulesForUpdateList,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_RULES_OF_RULES_FOR_UPDATE_REQUEST_SUCCESS:
      return payload.rulesList[0];
    case types.RESET_RULES_PAGE:
      return initialState.fetchRulesOfRulesForUpdateList;
    default:
      return state;
  }
};

export const updateRuleofRules = createThunk({
  requestActionType: types.UPDATE_RULES_OF_RULES_REQUEST,
  apiCall: backendAPI.rulestListAPI.updateRuleofRules,
});

// export Filtered Data

export const fetchRulesFiltered = (payload) => ({
  type: types.FETCH_RULES_FILTERED_DATA,
  payload: payload,
});

const rulesFilteredData = (
  state = initialState.rulesFilteredData,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_RULES_FILTERED_DATA:
      return payload;
    case types.RESET_RULES_PAGE:
      return initialState.rulesFilteredData;
    default:
      return state;
  }
};

export default combineReducers({
  rulesListsdata,
  IsExpressionValidated,
  dataExpressionForReview,
  routePageStatus,
  deviceCodeDetailsForReview,
  deviceExpressionsData,
  fetchExpressionForUpdateList,
  deviceCodeList,
  isFetchingRules,
  textAreaValues,
  fetchDeviceCodeForUpdateList,
  parameterLists,
  checkedModelsLists,
  textAreaValueToStore,
  rulesOfRulesCheckedList,
  rulesOfRulesListsdata,
  fetchRulesOfRulesForUpdateList,
  rulesFilteredData,
});
