import { connect } from "react-redux";
import { compose, withHandlers, withState, lifecycle } from "recompose";
import { reduxForm } from "redux-form";
import {
  fetchUploadFileContent,
  fetchUploadFile,
  resetFileUploadPage,
  resetUploadFileContent,
} from "../../redux/reducers/UploadFileReducer";
import { Alerts } from "../../redux/constants/Values";

/**
 *
 * @description {fetch values from store}
 */
export const mapStateToProps = (state) => ({
  isUploading: state.UploadFileReducer.isUploadingFile,
  fileName: state.UploadFileReducer.uploadFileData.name,
  fileUploadType: state.form.uploadFile,
});

/**
 *
 * @description {On Change in File Attachment}
 */
export const onAttachmentChange = ({
  fetchUploadFileContent,
  setFileError,
  fileUploadType,
  resetUploadFileContent,
}) => (e) => {
  e.preventDefault();
  const files = e.target.files[0];
  if (files) {
    setFileError("");
  }
  if (fileUploadType.values !== undefined) {
    let allowedExtensions = /(\.xls|\.xlsx|\.json|\.csv)$/i;
    if (allowedExtensions.exec(files.name) !== null) {
      fetchUploadFileContent(files);
    } else {
      window.alert(Alerts.invalidFile);
      resetUploadFileContent("");
      //document.getElementsByClassName("custom-file-label")[0].innerText = "Upload file";
      // document.getElementById("custom-file").value = "";
    }
  } else {
    window.alert(Alerts.selectFileType);
    e.target.value = "";
  }
};

/**
 *
 * @description {On Submit File will Upload}
 */
export const onSubmit = ({ fetchUploadFile, setFileError, history }) => async (
  values
) => {
  let data = {
    fileType: values.entity,
  };
  if (!document.getElementById("custom-file").files.length) {
    setFileError("Please Select File Before Uploading !!");
  } else {
    fetchUploadFile(data).then((payload) => {
      if (payload.type === "FETCH_UPLOAD_FILE_REQUEST_SUCCESS") {
        // if (payload.payload.Message === "Invalid file type") {
        //   document.getElementById("custom-file").files = "";
        // } else {
        window.alert(payload.payload.Message);
        history.go("/FileUpload");
        // }
      } else {
        if (payload.payload.Message === undefined) {
          window.alert(Alerts.uploadError);
        } else {
          window.alert(payload.payload.Message);
        }
      }
    });
  }
};

/**
 *
 * @description {remove/unmount component values}
 */

export function componentWillUnmount() {
  this.props.resetFileUploadPage();
  //this.props.reset("uploadFile");
}

export default compose(
  connect(mapStateToProps, {
    fetchUploadFileContent,
    fetchUploadFile,
    resetFileUploadPage,
    resetUploadFileContent,
  }),
  withState("fileError", "setFileError", ""),
  withHandlers({ onAttachmentChange, onSubmit }),
  lifecycle({
    componentWillUnmount,
  }),
  reduxForm({
    form: "uploadFile",
    enableReinitialize: true,
  })
);
