import React from "react";
import { Header, Button, Icon } from "semantic-ui-react";
import { commonLabels } from "../../../../../redux/constants/Values";

export const ModalHeader = (props) => {
  const { closeModal } = props;
  return (
    <div className="modalContainer">
      <Header as="h3" block style={{ height: "60px" }}>
        Historical Data Item Values
        <Button
          floated="right"
          primary
          onClick={closeModal}
          animated="vertical"
        >
          <Button.Content hidden>{commonLabels.cancel}</Button.Content>
          <Button.Content visible>
            <Icon name="cancel" />
          </Button.Content>
        </Button>
      </Header>
    </div>
  );
};

export default ModalHeader;
