import api from "../config_api";
import raiseError from "./../../utilities/raiseError";
import { exportCSVFile } from "./normalizers";
import moment from "moment/min/moment-with-locales";
import { getHeaders } from "../../utilities/helpers";
import { commonLabels, pageSize } from "../../redux/constants/Values";

/**
 *
 * @description {fetch rules lists API call}
 */
export const fetchRulesLists = async ({ getState, values }) => {
  const pageSetup = {
    pageSize: pageSize.SIZE,
    offSet: "1",
  };
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(pageSetup),
  };
  const response = await fetch(api.rules_url, requestOptions);
  let responseBody;
  if (response.ok) {
    responseBody = await response.json();
    return responseBody;
  } else {
    responseBody = await response.json();
    raiseError({ response, responseBody });
  }
};

/**
 *
 * @description {fetch rules lists API call for export}
 */
export const fetchRulesData = async ({ getState, values }) => {
  const pageSetup = {
    pageSize: pageSize.SIZE,
    offSet: "1",
  };
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(pageSetup),
  };
  const response = await fetch(api.rules_url, requestOptions);
  let responseBody;
  if (response.ok) {
    responseBody = await response.json();
    exportCSVFile({ responseBody });
    return responseBody;
  } else {
    responseBody = await response.json();
    raiseError({ response, responseBody });
  }
};

/**
 *
 * @description {rules advance search API call}
 */
export const fetchRulesByAdvanceSearch = async ({ getState, values }) => {
  const pageSetup = {
    pageSize: pageSize.SIZE,
    offSet: "1",
  };

  if (values.deleted) {
    let deleted = values.deleted ? "false" : "true";
    values = { ...values, deleted, ...pageSetup };
  } else {
    let deleted = "true";
    values = { ...values, deleted, ...pageSetup };
  }

  if (values.models) {
    var models = values.models.split(",");
    values = { ...values, models, ...pageSetup };
  } else {
    values = { ...values, ...pageSetup };
  }

  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(values),
  };
  const response = await fetch(api.rules_url, requestOptions);
  let responseBody;
  if (response.ok) {
    if (response.status === 204) {
      responseBody = {
        offSet: "1",
        pageSize: pageSize.SIZE,
        rulesList: [],
      };
      window.alert(commonLabels.noContent);
    } else {
      responseBody = await response.json();
    }

    return responseBody;
  } else {
    responseBody = await response.json();
    raiseError({ response, responseBody });
  }
};

/**
 *
 * @description {Device Code Modalpopup API call}
 */
export const saveDeviceCodeModalDetails = async ({ getState, values }) => {
  let otherParameters = {
    createdBy: sessionStorage.getItem("username"),
    //updatedBy: "zxc",
  };
  values = { ...values, ...otherParameters };
  const requestOptions = {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify(values),
  };
  let responseBody;
  const response = await fetch(api.create_device_code, requestOptions);
  if (response.ok) {
    responseBody = await response.json();
    return responseBody;
  } else {
    return response.json().then((text) => {
      throw new Error(text.message);
    });
  }
};

/**
 *
 * @description {save rules DataExpression API call}
 */
export const saveDataExpressionData = async ({ getState, values }) => {
  const {
    dataExpressionForReview,
    deviceExpressionsData,
  } = getState().rulesListreducer;
  let otherParameters = {
    ruleType: "Expression",
    resetAlarmWhenFalse: false,
    resetAlarmAfterFirstTrigger: true,
    //inputValues: ["e", "d"],
    // ruleAction: "lambdaAction",
    // userGroups: JSON.parse(sessionStorage.getItem("userInfo")).userGroupsList,
    // versionTimestamp: moment(new Date())
    //   .utc()
    //   .format(),
    createdBy: sessionStorage.getItem("username"),
    // updatedBy: sessionStorage.getItem("username"),
  };

  let models = dataExpressionForReview.models.map((a) => a.value);
  let alarmTriggerWindowPeriod = "";

  if (dataExpressionForReview.timeFormat === "Minutes") {
    alarmTriggerWindowPeriod =
      dataExpressionForReview.alarmTriggerWindowPeriod * 60;
  }

  if (dataExpressionForReview.timeFormat === "Hour") {
    alarmTriggerWindowPeriod =
      dataExpressionForReview.alarmTriggerWindowPeriod * 60 * 60;
  }

  delete dataExpressionForReview.timeFormat;
  let deleted = dataExpressionForReview.deleted ? "false" : "true";

  let expressionHas = "";
  if (
    deviceExpressionsData.ruleExpression.includes("has") ||
    deviceExpressionsData.ruleExpression.includes("contains")
  ) {
    //let expression = "(Ahas'Test')or(Bhas'Test')";
    let replacedString = deviceExpressionsData.ruleExpression
      .replace(/\(/g, "(|")
      .replace(/\)/g, "|)")
      .replace(/or/g, "|or|")
      .replace(/and/g, "|and|");
    let spliltedPipeString = replacedString.split("|");
    spliltedPipeString.map((item) => {
      if (item.includes("has")) {
        let separateString = item.split("has");
        expressionHas += `regexp_matches(${separateString[0]},${separateString[1]})`;
      } else {
        expressionHas += item;
      }
    });
  }
  let updatedRuleExpression = "";
  let ruleExpression =
    expressionHas === "" ? deviceExpressionsData.ruleExpression : expressionHas;
  if (/\[.*?\]/.test(deviceExpressionsData.ruleExpression)) {
    if (
      deviceExpressionsData.ruleExpression.includes("has") ||
      deviceExpressionsData.ruleExpression.includes("contains")
    ) {
      let replacedString = ruleExpression
        .replace(/\(/g, "(|")
        .replace(/\)/g, "|)")
        .replace(/or/g, "|or|")
        .replace(/\[/g, "|[")
        .replace(/\]/g, "]|")
        .replace(/and/g, "|and|");
      let spliltedPipeString = replacedString.split("|");
      spliltedPipeString.map((item) => {
        if (item.includes("[")) {
          var mapObj = {
            "+": " + ",
            "-": " - ",
            "*": " * ",
            "/": " / ",
            "[": " [ ",
            "]": " ] ",
            ",": ",",
          };
          updatedRuleExpression = item.replace(/[+-\/*\[\]]/gi, function(
            matched
          ) {
            return mapObj[matched];
          });
          ruleExpression = ruleExpression.replace(item, updatedRuleExpression);
        }
      });
    } else {
      ruleExpression = "";
      let replacedString = deviceExpressionsData.ruleExpression
        .replace(/\(/g, "(|")
        .replace(/\)/g, "|)")
        .replace(/or/g, "|or|")
        .replace(/\[/g, "|[")
        .replace(/\]/g, "]|")
        .replace(/and/g, "|and|");
      let spliltedPipeString = replacedString.split("|");
      spliltedPipeString.map((item) => {
        if (item.includes("[")) {
          var mapObj = {
            "+": " + ",
            "-": " - ",
            "*": " * ",
            "/": " / ",
            "[": " [ ",
            "]": " ] ",
            ",": ",",
          };
          ruleExpression += item.replace(/[+-\/*\[\]]/gi, function(matched) {
            return mapObj[matched];
          });
        } else {
          ruleExpression += item;
        }
      });
    }
    console.log(ruleExpression);
  }
  values = {
    ...dataExpressionForReview,
    ...{ models },
    ...{ ruleExpression },
    ...{ alarmTriggerWindowPeriod },
    ...{ deleted },
    ...otherParameters,
  };
  const requestOptions = {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify(values),
  };
  let responseBody;
  const response = await fetch(api.create_rules_url, requestOptions);

  if (response.ok) {
    responseBody = await response.json();

    return responseBody;
  } else {
    return response.json().then((text) => {
      throw new Error(text.message);
    });
  }
};

/**
 *
 * @description {save rules Device code API call}
 */
export const saveDeviceCodeData = async ({ getState, values }) => {
  const { deviceCodeDetailsForReview } = getState().rulesListreducer;
  let otherParameters = {
    ruleExpression: "Code=" + "'" + deviceCodeDetailsForReview.ruleName + "'",
    ruleType: "deviceCode",
    resetAlarmWhenFalse: false,
    resetAlarmAfterFirstTrigger: true,
    inputValues: ["Code"],
    userGroups: JSON.parse(sessionStorage.getItem("userInfo")).userGroupsList,
    versionTimestamp: moment(new Date())
      .utc()
      .format(),
    createdBy: sessionStorage.getItem("username"),
    updatedBy: sessionStorage.getItem("username"),
    //consecutive: "yes"
  };

  let models = deviceCodeDetailsForReview.models.map((a) => a.value);

  let deleted = deviceCodeDetailsForReview.deleted ? "false" : "true";

  values = {
    ...deviceCodeDetailsForReview,
    ...{ models },
    ...{ deleted },
    ...otherParameters,
  };

  const requestOptions = {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify(values),
  };
  let responseBody;
  const response = await fetch(api.create_rules_url, requestOptions);
  if (response.ok) {
    //return response;
    responseBody = await response.json();

    return responseBody;
  } else {
    return response.json().then((text) => {
      throw new Error(text.message);
    });
  }
};

/**
 *
 * @description {update rules data expression API call}
 */
export const fetchRulesExpressionForUpdate = async ({ getState, values }) => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };
  const response = await fetch(
    api.get_rules_based_on_rulename.concat(values),
    requestOptions
  );
  let responseBody;
  if (response.ok) {
    responseBody = await response.json();
    return responseBody;
  } else {
    responseBody = await response.json();
    raiseError({ response, responseBody });
  }
};

/**
 *
 * @description {fetch rules DeviceCode for update API call}
 */
export const fetchRulesDeviceCodeForUpdate = async ({ getState, values }) => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };
  const response = await fetch(
    api.get_rules_based_on_rulename.concat(values),
    requestOptions
  );
  let responseBody;
  if (response.ok) {
    responseBody = await response.json();
    return responseBody;
  } else {
    responseBody = await response.json();
    raiseError({ response, responseBody });
  }
};

/**
 *
 * @description {fetch device code list API call}
 */
export const fetchDeviceCodeList = async ({ getState, values }) => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };
  const response = await fetch(api.device_code_list, requestOptions);
  let responseBody;

  if (response.ok) {
    responseBody = await response.json();
    return responseBody;
  } else {
    responseBody = await response.json();
    raiseError({ response, responseBody });
  }
};

/**
 *
 * @description {update dataexpression data API call}
 */
export const updateDataExpressionData = async ({ getState, values }) => {
  const {
    dataExpressionForReview,
    deviceExpressionsData,
  } = getState().rulesListreducer;
  let otherParameters = {
    ruleType: "Expression",
    resetAlarmWhenFalse: false,
    resetAlarmAfterFirstTrigger: true,
    // userGroups: JSON.parse(sessionStorage.getItem("userInfo")).userGroupsList,
    // createdBy: fetchExpressionForUpdateList.createdBy,
    updatedBy: sessionStorage.getItem("username"),
    // versionTimestamp: moment(new Date())
    //   .utc()
    //   .format(),
  };
  let models = dataExpressionForReview.models.map((a) => a.value);
  let alarmTriggerWindowPeriod = "";
  if (dataExpressionForReview.timeFormat === "Minutes") {
    alarmTriggerWindowPeriod =
      dataExpressionForReview.alarmTriggerWindowPeriod * 60;
  }

  if (dataExpressionForReview.timeFormat === "Hour") {
    alarmTriggerWindowPeriod =
      dataExpressionForReview.alarmTriggerWindowPeriod * 60 * 60;
  }

  delete dataExpressionForReview.timeFormat;
  let deleted = dataExpressionForReview.deleted ? "false" : "true";

  let expressionHas = "";
  if (
    deviceExpressionsData.ruleExpression.includes("has") ||
    deviceExpressionsData.ruleExpression.includes("contains")
  ) {
    //let expression = "(Ahas'Test')or(Bhas'Test')";
    let replacedString = deviceExpressionsData.ruleExpression
      .replace(/\(/g, "(|")
      .replace(/\)/g, "|)")
      .replace(/or/g, "|or|")
      .replace(/and/g, "|and|");
    let spliltedPipeString = replacedString.split("|");
    spliltedPipeString.map((item) => {
      if (item.includes("has")) {
        let separateString = item.split("has");
        expressionHas += `regexp_matches(${separateString[0]},${separateString[1]})`;
      } else {
        expressionHas += item;
      }
    });
  }
  let updatedRuleExpression = "";
  let ruleExpression =
    expressionHas === "" ? deviceExpressionsData.ruleExpression : expressionHas;
  if (/\[.*?\]/.test(deviceExpressionsData.ruleExpression)) {
    if (
      deviceExpressionsData.ruleExpression.includes("has") ||
      deviceExpressionsData.ruleExpression.includes("contains")
    ) {
      let replacedString = ruleExpression
        .replace(/\(/g, "(|")
        .replace(/\)/g, "|)")
        .replace(/or/g, "|or|")
        .replace(/\[/g, "|[")
        .replace(/\]/g, "]|")
        .replace(/and/g, "|and|");
      let spliltedPipeString = replacedString.split("|");
      spliltedPipeString.map((item) => {
        if (item.includes("[")) {
          var mapObj = {
            "+": " + ",
            "-": " - ",
            "*": " * ",
            "/": " / ",
            "[": " [ ",
            "]": " ] ",
            ",": ",",
          };
          updatedRuleExpression = item.replace(/[+-\/*\[\]]/gi, function(
            matched
          ) {
            return mapObj[matched];
          });
          ruleExpression = ruleExpression.replace(item, updatedRuleExpression);
        }
      });
      console.log("updaterule", updatedRuleExpression);
    } else {
      ruleExpression = "";
      let replacedString = deviceExpressionsData.ruleExpression
        .replace(/\(/g, "(|")
        .replace(/\)/g, "|)")
        .replace(/or/g, "|or|")
        .replace(/\[/g, "|[")
        .replace(/\]/g, "]|")
        .replace(/and/g, "|and|");
      let spliltedPipeString = replacedString.split("|");
      spliltedPipeString.map((item) => {
        if (item.includes("[")) {
          var mapObj = {
            "+": " + ",
            "-": " - ",
            "*": " * ",
            "/": " / ",
            "[": " [ ",
            "]": " ] ",
            ",": ",",
          };
          ruleExpression += item.replace(/[+-\/*\[\]]/gi, function(matched) {
            return mapObj[matched];
          });
        } else {
          ruleExpression += item;
        }
      });
    }
    console.log(ruleExpression);
  }
  values = {
    ...dataExpressionForReview,
    ...{ models },
    ...{ ruleExpression },
    ...{ alarmTriggerWindowPeriod },
    ...otherParameters,
    ...{ deleted },
  };

  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(values),
  };
  let responseBody;
  const response = await fetch(api.update_rules_url, requestOptions);
  if (response.ok) {
    responseBody = await response.json();
    return responseBody;
  } else {
    return response.json().then((text) => {
      throw new Error(text.message);
    });
  }
};

/**
 *
 * @description {update devicecode API call}
 */
export const updateDeviceCodeData = async ({ getState, values }) => {
  const {
    deviceCodeDetailsForReview,
    fetchDeviceCodeForUpdateList,
  } = getState().rulesListreducer;
  let otherParameters = {
    ruleExpression: fetchDeviceCodeForUpdateList.ruleExpression,
    ruleType: "deviceCode",
    resetAlarmWhenFalse: false,
    resetAlarmAfterFirstTrigger: true,
    inputValues: ["Code"],
    userGroups: JSON.parse(sessionStorage.getItem("userInfo")).userGroupsList,
    versionTimestamp: moment(new Date())
      .utc()
      .format(),
    createdBy: fetchDeviceCodeForUpdateList.createdBy,
    updatedBy: sessionStorage.getItem("username"),
  };
  let models = deviceCodeDetailsForReview.models.map((a) => a.value);
  let deleted = deviceCodeDetailsForReview.deleted ? "false" : "true";
  values = {
    ...deviceCodeDetailsForReview,
    ...{ models },
    ...{ deleted },
    ...otherParameters,
  };
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(values),
  };
  let responseBody;
  const response = await fetch(api.update_rules_url, requestOptions);
  if (response.ok) {
    responseBody = await response.json();
    return responseBody;
  } else {
    return response.json().then((text) => {
      throw new Error(text.message);
    });
  }
};

/**
 *
 * @description {fetch parameterlist API call}
 */
export const fetchParamtersList = async ({ getState, values }) => {
  values = values ? values.map((a) => a.value) : [];

  let modelValues = {
    models: values,
    pageSize: pageSize.SIZE,
    offSet: "1",
  };

  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(modelValues),
  };

  const response = await fetch(api.parameter_list_url, requestOptions);

  let responseBody;
  if (response.ok) {
    responseBody = await response.json();

    return responseBody;
  } else {
    responseBody = await response.json();
    raiseError({ response, responseBody });
  }
};

/**
 *
 * @description {fetch Rules of Rules API call}
 */
export const fetchRulesOfRulesLists = async () => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };
  const response = await fetch(api.get_list_of_rules_of_rules, requestOptions);
  let responseBody;
  if (response.ok) {
    responseBody = await response.json();
    return responseBody;
  } else {
    responseBody = await response.json();
    raiseError({ response, responseBody });
  }
};

/**
 *
 * @description {save Rules of Rules API call}
 */
export const saveRuleofRules = async ({ getState, values }) => {
  let otherParameters = {
    createdBy: sessionStorage.getItem("username"),
    resetAlarmAfterFirstTrigger: false,
    resetAlarmWhenFalse: false,
    ruleType: "ruleOfRules",
    // userGroups: JSON.parse(sessionStorage.getItem("userInfo")).userGroupsList,
    // versionTimestamp: moment(new Date())
    //   .utc()
    //   .format(),
  };

  let rulesOfRulesCheckedList = getState().rulesListreducer
    .rulesOfRulesCheckedList;
  let dependentRules = rulesOfRulesCheckedList.map((item) => item.ruleName);
  let alarmTriggerWindowPeriod;

  if (values.timeFormat === "Minutes") {
    alarmTriggerWindowPeriod = values.alarmTriggerWindowPeriod * 60;
  }

  if (values.timeFormat === "Hour") {
    alarmTriggerWindowPeriod = values.alarmTriggerWindowPeriod * 60 * 60;
  }

  delete values.timeFormat;

  let deleted = values.deleted ? "false" : "true";

  values = {
    ...values,
    ...{ alarmTriggerWindowPeriod },
    ...{ dependentRules },
    ...{ deleted },
    ...otherParameters,
  };
  const requestOptions = {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify(values),
  };

  let responseBody;
  const response = await fetch(api.create_rules_of_rules, requestOptions);
  if (response.ok) {
    responseBody = await response.json();
    return responseBody;
  } else {
    return response.json().then((text) => {
      throw new Error(text.message);
    });
  }
};

/**
 *
 * @description {fetch Rules of Rules for update API call}
 */
export const fetchRulesofRulesForUpdate = async ({ getState, values }) => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };
  const response = await fetch(
    api.get_rules_based_on_rulename.concat(values),
    requestOptions
  );
  let responseBody;
  if (response.ok) {
    responseBody = await response.json();
    return responseBody;
  } else {
    responseBody = await response.json();
    raiseError({ response, responseBody });
  }
};

/**
 *
 * @description {update Rules of Rules API call}
 */
export const updateRuleofRules = async ({ getState, values }) => {
  let otherParameters = {
    // createdBy: getState().rulesListreducer.fetchRulesOfRulesForUpdateList
    //   .createdBy,
    resetAlarmAfterFirstTrigger: false,
    resetAlarmWhenFalse: false,
    ruleType: "ruleOfRules",
    // userGroups: JSON.parse(sessionStorage.getItem("userInfo")).userGroupsList,
    // versionTimestamp: moment(new Date())
    //   .utc()
    //   .format(),
    updatedBy: sessionStorage.getItem("username"),
  };

  let rulesOfRulesCheckedList = getState().rulesListreducer
    .rulesOfRulesCheckedList;
  let dependentRules = rulesOfRulesCheckedList.map((item) => item.ruleName);
  let alarmTriggerWindowPeriod;

  if (values.timeFormat === "Minutes") {
    alarmTriggerWindowPeriod = values.alarmTriggerWindowPeriod * 60;
  }

  if (values.timeFormat === "Hour") {
    alarmTriggerWindowPeriod = values.alarmTriggerWindowPeriod * 60 * 60;
  }

  delete values.timeFormat;

  let deleted = values.deleted ? "false" : "true";

  values = {
    ...values,
    ...{ alarmTriggerWindowPeriod },
    ...{ dependentRules },
    ...{ deleted },
    ...otherParameters,
  };
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(values),
  };

  let responseBody;
  const response = await fetch(api.update_rules_of_rules, requestOptions);
  if (response.ok) {
    responseBody = await response.json();
    return responseBody;
  } else {
    return response.json().then((text) => {
      throw new Error(text.message);
    });
  }
};
