import React from "react";
import PropTypes from "prop-types";
import "bootstrap";
import enhance from "./enhance";
import { Dimmer, Loader } from "semantic-ui-react";
import AdvanceSearch from "../AdvanceSearch";
import { Divider } from "semantic-ui-react";
import ContentsTableData from "./../ContentsTableData";
import { NavItems, commonLabels } from "../../../redux/constants/Values";

export const ContentsList = ({ contentsListsdata, isFetchingContents }) => (
  <div className="containerStyle">
    <h3>{NavItems.contents + " " + commonLabels.list}</h3>
    <Divider />
    <AdvanceSearch />
    <Divider />
    {isFetchingContents ? (
      <Dimmer active inverted>
        <Loader size="large" inverted>
          {commonLabels.loading}
        </Loader>
      </Dimmer>
    ) : (
      <ContentsTableData contentsListsdata={contentsListsdata} />
    )}
  </div>
);

ContentsList.propTypes = {
  contentsListsdata: PropTypes.object,
};

export default enhance(ContentsList);
