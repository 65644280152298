import types from "../actions/actionTypes";
import { createThunk } from "../../utilities/createThunk";
import backendAPI from "../../apis";
import { combineReducers } from "redux";

const initialState = {
  usersListData: [],
  createUserData: {},
  userDisableData: {},
  passwordChangeData: {},
  updateUserData: {},
  getUserData: {},
  isFetchingUsers: false,
};

export const fetchCreateUser = createThunk({
  requestActionType: types.FETCH_CREATE_USERS_REQUEST,
  apiCall: backendAPI.usersListAPI.fetchCreateUser,
});

export const userDisable = createThunk({
  requestActionType: types.FETCH_USER_DISABLE_REQUEST,
  apiCall: backendAPI.usersListAPI.userDisable,
});

export const passwordChange = createThunk({
  requestActionType: types.FETCH_FORCE_PASSWORD_CHANGE_REQUEST,
  apiCall: backendAPI.usersListAPI.passwordChange,
});

export const updateUser = createThunk({
  requestActionType: types.FETCH_UPDATE_USER_REQUEST,
  apiCall: backendAPI.usersListAPI.updateUser,
});

export const fetchUsersLists = createThunk({
  requestActionType: types.FETCH_USERS_LIST_REQUEST,
  apiCall: backendAPI.usersListAPI.fetchUsersLists,
});

export const fetchUserData = createThunk({
  requestActionType: types.FETCH_USER_DATA_REQUEST,
  apiCall: backendAPI.usersListAPI.fetchUserData,
});

export const UsersListReducer = {
  fetchCreateUser,
  userDisable,
  passwordChange,
  updateUser,
  fetchUsersLists,
  fetchUserData,
};

const usersListdata = (
  state = initialState.usersListData,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_USERS_LIST_REQUEST_SUCCESS:
      return payload;
    case "DEFAULT18":
      return payload;
    case "DEFAULT19":
      return payload;
    default:
      return state;
  }
};

const createUserData = (
  state = initialState.createUserData,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_CREATE_USERS_REQUEST_SUCCESS:
      return payload;
    case "DEFAULT20":
      return payload;
    case "DEFAULT21":
      return payload;
    default:
      return state;
  }
};

const userDisableData = (
  state = initialState.userDisableData,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_USER_DISABLE_REQUEST_SUCCESS:
      return payload;
    case "DEFAULT221":
      return payload;
    case "DEFAULT231":
      return payload;
    default:
      return state;
  }
};

const passwordChangeData = (
  state = initialState.passwordChangeData,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_FORCE_PASSWORD_CHANGE_REQUEST_SUCCESS:
      return payload;
    case "DEFAULT222":
      return payload;
    case "DEFAULT232":
      return payload;
    default:
      return state;
  }
};

const updateUserData = (
  state = initialState.updateUserData,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_UPDATE_USER_REQUEST_SUCCESS:
      return payload;
    case "DEFAULT22":
      return payload;
    case "DEFAULT23":
      return payload;
    default:
      return state;
  }
};

const getUserData = (
  state = initialState.updateUserData,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_USER_DATA_REQUEST_SUCCESS:
      return payload;
    case "DEFAULT24":
      return payload;
    case "DEFAULT25":
      return payload;
    default:
      return state;
  }
};

const isFetchingUsers = (
  state = initialState.isFetchingUsers,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_CREATE_USERS_REQUEST:
    case types.FETCH_USER_DISABLE_REQUEST:
    case types.FETCH_FORCE_PASSWORD_CHANGE_REQUEST:
    case types.FETCH_UPDATE_USER_REQUEST:
    case types.FETCH_USER_DATA_REQUEST:
    case types.FETCH_USERS_LIST_REQUEST:
      return true;
    case types.FETCH_CREATE_USERS_REQUEST_SUCCESS:
    case types.FETCH_USER_DISABLE_REQUEST_SUCCESS:
    case types.FETCH_FORCE_PASSWORD_CHANGE_REQUEST_SUCCESS:
    case types.FETCH_UPDATE_USER_REQUEST_SUCCESS:
    case types.FETCH_USER_DATA_REQUEST_SUCCESS:
    case types.FETCH_USERS_LIST_REQUEST_SUCCESS:
    case types.FETCH_CREATE_USERS_REQUEST_FAILURE:
    case types.FETCH_USER_DISABLE_REQUEST_FAILURE:
    case types.FETCH_FORCE_PASSWORD_CHANGE_REQUEST_FAILURE:
    case types.FETCH_UPDATE_USER_REQUEST_FAILURE:
    case types.FETCH_USER_DATA_REQUEST_FAILURE:
    case types.FETCH_USERS_LIST_REQUEST_FAILURE:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  usersListdata,
  createUserData,
  userDisableData,
  passwordChangeData,
  updateUserData,
  getUserData,
  isFetchingUsers,
});
