import React from "react";
import { Form, Grid, Button, Table } from "semantic-ui-react";
import { Dimmer, Loader } from "semantic-ui-react";
import styles from "./DataExpressionReview.module.scss";
import enhance from "./enhance";
import { commonLabels } from "../../../../redux/constants/Values";

export const DataExpressionReview = (props) => {
  const {
    returnToAddPage,
    dataExpressionForReview,
    expressionParamaters,
    saveDataExpressionEvent,
    updateProps,
    cancelEvent,
    isFetching,
  } = props;
  return (
    <>
      <div>
        {isFetching ? (
          <Dimmer active inverted>
            <Loader size="large" inverted>
              {commonLabels.loading}
            </Loader>
          </Dimmer>
        ) : (
          <div>
            <Grid columns="equal">
              <Grid.Row centered>
                <Grid.Column width={8}>
                  <Form>
                    <Table size="large">
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell width={10}>
                            Review Changes of Data Expression Rule
                          </Table.HeaderCell>
                          <Table.HeaderCell width="six"></Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>

                      <Table.Body>
                        <Table.Row>
                          <Table.Cell>Name</Table.Cell>
                          <Table.Cell>
                            {dataExpressionForReview.ruleName}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Description</Table.Cell>
                          <Table.Cell>
                            {dataExpressionForReview.description}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Model(s)</Table.Cell>
                          <Table.Cell>
                            {dataExpressionForReview.models.map(
                              (label, index) => (
                                <span key={`models_${index}`}>
                                  {(index ? ", " : "") + label.label}
                                </span>
                              )
                            )}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Expression</Table.Cell>
                          <Table.Cell>
                            <p className={styles.wordBreakStyle}>
                              {expressionParamaters.ruleExpression}
                            </p>
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell>Severity</Table.Cell>
                          <Table.Cell>
                            {dataExpressionForReview.severity}
                          </Table.Cell>
                        </Table.Row>

                        <Table.Row>
                          <Table.Cell>Enabled</Table.Cell>
                          <Table.Cell>
                            {dataExpressionForReview.deleted.toString() ===
                            "true"
                              ? "Yes"
                              : "No"}
                          </Table.Cell>
                        </Table.Row>
                      </Table.Body>
                    </Table>
                  </Form>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Grid>
              <Grid.Column textAlign="center">
                <Button primary onClick={returnToAddPage}>
                  {commonLabels.back}
                </Button>
                <Button primary onClick={saveDataExpressionEvent}>
                  {updateProps === "update" ? (
                    <span>{commonLabels.update}</span>
                  ) : (
                    <span>{commonLabels.finish}</span>
                  )}
                </Button>
                <Button primary onClick={cancelEvent}>
                  {commonLabels.cancel}
                </Button>
              </Grid.Column>
            </Grid>
          </div>
        )}
      </div>
    </>
  );
};
export default enhance(DataExpressionReview);
