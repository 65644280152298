const emailRE = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const required = (value) => (value ? undefined : "Required");

export const passwordFormat = (value) =>
  value &&
  value.length >= 10 &&
  value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,})/)
    ? undefined
    : "Password Should Contain 1 Uppercase, 1 Smallercase & 1 Special Character with minimum 10 characters";

export const PhoneNumFormat = (value) =>
  value && value.length === 13 && value.match(/^(?=.*[0-9])(?=.*[+])(?=.{13,})/)
    ? undefined
    : "Phone Number length should not exceed more than 13";

export const emailFormat = (value) =>
  value && value.match(emailRE) ? undefined : "Format: example@gmail.com";

export const resetPhoneIDFormat = (value) =>
  value && (value.length === 16 || value.length === 36)
    ? undefined
    : "Format: EJSN23546BJPPSEE and Characters length should be 16 or 36";

export const requiredMealname = (value) =>
  value ? undefined : "Please fill in the Meal Name.";

export const requiredCrabs = (value) =>
  value ? undefined : "Please fill Carbs Value.";

export const OnlyNumberAllowed = (val, prevVal) => {
  if (val) {
    let returValue = /^\d+$/.test(val);
    return returValue ? val : prevVal;
  }
  return val;
};

export const resetPhoneCharterAllowed = (val, prevVal) => {
  if (val) {
    let returValue = /^[a-zA-Z0-9-]+$/.test(val);
    return returValue ? val : prevVal;
  }
  return val;
};

export const limitCharater = (val, prevVal) => {
  if (val) {
    return val.length <= 255 ? val : prevVal;
  }
  return val;
};

export const limitPhoneNumber = (val, prevVal) => {
  if (val) {
    return val.length <= 13 && val.match(/^(?=.*[0-9])(?=.*[+])(?=.{13,})/)
      ? val
      : prevVal;
  }
  return val;
};

export const OnlyNumberHexaAllowed = (val, prevVal) => {
  if (val) {
    let returValue = /^[A-FXa-fx0-9]+$/.test(val);
    return returValue ? val.toLowerCase() : prevVal;
  }
  return val;
};
export const limitDescriptionChar = (val, prevVal) => {
  if (val) {
    return val.length <= 250 ? val : prevVal;
  }
  return val;
};
export const onlyAlphaNumericAllowed = (val, prevVal) => {
  if (val) {
    let returValue = /^[a-zA-Z0-9]+$/.test(val);
    return returValue ? val : prevVal;
  }
  return val;
};
export const omitWhitespaces = (val, prevVal) => {
  if (val) {
    let returValue = /^[a-zA-Z0-9-_]+$/.test(val);
    return returValue ? val : prevVal;
  }
  return val;
};
export const RangeBetweenNumbers = (val, prevVal) => {
  if (val) {
    let returValue = /^([1-9]|[1-8][0-9]|9[0-9]|[1-8][0-9]{2}|9[0-8][0-9]|99[0-9]|[1-7][0-9]{3}|8[0-6][0-9]{2}|87[0-7][0-9]|878[0-4])$/.test(
      val
    );
    return returValue ? val : prevVal;
  }
  return val;
};

export const ruleNameValidations = (val, prevVal) => {
  if (val) {
    let returValue = /^[a-zA-Z][a-zA-Z0-9_]*$/.test(val);
    return returValue ? val : prevVal;
  }
  return val;
};

export const limitTwoDigit = (val, prevVal) => {
  if (val) {
    let returValue = /^[0-9]{1,2}?$/.test(val);
    return returValue ? val : prevVal;
  }
  return val;
};
