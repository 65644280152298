import { fetchChangePasswordDetails } from "../../redux/reducers/AuthReducer";
import { connect } from "react-redux";
import { compose, withHandlers, withState } from "recompose";
import { Alerts } from "../../redux/constants/Values";

/**
 *
 * @description {updating change password values depending on onchange values}
 */
export const queryChange = ({ setChangePassword, ChangePassword }) => (
  props
) => {
  setChangePassword({
    ...ChangePassword,
    [props.target.name]: [props.target.value],
  });
};

/**
 *
 * @description {submitting change password values onsubmit}
 */

export const handleSubmit = ({ ChangePassword, setloader, history }) => async (
  e
) => {
  e.preventDefault();
  e.persist();
  if (
    ChangePassword.newPassword[0].length >= 10 &&
    ChangePassword.newPassword[0].match(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,})/
    ) &&
    ChangePassword.confirmPassword[0].length >= 10 &&
    ChangePassword.confirmPassword[0].match(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{10,})/
    )
  ) {
    if (ChangePassword.newPassword[0] === ChangePassword.confirmPassword[0]) {
      setloader(true);
      await fetchChangePasswordDetails(
        ChangePassword.email[0],
        ChangePassword.oldPassword[0],
        ChangePassword.newPassword[0],
        history
      );
      setloader(true);
    } else {
      window.alert(Alerts.passwordMismatch);
    }
  } else {
    window.alert(Alerts.passwordRequired);
  }
};

export default compose(
  connect(null, { fetchChangePasswordDetails }),
  withState("ChangePassword", "setChangePassword", {
    email: "",
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  }),
  withState("loader", "setloader", false),
  withHandlers({ queryChange, handleSubmit })
);
