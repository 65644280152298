import { connect } from "react-redux";
import { compose, lifecycle, withHandlers, withState } from "recompose";
import { fetchModelsLists } from "../../../../redux/reducers/ModelsListReducer";
import { fetchParamtersList } from "../../../../redux/reducers/RulesListReducer";

/**
 *
 * @description {fetch value from store}
 */
export const mapStateToProps = (state) => ({
  modelsListsdata: state.ModelsListReducer.modelsListsdata.modelsList
    ? state.ModelsListReducer.modelsListsdata.modelsList
    : [],
});

/**
 *
 * @description {onchange on models}
 */
export const onChange = ({ setCheckedValues }) => (values) => {
  setCheckedValues(values); // setting values bases on change
};

/**
 *
 * @description {onblur on models}
 */
export const onBlur = ({ fetchParamtersList, checkedValues }) => () => {
  fetchParamtersList(checkedValues); // fetch parameters based on blur values
};

/**
 *
 * @description {on page load API calls}
 */
export function componentDidMount() {
  this.props.fetchModelsLists(); // fetch models
}

export default compose(
  connect(mapStateToProps, { fetchModelsLists, fetchParamtersList }),

  withState("checkedValues", "setCheckedValues", []),
  withHandlers({ onChange, onBlur }),
  lifecycle({ componentDidMount })
);
