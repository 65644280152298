import React from "react";
import { Card } from "semantic-ui-react";
import { Field } from "redux-form";
import styles from "./ExpressionModelsForRuleCard.module.scss";
import RenderSelectInput from "../../ReactMultiSelectCheckboxes";
import { required } from "./../../../../utilities/validations";
import { CreateLabels } from "../../../../redux/constants/Values";
import enhance from "./enhance";

export const ExpressionModelsForRuleCard = (props) => {
  const { modelsListsdata, onBlur, onChange } = props;

  let options =
    modelsListsdata.length !== 0
      ? modelsListsdata.map((item) => ({
          label: item.attributes.modelName,
          value: item.attributes.modelName,
        }))
      : [{ label: "Loading...", value: "" }];
  return (
    <div className="cards modelCards">
      <Card fluid>
        <Card.Content
          header={CreateLabels.modelsForRules}
          className="cardHeader"
        />
        <Card.Content extra className={styles.customHeightFix}>
          <Field
            component={RenderSelectInput}
            name="models"
            label="Choose One"
            options={options}
            placeholder="Choose One"
            className="multiCheckboxFix"
            onBlur={onBlur}
            onChange={onChange}
            validate={[required]}
          />
        </Card.Content>
      </Card>
    </div>
  );
};
export default enhance(ExpressionModelsForRuleCard);
