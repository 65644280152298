import React from 'react';
import { Item, List, Label, Segment } from 'semantic-ui-react';
import asset from '../../../../images/asset.png';
import {
  getDeviceStatusMappings,
  getICStatus,
} from '../../../../utilities/helpers';
import { Grids } from '../../../../redux/constants/Values';

export const AssetDetails = (props) => {
  const { assetDetailsdata, deviceName } = props;
  return (
    <>
      <Segment padded>
        <Label attached='top' size='big' className='bgBlue'>
          {Grids.assetDetails}
        </Label>
        <Item.Group divided>
          <Item>
            <Item.Image src={asset} />
            <Item.Content>
              <List celled verticalAlign='middle'>
                <List.Item>
                  <List.Content>
                    {' '}
                    <List.Header>
                      Serial Number
                      <List.Content floated='right'>
                        <span>{deviceName}</span>
                      </List.Content>
                    </List.Header>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    {' '}
                    <List.Header>
                      Model
                      <List.Content floated='right'>
                        <span>{assetDetailsdata.modelName}</span>
                      </List.Content>
                    </List.Header>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    {' '}
                    <List.Header>
                      Instrument Status
                      <List.Content floated='right'>
                        <span>
                          {getDeviceStatusMappings(
                            assetDetailsdata.groupStatus
                          )}
                        </span>
                      </List.Content>
                    </List.Header>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    {' '}
                    <List.Header>
                      Device Provisioning Status
                      <List.Content floated='right'>
                        <span>{assetDetailsdata.deviceStatus}</span>
                      </List.Content>
                    </List.Header>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    {' '}
                    <List.Header>
                      Desired Status
                      <List.Content floated='right'>
                        <span>{assetDetailsdata.desiredStatus}</span>
                      </List.Content>
                    </List.Header>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    {' '}
                    <List.Header>
                      Registration
                      <List.Content floated='right'>
                        <span>{assetDetailsdata.registerdOn}</span>
                      </List.Content>
                    </List.Header>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    {' '}
                    <List.Header>
                      Last Contacted
                      <List.Content floated='right'>
                        <span>{assetDetailsdata.lastContacted}</span>
                      </List.Content>{' '}
                    </List.Header>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    {' '}
                    <List.Header>
                      IC Status
                      <List.Content floated='right'>
                        <span>
                          {assetDetailsdata.ICStatus !== undefined
                            ? getICStatus(assetDetailsdata.ICStatus)
                            : 'N/A'}
                        </span>
                      </List.Content>{' '}
                    </List.Header>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    {' '}
                    <List.Header>
                      IC Last Contacted
                      <List.Content floated='right'>
                        <span>
                          {assetDetailsdata.ICLastContactedTime !== undefined
                            ? assetDetailsdata.ICLastContactedTime
                            : 'N/A'}
                        </span>
                      </List.Content>{' '}
                    </List.Header>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    {' '}
                    <List.Header>
                      Remark
                      <List.Content floated='right'>
                        <span style={{textAlign: 'right', float:'right'}}>
                          {assetDetailsdata.remarks !== undefined
                            ? assetDetailsdata.remarks
                            : 'N/A'}
                        </span>
                      </List.Content>{' '}
                    </List.Header>
                  </List.Content>
                </List.Item>
              </List>
            </Item.Content>
          </Item>
        </Item.Group>
      </Segment>
    </>
  );
};

export default AssetDetails;
