import api from '../config_api';
import raiseError from './../../utilities/raiseError';
import { getHeaders } from '../../utilities/helpers';

export const fetchUsersLists = async ({ getState, values }) => {
  const requestOptions = {
    method: 'GET',
    headers: getHeaders(),
  };
  const response = await fetch(api.get_users_url, requestOptions);
  let responseBody;
  if (response.ok) {
    responseBody = await response.json();
    return responseBody;
  } else {
    responseBody = await response.json();
    raiseError({ response, responseBody });
  }
};

export const fetchUserData = async ({ getState, values }) => {
  const requestOptions = {
    method: 'GET',
    headers: getHeaders(),
  };
  const response = await fetch(
    api.get_user_data.concat(values),
    requestOptions
  );
  let responseBody;
  if (response.ok) {
    responseBody = await response.json();
    return responseBody;
  } else {
    responseBody = await response.json();
    raiseError({ response, responseBody });
  }
};

export const fetchCreateUser = async ({ getState, values }) => {
  const requestOptions = {
    method: 'PUT',
    headers: getHeaders(),
    body: JSON.stringify(values),
  };
  const response = await fetch(api.create_user_url, requestOptions);
  let responseBody;
  if (response.ok) {
    responseBody = await response.json();
    return responseBody;
  } else {
    return response.json().then((text) => {
      throw new Error(text.message);
    });
  }
};

export const userDisable = async ({ getState, values }) => {
  const requestOptions = {
    method: 'DELETE',
    headers: getHeaders(),
  };
  const urlParam = values.userName + '/' + values.type;
  const response = await fetch(
    api.disable_user_url.concat(urlParam),
    requestOptions
  );
  let responseBody;
  if (response.ok) {
    responseBody = await response.json();
    return responseBody;
  } else {
    return response.json().then((text) => {
      throw new Error(text.message);
    });
  }
};

export const passwordChange = async ({ getState, values }) => {
  const userDetail = {
    userName: values,
  };
  const requestOptions = {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(userDetail),
  };
  const response = await fetch(api.force_password_change, requestOptions);
  let responseBody;
  if (response.ok) {
    responseBody = await response.json();
    return responseBody;
  } else {
    return response.json().then((text) => {
      throw new Error(text.message);
    });
  }
};

export const updateUser = async ({ getState, values }) => {
  const requestOptions = {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(values),
  };
  const response = await fetch(api.update_user_url, requestOptions);
  let responseBody;
  if (response.ok) {
    responseBody = await response.json();
    return responseBody;
  } else {
    return response.json().then((text) => {
      throw new Error(text.message);
    });
  }
};
