import { connect } from "react-redux";
import { compose, withHandlers, lifecycle, withState } from "recompose";
import { reduxForm, reset, change } from "redux-form";
import sqlFormatter from "sql-formatter";
import { store } from "../../../../redux/StoreConfig";

import {
  fetchReportParameters,
  fetchReportCondtions,
  fecthAddNewReportFinal,
  fetchUpdateReportFinal,
  fetchDisableSubmit,
  fetchReportFinalObj,
  fetchSingleReportDetails,
  resetReportDetailsPage,
} from "../../../../redux/reducers/ReportsListReducer";
import addProps from "./../../../../utilities/addProps";
import moment from "moment/min/moment-with-locales";
import { Alerts } from "../../../../redux/constants/Values";
let CancelSubmit = false;
/**
 *
 * @description {get values from store}
 */
export const mapStateToProps = (state) => ({
  ReportConditionData:
    state.ReportsListReducer.reportConditionArrayData.payload,
  ReportReduxData: state.form.fecthAddNewReport,
  isFetching: state.ReportsListReducer.isFetchingReports,
  DisableSubmit: state.ReportsListReducer.disableReportSubmitVal,
  reportFinalObj: state.ReportsListReducer.reportFinalObj,
  reportPageStatus: state.ReportsListReducer.reportPageStatus,
  ReportParameters: state.ReportsListReducer.reportParametersdata.parametersList
    ? state.ReportsListReducer.reportParametersdata.parametersList
    : [],
  reportUpdateParams:
    state.ReportsListReducer.reportSingleReportDetails.length !== 0
      ? state.ReportsListReducer.reportSingleReportDetails[0].updateParams
          .parametersList
      : [],
  initialValues: {
    reportName:
      state.ReportsListReducer.reportSingleReportDetails.length !== 0
        ? state.ReportsListReducer.reportSingleReportDetails[0].reportName
        : "",
    reportDescription:
      state.ReportsListReducer.reportSingleReportDetails.length !== 0
        ? state.ReportsListReducer.reportSingleReportDetails[0]
            .reportDescription
        : "",
    entity:
      state.ReportsListReducer.reportSingleReportDetails.length !== 0
        ? state.ReportsListReducer.reportSingleReportDetails[0].reportPayload
            .entity
        : "",
    SelectParameter:
      state.ReportsListReducer.reportSingleReportDetails.length !== 0
        ? state.ReportsListReducer.reportSingleReportDetails[0].reportPayload
            .selectClause
        : "",
    startDate:
      state.ReportsListReducer.reportSingleReportDetails.length !== 0
        ? state.ReportsListReducer.reportSingleReportDetails[0].startDate
        : "",
    endDate:
      state.ReportsListReducer.reportSingleReportDetails.length !== 0
        ? state.ReportsListReducer.reportSingleReportDetails[0].endDate
        : "",
  },
});

/**
 *
 * @description {onpage load API calls}
 */

export function componentDidMount() {
  this.props.SetReportArrayList([]);
  this.props.SetSqlQuery(null);
  this.props.SetQuery(null);
  let routeVal = this.props.match.params;
  if (Object.entries(routeVal).length !== 0) {
    this.props.fetchSingleReportDetails(routeVal.id).then((res) => {
      /* istanbul ignore next */
      if (res.type === "FETCH_SINGLE_REPORT_REQUEST_SUCCESS") {
        let whereCaluse = res.payload[0].reportPayload.whereClause;
        let whereCaluseAfter = [];
        for (let i = 0; i <= whereCaluse.length; i++) {
          if (whereCaluse[i] !== undefined) {
            if (
              whereCaluse[i].parameterName !== "startDate" &&
              whereCaluse[i].parameterName !== "endDate"
            ) {
              whereCaluse[i].parameterName = getHexVal(
                whereCaluse[i].parameterName
              );
              whereCaluseAfter.push(whereCaluse[i]);
            }
          }
        }
        this.props.SetReportArrayList(whereCaluseAfter);
      }
    });
  }
}

/**
 *
 * @description {remove/unmount component values}
 */
export function componentWillUnmount() {
  this.props.resetReportDetailsPage();
}

/**
 *
 * @description {get props on change of states}
 */
export function componentWillReceiveProps(prevProps) {
  if (
    this.props.match.params.id !== prevProps.match.params.id ||
    this.props.location.key !== prevProps.location.key
  ) {
    this.props.resetReportDetailsPage();
    this.props.reset("fecthAddNewReport");
    this.props.SetReportArrayList([]);
    this.props.SetQuery(null);
    this.props.fetchReportFinalObj(null);
    this.props.SetSqlQuery(null);
  }
}

/**
 *
 * @description {where clause logic}
 */
export const addWhereClauseArray = ({
  SetReportArrayList,
  fetchReportCondtions,
}) => (ReportArrayList) => {
  if (ReportArrayList.length === 0) {
    ReportArrayList.push({
      parameterName: "",
      operands: "",
      parameterInputType: { constant: true, dropdown: false },
      parameterValue: "",
      logicalOperand: "N/A",
    });
    SetReportArrayList([...ReportArrayList]);
    fetchReportCondtions(ReportArrayList);
  } else {
    ReportArrayList.push({
      parameterName: "",
      operands: "",
      parameterInputType: { constant: true, dropdown: false },
      parameterValue: "",
      logicalOperand: "",
    });
    SetReportArrayList([...ReportArrayList]);
    fetchReportCondtions(ReportArrayList);
  }
};

/**
 *
 * @description {delete parameters}
 */
export const deleteParameter = ({
  SetReportArrayList,
  fetchReportCondtions,
}) => (index, ReportArrayList) => {
  if (index === 0) {
    ReportArrayList.splice(index, 1);
    if (ReportArrayList.length === 1) {
      let ReportArrayListCopy101 = Object.assign(
        { parameterName: ReportArrayList[0].parameterName },
        { operands: ReportArrayList[0].operands },
        {
          parameterInputType: {
            constant: ReportArrayList[0].parameterInputType.constant,
            dropdown: ReportArrayList[0].parameterInputType.dropdown,
          },
        },
        { parameterValue: ReportArrayList[0].parameterValue },
        { logicalOperand: "N/A" }
      );
      ReportArrayList.splice(0, 1, ReportArrayListCopy101);
      SetReportArrayList([...ReportArrayList]);
      fetchReportCondtions([...ReportArrayList]);
    }
    SetReportArrayList([...ReportArrayList]);
    fetchReportCondtions([...ReportArrayList]);
  } else {
    ReportArrayList.splice(index, 1);
    SetReportArrayList([...ReportArrayList]);
    fetchReportCondtions([...ReportArrayList]);
  }
};

/**
 *
 * @description {onchange of object type logics}
 */
export const changeOfObject = ({
  SetReportArrayList,
  fetchReportCondtions,
}) => (data, index, ObjType, ReportArrayList) => {
  if (ObjType === "parameter") {
    let ReportArrayListCopy101 = Object.assign(
      { parameterName: data },
      { operands: ReportArrayList[index].operands },
      {
        parameterInputType: {
          constant: ReportArrayList[index].parameterInputType.constant,
          dropdown: ReportArrayList[index].parameterInputType.dropdown,
        },
      },
      { parameterValue: ReportArrayList[index].parameterValue },
      { logicalOperand: ReportArrayList[index].logicalOperand }
    );
    ReportArrayList.splice(index, 1, ReportArrayListCopy101);
    SetReportArrayList([...ReportArrayList]);
    fetchReportCondtions([...ReportArrayList]);
  } else if (ObjType === "operand") {
    let ReportArrayListCopy101 = Object.assign(
      { parameterName: ReportArrayList[index].parameterName },
      { operands: data },
      {
        parameterInputType: {
          constant: ReportArrayList[index].parameterInputType.constant,
          dropdown: ReportArrayList[index].parameterInputType.dropdown,
        },
      },
      { parameterValue: ReportArrayList[index].parameterValue },
      { logicalOperand: ReportArrayList[index].logicalOperand }
    );
    ReportArrayList.splice(index, 1, ReportArrayListCopy101);
    SetReportArrayList([...ReportArrayList]);

    fetchReportCondtions([...ReportArrayList]);
  } else if (ObjType === "radioInput") {
    let ReportArrayListCopy101 = Object.assign(
      { parameterName: ReportArrayList[index].parameterName },
      { operands: ReportArrayList[index].operands },
      {
        parameterInputType: {
          constant: true,
          dropdown: false,
        },
      },
      { parameterValue: ReportArrayList[index].parameterValue },
      { logicalOperand: ReportArrayList[index].logicalOperand }
    );
    ReportArrayListCopy101.parameterValue = "";
    ReportArrayList.splice(index, 1, ReportArrayListCopy101);
    SetReportArrayList([...ReportArrayList]);

    fetchReportCondtions([...ReportArrayList]);
  } else if (ObjType === "radioDropdown") {
    let ReportArrayListCopy101 = Object.assign(
      { parameterName: ReportArrayList[index].parameterName },
      { operands: ReportArrayList[index].operands },
      {
        parameterInputType: {
          constant: false,
          dropdown: true,
        },
      },
      { parameterValue: ReportArrayList[index].parameterValue },
      { logicalOperand: ReportArrayList[index].logicalOperand }
    );
    ReportArrayListCopy101.parameterValue = "";
    ReportArrayList.splice(index, 1, ReportArrayListCopy101);
    SetReportArrayList([...ReportArrayList]);
    fetchReportCondtions([...ReportArrayList]);
  } else if (ObjType === "parameterValue") {
    let ReportArrayListCopy101 = Object.assign(
      { parameterName: ReportArrayList[index].parameterName },
      { operands: ReportArrayList[index].operands },
      {
        parameterInputType: {
          constant: ReportArrayList[index].parameterInputType.constant,
          dropdown: ReportArrayList[index].parameterInputType.dropdown,
        },
      },
      { parameterValue: data },
      { logicalOperand: ReportArrayList[index].logicalOperand }
    );
    ReportArrayList.splice(index, 1, ReportArrayListCopy101);
    SetReportArrayList([...ReportArrayList]);

    fetchReportCondtions([...ReportArrayList]);
  } else {
    let ReportArrayListCopy101 = Object.assign(
      { parameterName: ReportArrayList[index].parameterName },
      { operands: ReportArrayList[index].operands },
      {
        parameterInputType: {
          constant: ReportArrayList[index].parameterInputType.constant,
          dropdown: ReportArrayList[index].parameterInputType.dropdown,
        },
      },
      { parameterValue: ReportArrayList[index].parameterValue },
      { logicalOperand: index === 0 ? "N/A" : data }
    );
    ReportArrayList.splice(index, 1, ReportArrayListCopy101);
    SetReportArrayList([...ReportArrayList]);

    fetchReportCondtions([...ReportArrayList]);
  }
  SetReportArrayList([...ReportArrayList]);

  fetchReportCondtions(ReportArrayList);
};

/**
 *
 * @description {getting hexa value from ascii value}
 */

export const getHexVal = (strVal) => {
  let strarr1 = [];
  let hexVal = "";
  for (let n = 0, l = strVal.length; n < l; n++) {
    let hex = Number(strVal.charCodeAt(n)).toString(16);
    strarr1.push(hex);
  }
  hexVal = "_" + strarr1.join("");
  return hexVal;
};

/**
 *
 * @description {getting ascii value from hexa value}
 */

export const getAsciiVal = (hexVal) => {
  if (hexVal !== "*") {
    let hexValue = hexVal.substring(1).toString();
    let strVal = "";
    for (var n = 0; n < hexValue.length; n += 2) {
      strVal += String.fromCharCode(parseInt(hexValue.substr(n, 2), 16));
    }
    return strVal;
  } else {
    return hexVal;
  }
};

/**
 *
 * @description {get selected clause}
 */

export const getselectClause = (ReportReduxData) => {
  if (
    ReportReduxData.values !== undefined &&
    ReportReduxData.values.hasOwnProperty("SelectParameter")
  ) {
    return ReportReduxData.values.SelectParameter;
  } else {
    return "";
  }
};

/**
 *
 * @description {get selected clause with hexa to ascii}
 */

export const getselectClauseQuery = (ReportReduxData) => {
  if (
    ReportReduxData.values !== undefined &&
    ReportReduxData.values.hasOwnProperty("SelectParameter")
  ) {
    let strValarry = [];
    ReportReduxData.values.SelectParameter.forEach((strVal1) => {
      strValarry.push(getAsciiVal(strVal1));
    });
    return strValarry;
  } else {
    return "";
  }
};

/**
 *
 * @description {get sql date column depending on entity selection}
 */

export const getEntityDateName = (ReportReduxData) => {
  if (ReportReduxData.values !== undefined) {
    switch (ReportReduxData.values.entity) {
      case "device_information": {
        return "registeredOn";
      }
      case "rule": {
        return "createTime";
      }
      case "model": {
        return "createdTime";
      }
      case "events": {
        return "alarmDate";
      }
      case "timeseries": {
        return "deviceTimeStamp";
      }
    }
  }
};

/**
 *
 * @description {get sql query where clause selection with hexa to ascii}
 */

export const getEntityDateName1 = (ReportReduxData) => {
  if (ReportReduxData.values !== undefined) {
    switch (ReportReduxData.values.entity) {
      case "device_information": {
        return getHexVal("registeredOn");
      }
      case "rule": {
        return getHexVal("createTime");
      }
      case "model": {
        return getHexVal("createdTime");
      }
      case "events": {
        return getHexVal("alarmDate");
      }
      case "timeseries": {
        return getHexVal("deviceTimeStamp");
      }
    }
  }
};

/**
 *
 * @description {get sql query where clause selection with hexa to ascii}
 */

export const getSqlWhereClause1 = (ReportConditionData, ReportReduxData) => {
  let ReportArrayListCopy101 = "";
  let whereClauseArrayBeforeJoin = [];
  let whereClauseArrayBeforeJoinAsArray = [];
  let whereClauseArray = "";
  if (ReportConditionData !== undefined && ReportConditionData.length !== 0) {
    for (let i = 0; i < ReportConditionData.length; i += 1) {
      if (i >= 1) {
        ReportArrayListCopy101 = `${
          ReportConditionData[i].logicalOperand
        } ${getAsciiVal(ReportConditionData[i].parameterName)} ${
          ReportConditionData[i].operands
        } '${ReportConditionData[i].parameterValue}'`;
        whereClauseArrayBeforeJoin.push(ReportArrayListCopy101);
      } else {
        ReportArrayListCopy101 = `${getAsciiVal(
          ReportConditionData[i].parameterName
        )} ${ReportConditionData[i].operands} '${
          ReportConditionData[i].parameterValue
        }'`;
        whereClauseArrayBeforeJoin.push(ReportArrayListCopy101);
      }
    }
    whereClauseArrayBeforeJoinAsArray = whereClauseArrayBeforeJoin;
    whereClauseArray = whereClauseArrayBeforeJoin.join(" ").toString();
    ReportArrayListCopy101 = ` ${
      ReportConditionData.length === 0 ? "" : "AND"
    } ${getEntityDateName(ReportReduxData)} BETWEEN '${moment(
      ReportReduxData.values.startDate
    )
      .add(1, "days")
      .hours(-18)
      .minutes(-30)
      .seconds(1)
      .utc()
      .format()}' AND '${moment(ReportReduxData.values.endDate)
      .add(1, "days")
      .hours(5)
      .minutes(29)
      .seconds(59)
      .utc()
      .format()}'`;
    if (whereClauseArrayBeforeJoinAsArray.length === 0) {
      let result = ReportArrayListCopy101;
      return result;
    } else {
      let result = whereClauseArray.concat(ReportArrayListCopy101);
      return result;
    }
  } else {
    return `${getEntityDateName(ReportReduxData)} BETWEEN '${moment(
      ReportReduxData.values.startDate
    )
      .add(1, "days")
      .hours(-18)
      .minutes(-30)
      .seconds(1)
      .utc()
      .format()}' AND '${moment(ReportReduxData.values.endDate)
      .add(1, "days")
      .hours(5)
      .minutes(29)
      .seconds(59)
      .utc()
      .format()}'`;
  }
};

export const getSqlWhereClause = (ReportConditionData, ReportReduxData) => {
  let ReportArrayListCopy101 = "";
  let whereClauseArrayBeforeJoin = [];
  let whereClauseArrayBeforeJoinAsArray = [];
  let whereClauseArray = "";
  if (ReportConditionData !== undefined && ReportConditionData.length !== 0) {
    for (let i = 0; i < ReportConditionData.length; i += 1) {
      if (i >= 1) {
        ReportArrayListCopy101 = `${ReportConditionData[i].logicalOperand} '${ReportConditionData[i].parameterName}' ${ReportConditionData[i].operands} '${ReportConditionData[i].parameterValue}'`;
        whereClauseArrayBeforeJoin.push(ReportArrayListCopy101);
      } else {
        ReportArrayListCopy101 = `${ReportConditionData[i].parameterName} ${ReportConditionData[i].operands} '${ReportConditionData[i].parameterValue}'`;
        whereClauseArrayBeforeJoin.push(ReportArrayListCopy101);
      }
    }
    whereClauseArrayBeforeJoinAsArray = whereClauseArrayBeforeJoin;
    whereClauseArray = whereClauseArrayBeforeJoin.join(" ").toString();
    ReportArrayListCopy101 = ` ${
      ReportConditionData.length === 0 ? "" : "AND"
    } ${getEntityDateName1(ReportReduxData)} BETWEEN '${moment(
      ReportReduxData.values.startDate
    )
      .add(1, "days")
      .hours(-18)
      .minutes(-30)
      .seconds(1)
      .utc()
      .format()}' AND '${moment(ReportReduxData.values.endDate)
      .add(1, "days")
      .hours(5)
      .minutes(29)
      .seconds(59)
      .utc()
      .format()}'`;
    if (whereClauseArrayBeforeJoinAsArray.length === 0) {
      let result = ReportArrayListCopy101;
      return result;
    } else {
      let result = whereClauseArray.concat(ReportArrayListCopy101);
      return result;
    }
  } else {
    return `${getEntityDateName1(ReportReduxData)} BETWEEN '${moment(
      ReportReduxData.values.startDate
    )
      .add(1, "days")
      .hours(-18)
      .minutes(-30)
      .seconds(1)
      .utc()
      .format()}' AND '${moment(ReportReduxData.values.endDate)
      .add(1, "days")
      .hours(5)
      .minutes(29)
      .seconds(59)
      .utc()
      .format()}'`;
  }
};

/**
 *
 * @description {validate sql query}
 */
export const checkValidSqlQuery = (reduxFormval) => {
  let Todaydate = moment(new Date().setHours(0, 0, 0, 0))
    .add(1, "days")
    .utc()
    .format();
  if (
    reduxFormval.values.reportName !== "" &&
    reduxFormval.values.reportDescription !== "" &&
    reduxFormval.values.startDate !== "" &&
    reduxFormval.values.endDate !== "" &&
    reduxFormval.values.SelectParameter.length !== 0
  ) {
    let startDateVal = moment(reduxFormval.values.startDate)
      .add(1, "days")
      .utc()
      .format();
    let endDateVal = moment(reduxFormval.values.endDate)
      .add(1, "days")
      .utc()
      .format();
    if (startDateVal <= Todaydate && endDateVal <= Todaydate) {
      if (startDateVal <= endDateVal) {
        return true;
      } else {
        window.alert(Alerts.inputDate);
        return false;
      }
    } else {
      window.alert(Alerts.inputDate);
      return false;
    }
  } else {
    window.alert(Alerts.mandatoryError);
    return false;
  }
};

/**
 *
 * @description {validate where clause}
 */
export const ValidateWhereCaluse = (
  ReportConditionData,
  fetchDisableSubmit
) => {
  if (ReportConditionData !== undefined) {
    if (ReportConditionData.length !== 0) {
      for (let i = 0; i < ReportConditionData.length; i++) {
        if (
          ReportConditionData[i].parameterName !== "" &&
          ReportConditionData[i].operands !== "" &&
          ReportConditionData[i].parameterValue !== "" &&
          ReportConditionData[i].logicalOperand !== ""
        ) {
          CancelSubmit = false;
        } else {
          CancelSubmit = true;
        }
      }
      if (CancelSubmit) {
        window.alert(Alerts.validWhereClause);
        fetchDisableSubmit(true);
        return false;
      } else {
        fetchDisableSubmit(false);
        return true;
      }
    } else {
      fetchDisableSubmit(false);
      return true;
    }
  } else {
    return true;
  }
};

/**
 *
 * @description {constructing sql query}
 */
export const constructSqlQuery = (ReportConditionData, ReportReduxData) => {
  let sqlQuerysample;
  sqlQuerysample = `SELECT ${getselectClause(ReportReduxData)} FROM
  ${ReportReduxData.values.entity}
  WHERE ${getSqlWhereClause(ReportConditionData, ReportReduxData)}`;
  return sqlQuerysample;
};

/**
 *
 * @description {constructing sql query with hexa to ascii}
 */

export const constructSqlQuery1 = (ReportConditionData, ReportReduxData) => {
  let sqlQuerysample;
  sqlQuerysample = `SELECT ${getselectClauseQuery(ReportReduxData)} FROM
  ${ReportReduxData.values.entity}
  WHERE ${getSqlWhereClause1(ReportConditionData, ReportReduxData)}`;
  return sqlQuerysample;
};

/**
 *
 * @description {constructing query}
 */
export const constructQuery = ({
  SetQuery,
  SetSqlQuery,
  ReportReduxData,
  fetchDisableSubmit,
  fetchReportFinalObj,
  ReportConditionData,
  reportPageStatus,
}) => () => {
  if (checkValidSqlQuery(ReportReduxData)) {
    if (ValidateWhereCaluse(ReportConditionData, fetchDisableSubmit)) {
      let FinalObj = {
        reportName: ReportReduxData.values.reportName,
        selectClause: getselectClause(ReportReduxData),
        whereClause: getwhereClauseQuery(ReportConditionData, ReportReduxData),
        createdBy: sessionStorage.getItem("username"),
        reportDescription: ReportReduxData.values.reportDescription,
        reportQuery: constructSqlQuery(ReportConditionData, ReportReduxData),
        entity: ReportReduxData.values.entity,
      };
      if (reportPageStatus === "UPDATE_PAGE") {
        FinalObj.updatedBy = sessionStorage.getItem("username");
      }
      SetQuery(FinalObj);
      fetchReportFinalObj(FinalObj);
      let SqlQuery = constructSqlQuery1(ReportConditionData, ReportReduxData);
      SetSqlQuery(sqlFormatter.format(SqlQuery));
      fetchDisableSubmit(false);
    }
  }
};

/**
 *
 * @description {constructing final query before submitting form}
 */
export const contructBeforeSubmit = ({
  fetchReportFinalObj,
  SetQuery,
  SetSqlQuery,
  ReportReduxData,
  fetchDisableSubmit,
  ReportConditionData,
  reportPageStatus,
}) => {
  if (checkValidSqlQuery(ReportReduxData)) {
    if (ValidateWhereCaluse(ReportConditionData, fetchDisableSubmit)) {
      let FinalObj = {
        reportName: ReportReduxData.values.reportName,
        selectClause: getselectClause(ReportReduxData),
        whereClause: getwhereClauseQuery(ReportConditionData, ReportReduxData),
        createdBy: sessionStorage.getItem("username"),
        reportDescription: ReportReduxData.values.reportDescription,
        reportQuery: constructSqlQuery(ReportConditionData, ReportReduxData),
        entity: ReportReduxData.values.entity,
      };
      if (reportPageStatus === "UPDATE_PAGE") {
        FinalObj.updatedBy = sessionStorage.getItem("username");
      }
      SetQuery(FinalObj);
      fetchReportFinalObj(FinalObj);
      // let SqlQuery = FinalObj.reportQuery;
      let SqlQuery = constructSqlQuery1(ReportConditionData, ReportReduxData);
      SetSqlQuery(sqlFormatter.format(SqlQuery));
      fetchDisableSubmit(false);
      return true;
    }
  }
};

/**
 *
 * @description {get added parameters data}
 */
export const getAddParams = ({ ReportParameters }) => {
  let ParamsArray = [];
  for (let i = 0; i < ReportParameters.length; i += 1) {
    let obj = {
      key: i,
      text: getAsciiVal(ReportParameters[i]),
      value: ReportParameters[i],
    };
    ParamsArray.push(obj);
  }
  ParamsArray.push({
    key: ReportParameters.length + 1,
    text: "*",
    value: "*",
  });
  return ParamsArray;
};

/**
 *
 * @description {get updated parameters data}
 */

export const getwhereClauseQuery = (ReportConditionData, ReportReduxData) => {
  let whereClauseArray = [];
  if (ReportConditionData !== undefined && ReportConditionData.length !== 0) {
    for (let i = 0; i < ReportConditionData.length; i += 1) {
      let ReportArrayListCopy101 = Object.assign(
        { parameterName: getAsciiVal(ReportConditionData[i].parameterName) },
        { operands: ReportConditionData[i].operands },
        {
          parameterInputType: {
            constant: ReportConditionData[i].parameterInputType.constant,
            dropdown: ReportConditionData[i].parameterInputType.dropdown,
          },
        },
        { parameterValue: ReportConditionData[i].parameterValue },
        { logicalOperand: ReportConditionData[i].logicalOperand }
      );
      whereClauseArray.push(ReportArrayListCopy101);
    }
  }
  if (
    ReportReduxData.values !== undefined &&
    ReportReduxData.values.hasOwnProperty("startDate") &&
    ReportReduxData.values.hasOwnProperty("endDate")
  ) {
    let ReportArrayListCopy101 = Object.assign(
      { parameterName: "startDate" },
      { operands: "=" },
      {
        parameterInputType: {
          constant: false,
          dropdown: false,
        },
      },
      {
        parameterValue: moment(ReportReduxData.values.startDate)
          .add(1, "days")
          .hours(-18)
          .minutes(-30)
          .seconds(1)
          .utc()
          .format(),
      },
      { logicalOperand: "AND" }
    );
    whereClauseArray.push(ReportArrayListCopy101);
    let ReportArrayListCopy102 = Object.assign(
      { parameterName: "endDate" },
      { operands: "=" },
      {
        parameterInputType: {
          constant: false,
          dropdown: false,
        },
      },
      {
        parameterValue: moment(ReportReduxData.values.endDate)
          .add(1, "days")
          .hours(5)
          .minutes(29)
          .seconds(59)
          .utc()
          .format(),
      },
      { logicalOperand: "AND" }
    );
    whereClauseArray.push(ReportArrayListCopy102);
    return whereClauseArray;
  }
  return whereClauseArray;
};

/**
 *
 * @description {get updated parameters data}
 */

export const getUpdateParams = ({ reportUpdateParams }) => {
  let ParamsArray = [];
  for (let i = 0; i < reportUpdateParams.length; i += 1) {
    let obj = {
      key: i,
      text: getAsciiVal(reportUpdateParams[i]),
      value: reportUpdateParams[i],
    };
    ParamsArray.push(obj);
  }
  ParamsArray.push({
    key: reportUpdateParams.length + 1,
    text: "*",
    value: "*",
  });
  return ParamsArray;
};

/**
 *
 * @description {final submission calls}
 */
export const callingFinalSubmit = async (
  fecthAddNewReportFinal,
  fetchUpdateReportFinal,
  history,
  pageType
) => {
  let newState = store.getState();
  let values = newState.ReportsListReducer.reportFinalObj;
  if (pageType === "CREATE_PAGE") {
    fecthAddNewReportFinal(values).then((Payload) => {
      if (Payload.type === "ADDNEW_REPORT_FINAL_REQUEST_SUCCESS") {
        window.alert(
          `${Payload.payload.reportName} ${Payload.payload.message}`
        );
        history.push("/reports-list");
      }
    });
  } else {
    fetchUpdateReportFinal(values).then((Payload) => {
      if (Payload.type === "UPDATE_REPORT_FINAL_REQUEST_SUCCESS") {
        window.alert(
          `${Payload.payload.reportName} ${Payload.payload.message}`
        );
        history.push("/reports-list");
      }
    });
  }
};

/**
 *
 * @description {final submission}
 */
export const finalSubmit = ({
  SetQuery,
  SetSqlQuery,
  ReportReduxData,
  ReportConditionData,
  fetchDisableSubmit,
  fetchReportFinalObj,
  fecthAddNewReportFinal,
  fetchUpdateReportFinal,
  reportPageStatus,
  history,
  props,
}) => async (values, pageType) => {
  if (ReportReduxData.values !== undefined) {
    if (
      contructBeforeSubmit({
        fetchReportFinalObj,
        SetQuery,
        SetSqlQuery,
        ReportReduxData,
        fetchDisableSubmit,
        ReportConditionData,
        reportPageStatus,
      })
    ) {
      callingFinalSubmit(
        fecthAddNewReportFinal,
        fetchUpdateReportFinal,
        history,
        pageType
      );
    }
  }
};

/**
 *
 * @description {change of entity}
 */
export const changeEntity = ({ fetchReportParameters }) => async (
  value,
  props
) => {
  props.dispatch(change("fecthAddNewReport", "SelectParameter", ""));
  fetchReportParameters({ value });
};

/**
 *
 * @description {clear form values after submission}
 */
/* istanbul ignore next */
export const afterSubmit = (result, dispatch) =>
  dispatch(reset("fecthAddNewReportFinal"));

export default compose(
  connect(mapStateToProps, {
    fetchReportCondtions,
    fecthAddNewReportFinal,
    fetchUpdateReportFinal,
    fetchSingleReportDetails,
    fetchDisableSubmit,
    fetchReportFinalObj,
    fetchReportParameters,
    resetReportDetailsPage,
    reset,
  }),
  addProps({ getAddParams, getUpdateParams }),
  withState("SqlQuery", "SetSqlQuery", null),
  withState("ReportArrayList", "SetReportArrayList", []),
  withState("Query", "SetQuery", null),
  withHandlers({
    deleteParameter,
    finalSubmit,
    changeEntity,
    changeOfObject,
    addWhereClauseArray,
    constructQuery,
  }),
  lifecycle({
    componentDidMount,
    componentWillUnmount,
    componentWillReceiveProps,
  }),
  reduxForm({
    form: "fecthAddNewReport",
    enableReinitialize: true,
    onSubmitSuccess: afterSubmit,
  })
);
