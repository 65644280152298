import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import {
  fetchAdhocReports,
  downloadAdhocReports,
  fetchReportsLists,
} from "../../../redux/reducers/ReportsListReducer";

/**
 *
 * @description {get values from store}
 */
export const mapStateToProps = (state) => ({
  reportAdhocdata: state.ReportsListReducer.reportsDownloadsAdhocReport,
  isFetching: state.ReportsListReducer.isFetchingReports,
});

/**
 *
 * @description {export reports data}
 */
/* istanbul ignore next */
export const btnHandler = ({
  fetchAdhocReports,
  fetchReportsLists,
}) => async (values) => {
  fetchAdhocReports(values).then((payload) => {
    if (payload.type === "FETCH_ADHOC_REPORT_REQUEST_SUCCESS") {
      fetchReportsLists();
    } else {
      window.alert(payload.payload.message);
      fetchReportsLists();
    }
  });
};

export const downloadReport = ({ downloadAdhocReports, history }) => async (
  values
) => {
  downloadAdhocReports(values).then((payload) => {
    if (payload.type === "DOWNLOAD_ADHOC_REPORT_REQUEST_SUCCESS") {
      return window.open(payload.payload);
    } else {
      window.alert(payload.payload.message);
      history.push("/reports-list");
    }
  });
};

export default compose(
  connect(mapStateToProps, {
    fetchAdhocReports,
    downloadAdhocReports,
    fetchReportsLists,
  }),
  withHandlers({ btnHandler, downloadReport })
);
