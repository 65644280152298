import types from "../actions/actionTypes";
import { createThunk } from "../../utilities/createThunk";
import backendAPI from "../../apis";
import { combineReducers } from "redux";

const initialState = {
  reportsListdata: [],
  reportsListByAdvanceSearch: [],
  reportsExecuteAdhocReport: [],
  reportsDownloadAdhocReport: [],
  reportPageStatus: "CREATE_PAGE",
  reportConditionArrayData: [],
  disableReportSubmitVal: true,
  reportFinalObj: {},
  reportParametersdata: [],
  reportSingleReportDetails: [],
  isFetchingReports: false,
};
export const fetchReportsLists = createThunk({
  requestActionType: types.FETCH_REPORTS_LIST_REQUEST,
  apiCall: backendAPI.reportsListAPI.fetchReportsLists,
});

export const fetchReportsByAdvanceSearch = createThunk({
  requestActionType: types.FETCH_REPORTS_BY_ADVANCESEARCH_REQUEST,
  apiCall: backendAPI.reportsListAPI.fetchReportsByAdvanceSearch,
});

export const fecthAddNewReportFinal = createThunk({
  requestActionType: types.ADDNEW_REPORT_FINAL_REQUEST,
  apiCall: backendAPI.reportsListAPI.fecthAddNewReportFinal,
});

export const fetchUpdateReportFinal = createThunk({
  requestActionType: types.UPDATE_REPORT_FINAL_REQUEST,
  apiCall: backendAPI.reportsListAPI.fetchUpdateReportFinal,
});

export const fetchSingleReportDetails = createThunk({
  requestActionType: types.FETCH_SINGLE_REPORT_REQUEST,
  apiCall: backendAPI.reportsListAPI.fetchSingleReportDetails,
});

export const resetReportDetailsPage = () => ({
  type: types.RESET_REPORT_DETAIL_PAGE,
});

export const fetchAdhocReports = createThunk({
  requestActionType: types.FETCH_ADHOC_REPORT_REQUEST,
  apiCall: backendAPI.reportsListAPI.fetchAdhocReports,
});

export const downloadAdhocReports = createThunk({
  requestActionType: types.DOWNLOAD_ADHOC_REPORT_REQUEST,
  apiCall: backendAPI.reportsListAPI.downlaoadAdhocReports,
});

export const fetchReportCondtions = (reportConditionsArray) => ({
  type: types.ADD_VALUES_TO_ARRAY,
  payload: reportConditionsArray,
});

export const fetchDisableSubmit = (disableReportSubmitVal) => ({
  type: types.DISABLE_REPORT_SUBMIT_REQUEST,
  payload: disableReportSubmitVal,
});

export const fetchReportFinalObj = (reportFinalObj) => ({
  type: types.FETCH_REPORT_FINAL_OBJ_REQUEST,
  payload: reportFinalObj,
});

export const fetchReportParameters = createThunk({
  requestActionType: types.FETCH_REPORTS_PARAMETERS_REQUEST,
  apiCall: backendAPI.reportsListAPI.fetchReportParameters,
});

export const ReportsListReducer = {
  fetchReportParameters,
  fetchReportsLists,
  fetchReportsByAdvanceSearch,
  fetchAdhocReports,
  fecthAddNewReportFinal,
  fetchSingleReportDetails,
};

const reportParametersdata = (
  state = initialState.reportParametersdata,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_REPORTS_PARAMETERS_REQUEST_SUCCESS:
      return payload;
    case types.RESET_REPORT_DETAIL_PAGE:
      return initialState.reportParametersdata;
    default:
      return state;
  }
};

const reportPageStatus = (
  state = initialState.reportPageStatus,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_SINGLE_REPORT_REQUEST_SUCCESS:
      return "UPDATE_PAGE";
    case types.ADDNEW_REPORT_FINAL_REQUEST_SUCCESS:
      return "CREATE_PAGE";
    case types.FETCH_SINGLE_REPORT_REQUEST_FAILURE:
      return "UPDATE_PAGE";
    case types.RESET_REPORT_DETAIL_PAGE:
      return initialState.reportPageStatus;
    default:
      return state;
  }
};

const reportSingleReportDetails = (
  state = initialState.reportSingleReportDetails,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_SINGLE_REPORT_REQUEST_SUCCESS:
      return payload;
    case types.RESET_REPORT_DETAIL_PAGE:
      return initialState.reportSingleReportDetails;
    default:
      return state;
  }
};

const reportsListsdata = (
  state = initialState.reportsListdata,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_REPORTS_LIST_REQUEST_SUCCESS:
    case types.FETCH_REPORTS_BY_ADVANCESEARCH_REQUEST_SUCCESS:
      return payload;
    case types.RESET_REPORT_DETAIL_PAGE:
      return initialState.reportsListdata;
    default:
      return state;
  }
};

const disableReportSubmitVal = (
  state = initialState.disableReportSubmitVal,
  { type, payload }
) => {
  switch (type) {
    case types.DISABLE_REPORT_SUBMIT_REQUEST:
      return payload;
    case types.RESET_REPORT_DETAIL_PAGE:
      return initialState.disableReportSubmitVal;
    default:
      return state;
  }
};

const reportFinalObj = (
  state = initialState.reportFinalObj,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_REPORT_FINAL_OBJ_REQUEST:
      return payload;
    case types.RESET_REPORT_DETAIL_PAGE:
      return initialState.reportFinalObj;
    default:
      return state;
  }
};

const reportConditionArrayData = (
  state = initialState.reportConditionArrayData,
  { type, payload }
) => {
  switch (type) {
    case types.ADD_VALUES_TO_ARRAY:
      return { ...state, payload };
    case types.FETCH_SINGLE_REPORT_REQUEST_SUCCESS:
      let obj1 = {
        payload: payload[0].reportPayload.whereClause.filter(
          (whereClauseObj) =>
            whereClauseObj.parameterName !== "startDate" &&
            whereClauseObj.parameterName !== "endDate"
        ),
      };
      let obj2 = Object.assign({}, obj1);
      return obj2;
    case types.RESET_REPORT_DETAIL_PAGE:
      return initialState.reportConditionArrayData;
    default:
      return state;
  }
};

const reportsListByAdvanceSearch = (
  state = initialState.reportsListByAdvanceSearch,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_REPORTS_BY_ADVANCESEARCH_REQUEST_SUCCESS:
      return null;
    case types.RESET_REPORT_DETAIL_PAGE:
      return initialState.reportsListByAdvanceSearch;
    default:
      return state;
  }
};

const reportsExecuteAdhocReport = (
  state = initialState.reportsExecuteAdhocReport,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_ADHOC_REPORT_REQUEST_SUCCESS:
      return payload;
    case types.RESET_REPORT_DETAIL_PAGE:
      return initialState.reportsExecuteAdhocReport;
    default:
      return state;
  }
};

const reportsDownloadsAdhocReport = (
  state = initialState.reportsDownloadAdhocReport,
  { type, payload }
) => {
  switch (type) {
    case types.DOWNLOAD_ADHOC_REPORT_REQUEST_SUCCESS:
      return payload;
    case types.RESET_REPORT_DETAIL_PAGE:
      return initialState.reportsDownloadAdhocReport;
    default:
      return state;
  }
};

const isFetchingReports = (
  state = initialState.isFetchingReports,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_REPORTS_LIST_REQUEST:
    case types.FETCH_REPORTS_BY_ADVANCESEARCH_REQUEST:
    case types.ADDNEW_REPORT_FINAL_REQUEST:
    case types.FETCH_SINGLE_REPORT_REQUEST:
    case types.FETCH_ADHOC_REPORT_REQUEST:
    case types.DOWNLOAD_ADHOC_REPORT_REQUEST:
    case types.FETCH_REPORTS_PARAMETERS_REQUEST:
    case types.UPDATE_REPORT_FINAL_REQUEST:
      return true;
    case types.FETCH_REPORTS_LIST_REQUEST_SUCCESS:
    case types.FETCH_REPORTS_BY_ADVANCESEARCH_REQUEST_SUCCESS:
    case types.ADDNEW_REPORT_FINAL_REQUEST_SUCCESS:
    case types.FETCH_ADHOC_REPORT_REQUEST_SUCCESS:
    case types.DOWNLOAD_ADHOC_REPORT_REQUEST_SUCCESS:
    case types.FETCH_SINGLE_REPORT_REQUEST_SUCCESS:
    case types.FETCH_SINGLE_REPORT_REQUEST_FAILURE:
    case types.UPDATE_REPORT_FINAL_REQUEST_SUCCESS:
    case types.UPDATE_REPORT_FINAL_REQUEST_FAILURE:
    case types.FETCH_REPORTS_PARAMETERS_REQUEST_SUCCESS:
    case types.FETCH_REPORTS_LIST_REQUEST_FAILURE:
    case types.FETCH_REPORTS_BY_ADVANCESEARCH_REQUEST_FAILURE:
    case types.ADDNEW_REPORT_FINAL_REQUEST_FAILURE:
    case types.FETCH_ADHOC_REPORT_REQUEST_FAILURE:
    case types.DOWNLOAD_ADHOC_REPORT_REQUEST_FAILURE:
    case types.FETCH_REPORTS_PARAMETERS_REQUEST_FAILURE:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  reportsListsdata,
  disableReportSubmitVal,
  reportFinalObj,
  reportsListByAdvanceSearch,
  reportsExecuteAdhocReport,
  reportsDownloadsAdhocReport,
  reportConditionArrayData,
  reportParametersdata,
  reportPageStatus,
  resetReportDetailsPage,
  reportSingleReportDetails,
  isFetchingReports,
});
