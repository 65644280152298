import raiseError from "../../utilities/raiseError";
import moment from "moment/min/moment-with-locales";
// import { exportCSVFile } from "./normalizers";
import { getHeaders } from "../../utilities/helpers";
import { commonLabels, pageSize } from "../../redux/constants/Values";
import api from "../config_api";

/**
 * @description {this calls report list api}
 */

export const fetchReportsLists = async ({ getState, values }) => {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      pageSize: pageSize.SIZE,
      offset: 1,
    }),
  };
  const response = await fetch(api.get_report_list_url, requestOptions);
  let responseBody;
  if (response.ok) {
    if (response.status === 204) {
      window.alert(commonLabels.noContent);
    }
    responseBody = await response.json();
    return responseBody;
  } else {
    responseBody = await response.json();
    window.alert(responseBody.message);
    raiseError({ response });
  }
};

/**
 * @description {this calls getting details of single report api}
 */

export const fetchSingleReportDetails = async (values) => {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      reportName: values.values,
    }),
  };
  let responseBody1;
  let responseBody2;
  let startDateVal;
  let endDateVal;

  const response = await fetch(`${api.get_single_report_url}`, requestOptions);
  if (response.ok) {
    if (response.status === 204) {
      window.alert(commonLabels.noContent);
    }
    responseBody1 = await response.json();
    if (responseBody1[0] !== undefined) {
      let whereArray = responseBody1[0].reportPayload.whereClause;
      for (let i = 0; i <= whereArray.length; i++) {
        if (whereArray[i] !== undefined) {
          if (whereArray[i].parameterName === "startDate") {
            startDateVal = moment(whereArray[i].parameterValue).format(
              "YYYY-MM-DD"
            );
          }
          if (whereArray[i].parameterName === "endDate") {
            endDateVal = moment(whereArray[i].parameterValue)
              .subtract(1, "days")
              .format("YYYY-MM-DD");
          }
        }
      }
      let requestOptions1 = {
        method: "GET",
        headers: getHeaders(),
      };
      const response = await fetch(
        `${api.get_parameter_list_url}${responseBody1[0].reportPayload.entity}`, //calling subsequent api for getting report parameters
        requestOptions1
      );
      if (response.ok) {
        if (response.status === 204) {
          window.alert(commonLabels.noContent);
        }
        responseBody2 = await response.json();
        responseBody1[0].updateParams = responseBody2;
        responseBody1[0].startDate = startDateVal;
        responseBody1[0].endDate = endDateVal;
        return responseBody1;
      } else {
        let responseBody = await response.json();
        window.alert(responseBody.message);
        raiseError({ response });
      }
    }
  } else {
    let responseBody = await response.json();
    window.alert(responseBody.message);
    raiseError({ response });
  }
};

/**
 * @description {this calls get report parameters list api}
 */

export const fetchReportParameters = async ({ getState, values }) => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };
  const response = await fetch(
    `${api.get_parameter_list_url}${values.value}`,
    requestOptions
  );
  let responseBody;
  if (response.ok) {
    if (response.status === 204) {
      window.alert(commonLabels.noContent);
    }
    responseBody = await response.json();
    return responseBody;
  } else {
    responseBody = await response.json();
    window.alert(responseBody.message);
    raiseError({ response });
  }
};

/**
 * @description {this calls add new report api}
 */

export const fecthAddNewReportFinal = async ({ getState, values }) => {
  let requestOptions = {
    method: "POST", // or 'PUT'
    headers: getHeaders(),
    body: JSON.stringify(values),
  };

  const response = await fetch(api.create_report_url, requestOptions);
  let responseBody;
  if (response.ok) {
    if (response.status === 204) {
      window.alert(commonLabels.noContent);
    }
    responseBody = await response.json();
    return responseBody;
  } else {
    responseBody = await response.json();
    window.alert(responseBody.message);
    raiseError({ response });
  }
};

/**
 * @description {this calls update new report api}
 */

export const fetchUpdateReportFinal = async ({ getState, values }) => {
  let requestOptions = {
    method: "POST", // or 'PUT'
    headers: getHeaders(),
    body: JSON.stringify(values),
  };

  const response = await fetch(api.update_report_url, requestOptions);
  let responseBody;
  if (response.ok) {
    if (response.status === 204) {
      window.alert(commonLabels.noContent);
    }
    responseBody = await response.json();
    return responseBody;
  } else {
    responseBody = await response.json();
    window.alert(responseBody.message);
    raiseError({ response });
  }
};

/**
 * @description {this calls report advance search api}
 */

export const fetchReportsByAdvanceSearch = async ({ getState, values }) => {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(values),
  };
  const response = await fetch(api.get_report_list_url, requestOptions);
  let responseBody;
  if (response.ok) {
    if (response.status === 204) {
      window.alert(commonLabels.noContent);
      return [];
    }
    responseBody = await response.json();
    return responseBody;
  } else {
    responseBody = await response.json();
    window.alert(responseBody.message);
    raiseError({ response });
  }
};

/**
 * @description {this calls query execution api(Download Report api)}
 */

export const fetchAdhocReports = async ({ getState, values }) => {
  // let ReportName = values.reportName;
  let requestOptions = {
    method: "POST", // or 'PUT'
    headers: getHeaders(),
    body: JSON.stringify({
      reportName: values.reportName,
    }),
  };

  const response = await fetch(api.execute_report_url, requestOptions);

  let responseBody;
  if (response.ok) {
    if (response.status === 204) {
      window.alert(commonLabels.noContent);
    }
    responseBody = await response.json();
    return responseBody;
  } else {
    responseBody = await response.json();
    window.alert(responseBody.message);
    raiseError({ response });
  }
};

export const downlaoadAdhocReports = async ({ getState, values }) => {
  // let ReportName = values.reportName;
  let requestOptions = {
    method: "POST", // or 'PUT'
    headers: getHeaders(),
    body: JSON.stringify({
      queryExecutionId: values.queryExecutionId,
    }),
  };
  const response = await fetch(api.download_report_url, requestOptions);
  let responseBody;
  if (response.ok) {
    if (response.status === 204) {
      window.alert(commonLabels.noContent);
    }
    responseBody = await response.json();
    // exportCSVFile({ responseBody, ReportName });
    return responseBody.downloadReportUrl;
  } else {
    responseBody = await response.json();
    window.alert(responseBody.message);
    raiseError({ response });
  }
};
