import React from "react";
import enhance from "./enhance";
import { Field } from "redux-form";
import { Form, Button, Divider, Dimmer, Loader } from "semantic-ui-react";
import { InputField, TextAreaField } from "react-semantic-redux-form";
import styles from "./AddNewModel.module.scss";
import {
  required,
  onlyAlphaNumericAllowed,
  limitCharater,
} from "./../../../utilities/validations";
import { commonLabels, CreateLabels } from "../../../redux/constants/Values";
import ParamsTableData from "../ModelsTableData/paramsTableData";

export const CreateModel = (props) => {
  const {
    handleSubmit,
    modelsDeviceParams,
    isFetching,
    toggleRow,
    fetchParams,
    toggleSelectAll,
    backToListPage,
    modelsChekedLists,
    pageSize,
  } = props;
  /* istanbul ignore next */
  return (
    <>
      {isFetching ? (
        <Dimmer active inverted>
          <Loader size="large" inverted>
            {commonLabels.loading}
          </Loader>
        </Dimmer>
      ) : (
        <div className="containerStyle">
          <h4>{CreateLabels.models}</h4>
          <Divider />
          <div className="toggleContent">
            <Form onSubmit={handleSubmit}>
              <Form.Group widths="equal">
                <label className={styles.fieldLable}>Model Name</label>
                <Field
                  name="modelName"
                  component={InputField}
                  placeholder="Model Name"
                  validate={[required]}
                  onBlur={(e) => fetchParams(e, true)}
                  normalize={onlyAlphaNumericAllowed}
                />
                <label className={styles.fieldLable}>Description</label>
                <Field
                  name="description"
                  component={TextAreaField}
                  placeholder="Description"
                  cols="50"
                  validate={[required]}
                  normalize={limitCharater}
                />
              </Form.Group>
              <ParamsTableData
                toggleRow={toggleRow}
                toggleSelectAll={toggleSelectAll}
                pageSize={pageSize}
                modelsDeviceParams={modelsDeviceParams}
                modelsChekedLists={modelsChekedLists}
              />
              <Button.Group>
                <Button
                  primary
                  type="submit"
                  className={
                    modelsChekedLists.length > 0 ? "" : styles.addDisabled
                  }
                >
                  {commonLabels.add}
                </Button>
                <Button.Or />
                <Button
                  data-test="cancel-btn"
                  negative
                  onClick={backToListPage}
                >
                  {commonLabels.cancel}
                </Button>
              </Button.Group>
            </Form>
          </div>
        </div>
      )}
    </>
  );
};

export default enhance(CreateModel);
