import React, { useState } from 'react';
import enhance from './enhance';
import { Form, Button, Input, Select } from 'semantic-ui-react';
import { commonLabels } from '../../../redux/constants/Values';

const SelectOptions = [
  { key: 1, text: '121', value: '121' },
  { key: 2, text: '161', value: '161' },
  { key: 3, text: '181', value: '181' },
  { key: 4, text: '120', value: '120' },
  { key: 5, text: '160', value: '160' },
  { key: 6, text: '180', value: '180' },
  { key: 7, text: '122', value: '122' },
  { key: 8, text: '162', value: '162' },
  { key: 9, text: '182', value: '182' },
  { key: 19, text: '123', value: '123' },
  { key: 11, text: '163', value: '163' },
  { key: 12, text: '183', value: '183' },
];

export const AdvanceSearch = (props) => {
  const {
    modelsListsdata,
    deviceParameters,
    handleSubmit,
    handleChange,
    handleReset,
    AlarmForm,
  } = props;
  const [open, togglePanel] = useState(false);

  const model_params =
    modelsListsdata.length !== 0
      ? modelsListsdata.map((item) => ({
          key: item.attributes.modelName,
          value: item.attributes.modelName,
          text: item.attributes.modelName,
        }))
      : [{ key: 'Loading...', value: '', text: 'Loading...' }];
  const device_params =
    deviceParameters.length !== 0
      ? deviceParameters.map((item) => ({
          key: item.displayName + ' (' + item.mnemonic + ')',
          value: item.mnemonic,
          text: item.displayName + ' (' + item.mnemonic + ')',
        }))
      : [{ key: 'Loading...', value: '', text: 'Loading...' }];

  return (
    <div>
      <div onClick={() => togglePanel(!open)} className='toggleHeader'>
        <i
          className={open ? 'caret square up icon' : 'caret square down icon'}
        ></i>
        {commonLabels.advanceSearch}
      </div>
      {open ? (
        <div className='toggleContent'>
          {' '}
          <Form>
            <Form.Group widths='equal'>
              <Form.Field
                control={Input}
                fluid
                label='Asset'
                placeholder='Asset'
                name='assetName'
                value={AlarmForm.assetName}
                onChange={(e, data) => handleChange(e, data)}
              />
              <Form.Field
                control={Select}
                fluid
                label='Model'
                options={model_params}
                placeholder='Model'
                name='Model'
                value={AlarmForm.Model}
                onChange={(e, data) => handleChange(e, data)}
                search
                clearable
              />
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Field
                control={Select}
                fluid
                label='Data Item'
                options={device_params}
                placeholder='Data Item'
                name='DataItem'
                value={AlarmForm.DataItem}
                onChange={(e, data) => handleChange(e, data)}
                search
                clearable
              />
              <Form.Field
                fluid
                control={Select}
                label='Alarm Severity'
                options={SelectOptions}
                placeholder=''
                name='AlarmSeverity'
                value={AlarmForm.AlarmSeverity}
                onChange={(e, data) => handleChange(e, data)}
                search
                clearable
              />
              <Form.Field
                control={Input}
                fluid
                label='Alarm Description'
                placeholder='Alarm Description'
                name='AlarmDescription'
                value={AlarmForm.AlarmDescription}
                onChange={(e, data) => handleChange(e, data)}
              />
            </Form.Group>
            <Form.Group widths='equal'>
              <Form.Field
                control={Input}
                type='Date'
                label='Start Date'
                name='startDate'
                value={AlarmForm.startDate}
                onChange={(e, data) => handleChange(e, data)}
              />
              <Form.Field
                control={Input}
                type='Time'
                label='Start Time'
                name='startTime'
                value={AlarmForm.startTime}
                onChange={(e, data) => handleChange(e, data)}
              />
              <Form.Field
                control={Input}
                type='Date'
                label='End Date'
                placeholder='End date'
                name='endDate'
                value={AlarmForm.endDate}
                onChange={(e, data) => handleChange(e, data)}
              />
              <Form.Field
                control={Input}
                type='Time'
                label='End Time'
                placeholder='End date'
                name='endTime'
                value={AlarmForm.endTime}
                onChange={(e, data) => handleChange(e, data)}
              />
            </Form.Group>
            <Button.Group>
              <Button positive onClick={() => handleSubmit()}>
                {commonLabels.search}
              </Button>
              <Button.Or />
              <Button negative onClick={() => handleReset()}>
                {commonLabels.clear}
              </Button>
            </Button.Group>
          </Form>
        </div>
      ) : null}
    </div>
  );
};

export default enhance(AdvanceSearch);
