import React from "react";
import enhance from "./enhance";
import { Form, Button, Divider } from "semantic-ui-react";
import { Field } from "redux-form";
// import { isValidPhoneNumber } from "react-phone-number-input";
import { Dimmer, Loader } from "semantic-ui-react";
import {
  InputField,
  SelectField,
  // CheckboxField,
} from "react-semantic-redux-form";
import "react-phone-number-input/style.css";
// import RenderPhoneInput from "../PhoneInput";
import {
  required,
  emailFormat,
  passwordFormat,
} from "./../../../utilities/validations";
import { commonLabels, CreateLabels } from "../../../redux/constants/Values";

export const CreateUser = (props) => {
  const { handleSubmit, backToListPage, isFetching } = props;
  // const [phonevalue, setPhonevalue] = useState();
  const userGroupsOptions = [
    { key: 1, text: "Admin", value: "adminGroup" },
    { key: 2, text: "Read Only User", value: "readOnlyUser" },
  ];

  return (
    <>
      {isFetching && (
        <Dimmer active inverted>
          <Loader size="large" inverted>
            {commonLabels.loading}
          </Loader>
        </Dimmer>
      )}
      <div className="containerStyle">
        <h4>{CreateLabels.users}</h4>
        <Divider />
        <div className="toggleContent">
          <Form onSubmit={handleSubmit}>
            <Field
              component={InputField}
              label="First name"
              placeholder="First name"
              name="firstname"
              validate={[required]}
            />
            <Field
              component={InputField}
              label="Last name"
              placeholder="Last name"
              name="lastname"
              validate={[required]}
            />
            <Field
              component={SelectField}
              label="User groups"
              options={userGroupsOptions}
              value={userGroupsOptions}
              placeholder="User Groups"
              name="usergroups"
              validate={[required]}
            />
            <Field
              component={InputField}
              label="Email"
              placeholder="Email"
              name="email"
              validate={[required, emailFormat]}
            />
            {/* <Form.Group widths="equal">
              <Field
                component={CheckboxField}
                label="Send Mail"
                name="sendMail"
                defaultChecked={false}
              />
            </Form.Group> */}
            {/* <Field
              value={phonevalue}
              name="phone"
              onChange={setPhonevalue}
              component={RenderPhoneInput}
              validate={[required]}
              // normalize={limitPhoneNumber}
            /> */}
            <Field
              component={InputField}
              type="password"
              label="Password"
              placeholder="Password"
              name="password"
              validate={[required, passwordFormat]}
            />
            <Button.Group>
              <Button positive>{commonLabels.add}</Button>
              <Button.Or />
              <Button negative onClick={backToListPage}>
                {commonLabels.cancel}
              </Button>
            </Button.Group>
          </Form>
        </div>
      </div>
    </>
  );
};

export default enhance(CreateUser);
