import types from '../actions/actionTypes';
import { createThunk } from '../../utilities/createThunk';
import backendAPI from '../../apis';
import { combineReducers } from 'redux';

const initialState = {
  AssetFilteredData: [],
  assetsListData: [],
  assetDetailsData: {},
  isFetchingAssets: false,
  isFetchingAlarmsData: false,
  isFetchingRulesData: false,
  isFetchingWorkstationData: false,
  isFetchingMassspecData: false,
  isFetchingLCData: false,
  isFetchingErrorData: false,
  isFetchingSampleQueueData: false,
  isFetchingHistoryData: false,
  isFetchingHistoricalDataitemsData: false,
  alarmDetailsData: {},
  rulesDetailsdata: {},
  mnemonicsFilteredData: [],
  workstationDetailsData: {},
  massSpecDetailsData: {},
  lcDataDetailsData: {},
  errorDataDetailsData: {},
  sampleQueueListDetailsData: {},
  historyDetailsData: [],
  historicalDataitemsDetailsData: [],
  detailsPageStatus: 'VIEW_LIST_PAGE',
  assetsListByAdvanceSearch: [],
  parameterLists: [],
  assetsDeviceParameters: [],
  tableType: '',
  selectedMnemonic: '',
};

export const fetchAssetsLists = createThunk({
  requestActionType: types.FETCH_ASSETS_LIST_REQUEST,
  apiCall: backendAPI.assetListAPI.fetchAssetsLists,
});

export const fetchDeviceParameters = createThunk({
  requestActionType: types.FETCH_DEVICE_PARAMS_LIST_REQUEST,
  apiCall: backendAPI.assetListAPI.fetchDeviceParameters,
});

export const fetchParamtersList = createThunk({
  requestActionType: types.FETCH_MODEL_PARAMETERS_LIST_REQUEST,
  apiCall: backendAPI.assetListAPI.fetchParamtersList,
});

export const fetchAssetDetails = createThunk({
  requestActionType: types.FETCH_ASSET_DETAILS_REQUEST,
  apiCall: backendAPI.assetListAPI.fetchAssetDetails,
});

export const fetchAlarmDetails = createThunk({
  requestActionType: types.FETCH_ALARM_DETAILS_REQUEST,
  apiCall: backendAPI.assetListAPI.fetchAlarmDetails,
});

export const fetchRulesDetails = createThunk({
  requestActionType: types.FETCH_RULES_DETAILS_REQUEST,
  apiCall: backendAPI.assetListAPI.fetchRulesDetails,
});

export const fetchWorkstationDetails = createThunk({
  requestActionType: types.FETCH_WORKSTATION_DETAILS_REQUEST,
  apiCall: backendAPI.assetListAPI.fetchWorkstationDetails,
});

export const fetchMassSpecDetails = createThunk({
  requestActionType: types.FETCH_MASS_SPEC_DETAILS_REQUEST,
  apiCall: backendAPI.assetListAPI.fetchMassSpecDetails,
});

export const fetchLCDataDetails = createThunk({
  requestActionType: types.FETCH_LC_DATA_DETAILS_REQUEST,
  apiCall: backendAPI.assetListAPI.fetchLCDataDetails,
});

export const fetchErrorDataDetails = createThunk({
  requestActionType: types.FETCH_ERROR_DATA_DETAILS_REQUEST,
  apiCall: backendAPI.assetListAPI.fetchErrorDataDetails,
});

export const fetchSampleQueueListDetails = createThunk({
  requestActionType: types.FETCH_SAMPLE_QUEUE_LIST_DETAILS_REQUEST,
  apiCall: backendAPI.assetListAPI.fetchSampleQueueListDetails,
});

export const fetchMnemonicsData = (payload) => ({
  type: types.FETCH_MNEMONICS_DATA,
  payload: payload,
});

export const fetchHistoryData = createThunk({
  requestActionType: types.FETCH_HISTORY_DATA_REQUEST,
  apiCall: backendAPI.assetListAPI.fetchHistoryData,
});

export const fetchHistoricalDataitems = createThunk({
  requestActionType: types.FETCH_HISTORICAL_DATAITEMS_REQUEST,
  apiCall: backendAPI.assetListAPI.fetchHistoricalDataitems,
});

export const fetchAssetsByAdvanceSearch = createThunk({
  requestActionType: types.FETCH_ASSETS_LIST_BY_ADVANCESEARCH_REQUEST,
  apiCall: backendAPI.assetListAPI.fetchAssetsByAdvanceSearch,
});

export const fetchAssetsData = createThunk({
  requestActionType: types.FETCH_ASSETS_DATA_REQUEST,
  apiCall: backendAPI.assetListAPI.fetchAssetsData,
});

export const detailsPageButtonIsClicked = (type) => ({
  type: types.VIEW_ALL_BUTTON_CLICKED,
  payload: { type },
});

export const historicalDataitemsPageIsClicked = (type) => ({
  type: types.HISTORICAL_DATAITEMS_CLICKED,
  payload: { type },
});

export const fetchAssetFiltered = (payload) => ({
  type: types.FETCH_ASSETFILTER_DATA,
  payload: payload,
});
export const historyDataIsClicked = (type) => ({
  type: types.HISTORY_DATA_CLICKED,
  payload: { type },
});

export const detailsPageBackButtonIsClicked = () => ({
  type: types.DETAILS_BACK_BUTTON_CLICKED,
});

export const resetAssetDetailsPage = () => ({
  type: types.RESET_ASSET_DETAILS_PAGE,
});

export const resetHistoryData = () => ({
  type: types.RESET_HISTORY_DATA,
});

export const resetHistoricalDataitemsData = () => ({
  type: types.RESET_HISTORICAL_DATAITEMS_DATA,
});

export const AssetsListReducer = {
  fetchParamtersList,
  fetchAssetsLists,
  fetchAssetDetails,
  fetchAlarmDetails,
  fetchRulesDetails,
  fetchWorkstationDetails,
  fetchMassSpecDetails,
  fetchLCDataDetails,
  fetchErrorDataDetails,
  fetchSampleQueueListDetails,
  fetchAssetsByAdvanceSearch,
  fetchAssetsData,
};

const AssetFiltered = (
  state = initialState.AssetFilteredData,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_ASSETFILTER_DATA:
      return payload;
    case 'DEFAULT40':
      return payload;
    case 'DEFAULT41':
      return payload;
    default:
      return state;
  }
};
const parameterLists = (
  state = initialState.parameterLists,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_MODEL_PARAMETERS_LIST_REQUEST_SUCCESS:
      return payload;
    case 'DEFAULT':
      return payload;
    case 'DEFAULT1':
      return payload;
    default:
      return state;
  }
};

const detailsPageStatus = (
  state = initialState.detailsPageStatus,
  { type, payload }
) => {
  switch (type) {
    case types.VIEW_ALL_BUTTON_CLICKED:
      return 'VIEW_DETAILS_PAGE';
    case types.DETAILS_BACK_BUTTON_CLICKED:
      return 'VIEW_LIST_PAGE';
    case types.HISTORICAL_DATAITEMS_CLICKED:
      return 'VIEW_HISTORICAL_DATAITEM_PAGE';
    case types.RESET_ASSET_DETAILS_PAGE:
      return initialState.detailsPageStatus;
    default:
      return state;
  }
};

const assetsDeviceParameters = (
  state = initialState.assetsDeviceParameters,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_DEVICE_PARAMS_LIST_REQUEST_SUCCESS:
      return payload;
    case 'DEFAULT2':
      return payload;
    case 'DEFAULT3':
      return payload;
    default:
      return state;
  }
};

const assetsListsdata = (
  state = initialState.assetsListData,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_ASSETS_LIST_REQUEST_SUCCESS:
      return payload;
    case types.FETCH_ASSETS_LIST_BY_ADVANCESEARCH_REQUEST_SUCCESS:
      return payload;
    default:
      return state;
  }
};

const assetDetailsdata = (
  state = initialState.assetDetailsData,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_ASSET_DETAILS_REQUEST_SUCCESS:
      return payload;
    case types.RESET_ASSET_DETAILS_PAGE:
      return initialState.assetDetailsData;
    default:
      return state;
  }
};

const alarmDetailsdata = (
  state = initialState.alarmDetailsData,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_ALARM_DETAILS_REQUEST_SUCCESS:
      return payload;
    case types.RESET_ASSET_DETAILS_PAGE:
      return initialState.alarmDetailsData;
    default:
      return state;
  }
};

const rulesDetailsdata = (
  state = initialState.rulesDetailsdata,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_RULES_DETAILS_REQUEST_SUCCESS:
      return payload.rulesList;
    case types.RESET_ASSET_DETAILS_PAGE:
      return initialState.rulesDetailsdata;
    default:
      return state;
  }
};

const workstationDetailsData = (
  state = initialState.workstationDetailsData,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_WORKSTATION_DETAILS_REQUEST_SUCCESS:
      return payload;
    case types.RESET_ASSET_DETAILS_PAGE:
      return initialState.workstationDetailsData;
    default:
      return state;
  }
};

const massSpecDetailsData = (
  state = initialState.massSpecDetailsData,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_MASS_SPEC_DETAILS_REQUEST_SUCCESS:
      return payload;
    case types.RESET_ASSET_DETAILS_PAGE:
      return initialState.massSpecDetailsData;
    default:
      return state;
  }
};

const lcDataDetailsData = (
  state = initialState.lcDataDetailsData,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_LC_DATA_DETAILS_REQUEST_SUCCESS:
      return payload;
    case types.RESET_ASSET_DETAILS_PAGE:
      return initialState.lcDataDetailsData;
    default:
      return state;
  }
};

const errorDataDetailsData = (
  state = initialState.errorDataDetailsData,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_ERROR_DATA_DETAILS_REQUEST_SUCCESS:
      return payload;
    case types.RESET_ASSET_DETAILS_PAGE:
      return initialState.errorDataDetailsData;
    default:
      return state;
  }
};

const sampleQueueListDetailsData = (
  state = initialState.sampleQueueListDetailsData,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_SAMPLE_QUEUE_LIST_DETAILS_REQUEST_SUCCESS:
      return payload;
    case types.RESET_ASSET_DETAILS_PAGE:
      return initialState.sampleQueueListDetailsData;
    default:
      return state;
  }
};

const mnemonicsFilteredData = (
  state = initialState.mnemonicsFilteredData,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_MNEMONICS_DATA:
      return payload;
    case types.RESET_ASSET_DETAILS_PAGE:
      return initialState.mnemonicsFilteredData;
    default:
      return state;
  }
};

const historyDetailsData = (
  state = initialState.historyDetailsData,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_HISTORY_DATA_REQUEST_SUCCESS:
      // return payload.data.devices[0].readBacksData[0].readbacks[0];
      return payload.mnemonicValuesList;
    case types.RESET_HISTORY_DATA:
      return initialState.historyDetailsData;
    default:
      return state;
  }
};

const historicalDataitemsDetailsData = (
  state = initialState.historicalDataitemsDetailsData,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_HISTORICAL_DATAITEMS_REQUEST_SUCCESS:
      return payload.historicalData;
    case types.RESET_HISTORICAL_DATAITEMS_DATA:
      return initialState.historicalDataitemsDetailsData;
    default:
      return state;
  }
};

const tableType = (state = initialState.tableType, { type, payload }) => {
  switch (type) {
    case types.VIEW_ALL_BUTTON_CLICKED:
      return payload.type;
    case 'DEFAULT4':
      return payload;
    case 'DEFAULT5':
      return payload;
    default:
      return state;
  }
};

const selectedMnemonic = (
  state = initialState.selectedMnemonic,
  { type, payload }
) => {
  switch (type) {
    case types.HISTORY_DATA_CLICKED:
      return payload.type;
    case 'DEFAULT6':
      return payload;
    case 'DEFAULT7':
      return payload;
    default:
      return state;
  }
};

const assetsListByAdvanceSearch = (
  state = initialState.assetsListByAdvanceSearch,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_ASSETS_LIST_BY_ADVANCESEARCH_REQUEST_SUCCESS:
      return null;
    case 'DEFAULT8':
      return payload;
    case 'DEFAULT9':
      return payload;
    default:
      return state;
  }
};

const isFetchingAssets = (
  state = initialState.isFetchingAssets,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_ASSETS_LIST_REQUEST:
    case types.FETCH_ASSETS_LIST_BY_ADVANCESEARCH_REQUEST:
    case types.FETCH_ASSETS_DATA_REQUEST:
    case types.FETCH_ASSET_DETAILS_REQUEST:
      return true;
    case types.FETCH_ASSETS_LIST_BY_ADVANCESEARCH_REQUEST_SUCCESS:
    case types.FETCH_ASSETS_LIST_REQUEST_SUCCESS:
    case types.FETCH_ASSETS_DATA_REQUEST_SUCCESS:
    case types.FETCH_ASSET_DETAILS_REQUEST_SUCCESS:
    case types.FETCH_ASSETS_LIST_BY_ADVANCESEARCH_REQUEST_FAILURE:
    case types.FETCH_ASSETS_LIST_REQUEST_FAILURE:
    case types.FETCH_ASSETS_DATA_REQUEST_FAILURE:
    case types.FETCH_ASSET_DETAILS_REQUEST_FAILURE:
      return false;
    default:
      return state;
  }
};

const isFetchingAlarmsData = (
  state = initialState.isFetchingAlarmsData,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_ALARM_DETAILS_REQUEST:
      return true;
    case types.FETCH_ALARM_DETAILS_REQUEST_SUCCESS:
    case types.FETCH_ALARM_DETAILS_REQUEST_FAILURE:
      return false;
    default:
      return state;
  }
};

const isFetchingRulesData = (
  state = initialState.isFetchingRulesData,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_RULES_DETAILS_REQUEST:
      return true;
    case types.FETCH_RULES_DETAILS_REQUEST_SUCCESS:
    case types.FETCH_RULES_DETAILS_REQUEST_FAILURE:
      return false;
    default:
      return state;
  }
};

const isFetchingWorkstationData = (
  state = initialState.isFetchingWorkstationData,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_WORKSTATION_DETAILS_REQUEST:
      return true;
    case types.FETCH_WORKSTATION_DETAILS_REQUEST_SUCCESS:
    case types.FETCH_WORKSTATION_DETAILS_REQUEST_FAILURE:
      return false;
    default:
      return state;
  }
};

const isFetchingMassspecData = (
  state = initialState.isFetchingMassspecData,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_MASS_SPEC_DETAILS_REQUEST:
      return true;
    case types.FETCH_MASS_SPEC_DETAILS_REQUEST_SUCCESS:
    case types.FETCH_MASS_SPEC_DETAILS_REQUEST_FAILURE:
      return false;
    default:
      return state;
  }
};

const isFetchingLCData = (
  state = initialState.isFetchingLCData,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_LC_DATA_DETAILS_REQUEST:
      return true;
    case types.FETCH_LC_DATA_DETAILS_REQUEST_SUCCESS:
    case types.FETCH_LC_DATA_DETAILS_REQUEST_FAILURE:
      return false;
    default:
      return state;
  }
};

const isFetchingErrorData = (
  state = initialState.isFetchingErrorData,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_ERROR_DATA_DETAILS_REQUEST:
      return true;
    case types.FETCH_ERROR_DATA_DETAILS_REQUEST_SUCCESS:
    case types.FETCH_ERROR_DATA_DETAILS_REQUEST_FAILURE:
      return false;
    default:
      return state;
  }
};

const isFetchingSampleQueueData = (
  state = initialState.isFetchingSampleQueueData,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_SAMPLE_QUEUE_LIST_DETAILS_REQUEST:
      return true;
    case types.FETCH_SAMPLE_QUEUE_LIST_DETAILS_REQUEST_SUCCESS:
    case types.FETCH_SAMPLE_QUEUE_LIST_DETAILS_REQUEST_FAILURE:
      return false;
    default:
      return state;
  }
};

const isFetchingHistoryData = (
  state = initialState.isFetchingHistoryData,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_HISTORY_DATA_REQUEST:
      return true;
    case types.FETCH_HISTORY_DATA_REQUEST_SUCCESS:
    case types.FETCH_HISTORY_DATA_REQUEST_FAILURE:
      return false;
    default:
      return state;
  }
};

const isFetchingHistoricalDataitemsData = (
  state = initialState.isFetchingHistoricalDataitemsData,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_HISTORICAL_DATAITEMS_REQUEST:
      return true;
    case types.FETCH_HISTORICAL_DATAITEMS_REQUEST_SUCCESS:
    case types.FETCH_HISTORICAL_DATAITEMS_REQUEST_FAILURE:
      return false;
    default:
      return state;
  }
};

export default combineReducers({
  detailsPageStatus,
  assetsListsdata,
  assetDetailsdata,
  isFetchingAssets,
  isFetchingAlarmsData,
  isFetchingRulesData,
  isFetchingWorkstationData,
  isFetchingMassspecData,
  isFetchingLCData,
  isFetchingErrorData,
  isFetchingSampleQueueData,
  isFetchingHistoryData,
  isFetchingHistoricalDataitemsData,
  assetsDeviceParameters,
  parameterLists,
  alarmDetailsdata,
  rulesDetailsdata,
  workstationDetailsData,
  massSpecDetailsData,
  lcDataDetailsData,
  errorDataDetailsData,
  sampleQueueListDetailsData,
  mnemonicsFilteredData,
  historyDetailsData,
  historicalDataitemsDetailsData,
  tableType,
  selectedMnemonic,
  assetsListByAdvanceSearch,
  AssetFiltered,
});
