import React from 'react';
import matchSorter from 'match-sorter';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import enhance from './enhance';
import { Alerts, commonLabels } from '../../../../redux/constants/Values';
import { Divider } from 'semantic-ui-react';
import { HistoricalForm } from './form';

export const HistoricalDataitems = (props) => {
  const {
    handleSubmit,
    deviceParameters,
    isFetchingHistoricalDataitemsData,
    deviceName,
    historicalDataitemsDetailsData,
    getHistoricalDataitems,
    isDetailsPageBackButtonIsClicked,
    exportFile,
  } = props;
  /* istanbul ignore next */
  return (
    <div>
      <div>
        <h5>
          {commonLabels.historicalDataitems} - {deviceName}
          <button className='exportButton' onClick={exportFile}>
            {commonLabels.exportToCSV}
          </button>
        </h5>
      </div>
      <Divider />
      <HistoricalForm
        handleSubmit={handleSubmit}
        deviceParameters={deviceParameters}
        deviceName={deviceName}
        getHistoricalDataitems={getHistoricalDataitems}
        isDetailsPageBackButtonIsClicked={isDetailsPageBackButtonIsClicked}
      />
      <Divider />
      <ReactTable
        data={historicalDataitemsDetailsData}
        loading={isFetchingHistoricalDataitemsData ? true : false}
        filterable
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id]) === filter.value
        }
        columns={[
          {
            Header: 'Name',
            id: 'mnemonicName',
            accessor: (d) => d.mnemonicName,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ['mnemonicName'] }),
            filterAll: true,
          },
          {
            Header: 'Value',
            id: 'mnemonicValue',
            accessor: (d) => d.mnemonicValue,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ['mnemonicValue'] }),
            filterAll: true,
          },
          {
            Header: 'Timestamp',
            id: 'timeStamp',
            accessor: (d) => d.timeStamp,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ['timeStamp'] }),
            filterAll: true,
          },
        ]}
        defaultPageSize={10}
        minRows={4}
        noDataText={Alerts.noRows}
        className='-striped -highlight'
      />
      {/* <Divider />
      <Grid>
        <Grid.Column>
          <Button primary onClick={isDetailsPageBackButtonIsClicked}>
            {commonLabels.back}
          </Button>
        </Grid.Column>
      </Grid> */}
    </div>
  );
};
export default enhance(HistoricalDataitems);
