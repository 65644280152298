import React, { useState } from 'react';
import matchSorter from 'match-sorter';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import moment from 'moment/min/moment-with-locales';
import { Field } from 'redux-form';
import { reduxForm } from 'redux-form';
import { Form } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import {
  getDeviceStatusMappings,
  getICStatusIndicator,
  getICStatus,
  getStatusIndicator,
} from '../../../utilities/helpers';
import { fetchAssetFiltered } from '../../../redux/reducers/AssetListReducer';
import { Alerts } from '../../../redux/constants/Values';

export const Assets = (props) => {
  /* istanbul ignore next */
  const [RonDateFilterVal, setRonDateFilterVal] = useState('');
  /* istanbul ignore next */
  const [LcDateFilterVal, setLcDateFilterVal] = useState('');
  /* istanbul ignore next */
  const [IcDateFilterVal, setIcDateFilterVal] = useState('');
  /* istanbul ignore next */
  const [FilterDatePicker, setFilterDatePicker] = useState(false);
  /* istanbul ignore next */
  const [AfterFilter, setAfterFilter] = useState([]);

  /* istanbul ignore next */
  const handleDatepicker = (input, datetype) => {
    const value = input.currentTarget.valueAsDate;
    value === null ? setFilterDatePicker(false) : setFilterDatePicker(true);
    const dateinput = moment(value).format('YYYY-MM-DD');
    const beforefilter = props.assetsListsdata;

    if (datetype === 'registerdOn' && value !== null) {
      const filterresult = beforefilter.filter(
        (data) => data.registerdOn.slice(0, 10) === dateinput
      );
      setFilterDatePicker(true);
      setAfterFilter(filterresult);
      setRonDateFilterVal(input);
    } else if (datetype === 'lastContacted' && value !== null) {
      const filterresult = beforefilter.filter(
        (data) => data.lastContacted.slice(0, 10) === dateinput
      );
      setFilterDatePicker(true);
      setAfterFilter(filterresult);
      setLcDateFilterVal(input);
    } else if (datetype === 'icLastContacted' && value !== null) {
      const filterresult = beforefilter.filter(
        (data) =>
          data.ICLastContactedTime &&
          data.ICLastContactedTime.slice(0, 10) === dateinput
      );
      setFilterDatePicker(true);
      setAfterFilter(filterresult);
      setIcDateFilterVal(input);
    }
  };

  /* istanbul ignore next */
  const handleDatepickerRonTemplate = (props) => (
    <Form>
      <Field
        component={Form.Input}
        type='Date'
        name='registeredOnDate'
        className='fullWidth'
        value={RonDateFilterVal}
        onChange={(event) => {
          if (event != null) {
            handleDatepicker(event, 'registerdOn');
          } else {
            setRonDateFilterVal('');
            setFilterDatePicker(false);
          }
        }}
      />
    </Form>
  );

  /* istanbul ignore next */
  const handleDatepickerLcTemplate = (props) => (
    <Form>
      <Field
        component={Form.Input}
        type='Date'
        name='lastContactedDate'
        className='fullWidth'
        onChange={(event) => {
          if (event != null) {
            handleDatepicker(event, 'lastContacted');
          } else {
            setLcDateFilterVal('');
            setFilterDatePicker(false);
          }
        }}
        value={LcDateFilterVal}
      />
    </Form>
  );

  /* istanbul ignore next */
  const handleDatepickerIcTemplate = (props) => (
    <Form>
      <Field
        component={Form.Input}
        type='Date'
        clear={true}
        name='icLastContactedDate'
        className='fullWidth'
        onChange={(event) => {
          if (event != null) {
            handleDatepicker(event, 'icLastContacted');
          } else {
            setIcDateFilterVal('');
            setFilterDatePicker(false);
          }
        }}
        value={IcDateFilterVal}
      />
    </Form>
  );

  /* istanbul ignore next */
  const getCoumns = () => {
    if (
      props &&
      props.assetsListsdata !== undefined &&
      props.assetsListsdata.length > 0
    ) {
      return props.assetsListsdata[0].dataItemValue === undefined
        ? true
        : false;
    } else {
      return false;
    }
  };

  /* istanbul ignore next */
  return (
    <div>
      {}
      <ReactTable
        data={FilterDatePicker ? AfterFilter : props.assetsListsdata}
        getTheadFilterThProps={(e) => {
          let filtredData = e.sortedData.map((eachVal) => {
            return eachVal._original;
          });
          props.dispatch(fetchAssetFiltered(filtredData));
          return {
            style: { position: 'inherit', overflow: 'inherit' },
          };
        }}
        filterable
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id]) === filter.value
        }
        columns={[
          {
            Header: 'Instrument Status',
            id: 'groupStatus',
            accessor: (d) => getDeviceStatusMappings(d.groupStatus),
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ['groupStatus'] }),
            filterAll: true,
            Filter: ({ filter, onChange }) => (
              <select
                onChange={(event) => onChange(event.target.value)}
                style={{ width: '100%', height: '37px' }}
                value={filter ? filter.value : `'Active':'Inactive'`}
              >
                <option value=''>ALL</option>
                <option value='READY'>READY</option>
                <option value='FAULT'>FAULT</option>
                <option value='RUNNING'>RUNNING</option>
                <option value='UNKNOWN'>UNKNOWN</option>
                <option value='DISCONNECTED'>DISCONNECTED</option>
              </select>
            ),
            Cell: (props) => getStatusIndicator(props.value),
          },
          {
            Header: 'Serial Number',
            id: 'deviceName',
            accessor: (d) => d.deviceName,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ['deviceName'] }),
            filterAll: true,
            Cell: (e) => (
              <Link
                to={`/assets/${e.original.deviceName}/${e.original.modelName}`}
              >
                {e.original.deviceName}
              </Link>
            ),
          },
          {
            Header: 'Model',
            id: 'modelName',
            accessor: (d) => d.modelName,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ['modelName'] }),
            filterAll: true,
          },
          {
            Header: 'Data Item Value',
            id: 'dataItemValue',
            accessor: (d) => d.dataItemValue,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ['dataItemValue'] }),
            sortMethod: (a, b) => Number(a) - Number(b),
            filterAll: true,
            show: getCoumns() ? false : true,
          },
          {
            Header: 'IC Status',
            id: 'icStatus',
            accessor: (d) =>
              d.ICStatus !== undefined ? getICStatus(d.ICStatus) : 'N/A',
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ['icStatus'] }),
            filterAll: true,
            Filter: ({ filter, onChange }) => (
              <select
                onChange={(event) => onChange(event.target.value)}
                style={{ width: '100%', height: '37px' }}
                value={filter ? filter.value : `'Active':'Inactive'`}
              >
                <option value=''>ALL</option>
                <option value='ON'>ON</option>
                <option value='OFF'>OFF</option>
              </select>
            ),
            Cell: (props) => getICStatusIndicator(props.value),
          },
          {
            Header: 'IC Last Contacted',
            id: 'icLastContacted',
            accessor: (d) =>
              d.ICLastContactedTime !== undefined
                ? d.ICLastContactedTime
                : 'N/A',
            filterAll: true,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: ['icLastContacted'],
              }),
            Filter: (props) => handleDatepickerIcTemplate(props),
          },
          {
            Header: 'Registered On',
            id: 'registerdOn',
            accessor: (d) => d.registerdOn,
            Filter: (props) => handleDatepickerRonTemplate(props),
            filterAll: true,
          },
          {
            Header: 'Last Data Item Date',
            id: 'lastContacted',
            accessor: (d) => d.lastContacted,
            filterAll: true,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: ['lastContacted'],
              }),
            Filter: (props) => handleDatepickerLcTemplate(props),
          },
        ]}
        defaultPageSize={10}
        minRows={3}
        multiSort={true}
        showPageJump={true}
        noDataText={Alerts.noRows}
        className='-striped -highlight'
      />
    </div>
  );
};
export default reduxForm({
  form: 'AssetsTableDataForm',
  enableReinitialize: true,
})(Assets);
