import React from "react";

export const useValueState = () => {
  const [value, setValue] = React.useState("Expression");
  const handleChange = value => {
    setValue(value);
  };

  return { value, handleChange };
};
