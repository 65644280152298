import api from '../config_api';
import raiseError from '../../utilities/raiseError';
import { getHeaders } from '../../utilities/helpers';
import { commonLabels, pageSize } from '../../redux/constants/Values';
import moment from 'moment/min/moment-with-locales';

/**
 * @description {this calls alarms report list api}
 */

export const fetchAlarmsReportLists = async ({ getState, values }) => {
  const requestOptions = {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({
      pageSize: 10000,
      offSet: 1,
    }),
  };

  const response = await fetch(api.get_alarms_report_list_url, requestOptions);
  let responseBody;
  if (response.ok) {
    if (response.status === 204) {
      responseBody = {
        offSet: '1',
        pageSize: pageSize.SIZE,
        deviceList: [],
      };
    }
    responseBody = await response.json();
    return responseBody;
  } else {
    responseBody = await response.json();
    window.alert(responseBody.message);
    raiseError({ response });
  }
};

export const fetchDeviceParameters = async ({ getState, values }) => {
  let requestOptions;
  if (values === 'onload') {
    requestOptions = {
      method: 'POST',
      headers: getHeaders(),
    };
  } else {
    requestOptions = {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({
        modelName: values,
      }),
    };
  }

  const response = await fetch(api.models_device_params, requestOptions);
  let responseBody;
  if (response.ok) {
    if (response.status === 204) {
      window.alert(commonLabels.noDeviceParams);
    }
    responseBody = await response.json();
    return responseBody;
  } else {
    responseBody = await response.json();
    raiseError({ response, responseBody });
  }
};

export const fetchParamtersList = async ({ values }) => {
  let modelarray = [];
  modelarray.push(values); // no separator passed to split
  const requestOptions = {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({
      models: modelarray,
      pageSize: pageSize.SIZE,
      offSet: 1,
    }),
  };

  const response = await fetch(api.parameter_list_url, requestOptions);

  let responseBody;
  if (response.ok) {
    responseBody = await response.json();
    return responseBody;
  } else {
    responseBody = await response.json();
    window.alert(responseBody.message);
    raiseError({ response, responseBody });
  }
};

/**
 * @description {this calls alarms report list advance search api}
 */

export const fetchAlarmsByAdvanceSearch = async ({ getState, values }) => {
  const requestOptions = {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({
      deviceName: values.assetName === '' ? undefined : values.assetName,
      modelName: values.Model === '' ? undefined : values.Model,
      mnemonic: values.DataItem === '' ? undefined : values.DataItem,
      alarmSeverity:
        values.AlarmSeverity === '' ? undefined : values.AlarmSeverity,
      alarmDescription:
        values.AlarmDescription === '' ? undefined : values.AlarmDescription,
      startDate:
        values.startDate === ''
          ? undefined
          : moment(values.startDate + values.startTime, 'YYYY-MM-DDTHH:mm:s')
              .utc()
              .format(),
      endDate:
        values.endDate === ''
          ? undefined
          : moment(values.endDate + values.endTime, 'YYYY-MM-DDTHH:mm:s')
              .utc()
              .format(),
      pageSize: pageSize.SIZE,
      offSet: 1,
    }),
  };
  const response = await fetch(api.get_alarms_report_list_url, requestOptions);
  let responseBody;
  if (response.ok) {
    if (response.status === 204) {
      responseBody = {
        offSet: '1',
        pageSize: pageSize.SIZE,
        deviceList: [],
      };
      window.alert(commonLabels.noContent);
    } else {
      responseBody = await response.json();
    }
    return responseBody;
  } else {
    responseBody = await response.json();
    window.alert(responseBody.message);
    raiseError({ response });
  }
};

export const fetchAssetsData = async ({ getState, values }) => {
  const requestOptions = {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({
      assetPage: 'ASSET',
      pageSize: pageSize.SIZE,
      offSet: 1,
    }),
  };
  const response = await fetch(api.assets_url, requestOptions);
  let responseBody;
  if (response.ok) {
    if (response.status === 204) {
      responseBody = {
        offSet: '1',
        pageSize: pageSize.SIZE,
        deviceList: [],
      };
    }
    responseBody = await response.json();
    return responseBody;
  } else {
    responseBody = await response.json();
    raiseError({ response, responseBody });
  }
};
