import React from "react";
import { Grid, Header, Button, Card, Form } from "semantic-ui-react";

import DeviceCodeDetails from "../DeviceCodeDetails";
import DeviceCodeSeverityForCard from "../DeviceCodeSeverityForCard";
import DeviceCodeModelsForRuleCard from "../DeviceCodeModelsForRuleCard";
import Modal from "react-modal";
import AddDeviceCodeModal from "../AddDeviceCodeModal/Root";
import DeviceCodeReview from "../DeviceCodeReview";
import styles from "./DeviceCodeRoot.module.scss";
import { useModalState } from "./useModalState";
import { Dimmer, Loader } from "semantic-ui-react";
import enhance from "./enhance";
import {
  commonLabels,
  CreateLabels,
  UpdateLabels,
} from "../../../../redux/constants/Values";

export const DeviceCodeRoot = (props) => {
  const {
    handleSubmit,
    routePageStatus,
    initialValues,
    backToListPage,
    unique_key,
  } = props;
  const { modalIsOpen, openModal, closeModal } = useModalState();

  return (
    <div>
      {props.match.params.update === "update" && initialValues.ruleName === "" && (
        <Dimmer active inverted>
          <Loader size="large" inverted>
            {commonLabels.loading}
          </Loader>
        </Dimmer>
      )}
      {routePageStatus === "CREATE_PAGE" && (
        <div className={styles.deviceCodeContainer}>
          <Header as="h3" block className={styles.cardHeader}>
            {props.match.params.update === "update" ? (
              <span>{UpdateLabels.deviceCode}</span>
            ) : (
              <span>{CreateLabels.deviceCode}</span>
            )}
            {props.match.params.update === undefined && (
              <span onClick={openModal} className={styles.anchorButton}>
                {CreateLabels.addDeviceCode}
              </span>
            )}
          </Header>
          <Form onSubmit={handleSubmit} autoComplete="off" key={unique_key}>
            <div className={styles.fieldContainer}>
              <DeviceCodeDetails updateProps={props.match.params.update} />
            </div>

            <div className={styles.cardsContainer}>
              <Card.Group centered itemsPerRow={2}>
                <DeviceCodeModelsForRuleCard />

                <DeviceCodeSeverityForCard />
              </Card.Group>
            </div>

            <Grid>
              <Grid.Column textAlign="center">
                <Button primary>{commonLabels.next}</Button>
                <Button primary onClick={backToListPage}>
                  {commonLabels.cancel}
                </Button>
              </Grid.Column>
            </Grid>
          </Form>

          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            className={styles.ReactModal__Content}
            ariaHideApp={false}
          >
            <AddDeviceCodeModal closeModal={closeModal} />
          </Modal>
        </div>
      )}
      {routePageStatus === "REVIEW_PAGE" && (
        <div className="addNewMainContainer">
          <DeviceCodeReview
            updateProps={props.match.params.update}
            {...props}
          />
        </div>
      )}
    </div>
  );
};
export default enhance(DeviceCodeRoot);
