import React from "react";

export const RadioGroup = (props) => (
  <div className="field">
    {props.options.map((o) => (
      <label key={o.value} className="fileUploadStyle">
        <input
          type="radio"
          disabled={o.value === "Models and Families"}
          {...props.input}
          value={o.value}
          checked={o.value === props.input.value}
        />{" "}
        {o.title}
      </label>
    ))}
    {props.meta.submitFailed && props.meta.error && (
      <div className="ui red pointing basic label">"{props.meta.error}"</div>
    )}
  </div>
);

export default RadioGroup;
