export const exportCSVFile = (responseBody) => {
  const jsonData = responseBody.map((row) => ({
    'Serial Number': row.deviceName,
    Model: row.modelName ? row.modelName : '',
    'Alarm Description': row.alarmDescription,
    'Alarm Severity': row.alarmSeverity,
    'Mnemonic Values': row.mnemonicValues
      ? JSON.stringify(row.mnemonicValues)
          .replace(/[{"}]/g, '')
          .replace(',', ', ')
      : '',
    Timestamp: row.alarmDate,
  }));
  if (jsonData.length !== 0) {
    const csvData = objectToCsv(jsonData);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'AlarmsReport_Data.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    window.alert('There is No data to Export');
  }
};

export const objectToCsv = (jsonData) => {
  const csvRows = [];
  //get the Headers
  const headers = Object.keys(jsonData[0]);
  csvRows.push(headers.join(','));
  //loop over the rows
  for (const row of jsonData) {
    const values = headers.map((header) => {
      const escaped = ('' + row[header]).replace(/"/g, '\\"');
      return `"${escaped}"`;
    });
    csvRows.push(values.join(','));
  }
  return csvRows.join('\n');
};
