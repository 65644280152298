import React, { useState } from "react";
import { Field } from "redux-form";
import enhance from "./enhance";
import { Form, Button } from "semantic-ui-react";
import { commonLabels } from "../../../redux/constants/Values";

export const AdvanceSearch = (props) => {
  const { handleSubmit, resetAdvSearch } = props;
  const [open, togglePanel] = useState(false);

  return (
    <div>
      <div>
        <div onClick={() => togglePanel(!open)} className="toggleHeader">
          <i
            className={open ? "caret square up icon" : "caret square down icon"}
          ></i>
          {commonLabels.advanceSearch}
        </div>

        {open ? (
          <div className="toggleContent">
            <Form onSubmit={handleSubmit}>
              <Form.Group widths="equal">
                <Field
                  component={Form.Input}
                  type="Date"
                  label="Start Date"
                  placeholder="Start date"
                  name="startDate"
                  required
                />
                <Field
                  component={Form.Input}
                  type="Date"
                  label="End Date"
                  placeholder="End date"
                  name="endDate"
                  required
                />
              </Form.Group>
            </Form>
            <Button.Group>
              <Button positive onClick={handleSubmit}>
                {commonLabels.search}
              </Button>
              <Button.Or />
              <Button negative onClick={() => resetAdvSearch(props)}>
                {commonLabels.clear}
              </Button>
            </Button.Group>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default enhance(AdvanceSearch);
