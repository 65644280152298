import React from "react";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import { ModalHeader } from "../ModalHeader";
import { ModalForm } from "./form";
import styles from "./../HistoryModal.module.scss";
import enhance from "./enhance";
import { Alerts } from "../../../../../redux/constants/Values";

export const HistoryModal = (props) => {
  const {
    historyDetailsData,
    selectedMnemonic,
    handleSubmit,
    isFetchingHistoryData,
    getHistoryData,
    exportFile,
    reset,
  } = props;
  /* istanbul ignore next */
  return (
    <div>
      <ModalHeader closeModal={props.closeModal} />
      <ModalForm
        reset={reset}
        handleSubmit={handleSubmit}
        getHistoryData={getHistoryData}
        exportFile={exportFile}
        name={selectedMnemonic}
        id={props.id}
        category={props.category}
      />
      <div className={styles.formContainer}>
        <ReactTable
          data={historyDetailsData}
          loading={isFetchingHistoryData ? true : false}
          style={{ height: "19pc" }}
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          columns={[
            {
              Header: "Timestamp",
              id: "mnemonicTimeStamp",
              accessor: (d) => {
                return d.mnemonicTimeStamp;
              },
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, {
                  keys: ["mnemonicTimeStamp"],
                }),
              filterAll: true,
            },
            {
              Header: "Value",
              id: "mnemonicValue",
              accessor: (d) =>
                d.mnemonicValue && d.mnemonicValue.match(/^[0-9]+$/) !== null
                  ? parseInt(d.mnemonicValue)
                  : d.mnemonicValue,
              sortMethod: (a, b) => Number(a) - Number(b),
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["mnemonicValue"] }),
              filterAll: true,
            },
          ]}
          defaultPageSize={5}
          minRows={4}
          noDataText={Alerts.noRows}
          className="-striped -highlight"
        />
      </div>
    </div>
  );
};

export default enhance(HistoryModal);
