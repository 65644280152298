import React from "react";
import { Field } from "redux-form";
import { Form } from "semantic-ui-react";
import {
  InputField,
  CheckboxField,
  TextAreaField,
} from "react-semantic-redux-form";
import styles from "./DataExpressionDetails.module.scss";
import {
  required,
  limitCharater,
  ruleNameValidations,
} from "./../../../../utilities/validations";

export const DataExpressionDetails = (props) => {
  const { updateProps } = props;
  return (
    <div>
      <Form.Group widths="equal">
        <label className={styles.fieldLable}>Name</label>
        <Field
          name="ruleName"
          component={InputField}
          placeholder="Rule Name"
          validate={[required]}
          disabled={updateProps ? true : false}
          normalize={ruleNameValidations}
        />
        <label className={styles.fieldLable}>Description</label>
        <Field
          name="description"
          component={TextAreaField}
          placeholder="Rule Description"
          validate={[required]}
          normalize={limitCharater}
        />

        <Field name="deleted" component={CheckboxField} label="Enable" />
      </Form.Group>
    </div>
  );
};

export default DataExpressionDetails;
