import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import { reduxForm } from "redux-form";
import moment from "moment/min/moment-with-locales";
import { fecthContentsListByAdvanceSearch } from "../../../redux/reducers/ContentsListReducer";
import { Alerts } from "../../../redux/constants/Values";

/**
 *
 * @description {On submit fetch contents Based on Values}
 */
export const onSubmit = ({ fecthContentsListByAdvanceSearch }) => async (
  values
) => {
  let Todaydate = moment(new Date().setHours(0, 0, 0, 0))
    .add(1, "days")
    .utc()
    .format();
  if (values.hasOwnProperty("updateTime")) {
    if (
      moment(values.updateTime)
        .add(1, "days")
        .utc()
        .format() <= Todaydate
    ) {
      fecthContentsListByAdvanceSearch(values);
    } else {
      window.alert(Alerts.inputDate);
    }
  } else {
    fecthContentsListByAdvanceSearch(values);
  }
};

export default compose(
  connect(null, { fecthContentsListByAdvanceSearch }),
  withHandlers({ onSubmit }),
  reduxForm({
    form: "ContentsAdvanceSearchForm",
    enableReinitialize: true,
  })
);
