import React, { useState } from "react";
import PropTypes from "prop-types";
import { Field } from "redux-form";
import enhance from "./enhance";
import { Form, Button } from "semantic-ui-react";
import { commonLabels } from "./../../../redux/constants/Values";
import { SelectField } from "react-semantic-redux-form";

export const OnBoardingAdvanceSearch = (props) => {
  const { handleSubmit, reset } = props;
  const [open, togglePanel] = useState(false);

  return (
    <div>
      <div>
        <div onClick={() => togglePanel(!open)} className="toggleHeader">
          <i
            className={open ? "caret square up icon" : "caret square down icon"}
          ></i>
          {commonLabels.advanceSearch}
        </div>

        {open ? (
          <div className="toggleContent">
            <Form onSubmit={handleSubmit}>
              <div class="three fields">
                <Field
                  component={Form.Input}
                  label="Asset"
                  placeholder="Asset"
                  name="asset"
                />
                <Field
                  component={Form.Input}
                  label="Model"
                  placeholder="Model"
                  name="model"
                />
                <Field
                  component={SelectField}
                  label="Device Status"
                  placeholder="Device Status"
                  name="device_status"
                  options={[
                    { key: "1", text: "PENDING", value: "PENDING" },
                    { key: "2", text: "OK", value: "OK" },
                    { key: "3", text: "DENIED", value: "DENIED" },
                    { key: "4", text: "DORMANT", value: "DORMANT" },
                  ]}
                />
              </div>

              <Button.Group>
                <Button type="submit" positive>
                  {commonLabels.search}
                </Button>
                <Button.Or />
                <Button negative onClick={reset}>
                  {commonLabels.clear}
                </Button>
              </Button.Group>
            </Form>
          </div>
        ) : null}
      </div>
    </div>
  );
};

OnBoardingAdvanceSearch.propTypes = {
  handleSubmit: PropTypes.func,
  reset: PropTypes.func,
};

export default enhance(OnBoardingAdvanceSearch);
