import React from "react";
import "semantic-ui-css/semantic.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "./index.scss";
import App from "./App";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { store } from "./redux/StoreConfig";
import { positions } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import 'react-json-pretty/themes/monikai.css';

const options = {
  position: positions.TOP_CENTER,
  timeout: 5000,
}

render(
  <Provider store={store} template={AlertTemplate} {...options}>
    <App />
  </Provider>,
  document.getElementById("root")
);
