import types from "../actions/actionTypes";
import { createThunk } from "../../utilities/createThunk";
import backendAPI from "../../apis";
import { combineReducers } from "redux";

const initialState = {
  OnboardingFilteredData: [],
  onBoardingListsdata: [],
  onBoardingListByAdvanceSearch: [],
  OnBoardingRemarksdata: [],
  isFetchingOnBoardingStatus: false,
};

export const fetchOnBoardingLists = createThunk({
  requestActionType: types.FETCH_ON_BOARDING_LIST_REQUEST,
  apiCall: backendAPI.onBoardingAPI.fetchOnBoardingLists,
});

export const fetchOnboardingFiltered = (payload) => ({
  type: types.FETCH_ONBOARDINGFILTER_DATA,
  payload: payload,
});

export const fecthOnBoardingListByAdvanceSearch = createThunk({
  requestActionType: types.FECTH_ON_BOARDING_STATUS_BY_ADVANCESEARCH_REQUEST,
  apiCall: backendAPI.onBoardingAPI.fecthonBoardingListByAdvanceSearch,
});

export const fetchupdateOnBoardingStatus = createThunk({
  requestActionType: types.FECTH_ON_BOARDING_STATUS_REQUEST,
  apiCall: backendAPI.onBoardingAPI.fetchupdateOnBoardingStatus,
});

export const saveOnBoardingRemark = 
  createThunk({
    requestActionType: types.UPDATE_REMARK_REQUEST,
    apiCall: backendAPI.onBoardingAPI.saveOnBoardingRemark,
  });


export const OnBoardingReducer = {
  fetchOnBoardingLists,
  fecthOnBoardingListByAdvanceSearch,
  fetchupdateOnBoardingStatus,
  saveOnBoardingRemark,
};

const OnboardingFiltered = (
  state = initialState.OnboardingFilteredData,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_ONBOARDINGFILTER_DATA:
      return payload;
    case "DEFAULT70":
      return payload;
    case "DEFAULT71":
      return payload;
    default:
      return state;
  }
};

const onBoardingListsdata = (
  state = initialState.onBoardingListsdata,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_ON_BOARDING_LIST_REQUEST_SUCCESS:
      return payload;
    case types.FECTH_ON_BOARDING_STATUS_BY_ADVANCESEARCH_REQUEST_SUCCESS:
      return payload;
    default:
      return state;
  }
};

const isFetchingOnBoardingStatus = (
  state = initialState.isFetchingOnBoardingStatus,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_ON_BOARDING_LIST_REQUEST:
    case types.FECTH_ON_BOARDING_STATUS_BY_ADVANCESEARCH_REQUEST:
    case types.FECTH_ON_BOARDING_STATUS_REQUEST:
      return true;
    case types.FECTH_ON_BOARDING_STATUS_REQUEST_SUCCESS:
    case types.FECTH_ON_BOARDING_STATUS_BY_ADVANCESEARCH_REQUEST_SUCCESS:
    case types.FETCH_ON_BOARDING_LIST_REQUEST_SUCCESS:
    case types.FECTH_ON_BOARDING_STATUS_REQUEST_FAILURE:
    case types.FECTH_ON_BOARDING_STATUS_BY_ADVANCESEARCH_REQUEST_FAILURE:
    case types.FETCH_ON_BOARDING_LIST_REQUEST_FAILURE:
      return false;
    default:
      return state;
  }
};

const OnBoardingRemarksdata = (
  state = initialState.OnBoardingRemarksdata,
  { type, payload }
) => {
  switch (type) {
    case types.UPDATE_REMARK_REQUEST_SUCCESS:
      return payload;
    case "DEFAULT10":
      return payload;
    case "DEFAULT11":
      return payload;
    default:
      return state;
  }
};

export default combineReducers({
  onBoardingListsdata,
  isFetchingOnBoardingStatus,
  OnboardingFiltered,
  OnBoardingRemarksdata,
});
