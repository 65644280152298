import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";
import { fetchUsersLists } from "../../../redux/reducers/UsersListReducer";
import { reduxForm } from "redux-form";

/**
*
* @description {fetch values from store}
*/
export const mapStateToProps = (state) => ({
  UserListData: state.UsersListReducer.usersListdata,
  isFetching: state.UsersListReducer.isFetchingUsers,
});

/**
 *
 * @description {onpage load call API}
 */
export function componentDidMount() {
  this.props.fetchUsersLists();
}

export default compose(
  connect(mapStateToProps, { fetchUsersLists }),
  lifecycle({ componentDidMount }),
  reduxForm({
    form: "UserReviewForm",
    enableReinitialize: true,
  })
);
