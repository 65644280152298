import api from '../config_api';
import raiseError from '../../utilities/raiseError';
import moment from 'moment/min/moment-with-locales';
import { getHeaders } from '../../utilities/helpers';
import { commonLabels, pageSize } from '../../redux/constants/Values';

/**
 * @description {this calls asset list api}
 */

export const fetchAssetsLists = async ({ getState, values }) => {
  const requestOptions = {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({
      assetPage: 'ASSET',
      pageSize: pageSize.SIZE,
      offSet: 1,
    }),
  };

  const response = await fetch(api.assets_url, requestOptions);
  let responseBody;
  if (response.ok) {
    if (response.status === 204) {
      responseBody = {
        offSet: '1',
        pageSize: pageSize.SIZE,
        deviceList: [],
      };
    }
    responseBody = await response.json();
    return responseBody;
  } else {
    responseBody = await response.json();
    window.alert(responseBody.message);
    raiseError({ response });
  }
};

export const fetchDeviceParameters = async ({ getState, values }) => {
  let requestOptions;
  if (values === 'onload') {
    requestOptions = {
      method: 'POST',
      headers: getHeaders(),
    };
  } else {
    requestOptions = {
      method: 'POST',
      headers: getHeaders(),
      body: JSON.stringify({
        modelName: values,
      }),
    };
  }

  const response = await fetch(api.models_device_params, requestOptions);
  let responseBody;
  if (response.ok) {
    if (response.status === 204) {
      window.alert(commonLabels.noDeviceParams);
    }
    responseBody = await response.json();
    return responseBody;
  } else {
    responseBody = await response.json();
    raiseError({ response, responseBody });
  }
};

export const fetchParamtersList = async ({ values }) => {
  let modelarray = [];
  modelarray.push(values); // no separator passed to split
  const requestOptions = {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({
      models: modelarray,
      pageSize: pageSize.SIZE,
      offSet: 1,
    }),
  };

  const response = await fetch(api.parameter_list_url, requestOptions);

  let responseBody;
  if (response.ok) {
    responseBody = await response.json();
    return responseBody;
  } else {
    responseBody = await response.json();
    window.alert(responseBody.message);
    raiseError({ response, responseBody });
  }
};

/**
 * @description {this calls asset list advance search api}
 */

export const fetchAssetsByAdvanceSearch = async ({ getState, values }) => {
  const requestOptions = {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({
      deviceName: values.assetName === '' ? undefined : values.assetName,
      modelName: values.Model === '' ? undefined : values.Model,
      parameterValue:
        values.DataItemValue === '' ? undefined : values.DataItemValue,
      condition:
        values.DataItemConditions === ''
          ? undefined
          : values.DataItemConditions,
      dataItem: values.DataItem === '' ? undefined : values.DataItem,
      assetPage: 'ASSET',
      deviceStatus:
        values.device_status === '' ? undefined : values.device_status,
      pageSize: pageSize.SIZE,
      offSet: 1,
    }),
  };
  const response = await fetch(api.assets_url, requestOptions);
  let responseBody;
  if (response.ok) {
    if (response.status === 204) {
      responseBody = {
        offSet: '1',
        pageSize: pageSize.SIZE,
        deviceList: [],
      };
      window.alert(commonLabels.noContent);
    } else {
      responseBody = await response.json();
    }
    return responseBody;
  } else {
    responseBody = await response.json();
    window.alert(responseBody.message);
    raiseError({ response });
  }
};

export const fetchAssetDetails = async ({ getState, values }) => {
  const requestOptions = {
    method: 'GET',
    headers: getHeaders(),
  };
  const response = await fetch(
    api.asset_details_url.concat(values),
    requestOptions
  );
  let responseBody;
  if (response.ok) {
    if (response.status === 204) {
      // window.alert("No Content for the Asset");
    }
    responseBody = await response.json();
    return responseBody;
  } else {
    responseBody = await response.json();
    raiseError({ response, responseBody });
  }
};

export const fetchAlarmDetails = async ({ getState, values }) => {
  const valueBody = {
    pageSize: pageSize.SIZE,
    offSet: '1',
    deviceName: values,
  };
  const requestOptions = {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(valueBody),
  };
  const response = await fetch(api.get_alarms_url, requestOptions);
  let responseBody;
  if (response.ok) {
    if (response.status === 204) {
      // window.alert("No Content in Alarms");
    }
    responseBody = await response.json();
    return responseBody;
  } else {
    responseBody = await response.json();
    raiseError({ response, responseBody });
  }
};

export const fetchRulesDetails = async ({ getState, values }) => {
  const requestOptions = {
    method: 'GET',
    headers: getHeaders(),
  };
  const response = await fetch(
    api.get_rules_based_on_modelname.concat(values),
    requestOptions
  );
  let responseBody;
  if (response.ok) {
    if (response.status === 204) {
      // window.alert("No Content in Alarms");
    }
    responseBody = await response.json();
    return responseBody;
  } else {
    responseBody = await response.json();
    raiseError({ response, responseBody });
  }
};

export const fetchWorkstationDetails = async ({ getState, values }) => {
  const valueBody = {
    deviceName: values,
    category: 'device Workstation',
    pageSize: 10000,
    offset: 1,
  };
  const requestOptions = {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(valueBody),
  };
  const response = await fetch(api.get_assets_grid_data, requestOptions);
  let responseBody;
  if (response.ok) {
    if (response.status === 204) {
      // window.alert("No Content in Workstation");
    }
    responseBody = await response.json();
    return responseBody;
  } else {
    responseBody = await response.json();
    raiseError({ response, responseBody });
  }
};

export const fetchMassSpecDetails = async ({ getState, values }) => {
  const valueBody = {
    deviceName: values,
    category: 'MassSpec',
    pageSize: 10000,
    offset: 1,
  };
  const requestOptions = {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(valueBody),
  };
  const response = await fetch(api.get_assets_grid_data, requestOptions);
  let responseBody;
  if (response.ok) {
    if (response.status === 204) {
      // window.alert("No Content in Mass Spec");
    }
    responseBody = await response.json();
    return responseBody;
  } else {
    responseBody = await response.json();
    raiseError({ response, responseBody });
  }
};

export const fetchLCDataDetails = async ({ getState, values }) => {
  const valueBody = {
    deviceName: values,
    category: 'LC Data',
    pageSize: 10000,
    offset: 1,
  };
  const requestOptions = {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(valueBody),
  };
  const response = await fetch(api.get_assets_grid_data, requestOptions);
  let responseBody;
  if (response.ok) {
    if (response.status === 204) {
      // window.alert("No Content in LC");
    }
    responseBody = await response.json();
    return responseBody;
  } else {
    responseBody = await response.json();
    raiseError({ response, responseBody });
  }
};

export const fetchErrorDataDetails = async ({ getState, values }) => {
  const valueBody = {
    deviceName: values,
    category: 'error',
    pageSize: 10000,
    offset: 1,
  };
  const requestOptions = {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(valueBody),
  };
  const response = await fetch(api.get_assets_grid_data, requestOptions);
  let responseBody;
  if (response.ok) {
    if (response.status === 204) {
      // window.alert("No Content in Error");
    }
    responseBody = await response.json();
    return responseBody;
  } else {
    responseBody = await response.json();
    raiseError({ response, responseBody });
  }
};

export const fetchSampleQueueListDetails = async ({ getState, values }) => {
  const valueBody = {
    deviceName: values,
    category: 'sample Item',
    pageSize: 10000,
    offset: 1,
  };

  const requestOptions = {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(valueBody),
  };
  const response = await fetch(api.get_assets_grid_data, requestOptions);
  let responseBody;
  if (response.ok) {
    if (response.status === 204) {
      // window.alert("No Content in Sample Queue");
    }
    responseBody = await response.json();
    return responseBody;
  } else {
    responseBody = await response.json();
    raiseError({ response, responseBody });
  }
};

export const fetchHistoryData = async ({ getState, values }) => {
  const valueBody = {
    offset: 1,
    pageSize: 10000,
    startDate:
      values.startDate !== undefined
        ? values.startDate
        : moment(values.startDate)
            .add(-1, 'days')
            .utc()
            .format(),
    endDate:
      values.endDate !== undefined
        ? values.endDate
        : moment(values.startDate)
            .utc()
            .format(),
    deviceNames: values.id,
    allOrLatest: 'all',
    requestedReadbacks: values.name,
    deviceCategory: values.category,
  };
  const requestOptions = {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(valueBody),
  };
  const response = await fetch(api.get_history_data, requestOptions);
  let responseBody;
  if (response.ok) {
    if (response.status === 204) {
      responseBody = {
        offSet: '1',
        pageSize: 10000,
        historyDetailsData: [],
      };
    }
    responseBody = await response.json();
    return responseBody;
  } else {
    return response.json().then((text) => {
      window.alert(text.message);
      throw new Error(text.message);
    });
  }
};

export const fetchHistoricalDataitems = async ({ getState, values }) => {
  const valueBody = {
    pageSize: 10000,
    offSet: 1,
    startDate: values.startDate,
    endDate: values.endDate,
    mnemonics: values.mnemonics,
    deviceName: values.deviceName,
  };
  const requestOptions = {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify(valueBody),
  };
  const response = await fetch(api.get_historical_dataitems, requestOptions);
  let responseBody;
  if (response.ok) {
    if (response.status === 204 || response.status === 504) {
      responseBody = {
        offSet: '1',
        pageSize: 10000,
        historicalDataitemsDetailsData: [],
      };
    }
    responseBody = await response.json();
    //window.alert(responseBody.message);
    return responseBody;
  } else {
    responseBody = await response.json();
    raiseError({ response, responseBody });
  }
};

export const fetchAssetsData = async ({ getState, values }) => {
  const requestOptions = {
    method: 'POST',
    headers: getHeaders(),
    body: JSON.stringify({
      assetPage: 'ASSET',
      pageSize: pageSize.SIZE,
      offSet: 1,
    }),
  };
  const response = await fetch(api.assets_url, requestOptions);
  let responseBody;
  if (response.ok) {
    if (response.status === 204) {
      responseBody = {
        offSet: '1',
        pageSize: pageSize.SIZE,
        deviceList: [],
      };
    }
    responseBody = await response.json();
    return responseBody;
  } else {
    responseBody = await response.json();
    raiseError({ response, responseBody });
  }
};
