import React from "react";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import "react-table/react-table.css";
import enhance from "./enhance";
import { Link } from "react-router-dom";
import { useModalState } from "./useModalState";
import Modal from "react-modal";
import styles from "../Root/DeviceDetail.module.scss";
import HistoryModal from "../HistoryModal/Root";
import { CreateLabels, Alerts } from "../../../../redux/constants/Values";

export const TableData = (props) => {
  const { modalIsOpen, openModal, closeModal } = useModalState();
  const {
    getHistoryData,
    isHistoryDataClicked,
    category,
    data,
    getMnemonicsData,
  } = props;
  /* istanbul ignore next */
  return (
    <div>
      <ReactTable
        data={data}
        getTheadFilterThProps={(e) => {
          let filtredData = e.sortedData.map((eachVal) => {
            return eachVal._original;
          });
          getMnemonicsData(filtredData.length > 0 ? filtredData : []);
          return {
            style: { position: "inherit", overflow: "inherit" },
          };
        }}
        filterable
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id]) === filter.value
        }
        columns={[
          {
            Header: "Display Name",
            id: "mnemonicDisplayName",
            accessor: (d) =>
              d.mnemonicDisplayName !== "N/A"
                ? d.mnemonicDisplayName
                : d.mnemonicName,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: ["mnemonicDisplayName"],
              }),
            filterAll: true,
          },
          {
            Header: "Mnemonic",
            id: "mnemonicName",
            accessor: (d) => d.mnemonicName,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["mnemonicName"] }),
            filterAll: true,
          },
          {
            Header: "Timestamp",
            id: "mnemonicTimeStamp",
            accessor: (d) => d.mnemonicTimeStamp,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["mnemonicTimeStamp"] }),
            filterAll: true,
          },
          {
            Header: "Value",
            id: "mnemonicValue",
            accessor: (d) => d.mnemonicValue,
            sortMethod: (a, b) => Number(a) - Number(b),
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["mnemonicValue"] }),
            filterAll: true,
          },
          {
            Header: "View History Data",
            id: "historyDataLC",
            accessor: (d) => "View history data",
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["historyData"] }),
            filterAll: true,
            Cell: ({ original }) => {
              return (
                <Link>
                  <span
                    onClick={() => {
                      isHistoryDataClicked(original.mnemonicName);
                      openModal();
                      getHistoryData({
                        id: props.match.params.id,
                        name: original.mnemonicName,
                        category: category,
                      });
                    }}
                  >
                    {CreateLabels.historyData}
                  </span>
                </Link>
              );
            },
          },
        ]}
        defaultPageSize={10}
        minRows={3}
        noDataText={Alerts.noRows}
        className="-striped -highlight"
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        className={styles.ReactModal__Content}
        ariaHideApp={false}
      >
        <HistoryModal
          id={props.match.params.id}
          category={category}
          closeModal={closeModal}
        />
      </Modal>
    </div>
  );
};
export default enhance(TableData);
