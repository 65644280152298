import { connect } from "react-redux";
import { compose, lifecycle } from "recompose";

import { fetchDeviceCodeList } from "../../../../redux/reducers/RulesListReducer";

/**
 *
 * @description {fetch values from store}
 */
export const mapStateToProps = (state) => ({
  deviceCodeList: state.rulesListreducer.deviceCodeList
    ? state.rulesListreducer.deviceCodeList
    : [],
});

/**
 *
 * @description {onpage load call API}
 */
export function componentDidMount() {
  this.props.fetchDeviceCodeList();
}

export default compose(
  connect(mapStateToProps, {
    fetchDeviceCodeList,
  }),

  lifecycle({
    componentDidMount,
  })
);
