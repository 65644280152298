import React from "react";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import enhance from "./enhance";
import { Link } from "react-router-dom";
import { Dimmer, Loader } from "semantic-ui-react";
import { Alerts, commonLabels } from "../../../redux/constants/Values";

export const UsersTableData = (props) => {
  /* istanbul ignore next */
  const { disableUser, forcePasswordChange, isFetchingUsers } = props;
  /* istanbul ignore next */
  const roles = sessionStorage.getItem("roles").split(",");
  /* istanbul ignore next */
  const isAdmin = roles.indexOf("adminGroup") > -1 ? true : false;
  /* istanbul ignore next */
  return (
    <>
      {isFetchingUsers && (
        <Dimmer active inverted>
          <Loader size="large" inverted>
            {commonLabels.loading}
          </Loader>
        </Dimmer>
      )}
      <div>
        <ReactTable
          data={props.usersListsdata.userList}
          filterable
          defaultFilterMethod={(filter, row) =>
            String(row[filter.id]) === filter.value
          }
          columns={[
            {
              Header: "Name",
              id: "userName",
              accessor: (d) => d.userAttributes.userName,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["userName"] }),
              filterAll: true,
              Cell: (row) => {
                return sessionStorage
                  .getItem("roles")
                  .split(",")
                  .indexOf("adminGroup") > -1 ? (
                  <div>
                    <Link
                      to={`/update-user/update/${row.original.userAttributes.userName}`}
                    >
                      {row.original.userAttributes.userName}
                    </Link>
                  </div>
                ) : (
                  <div>{row.original.userAttributes.userName}</div>
                );
              },
            },
            {
              Header: "Email",
              id: "email",
              accessor: (d) => d.userAttributes.email,
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["email"] }),
              filterAll: true,
            },
            {
              Header: "User Groups",
              id: "usergroups",
              accessor: (d) => d.userGroupsNameList.join(", "),
              filterMethod: (filter, rows) =>
                matchSorter(rows, filter.value, { keys: ["usergroups"] }),
              filterAll: true,
              Filter: ({ filter, onChange }) => (
                <select
                  onChange={(event) => onChange(event.target.value)}
                  style={{ width: "100%", height: "37px" }}
                  value={filter ? filter.value : `'readOnlyUser':'adminGroup'`}
                >
                  <option value="">All</option>
                  <option value="readOnlyUser">readOnlyUser</option>
                  <option value="adminGroup">adminGroup</option>
                </select>
              ),
            },
            {
              Header: "Actions",
              id: "user_actions",
              show: isAdmin ? true : false,
              filterable: false,
              Cell: (row) => {
                return (
                  <div>
                    {row.original.userAttributes.Enabled === "true" ? (
                      <button
                        style={{ background: "#ad2323", color: "white" }}
                        onClick={(e) =>
                          disableUser(row.original.userAttributes.userName, 1)
                        }
                      >
                        {commonLabels.disable}
                      </button>
                    ) : (
                      <button
                        style={{ background: "green", color: "white" }}
                        onClick={(e) =>
                          disableUser(row.original.userAttributes.userName, 0)
                        }
                      >
                        {commonLabels.enable}
                      </button>
                    )}
                    <button
                      disabled={
                        row.original.userAttributes.userStatus ===
                        "RESET_REQUIRED"
                          ? true
                          : false
                      }
                      style={{ marginLeft: "10px" }}
                      onClick={(e) =>
                        forcePasswordChange(
                          row.original.userAttributes.userName
                        )
                      }
                    >
                      {commonLabels.passwordChange}
                    </button>
                  </div>
                );
              },
            },
          ]}
          defaultPageSize={10}
          minRows={3}
          noDataText={Alerts.noRows}
          className="-striped -highlight"
        />
      </div>
    </>
  );
};
export default enhance(UsersTableData);
