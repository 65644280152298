import React from "react";
import "react-table/react-table.css";
import enhance from "./enhance";
import TableData from "./TableData";

export const LCTableData = (props) => {
  const { getHistoryData, isHistoryDataClicked } = props;
  /* istanbul ignore next */
  return (
    <div>
      <TableData
        data={props.data}
        getHistoryData={getHistoryData}
        isHistoryDataClicked={isHistoryDataClicked}
        category="LC Data"
      />
    </div>
  );
};
export default enhance(LCTableData);
