import React from "react";
import { Form, Radio, Container } from "semantic-ui-react";
import DataExpressionRoot from "../DataExpressionRoot/Root";
import DeviceCodeRoot from "../DeviceCodeRoot/Root";
import RulesOfRules from "../RulesOfRules/Root";
import { useValueState } from "./useValueState";
import enhance from "./enhance";

export const AddNewRule = (props) => {
  const { routePageStatus } = props;
  const { value, handleChange } = useValueState();

  return (
    <div>
      <div className="addNewMainContainer">
        <Container fluid>
          {routePageStatus === "CREATE_PAGE" && (
            <Form>
              <Form.Group inline>
                <Form.Field
                  data-test-id="radioGroup1"
                  control={Radio}
                  label="Data Expression"
                  name="radioGroup"
                  value="Expression"
                  checked={
                    props.match.params.type === "Expression"
                      ? props.match.params.type
                      : value === "Expression"
                  }
                  onChange={(e) => handleChange("Expression")}
                  disabled={
                    props.match.params.type === "deviceCode" ||
                    props.match.params.type === "ruleOfRules"
                      ? true
                      : false
                  }
                />
                <Form.Field
                  data-test-id="radioGroup2"
                  control={Radio}
                  label="Device Code"
                  name="radioGroup"
                  value="deviceCode"
                  checked={
                    props.match.params.type === "deviceCode"
                      ? props.match.params.type
                      : value === "deviceCode"
                  }
                  onChange={(e) => handleChange("deviceCode")}
                  disabled={
                    props.match.params.type === "Expression" ||
                    props.match.params.type === "ruleOfRules"
                      ? true
                      : false
                  }
                />

                <Form.Field
                  data-test-id="radioGroup3"
                  control={Radio}
                  label="Rules of Rules"
                  name="radioGroup"
                  value="ruleOfRules"
                  checked={
                    props.match.params.type === "ruleOfRules"
                      ? props.match.params.type
                      : value === "ruleOfRules"
                  }
                  onChange={(e) => handleChange("ruleOfRules")}
                  disabled={
                    props.match.params.type === "Expression" ||
                    props.match.params.type === "deviceCode"
                      ? true
                      : false
                  }
                />
              </Form.Group>
            </Form>
          )}

          <div className="radioIconContainer">
            {/* istanbul ignore next */ props.match.params.type !== undefined
              ? (props.match.params.type === "Expression" && (
                  <DataExpressionRoot {...props} />
                )) ||
                (props.match.params.type === "deviceCode" && (
                  <DeviceCodeRoot {...props} />
                )) ||
                (props.match.params.type === "ruleOfRules" && (
                  <RulesOfRules
                    {...props}
                    updateProps={props.match.params.update}
                  />
                ))
              : (value === "Expression" && <DataExpressionRoot {...props} />) ||
                (value === "deviceCode" && <DeviceCodeRoot {...props} />) ||
                (value === "ruleOfRules" && <RulesOfRules {...props} />)}
          </div>
        </Container>
      </div>
    </div>
  );
};
export default enhance(AddNewRule);
