export const inputValuesData = (ruleExpression) => {
  var ruleExpression = ruleExpression.replace(/'(.*?)'/g, "");
  ruleExpression = ruleExpression.replace(/\(|\)|has|contains|\W/g, ",");
  if (/\b(AND|OR|and|or)\b/g.test(ruleExpression)) {
    ruleExpression = ruleExpression.replace(/\b(AND|OR|and|or)\b/g, ",");
  }
  var finalArr = [];
  var str_array = ruleExpression.split(",");
  for (var i = 0; i < str_array.length; i++) {
    str_array[i] = str_array[i].replace(/^\s*/, "").replace(/\s*$/, "");

    //if (str_array[i] !== "" && !/\b(true|TRUE|false|FALSE)\b/g.test(str_array[i]) && (!/^\d+$/g.test(str_array[i]))) {
    // if (str_array[i] !== "" && (!/(?<=^| )\d+(\.\d+)?(?=$| )|(?<=^| )\.\d+(?=$| )/g.test(str_array[i]))) {
    if (str_array[i] !== "" && /^\d*[A-Za-z]{1,}\d*/g.test(str_array[i])) {
      //if (\b(\d | true | TRUE | false | FALSE) \b)
      if (!finalArr.includes(str_array[i])) {
        finalArr.push(str_array[i]);
      }
    }
  }
  return finalArr;
};
