import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import { reduxForm, reset } from "redux-form";
import {
  fetchRulesByAdvanceSearch,
  fetchRulesLists,
} from "../../../redux/reducers/RulesListReducer";
import { withRouter } from "react-router";

/**
 *
 * @description {submit search form values }
 */
export const onSubmit = ({ fetchRulesByAdvanceSearch }) => async (values) => {
  fetchRulesByAdvanceSearch(values);
};

/**
 *
 * @description {clear search form}
 */
export const clearAdvanceSearchForm = ({ fetchRulesLists, reset }) => (
  event
) => {
  event.preventDefault();
  reset("RulesAdvanceSearchForm"); //reseting the advance search form
  fetchRulesLists(); // fetch rulelist
};

export default compose(
  withRouter,
  connect(null, { fetchRulesByAdvanceSearch, fetchRulesLists, reset }),
  withHandlers({ onSubmit, clearAdvanceSearchForm }),
  reduxForm({
    form: "RulesAdvanceSearchForm",
    enableReinitialize: true,
  })
);
