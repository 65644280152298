import { connect } from 'react-redux';
import { compose, withHandlers } from 'recompose';
import { reduxForm } from 'redux-form';
import {
  fetchupdateOnBoardingStatus,
  fetchOnBoardingLists,
  saveOnBoardingRemark,
} from '../../../redux/reducers/OnBoardingReducer';
import { Alerts } from '../../../redux/constants/Values';

/**
 *
 * @description {Onboarding Device Remarks code is updated here}
 */
export const saveRemark = ({ saveOnBoardingRemark, fetchOnBoardingLists }) => (
  obj
) => {
  obj.remarks = obj.remarks.charAt(0).toUpperCase() + obj.remarks.slice(1);
  saveOnBoardingRemark({ obj }).then((payload) => {
    /* istanbul ignore if */
    if (payload.type === 'UPDATE_REMARK_REQUEST_SUCCESS') {
      fetchOnBoardingLists().then(() => {
        window.alert(payload.payload.message);
      });
    } else {
      window.alert(payload.payload.message);
    }
  });
};

/**
 *
 * @description {Onboarding Device Status Update}
 */

export const updateStatus = ({
  fetchupdateOnBoardingStatus,
  fetchOnBoardingLists,
}) => (values2) => {
  if (values2.selectedDevice4Status !== '') {
    if (values2.deviceName === values2.selectedDevice4Status) {
      delete values2.selectedDevice4Status;
      fetchupdateOnBoardingStatus({ values2 }).then((payload) => {
        /* istanbul ignore if */
        if (payload.type === 'FECTH_ON_BOARDING_STATUS_REQUEST_SUCCESS') {
          fetchOnBoardingLists().then(() => {
            window.alert(payload.payload.message);
          });
        } else {
          window.alert(Alerts.fetchError);
        }
      });
    } else {
      window.alert(
        `Please update the correct device [ Last Selected Device for Update is : "${values2.selectedDevice4Status}"]`
      );
    }
  } else {
    window.alert(Alerts.selectDevice);
  }
};

export default compose(
  connect(null, {
    fetchupdateOnBoardingStatus,
    fetchOnBoardingLists,
    saveOnBoardingRemark,
  }),
  withHandlers({ updateStatus, saveRemark }),
  reduxForm({
    form: 'OnboardingStatusTableDataForm',
    enableReinitialize: true,
  })
);
