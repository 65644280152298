import React, { useState } from 'react';
import matchSorter from 'match-sorter';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import moment from 'moment/min/moment-with-locales';
import { Field } from 'redux-form';
import { reduxForm } from 'redux-form';
import { Form } from 'semantic-ui-react';
import { fetchAssetFiltered } from '../../../redux/reducers/AssetListReducer';
import { Alerts } from '../../../redux/constants/Values';

export const AlarmsReport = (props) => {
  /* istanbul ignore next */
  const [TimestampFilterVal, setTimestampFilterVal] = useState('');
  /* istanbul ignore next */
  const [FilterDatePicker, setFilterDatePicker] = useState(false);
  /* istanbul ignore next */
  const [AfterFilter, setAfterFilter] = useState([]);

  /* istanbul ignore next */
  const handleDatepicker = (input) => {
    const value = input.currentTarget.valueAsDate;
    value === null ? setFilterDatePicker(false) : setFilterDatePicker(true);
    const dateinput = moment(value).format('YYYY-MM-DD');
    const beforefilter = props.alarmsReportListsdata;
    if (value !== null) {
      const filterresult = beforefilter.filter(
        (data) => data.alarmDate.slice(0, 10) === dateinput
      );
      setFilterDatePicker(true);
      setAfterFilter(filterresult);
      setTimestampFilterVal(input);
    }
  };

  /* istanbul ignore next */
  const handleDatepickerTemplate = (props) => (
    <Form>
      <Field
        component={Form.Input}
        type='Date'
        name='timestampDate'
        className='fullWidth'
        value={TimestampFilterVal}
        onChange={(event) => {
          if (event != null) {
            handleDatepicker(event);
          } else {
            setTimestampFilterVal('');
            setFilterDatePicker(false);
          }
        }}
      />
    </Form>
  );

  /* istanbul ignore next */
  return (
    <div>
      {}
      <ReactTable
        data={FilterDatePicker ? AfterFilter : props.alarmsReportListsdata}
        // data={props.alarmsReportListsdata}
        getTheadFilterThProps={(e) => {
          let filtredData = e.sortedData.map((eachVal) => {
            return eachVal._original;
          });
          props.dispatch(fetchAssetFiltered(filtredData));
          return {
            style: { position: 'inherit', overflow: 'inherit' },
          };
        }}
        filterable
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id]) === filter.value
        }
        columns={[
          {
            Header: 'Serial Number',
            id: 'deviceName',
            accessor: (d) => d.deviceName,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ['deviceName'] }),
            filterAll: true,
          },
          {
            Header: 'Model',
            id: 'modelName',
            accessor: (d) => d.modelName,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ['modelName'] }),
            filterAll: true,
          },
          {
            Header: 'Alarm Description',
            id: 'description',
            accessor: (d) => d.alarmDescription,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ['description'] }),
            filterAll: true,
          },
          {
            Header: 'Alarm Severity',
            id: 'severity',
            accessor: (d) =>
              d.alarmSeverity && d.alarmSeverity.match(/^[0-9]+$/) !== null
                ? parseInt(d.alarmSeverity)
                : d.alarmSeverity,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ['severity'] }),
            filterAll: true,
          },
          {
            Header: 'Mnemonic Values',
            id: 'mnemonicValues',
            getProps: (state, rowInfo, column) => {
              return {
                style: {
                  overflow:
                    rowInfo &&
                    rowInfo.original.mnemonicValues &&
                    Object.keys(rowInfo.original.mnemonicValues).length > 3
                      ? 'scroll'
                      : null,
                  textOverflow:
                    rowInfo &&
                    rowInfo.original.mnemonicValues &&
                    Object.keys(rowInfo.original.mnemonicValues).length > 3
                      ? 'clip'
                      : null,
                },
              };
            },
            accessor: (d) =>
              d.mnemonicValues &&
              JSON.stringify(d.mnemonicValues)
                .replace(/[{"}]/g, '')
                .replace(',', ', '),
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ['mnemonicValues'] }),
            filterAll: true,
          },
          {
            Header: 'Timestamp',
            id: 'alarmDate',
            accessor: (d) => d.alarmDate,
            Filter: (props) => handleDatepickerTemplate(props),
            filterAll: true,
          },
        ]}
        defaultPageSize={10}
        minRows={3}
        multiSort={true}
        showPageJump={true}
        noDataText={Alerts.noRows}
        className='-striped -highlight'
      />
    </div>
  );
};
export default reduxForm({
  form: 'AlarmsReportTableDataForm',
  enableReinitialize: true,
})(AlarmsReport);
