import React from "react";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

export const RenderSelectInput = ({
  input,
  options,
  disabled,
  meta: { error, submitFailed },
}) => (
  <div className="multiCheckDiv">
    <ReactMultiSelectCheckboxes
      {...input}
      options={options}
      value={input.value}
      onChange={(value) => input.onChange(value)}
      onBlur={(value) => input.onBlur()}
      meta={error}
      isDisabled={disabled}
    />
    {submitFailed &&
      disabled === false &&
      (input.value.length === 0 || input.value === "") && (
        <div className="ui red pointing basic label">Required</div>
      )}
  </div>
);

export default RenderSelectInput;
