import { getDeviceStatusMappings, getICStatus } from '../../utilities/helpers';
import { store } from '../../redux/StoreConfig';

export const exportCSVFile = (responseBody) => {
  const jsonData = responseBody.map((row) =>
    row.dataItemValue === undefined
      ? {
          'Instrument Status': getDeviceStatusMappings(row.groupStatus),
          'Serial Number': row.deviceName,
          Model: row.modelName,
          'IC Status':
            row.ICStatus !== undefined ? getICStatus(row.ICStatus) : 'N/A',
          'IC Last Contacted':
            row.ICLastContactedTime !== undefined
              ? row.ICLastContactedTime
              : 'N/A',
          'Registered On': row.registerdOn,
          'Last Data Item Date': row.lastContacted,
        }
      : {
          'Instrument Status': getDeviceStatusMappings(row.groupStatus),
          'Serial Number': row.deviceName,
          Model: row.modelName,
          'Data Item Value': row.dataItemValue,
          'IC Status':
            row.ICStatus !== undefined ? getICStatus(row.ICStatus) : 'N/A',
          'IC Last Contacted':
            row.ICLastContactedTime !== undefined
              ? row.ICLastContactedTime
              : 'N/A',
          'Registered On': row.registerdOn,
          'Last Data Item Date': row.lastContacted,
        }
  );
  if (jsonData.length !== 0) {
    const csvData = objectToCsv(jsonData);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'Assets_Data.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    window.alert('There is No data to Export');
  }
};

export const getData = (data, type) => {
  switch (type) {
    case 'Alarms':
      return (data = data.map((row) => ({
        'Alarm Date': row.alarmDate,
        'Alarm Description': row.alarmDescription,
        'Alarm Severity': row.alarmSeverity,
        'Mnemonic Values': JSON.stringify(row.mnemonicValues)
          .replace(/[{"}]/g, '')
          .replace(',', ', '),
      })));
    case 'Rules':
      return (data = data.map((row) => ({
        'Rule Name': row.ruleName,
        'Rule Expression': row.ruleExpression,
        'Rule Description': row.description,
        Severity: row.severity,
      })));
    case 'WorkStation':
    case 'Mass Spec':
    case 'LC':
      return (data = data.map((row) => ({
        'Display Name':
          row.mnemonicDisplayName !== 'N/A'
            ? row.mnemonicDisplayName
            : row.mnemonicName,
        Mnemonic: row.mnemonicName,
        Timestamp: row.mnemonicTimeStamp,
        Value: row.mnemonicValue,
      })));
    case 'Sample Queue Events':
      return (data = data.map((row) => ({
        'Sample ID': row.sampleId,
        'Batch ID': row.batchId,
        Timestamp: row.deviceTimeStamp,
        'Sample Status': row.sampleStatus,
      })));
    case 'Error':
      return (data = data.map((row) => ({
        'Error Type': row.errorType,
        'Error Code': row.errorCode,
        'Error Timestamp': row.errorTimeStamp,
        'Error Info': row.errorInfo,
      })));
    default:
      return data;
  }
};

export const exportCSVForMnemonicsData = (type) => {
  let responseBody = store.getState().assetListReducer.mnemonicsFilteredData;
  responseBody = getData(responseBody, type);
  if (responseBody.length !== 0) {
    const csvData = objectToCsv(responseBody);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', type + '.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    window.alert('There is No data to Export');
  }
};

export const exportCSVForHistoryData = (type) => {
  let responseBody = store.getState().assetListReducer.historyDetailsData;
  const jsonData = responseBody.map((row) => ({
    Timestamp: row.mnemonicTimeStamp,
    Value: row.mnemonicValue,
  }));
  if (jsonData.length !== 0) {
    const csvData = objectToCsv(jsonData);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', type + '-history' + '.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    window.alert('There is No data to Export');
  }
};

export const exportCSVForHistoricalDataitems = () => {
  let responseBody = store.getState().assetListReducer
    .historicalDataitemsDetailsData;
  const jsonData = responseBody.map((row) => ({
    Mnemonic: row.mnemonicName,
    Value: row.mnemonicValue,
    Timestamp: row.timeStamp,
  }));
  if (jsonData.length !== 0) {
    const csvData = objectToCsv(jsonData);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'HistoricalDataitems.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    window.alert('There is No data to Export');
  }
};

export const objectToCsv = (jsonData) => {
  const csvRows = [];
  //get the Headers
  const headers = Object.keys(jsonData[0]);
  csvRows.push(headers.join(','));
  //loop over the rows
  for (const row of jsonData) {
    const values = headers.map((header) => {
      const escaped = ('' + row[header]).replace(/"/g, '\\"');
      return `"${escaped}"`;
    });
    csvRows.push(values.join(','));
  }
  return csvRows.join('\n');
};
