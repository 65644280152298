export const dashboardPath = "/dashboard";
export const loginPath = "/login";

// New Change For Route
export const studySite = "/studysite";
export const DropDownPath = "/new-study-site";

export const ManageTagPath = "/manage-tag";
export const AddClinician = `${DropDownPath}/AssociateClinician`;

export const modelReviewPagePath = "/models-review";
export const modelCreatePagePath = "/models-add-new";

export const modelsListPagePath = "/models-list";
export const rulesListPagePath = "/rules-list";
export const contentsListPagePath = "/contents-list";
export const usersListPagePath = "/users-list";
export const reportListPagePath = "/reports-list";

export const dashboardParticipantsPath = `${dashboardPath}/participants`;
export const dashboardParticipantPath = ({ id }) =>
  `${dashboardParticipantsPath}/${id}`;

export const dashboardParticipantCommonMealsPath = ({ participantId }) =>
  `${dashboardParticipantsPath}/${participantId}/common-meals`;

export const dashboardParticipantTestMealsPath = ({ participantId }) =>
  `${dashboardParticipantsPath}/${participantId}/test-meals`;
