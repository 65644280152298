import { connect } from "react-redux";
import { compose, lifecycle, withHandlers } from "recompose";
import {
  fetchAssetsLists,
  fetchAssetsData,
  fetchDeviceParameters,
} from "../../../redux/reducers/AssetListReducer";
import { fetchModelsLists } from "../../../redux/reducers/ModelsListReducer";
import { exportCSVFile } from "./../../../apis/assetListAPI/normalizers";
/**
 *
 * @description {fetch values from store}
 */
export const mapStateToProps = (state) => ({
  assetsListsdata: state.assetListReducer.assetsListsdata.deviceList,
  filteredData: state.assetListReducer.AssetFiltered,
  isFetchingAssets: state.assetListReducer.isFetchingAssets,
  modelsListsdata: state.ModelsListReducer.modelsListsdata.modelsList
    ? state.ModelsListReducer.modelsListsdata.modelsList
    : [],
  deviceParameters: state.assetListReducer.assetsDeviceParameters
    ? state.assetListReducer.assetsDeviceParameters
    : [],
});

/**
 *
 * @description {remove/unmount component values}
 */
export function componentDidMount() {
  this.props.fetchAssetsLists();
  this.props.fetchModelsLists();
  this.props.fetchDeviceParameters("onload");
}

/**
 *
 * @description {export assets data}
 */
  /* istanbul ignore next */
export const btnHandler = ({filteredData}) => {
  exportCSVFile(filteredData);
};

export default compose(
  connect(mapStateToProps, {
    fetchAssetsLists,
    fetchAssetsData,
    fetchModelsLists,
    fetchDeviceParameters,
  }),
  withHandlers({ btnHandler }),
  lifecycle({ componentDidMount })
);
