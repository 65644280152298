import React from "react";

export const TextArea = (props) => (
  <div className="field">
    <label>{props.title}</label>
    <textarea
      className={props.className}
      style={props.resize ? null : { resize: "none" }}
      name={props.input.name}
      rows={props.rows}
      cols={props.cols}
      value={props.content}
      onChange={props.controlFunc}
      placeholder={props.placeholder}
      disabled={props.isDisabled ? true : false}
      onKeyDown={props.onKeyDown}
    ></textarea>
    {/* {props.meta.submitFailed && props.meta.error && (
      <div className="ui red pointing basic label">{props.meta.error}</div>
    )} */}
  </div>
);

export default TextArea;
