import moment from 'moment/min/moment-with-locales';
import React from 'react';
import { authHeader } from '../apis/authAPI/loginAPI';

export const getLocaleTimezone = (value) => {
  const currentZone = moment.tz.guess();
  const zoneValue = moment.tz(value, currentZone);
  let utcZoneValue = moment.utc(zoneValue).toDate();
  return moment(utcZoneValue)
    .local()
    .format('YYYY-MM-DD HH:mm:ss');
};

export const getHeaders = () => {
  return {
    'Content-Type': 'application/json',
    AccessToken: authHeader(),
  };
};

export const getDeviceStatusMappings = (value) => {
  switch (value) {
    case 'ACTIVATED':
    case 'RESTARTED':
    case 'INITIALIZING':
    case 'INITIALIZED':
    case 'IDLE':
    case 'INIT':
    case 'STANDBY':
    case 'READY':
      return 'READY';
    case 'UNKNOWN':
      return 'UNKNOWN';
    case 'DISCONNECTED':
    case 'STOPPED':
    case 'DEACTIVATED':
    case 'SHUTDOWN':
    case 'OFF':
    case 'N/A':
      return 'DISCONNECTED';
    case 'EQUILDOWN':
    case 'EQUILUP':
    case 'ABORTING':
    case 'RUN':
    case 'PRERUN':
    case 'PRERUNDONE':
    case 'LOADINGSAMPLE':
    case 'SAMPLEREADY':
    case 'ACTIVEMETHOD':
      return 'RUNNING';
    case 'FAULT':
      return 'FAULT';
    default:
      return 'DISCONNECTED';
  }
};

//function to get the status indicator
export const getStatusIndicator = (input) => {
  switch (input) {
    case 'READY':
      return (
        <span>
          <i className={'circle small icon marginRight green'}></i>
          {input}
        </span>
      );
    case 'DISCONNECTED':
      return (
        <span>
          <i className={'circle small icon marginRight black'}></i>
          {input}
        </span>
      );
    case 'RUNNING':
      return (
        <span>
          <i className={'circle small icon marginRight blue'}></i>
          {input}
        </span>
      );
    case 'FAULT':
      return (
        <span>
          <i className={'circle small icon marginRight red'}></i>
          {input}
        </span>
      );
    default:
      return (
        <span>
          <i className={'circle small icon marginRight lightblack'}></i>
          {input}
        </span>
      );
  }
};

export const getICStatusIndicator = (input) => {
  switch (input) {
    case 'ON':
      return (
        <span>
          <i className={'circle small icon marginRight green'}></i>
          {input}
        </span>
      );
    case 'OFF':
      return (
        <span>
          <i className={'circle small icon marginRight black'}></i>
          {input}
        </span>
      );
    default:
      return (
        <span>
          <i className={'circle small icon marginRight lightblack'}></i>
          {input}
        </span>
      );
  }
};

export const getICStatus = (input) => {
  switch (input) {
    case '1':
      return 'ON';
    case '0':
      return 'OFF';
    default:
      return '';
  }
};
