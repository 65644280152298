import { connect } from "react-redux";
import { compose, withHandlers, withState } from "recompose";
import {
  setDeviceParameterToStore,
  appendValuesToTextArea,
  clearExpression,
  setInvalidExpression,
  removeValuesFromTextArea,
  setTextAreaValueToStore,
  reSetDeviceParameters,
} from "../../../../redux/reducers/RulesListReducer";
import addProps from "../../../../utilities/addProps";
import { change } from "redux-form";
import { validateRegEXExpression } from "../../../../utilities/validateExpression";
import { inputValuesData } from "../../../../utilities/inputValuesData";

/**
 *
 * @description {fetch values from store}
 */
export const mapStateToProps = (state) => ({
  expressionParamaters:
    state.rulesListreducer.deviceExpressionsData.ruleExpression,
  ruleExpression: state.rulesListreducer.textAreaValues
    ? state.rulesListreducer.textAreaValues
        .map((a) => a.expressionValues)
        .toString()
        .replace(/,/g, "")
    : "",
  IsExpressionValidated: state.rulesListreducer.IsExpressionValidated,
  parameterLists: state.rulesListreducer.parameterLists
    ? state.rulesListreducer.parameterLists
    : [],
  textAreaValueToStore: state.rulesListreducer.textAreaValueToStore,
  isFetching: state.rulesListreducer.isFetchingRules,
});

/**
 *
 * @description {fetch device paramters}
 */
export const paramterListForDropdown = ({ parameterLists }) => {
  const paramtersList = [];
  parameterLists.map(function(groupItem, key) {
    return Object.entries(groupItem.attributes).map(function(item) {
      return paramtersList.push({
        value: item[0], // pushing menomnic value
        displayText: item[1].displayName + " (" + item[0] + ")", // pushing display text value
      });
    });
  });
  return paramtersList.filter(
    (v, i, a) => a.findIndex((t) => t.value === v.value) === i // removing duplicates
  );
};

/**
 *
 * @description {remove values from textarea}
 */
export const removeValuesFromTextAreaEvent = ({ removeValuesFromTextArea }) => (
  event,
  rows
) => {
  event.preventDefault();
  removeValuesFromTextArea({ rowID: rows });
};

/**
 *
 * @description {append values to textarea on onchange}
 */
export const appendValuesToTextAreaOnchangeEvent = ({
  appendValuesToTextArea,
}) => (event) => {
  event.preventDefault();
  if (event.target.value !== "") {
    appendValuesToTextArea({ expressionValues: event.target.value, rowID: 0 });
  }
};

/**
 *
 * @description {append values to textarea}
 */
export const appendValuesToTextAreaEvent = ({ appendValuesToTextArea }) => (
  event,
  appendValues,
  rows
) => {
  event.preventDefault();
  if (appendValues !== "") {
    appendValuesToTextArea({ expressionValues: appendValues, rowID: rows });
  }
};

/**
 *
 * @description {clear textarea}
 */
export const clearExpressionEvent = ({
  clearExpression,
  reSetDeviceParameters,
  change,
}) => (event) => {
  event.preventDefault();
  clearExpression();
  reSetDeviceParameters();
};

/**
 *
 * @description {keyboard events}
 */
export const onKeyDown = ({ setKeyboardEvent }) => (event) => {
  var code = event.which;
  var evtobj = window.event ? event : "";

  if (evtobj.keyCode === 90 && evtobj.ctrlKey) {
    // if it is control + z key event
    return setKeyboardEvent("historyUndo");
  }
  setKeyboardEvent(code);
};

/**
 *
 * @description {expression validation}
 */
export const validateExpression = ({
  setDeviceParameterToStore,
  setInvalidExpression,
  change,
}) => (rows, ruleExpression, e) => {
  e.preventDefault();
  // validate expression method
  var validate = validateRegEXExpression(
    ruleExpression.replace(/&lt;/g, "<").replace(/&gt;/g, ">"),
    rows
  );

  if (validate) {
    setDeviceParameterToStore(
      ruleExpression.replace(/&lt;/g, "<").replace(/&gt;/g, ">") // if validated, sets the valid value to the store
    );

    var finalArr = inputValuesData(
      ruleExpression.replace(/&lt;/g, "<").replace(/&gt;/g, ">")
    );

    change("DeviceExpressionRootForm", "inputValues", finalArr);
  } else {
    setInvalidExpression(); // if not validated, sets the invalid value to the store
  }
};

/**
 *
 * @description {onchange of textarea values}
 */
export const onChangeTextarea = ({
  expressionParamaters,
  setTextAreaValueToStore,
  textAreaValueToStore,
  reSetDeviceParameters,
  clearExpression,
}) => (event, params, status, keyboardEvent) => {
  if (status === true) {
    if (
      expressionParamaters !== "" &&
      expressionParamaters !== undefined &&
      expressionParamaters !== "NOVALUE"
    ) {
      setTextAreaValueToStore(expressionParamaters + params);
      reSetDeviceParameters();
    } else {
      setTextAreaValueToStore(textAreaValueToStore + params);
    }
  } else {
    if (
      event.nativeEvent.inputType !== "historyUndo" || //history undo control +z
      keyboardEvent !== "historyUndo"
    ) {
      if (!/[^-+\/*()0-9\[\]]/g.test(event.nativeEvent.data)) {
        setTextAreaValueToStore(params);
      } else if (
        event.nativeEvent.inputType === "deleteContentBackward" || // backspace to remove the values
        keyboardEvent === 8
      ) {
        setTextAreaValueToStore(params);
        if (params === "") {
          clearExpression();
          reSetDeviceParameters();
        }
      } else {
        setTextAreaValueToStore(textAreaValueToStore);
      }
    } else {
      setTextAreaValueToStore(params);
    }
  }
};

export default compose(
  connect(mapStateToProps, {
    setDeviceParameterToStore,
    appendValuesToTextArea,
    clearExpression,
    setInvalidExpression,
    removeValuesFromTextArea,
    change,
    setTextAreaValueToStore,
    reSetDeviceParameters,
    // divOnChange
  }),
  addProps({ paramterListForDropdown }),
  withState("keyboardEvent", "setKeyboardEvent", ""),
  withHandlers({
    validateExpression,
    appendValuesToTextAreaEvent,
    clearExpressionEvent,
    removeValuesFromTextAreaEvent,
    appendValuesToTextAreaOnchangeEvent,
    onChangeTextarea,
    onKeyDown,
  })
);
