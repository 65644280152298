import raiseError from "./../../utilities/raiseError";
import api from "../config_api";
import moment from "moment/min/moment-with-locales";
import { authHeader } from "../authAPI/loginAPI";
import { getHeaders } from "../../utilities/helpers";
import { commonLabels, pageSize } from "../../redux/constants/Values";

export const fetchContentsLists = async ({ getState, values }) => {
  const pageSetup = {
    pageSize: pageSize.SIZE,
    offset: 1,
  };

  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(pageSetup),
  };

  const response = await fetch(api.get_content, requestOptions);
  let responseBody;
  if (response.ok) {
    responseBody = await response.json();
    return responseBody;
  } else {
    responseBody = await response.json();
    raiseError({ response, responseBody });
  }
};

//update contents
export const fetchContentsDataForUpdate = async ({ getState, values }) => {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(values),
  };
  const response = await fetch(api.contents_url, requestOptions);
  let responseBody;
  if (response.ok) {
    responseBody = await response.json();
    return responseBody;
  } else {
    responseBody = await response.json();
    raiseError({ response, responseBody });
  }
};

export const fecthContentsByAdvanceSearch = async ({ getState, values }) => {
  const pageSetup = {
    pageSize: pageSize.SIZE,
    offset: 1,
  };
  var parameterOptions;
  if (values.serialNumbers) {
    var serialNumbers = values.serialNumbers.split(",");
    parameterOptions = { serialNumbers };
  }
  if (values.updateTime) {
    let updateTime = moment(values.updateTime)
      .add(1, "days")
      .utc()
      .format();
    parameterOptions = { updateTime };
  }
  var models = [];
  if (values.models) {
    models = values.models.split(",");
    values = {
      ...values,
      ...{ models },
      ...parameterOptions,
      ...{ serialNumbers },
      ...pageSetup,
    };
  } else {
    values = { ...values, ...parameterOptions, ...pageSetup };
  }
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(values),
  };
  const response = await fetch(api.get_content, requestOptions);
  let responseBody;
  if (response.ok) {
    if (response.status === 204) {
      responseBody = {
        offSet: "1",
        pageSize: pageSize.SIZE,
        deviceList: [],
      };
      window.alert(commonLabels.noContent);
    } else {
      responseBody = await response.json();
    }
    return responseBody;
  } else {
    responseBody = await response.json();
    raiseError({ response, responseBody });
  }
};

export const createContent = async ({ getState, values }) => {
  const getFile = getState().ContentsListReducer.createFileContentData;
  let reqPostObj = {
    fileKey: getState().ContentsListReducer.createFileContentData.name,
  };
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(reqPostObj),
  };

  const response = await fetch(api.create_content_init_url, requestOptions);
  let responseBody;
  if (response.ok) {
    responseBody = await response.json();

    return createContent2(responseBody, getFile, values, getState);
  } else {
    return response.json().then((text) => {
      throw new Error(text.Message);
    });
  }
};
export const createContent2 = async (
  responseBody2,
  getFile2,
  values,
  getState
) => {
  const requestOptions2 = {
    method: "PUT",
    headers: {
      "Content-Type": "application/octet-stream",
      AccessToken: authHeader(),
    },
    body: getFile2,
  };
  const upload_putUrl = responseBody2.putUrl;
  const final_postUrl1 = responseBody2.getUrl;

  const responseSecond = await fetch(upload_putUrl, requestOptions2);
  if (responseSecond.ok) {
    return createContent3(final_postUrl1, values, getState);
  } else {
    return responseSecond.json().then((text) => {
      throw new Error(text.message);
    });
  }
};
export const createContent3 = async (final_postUrl1, values, getState) => {
  let modelsLabelConstructArray =
    values.models !== ""
      ? values.models.map((labelItem) => {
          return labelItem.value;
        })
      : [];
  let finalPostObj = {
    jobName: values.jobName,
    description: values.description,
    fileName: getState().ContentsListReducer.createFileContentData.name,
    targets: {
      models: modelsLabelConstructArray,
      deviceSerialNumbers:
        modelsLabelConstructArray.length > 0
          ? []
          : getState().ContentsListReducer.matchedSerialNumbers,
    },
    mandatoryUpdate: values.mandatoryUpdate,
    operation: values.operation,
    version: values.version,
    criteriaList: [
      {
        failureType: "TIMED_OUT",
        action: "CANCEL",
        thresholdPercentage: "97.0",
        minNumberOfExecutedThings: "97",
      },
    ],
    preSignedUrl: final_postUrl1,
    timeoutConfig: "1",
    createdBy: sessionStorage.getItem("username"),
    updatedBy: sessionStorage.getItem("username"),
    updateTime: moment(new Date())
      .utc()
      .format(),
    createTime: moment(new Date())
      .utc()
      .format(),
    status: "active",
  };
  const requestOptions3 = {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify(finalPostObj),
  };
  const responseThree = await fetch(
    api.create_content_final_url,
    requestOptions3
  );
  let responseBody3new;
  if (responseThree.ok) {
    responseBody3new = await responseThree.json();
    return responseBody3new;
  } else {
    return responseThree.json().then((text) => {
      throw new Error(text.message);
    });
  }
};

//update content
export const updateContent = async ({ getState, values }) => {
  const { fetchContentForUpdateList } = getState().ContentsListReducer;
  let description = values.description;
  let updatedBy = sessionStorage.getItem("username");

  let updateTime = moment(new Date())
    .utc()
    .format();

  values = {
    ...fetchContentForUpdateList,
    ...{ description },
    ...{ updatedBy },
    ...{ updateTime },
  };
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(values),
  };

  let responseBody;
  const response = await fetch(api.update_content, requestOptions);
  if (response.ok) {
    responseBody = await response.json();
    return responseBody;
  } else {
    return response.json().then((text) => {
      throw new Error(text.message);
    });
  }
};

export const fetchContentForUpdate = async ({ getState, values }) => {
  const requestOptions = {
    method: "GET",
    headers: getHeaders(),
  };
  const response = await fetch(
    api.get_content_jobname.concat(values.trim()),
    requestOptions
  );
  let responseBody;
  if (response.ok) {
    responseBody = await response.json();
    return responseBody;
  } else {
    return response.json().then((text) => {
      throw new Error(text.message);
    });
  }
};
