import { connect } from "react-redux";
import { compose, withHandlers, withState } from "recompose";
import { reduxForm } from "redux-form";
import {
  fetchAssetsByAdvanceSearch,
  fetchDeviceParameters,
  fetchAssetsLists,
} from "../../../redux/reducers/AssetListReducer";
let AssetFormInitial = {
  assetName: "",
  Model: "",
  DataItem: "",
  DataItemConditions: "",
  DataItemValue: "",
};

/**
 *
 * @description {submit search values}
 */
export const handleSubmit = ({
  AssetForm,
  fetchAssetsByAdvanceSearch,
}) => () => {
  fetchAssetsByAdvanceSearch(AssetForm);
};

/**
 *
 * @description {update onchange search values}
 */

export const handleChange = ({ AssetForm, SetAssetForm }) => (e, data) => {
  e.preventDefault();
  if (data === undefined) {
    SetAssetForm({
      ...AssetForm,
      [e.target.name]: e.target.value,
    });
  } else {
    SetAssetForm({
      ...AssetForm,
      [data.name]: data.value,
    });
  }
};

/**
 *
 * @description {reset form values and data of asset page}
 */

export const handleReset = ({ SetAssetForm, fetchAssetsLists }) => () => {
  SetAssetForm(AssetFormInitial);
  fetchAssetsLists(AssetFormInitial);
};

export default compose(
  connect(null, {
    fetchAssetsByAdvanceSearch,
    fetchAssetsLists,
    fetchDeviceParameters,
  }),
  withState("AssetForm", "SetAssetForm", AssetFormInitial),
  withHandlers({ handleSubmit, handleChange, handleReset }),
  reduxForm({
    form: "AssetsByAdvanceSearch",
    enableReinitialize: true,
  })
);
