import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import { reduxForm, reset } from "redux-form";
import {
  rulesAddPageRoute,
  saveDataExpressionData,
  updateDataExpressionData,
  resetRulesPage,
} from "../../../../redux/reducers/RulesListReducer";
import { withRouter } from "react-router";

/**
 *
 * @description {confirmation message}
 */
export const confirmMsg =
  "Are you sure you want to leave this screen? \nEntry will be discarded. ";
/**
 *
 * @description {fetch values from store}
 */
export const mapStateToProps = (state) => ({
  dataExpressionForReview: state.rulesListreducer.dataExpressionForReview,
  expressionParamaters: state.rulesListreducer.deviceExpressionsData,
  isFetching: state.rulesListreducer.isFetchingRules,
});
/**
 *
 * @description {return to create page}
 */
export const returnToAddPage = ({ rulesAddPageRoute }) => () => {
  rulesAddPageRoute();
};
/**
 *
 * @description {save/update dataexpression}
 */
export const saveDataExpressionEvent = ({
  saveDataExpressionData,
  updateDataExpressionData,
  updateProps,
  redirectToRuleListpath,
}) => () => {
  if (updateProps === "update") {
    updateDataExpressionData().then((payload) => {
      if (payload.type === "UPDATE_DATA_EXPRESSION_DATA_REQUEST_SUCCESS") {
        window.alert(payload.payload.message);
        redirectToRuleListpath();
      } else {
        window.alert(payload.payload.message);
      }
    });
  } else {
    saveDataExpressionData().then((payload) => {
      if (payload.type === "SAVE_DATA_EXPRESSION_DATA_REQUEST_SUCCESS") {
        window.alert(payload.payload.message);
        redirectToRuleListpath();
      } else {
        window.alert(payload.payload.message);
      }
    });
  }
};

/**
 *
 * @description {redirect to ruleList page}
 */
export const redirectToRuleListpath = ({ history }) => () =>
  history.push("/rules-list");

/**
 *
 * @description {cancel button click}
 */
export const cancelEvent = ({
  resetRulesPage,
  redirectToRuleListpath,
  reset,
  updateProps,
}) => () => {
  if (window.confirm(confirmMsg)) { // if "ok" return to rulel list page
    reset("DeviceExpressionRootForm");
    resetRulesPage();
    if (updateProps === "update") {
      redirectToRuleListpath();
    }
  }
};

/**
 *
 * @description {clear form after submission}
 */
export const afterSubmit = (result, dispatch) => {
  dispatch(reset("DeviceExpressionRootForm")); //reseting the expression form
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    rulesAddPageRoute,
    saveDataExpressionData,
    updateDataExpressionData,
    resetRulesPage,
    reset,
  }),
  withHandlers({
    redirectToRuleListpath,
  }),
  withHandlers({
    returnToAddPage,
    saveDataExpressionEvent,
    cancelEvent,
  }),
  // addProps({
  //   modelsForRulesData
  // }),

  reduxForm({
    form: "DataExpressionReviewForm",
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    destroyOnUnmount: false,
    onSubmitSuccess: afterSubmit,
  })
);
