import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import { reduxForm } from "redux-form";
import { fetchCreateUser } from "../../../redux/reducers/UsersListReducer";
import { usersListPagePath } from "./../../../utilities/pathHelpers";
import { withRouter } from "react-router";

export const mapStateToProps = (state) => ({
  isFetching: state.UsersListReducer.isFetchingUsers,
});

export const backToListPage = ({ history }) => () => {
  history.push(usersListPagePath);
};

export const onSubmit = ({ fetchCreateUser, history }) => async (values) => {
  let data = {
    userName: values.firstname + values.lastname,
    password: values.password,
    userEmail: values.email,
    // phoneNumber: values.phone,
    userGroupsList: [{ groupName: values.usergroups }],
    createdBy: sessionStorage.getItem("username"),
    // sendMail: values.sendMail !== undefined ? values.sendMail : false,
    sendMail: true,
  };
  fetchCreateUser(data).then((payload) => {
    if (payload.type === "FETCH_CREATE_USERS_REQUEST_SUCCESS") {
      window.alert(payload.payload.message);
      history.push("/users-list");
    } else {
      window.alert(payload.payload.message);
    }
  });
};

export default compose(
  withRouter,
  connect(mapStateToProps, { fetchCreateUser }),
  withHandlers({ onSubmit, backToListPage }),
  reduxForm({
    form: "fecthCreateUser",
    enableReinitialize: true,
  })
);
