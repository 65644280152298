import React from "react";
import PropTypes from "prop-types";
// import "bootstrap";
import AdvanceSearch from "../AdvanceSearch";
import { Dimmer, Loader } from "semantic-ui-react";
import ReportsTableData from "./../ReportsTableData";
import { Divider } from "semantic-ui-react";
import enhance from "./enhance";
import { NavItems, commonLabels } from "../../../redux/constants/Values";

export const ReportsList = (props) => {
  const { reportsListdata, isFetching } = props;
  return (
    <>
      {isFetching && (
        <Dimmer active inverted>
          <Loader size="large" inverted>
            {commonLabels.loading}
          </Loader>
        </Dimmer>
      )}
      <div className="containerStyle">
        <h3>{NavItems.reports + " " + commonLabels.list}</h3>
        <Divider />
        <AdvanceSearch />
        <Divider />
        <ReportsTableData reportsListsdata={reportsListdata} {...props} />
      </div>
    </>
  );
};

ReportsList.propTypes = {
  reportsListsdata: PropTypes.array,
};

export default enhance(ReportsList);
