import React from "react";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import "react-table/react-table.css";
import enhance from "./enhance";
import { Alerts } from "../../../../redux/constants/Values";

export const TableData = (props) => {
  const { getMnemonicsData } = props;
  /* istanbul ignore next */
  return (
    <div>
      <ReactTable
        data={Object.keys(props.data).length !== 0 ? props.data : []}
        getTheadFilterThProps={(e) => {
          let filtredData = e.sortedData.map((eachVal) => {
            return eachVal._original;
          });
          getMnemonicsData(filtredData.length > 0 ? filtredData : []);
          return {
            style: { position: "inherit", overflow: "inherit" },
          };
        }}
        filterable
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id]) === filter.value
        }
        columns={[
          {
            Header: "Rule Name",
            id: "ruleName",
            accessor: (d) => d.ruleName,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["ruleName"] }),
            filterAll: true,
          },
          {
            Header: "Rule Expression",
            id: "ruleExpression",
            accessor: (d) => d.ruleExpression,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["ruleExpression"] }),
            filterAll: true,
          },
          {
            Header: "Rule Description",
            id: "description",
            accessor: (d) => d.description,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["description"] }),
            filterAll: true,
          },
          {
            Header: "Severity",
            id: "severity",
            accessor: (d) =>
              d.severity && d.severity.match(/^[0-9]+$/) !== null
                ? parseInt(d.severity)
                : d.severity,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["severity"] }),
            filterAll: true,
          },
        ]}
        defaultPageSize={10}
        minRows={3}
        noDataText={Alerts.noRows}
        className="-striped -highlight"
      />
    </div>
  );
};
export default enhance(TableData);
