import types from "../actions/actionTypes";
import { createThunk } from "../../utilities/createThunk";
import backendAPI from "../../apis";
import { combineReducers } from "redux";
import { Alerts } from "../constants/Values";

const initialState = {
  modelsListsdata: {},
  modelsDataforReview: [],
  modelData: {},
  modelDeviceParams: [],
  excelModelDeviceParams: [],
  modelsCheckedLists: [],
  createModelResponse: "",
  updateModelResponse: "",
  deleteModelResponse: "",
  isFetchingModels: false,
  isFetchingDeviceParams: false,
  modelParamsOnUpdate: [],
  excelModelParamsOnUpdate: [],
  pageSize: [],
};

export const fetchModelsLists = createThunk({
  requestActionType: types.FETCH_MODELS_LIST_REQUEST,
  apiCall: backendAPI.modelsListAPI.fetchModelsLists,
});

export const forceDeleteModel=createThunk({
  requestActionType: types.DELETE_MODEL_REQUEST,
  apiCall: backendAPI.modelsListAPI.forceDeleteModel,
})

export const modelsNextPageRoute = () => ({
  type: types.MODEL_NEXT_BUTTON_CLICKED,
});

export const modelBackButtonIsClicked = () => ({
  type: types.IS_BACK_BUTTON_CLICKED,
});

export const modelCancelButtonIsClicked = () => ({
  resetModelsPage,
  type: types.IS_CANCEL_BUTTON_CLICKED,
});

export const ModelsListReducer = {
  fetchModelsLists,
};

const modelsListsdata = (
  state = initialState.modelsListsdata,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_MODELS_LIST_REQUEST_SUCCESS:
      return payload;
    case "DEFAULT14":
      return payload;
    case "DEFAULT15":
      return payload;
    default:
      return state;
  }
};

const isFetchingModels = (
  state = initialState.isFetchingModels,
  { type, payload }
) => {
  switch (type) {
    case types.CREATE_MODEL_DATA_REQUEST:
    case types.UPDATE_MODEL_DATA_REQUEST:
    case types.FETCH_MODELS_LIST_REQUEST:
    case types.FETCH_MODELS_DATA_REQUEST:
    case types.FETCH_MODELS_DEVICE_PARAMS_REQUEST:
    case types.FETCH_EXCEL_MODELS_DEVICE_PARAMS_REQUEST:
    case types.DELETE_MODEL_REQUEST:
      return true;
    case types.CREATE_MODEL_DATA_REQUEST_SUCCESS:
    case types.CREATE_MODEL_DATA_REQUEST_FAILURE:
    case types.UPDATE_MODEL_DATA_REQUEST_SUCCESS:
    case types.UPDATE_MODEL_DATA_REQUEST_FAILURE:
    case types.FETCH_MODELS_LIST_REQUEST_SUCCESS:
    case types.FETCH_MODELS_LIST_REQUEST_FAILURE:
    case types.FETCH_MODELS_DATA_REQUEST_SUCCESS:
    case types.FETCH_MODELS_DATA_REQUEST_FAILURE:
    case types.FETCH_MODELS_DEVICE_PARAMS_REQUEST_SUCCESS:
    case types.FETCH_MODELS_DEVICE_PARAMS_REQUEST_FAILURE:
    case types.FETCH_EXCEL_MODELS_DEVICE_PARAMS_REQUEST_SUCCESS:
    case types.FETCH_EXCEL_MODELS_DEVICE_PARAMS_REQUEST_FAILURE:
    case types.DELETE_MODEL_REQUEST_SUCCESS:
    case types.DELETE_MODEL_REQUEST_FAILURE:
      return false;
    default:
      return state;
  }
};

const isFetchingDeviceParams = (
  state = initialState.isFetchingDeviceParams,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_MODELS_DEVICE_PARAMS_REQUEST:
    case types.FETCH_EXCEL_MODELS_DEVICE_PARAMS_REQUEST:
      return true;
    case types.FETCH_MODELS_DEVICE_PARAMS_REQUEST_SUCCESS:
    case types.FETCH_MODELS_DEVICE_PARAMS_REQUEST_FAILURE:
    case types.FETCH_EXCEL_MODELS_DEVICE_PARAMS_REQUEST_SUCCESS:
    case types.FETCH_EXCEL_MODELS_DEVICE_PARAMS_REQUEST_FAILURE:
      return false;

    default:
      return state;
  }
};

// fetch model data
export const fetchModelsData = createThunk({
  requestActionType: types.FETCH_MODELS_DATA_REQUEST,
  apiCall: backendAPI.modelsListAPI.fetchModelsData,
});

const modelData = (state = initialState.modelData, { type, payload }) => {
  switch (type) {
    case types.FETCH_MODELS_DATA_REQUEST_SUCCESS:
      return payload;
    case types.RESET_MODELS_PAGE:
      return initialState.modelData;
    default:
      return state;
  }
};

// fetch model device params
export const fecthModelDeviceParams = createThunk({
  requestActionType: types.FETCH_MODELS_DEVICE_PARAMS_REQUEST,
  apiCall: backendAPI.modelsListAPI.fetchModelsDeviceParams,
});

export const fecthExcelModelDeviceParams = createThunk({
  requestActionType: types.FETCH_EXCEL_MODELS_DEVICE_PARAMS_REQUEST,
  apiCall: backendAPI.modelsListAPI.fetchExcelModelsDeviceParams,
});

const modelDeviceParams = (
  state = initialState.modelDeviceParams,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_MODELS_DEVICE_PARAMS_REQUEST_SUCCESS:
      return payload;
    case types.RESET_MODELS_PAGE:
      return initialState.modelDeviceParams;
    default:
      return state;
  }
};

const excelModelDeviceParams = (
  state = initialState.excelModelDeviceParams,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_EXCEL_MODELS_DEVICE_PARAMS_REQUEST_SUCCESS:
      return payload;
    case types.RESET_MODELS_PAGE:
      return initialState.excelModelDeviceParams;
    default:
      return state;
  }
};

//checkd models list
export const addAttributesToAttributesCheckedList = (attributes) => ({
  type: types.ADD_ATTRIBUTS_TO_ATTRIBUTES_CHECKED_LISTS,
  payload: attributes,
});

export const removeAttributesToAttributesCheckedList = (attributes) => ({
  type: types.REMOVE_ATTRIBUTS_TO_ATTRIBUTES_CHECKED_LISTS,
  payload: attributes,
});

const modelsCheckedLists = (
  state = initialState.modelsCheckedLists,
  { type, payload }
) => {
  switch (type) {
    case types.ADD_ATTRIBUTS_TO_ATTRIBUTES_CHECKED_LISTS:
      return [...state, payload];
    case types.REMOVE_ATTRIBUTS_TO_ATTRIBUTES_CHECKED_LISTS:
      return state.filter((t1) => t1.t1 !== payload.t1);
    case types.RESET_MODELS_PAGE:
    case types.FETCH_MODELS_DATA_REQUEST_SUCCESS:
    case types.FETCH_EXCEL_MODELS_DEVICE_PARAMS_REQUEST_SUCCESS:
    case types.CREATE_MODEL_DATA_REQUEST_FAILURE:
    case types.UPDATE_MODEL_DATA_REQUEST_FAILURE:
      return [];
    default:
      return state;
  }
};

// save models data

export const saveModelsDataToStore = ({ values }) => ({
  type: types.SAVE_MODELS_DATA_TO_STORE,
  payload: { values },
});

export const resetModelsPage = () => ({
  type: types.RESET_MODELS_PAGE,
});

const modelsDataforReview = (
  state = initialState.modelsDataforReview,
  { type, payload }
) => {
  switch (type) {
    case types.SAVE_MODELS_DATA_TO_STORE:
      return payload.values;
    case types.MODEL_BACK_BUTTON_IS_CLICKED:
      return state;
    case types.RESET_MODELS_PAGE:
      return initialState.modelsDataforReview;
    case types.MODEL_CANCEL_BUTTON_IS_CLICKED:
      return initialState.modelsDataforReview;
    default:
      return state;
  }
};

export const createModel = createThunk({
  requestActionType: types.CREATE_MODEL_DATA_REQUEST,
  apiCall: backendAPI.modelsListAPI.createModel,
});

export const updateModel = createThunk({
  requestActionType: types.UPDATE_MODEL_DATA_REQUEST,
  apiCall: backendAPI.modelsListAPI.updateModel,
});

const createModelResponse = (
  state = initialState.createModelResponse,
  { type, payload }
) => {
  switch (type) {
    case types.CREATE_MODEL_DATA_REQUEST_SUCCESS:
      return payload;
    case types.CREATE_MODEL_DATA_REQUEST_FAILURE:
      return payload;
    case types.RESET_MODELS_PAGE:
      return initialState.createModelResponse;
    default:
      return state;
  }
};

const deleteModelResponse = (
  state = initialState.deleteModelResponse,
  { type, payload }
) => {
  switch (type) {
    case types.DELETE_MODEL_REQUEST_SUCCESS:
      return payload;
    case types.DELETE_MODEL_REQUEST_FAILURE:
      return payload;
    case types.RESET_MODELS_PAGE:
      return initialState.deleteModelResponse;
    default:
      return state;
  }
};

const updateModelResponse = (
  state = initialState.updateModelResponse,
  { type, payload }
) => {
  switch (type) {
    case types.UPDATE_MODEL_DATA_REQUEST_SUCCESS:
      return payload;
    case types.UPDATE_MODEL_DATA_REQUEST_FAILURE:
      return payload;
    case types.RESET_MODELS_PAGE:
      return initialState.updateModelResponse;
    default:
      return state;
  }
};

export const addModelListOnUpdate = (mnemonic) => ({
  type: types.ADD_MODELS_TO_CHECKED_LISTS_ON_UPDATE,
  payload: mnemonic,
});

export const removeModelListOnUpdate = (mnemonic) => ({
  type: types.REMOVE_MODELS_TO_CHECKED_LISTS_ON_UPDATE,
  payload: mnemonic,
});

const modelParamsOnUpdate = (
  state = initialState.modelParamsOnUpdate,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_MODELS_DATA_REQUEST_SUCCESS:
      const modelList = [];
      payload.paramsList.map((groupItem, key) => {
        return Object.entries(groupItem.attributes).map((item) => {
          return modelList.push(item[0]);
        });
      });
      return [...state, ...modelList];
    case types.FETCH_EXCEL_MODELS_DEVICE_PARAMS_REQUEST_SUCCESS:
      if (payload.length !== 0) {
        state = [];
        const excelModelList = [];
        payload.map((item, key) => {
          return excelModelList.push(item.mnemonic);
        });
        return [...state, ...excelModelList];
      } else {
        window.alert(Alerts.noData);
        return [...state];
      }
    case types.ADD_MODELS_TO_CHECKED_LISTS_ON_UPDATE:
      return [...state, payload];
    case types.REMOVE_MODELS_TO_CHECKED_LISTS_ON_UPDATE:
      return state.filter((t1) => t1 !== payload);
    case types.RESET_MODELS_PAGE:
      return initialState.modelParamsOnUpdate;
    default:
      return state;
  }
};

const excelModelParamsOnUpdate = (
  state = initialState.excelModelParamsOnUpdate,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_EXCEL_MODELS_DEVICE_PARAMS_REQUEST_SUCCESS:
      state = [];
      const modelList = [];
      payload.map((item, key) => {
        return modelList.push(item.mnemonic);
      });
      return [...state, ...modelList];
    case types.ADD_MODELS_TO_CHECKED_LISTS_ON_UPDATE:
      return [...state, payload];
    case types.REMOVE_MODELS_TO_CHECKED_LISTS_ON_UPDATE:
      return state.filter((t1) => t1 !== payload);
    case types.RESET_MODELS_PAGE:
      return initialState.excelModelParamsOnUpdate;
    default:
      return state;
  }
};

export const addPageSize = (page) => ({
  type: types.ADD_PAGE_NUMBER,
  payload: page,
});

export const removePageSize = (page) => ({
  type: types.REMOVE_PAGE_NUMBER,
  payload: page,
});

const pageSize = (state = initialState.pageSize, { type, payload }) => {
  switch (type) {
    case types.ADD_PAGE_NUMBER:
      return [...state, payload];
    case types.REMOVE_PAGE_NUMBER:
      return state.filter((t1) => t1 !== payload);
    case types.RESET_MODELS_PAGE:
      return initialState.pageSize;
    default:
      return state;
  }
};

export default combineReducers({
  modelsListsdata,
  modelDeviceParams,
  excelModelDeviceParams,
  modelsDataforReview,
  modelData,
  modelsCheckedLists,
  createModelResponse,
  deleteModelResponse,
  updateModelResponse,
  isFetchingModels,
  isFetchingDeviceParams,
  modelParamsOnUpdate,
  excelModelParamsOnUpdate,
  pageSize,
});
