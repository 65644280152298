import React from "react";

import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

export const RenderSelectInput = ({
  input,
  options,
  // name,
  // id,
  meta: { error, submitFailed }
}) => (


    <div>
      <ReactMultiSelectCheckboxes
        {...input}
        // id={id}
        // name={name}
        options={options}
        value={input.value}
        onChange={value => input.onChange(value)}
        onBlur={value => input.onBlur(input.value)}
        meta={error}
      />
      {submitFailed && (input.value.length === 0 || input.value === "") && (
        <div className="ui red pointing basic label">Required</div>
      )}
    </div>
  );

export default RenderSelectInput;
