import types from "../actions/actionTypes";
import { createThunk } from "../../utilities/createThunk";
import backendAPI from "../../apis";
import { combineReducers } from "redux";

const initialState = {
  contentsListsdata: {},
  createContentData: [],
  createContentFileData: "",
  //contentsListByAdvanceSearch: [],
  fetchDataForContentUpdate: "",
  isFetchingContents: false,
  matchedSerialNumbers: [],
  fetchContentForUpdateList: [],
  isSerialMatched: "",
};

export const fetchContentsLists = createThunk({
  requestActionType: types.FETCH_CONTENTS_LIST_REQUEST,
  apiCall: backendAPI.contentsListAPI.fetchContentsLists,
});

export const fecthContentsListByAdvanceSearch = createThunk({
  requestActionType: types.FETCH_CONTENTS_BY_ADVANCESEARCH_REQUEST,
  apiCall: backendAPI.contentsListAPI.fecthContentsByAdvanceSearch,
});

export const fetchContentsDataForUpdate = createThunk({
  requestActionType: types.FETCH_CONTENTS_DATA_FOR_UPDATE_REQUEST,
  apiCall: backendAPI.contentsListAPI.fetchContentsDataForUpdate,
});

export const createContent = createThunk({
  requestActionType: types.CREATE_CONTENT_REQUEST,
  apiCall: backendAPI.contentsListAPI.createContent,
});

export const fetchCreateFileContent = (values) => ({
  type: types.CREATE_CONTENT_FILE,
  payload: values,
});
export const ContentsListReducer = {
  fetchContentsLists,
  createContent,
};

const contentsListsdata = (
  state = initialState.contentsListsdata,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_CONTENTS_LIST_REQUEST_SUCCESS:
    case types.FETCH_CONTENTS_BY_ADVANCESEARCH_REQUEST_SUCCESS:
      return payload;
    case types.RESET_CONTENT_PAGE:
      return initialState.contentsListsdata;
    default:
      return state;
  }
};

const fetchDataForContentUpdate = (
  state = initialState.fetchDataForContentUpdate,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_CONTENTS_DATA_FOR_UPDATE_REQUEST_SUCCESS:
      return payload[0].attributes;
    case types.RESET_CONTENT_PAGE:
      return initialState.fetchDataForContentUpdate;
    default:
      return state;
  }
};

const createContentData = (
  state = initialState.createContentData,
  { type, payload }
) => {
  switch (type) {
    case types.CREATE_CONTENT_REQUEST_SUCCESS:
      return payload;
    case types.RESET_CONTENT_PAGE:
      return initialState.createContentData;
    default:
      return state;
  }
};
const createFileContentData = (
  state = initialState.createContentFileData,
  { type, payload }
) => {
  switch (type) {
    case types.CREATE_CONTENT_FILE:
      return payload === undefined ? null : payload;
    case types.RESET_CONTENT_PAGE:
      return initialState.createContentFileData;
    default:
      return state;
  }
};
const isFetchingContents = (
  state = initialState.isFetchingContents,
  { type, payload }
) => {
  switch (type) {
    case types.CREATE_CONTENT_REQUEST:
    case types.FETCH_CONTENT_FOR_UPDATE_REQUEST:
    case types.UPDATE_CONTENT_REQUEST:
    case types.FETCH_CONTENTS_LIST_REQUEST:
    case types.FETCH_CONTENTS_BY_ADVANCESEARCH_REQUEST:
      return true;
    case types.CREATE_CONTENT_REQUEST_SUCCESS:
    case types.FETCH_CONTENT_FOR_UPDATE_REQUEST_SUCCESS:
    case types.UPDATE_CONTENT_REQUEST_SUCCESS:
    case types.FETCH_CONTENTS_LIST_REQUEST_SUCCESS:
    case types.FETCH_CONTENTS_BY_ADVANCESEARCH_REQUEST_SUCCESS:
      return false;
    case types.CREATE_CONTENT_REQUEST_FAILURE:
    case types.FETCH_CONTENT_FOR_UPDATE_REQUEST_FAILURE:
    case types.UPDATE_CONTENT_REQUEST_FAILURE:
    case types.FETCH_CONTENTS_LIST_REQUEST_FAILURE:
    case types.FETCH_CONTENTS_BY_ADVANCESEARCH_REQUEST_FAILURE:
      return false;
    default:
      return state;
  }
};

//update content

export const fetchContentForUpdate = createThunk({
  requestActionType: types.FETCH_CONTENT_FOR_UPDATE_REQUEST,
  apiCall: backendAPI.contentsListAPI.fetchContentForUpdate,
});

export const updateContent = createThunk({
  requestActionType: types.UPDATE_CONTENT_REQUEST,
  apiCall: backendAPI.contentsListAPI.updateContent,
});

const fetchContentForUpdateList = (
  state = initialState.fetchContentForUpdateList,
  { type, payload }
) => {
  switch (type) {
    case types.FETCH_CONTENT_FOR_UPDATE_REQUEST_SUCCESS:
      return payload;
    case types.RESET_CONTENT_PAGE:
      return initialState.fetchContentForUpdateList;
    default:
      return state;
  }
};

// check Serial Present

export const IscheckSerialPresent = (values) => ({
  type: types.IS_CHECKED_SERIAL_NUMBER_PRESENT,
  payload: values,
});

export const resetSerialNumbersMatched = () => ({
  type: types.RESET_SERIAL_NUMBERS_MATCHED,
});

const matchedSerialNumbers = (
  state = initialState.matchedSerialNumbers,
  { type, payload }
) => {
  switch (type) {
    case types.IS_CHECKED_SERIAL_NUMBER_PRESENT:
      return [...state, payload];
    case types.RESET_SERIAL_NUMBERS_MATCHED:
    case types.RESET_CONTENT_PAGE:
      return initialState.matchedSerialNumbers;
    default:
      return state;
  }
};

export const serialIsMatched = () => ({
  type: types.SERIAL_IS_MATCHED,
});

export const serialIsNotMatched = () => ({
  type: types.SERIAL_IS_NOT_MATCHED,
});

export const resetSerialMatched = () => ({
  type: types.RESET_SERIAL_MATCHED_STATUS,
});

const isSerialMatched = (state = initialState.isSerialMatched, { type }) => {
  switch (type) {
    case types.SERIAL_IS_MATCHED:
      return true;
    case types.SERIAL_IS_NOT_MATCHED:
      return false;
    case types.RESET_SERIAL_MATCHED_STATUS:
    case types.RESET_CONTENT_PAGE:
      return initialState.isSerialMatched;
    default:
      return state;
  }
};

export const resetContentPage = () => ({
  type: types.RESET_CONTENT_PAGE,
});

export default combineReducers({
  contentsListsdata,
  //contentsListByAdvanceSearch,
  fetchDataForContentUpdate,
  createContentData,
  createFileContentData,
  isFetchingContents,
  matchedSerialNumbers,
  fetchContentForUpdateList,
  isSerialMatched,
});
