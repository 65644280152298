import React, { useState } from "react";
import { Field } from "redux-form";
import enhance from "./enhance";
import { Form, Button } from "semantic-ui-react";
import { commonLabels } from "../../../redux/constants/Values";

export const AdvanceSearch = (props) => {
  const { handleSubmit, reset } = props;
  const [open, togglePanel] = useState(false);
  const publishedOptions = [
    { text: "YES", value: "YES" },
    { text: "NO", value: "NO" },
  ];

  return (
    <div>
      <div>
        <div onClick={() => togglePanel(!open)} className="toggleHeader">
          <i
            className={open ? "caret square up icon" : "caret square down icon"}
          ></i>
          {commonLabels.advanceSearch}
        </div>

        {open ? (
          <div className="toggleContent">
            <Form onSubmit={handleSubmit}>
              <Form.Group widths="equal">
                <Field
                  component={Form.Input}
                  label="Job Name"
                  placeholder="Job Name"
                  name="jobName"
                />
                <Field
                  component={Form.Input}
                  label="Version"
                  placeholder="Version"
                  name="version"
                />
                <Field
                  component={Form.Input}
                  label="Model"
                  placeholder="Model"
                  name="models"
                />
              </Form.Group>
              <Form.Group widths="equal">
                <Field
                  component={Form.Input}
                  label="Serial Number"
                  options={publishedOptions}
                  placeholder="Serial Number"
                  name="serialNumbers"
                />
                <Field
                  component={Form.Input}
                  label="Last Updated By"
                  placeholder="Last updated by"
                  name="updatedBy"
                />
                <Field
                  component={Form.Input}
                  type="Date"
                  label="Last Updated On"
                  placeholder="Last updated on"
                  name="updateTime"
                />
              </Form.Group>

              <Button.Group>
                <Button positive>{commonLabels.search}</Button>
                <Button.Or />
                <Button negative onClick={reset}>
                  {commonLabels.clear}
                </Button>
              </Button.Group>
            </Form>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default enhance(AdvanceSearch);
