import { connect } from "react-redux";
import { compose, withHandlers, lifecycle, withState } from "recompose";
import { reduxForm, reset } from "redux-form";
import {
  fecthDataCodeForReview,
  routeToReviewPage,
  fetchRulesDeviceCodeForUpdate,
  resetRulesPage,
} from "../../../../redux/reducers/RulesListReducer";
import { rulesListPagePath } from "./../../../../utilities/pathHelpers";
import { withRouter } from "react-router";

/**
 *
 * @description {fetch values from store}
 */
export const mapStateToProps = (state) => ({
  routePageStatus: state.rulesListreducer.routePageStatus,
  initialValues: {
    ruleName: state.rulesListreducer.fetchDeviceCodeForUpdateList.ruleName
      ? state.rulesListreducer.fetchDeviceCodeForUpdateList.ruleName
      : "",
    description: state.rulesListreducer.fetchDeviceCodeForUpdateList.description
      ? state.rulesListreducer.fetchDeviceCodeForUpdateList.description
      : "",
    severity: state.rulesListreducer.fetchDeviceCodeForUpdateList.severity
      ? state.rulesListreducer.fetchDeviceCodeForUpdateList.severity
      : "",
    models: state.rulesListreducer.fetchDeviceCodeForUpdateList.models
      ? state.rulesListreducer.fetchDeviceCodeForUpdateList.models.map(
        (item) => ({
          label: item,
          value: item,
        })
      )
      : "",
    deleted: state.rulesListreducer.fetchDeviceCodeForUpdateList.isDeleted
      ? state.rulesListreducer.fetchDeviceCodeForUpdateList.isDeleted ===
        "false"
        ? true
        : false
      : "",
  },
});

/**
 *
 * @description {return to rulelist page}
 */
export const backToListPage = ({ history }) => () => {
  history.push(rulesListPagePath);
};

/**
 *
 * @description {save values for review page}
 */
export const onSubmit = ({
  fecthDataCodeForReview,
  routeToReviewPage,
}) => async (values) => {
  fecthDataCodeForReview({ values });
  routeToReviewPage();
};
/**
 *
 * @description {onpage load call API}
 */
export function componentDidMount() {
  if (this.props.match.params.update === "update") {
    this.props.fetchRulesDeviceCodeForUpdate(this.props.match.params.id);
    //   ruleName: this.props.match.params.id,
    //   deleted: this.props.match.params.status
    // });
  }
}
/**
 *
 * @description {remove/unmount component}
 */
export function componentWillUnmount() {
  this.props.resetRulesPage();
}

/**
 *
 * @description {fetch props on change of states}
 */
export function componentWillReceiveProps(prevProps) {
  if (
    this.props.match.params.id !== prevProps.match.params.id ||
    this.props.location.key !== prevProps.location.key
  ) {
    this.props.resetRulesPage();
    this.props.reset("DeviceCodeRootForm");
    this.props.setFormState(this.props.location.key);
    //this.setState({ unique_key: this.props.location.key });
  }
}
/**
 *
 * @description {clear form}
 */
export const setFormState = ({ setUniqueKey }) => (key) => {
  setUniqueKey(key);
};

/**
 *
 * @description {redux validation for time occurs field}
 */
export const validate = (values) => {
  const errors = {};
  if (values.models) {
    if (!values.models.toString()) {
      errors.models = "Required";
    }
  }
  return errors;
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    fecthDataCodeForReview,
    routeToReviewPage,
    fetchRulesDeviceCodeForUpdate,
    resetRulesPage,
    reset,
  }),
  withState("unique_key", "setUniqueKey", null),
  withHandlers({ setFormState }),
  withHandlers({ onSubmit, backToListPage }),
  lifecycle({
    componentDidMount,
    componentWillUnmount,
    componentWillReceiveProps,
  }),
  reduxForm({
    form: "DeviceCodeRootForm",
    enableReinitialize: true,
    validate
  })
);
