import { connect } from "react-redux";
import { compose, withHandlers, lifecycle } from "recompose";
import { reduxForm } from "redux-form";
import {
  saveModelsDataToStore,
  createModel,
  resetModelsPage,
  modelsNextPageRoute,
  addPageSize,
  modelCancelButtonIsClicked,
  addModelListOnUpdate,
  fecthModelDeviceParams,
  fecthExcelModelDeviceParams,
  addAttributesToAttributesCheckedList,
  removeAttributesToAttributesCheckedList,
  removePageSize,
  removeModelListOnUpdate,
} from "../../../redux/reducers/ModelsListReducer";
import { modelsListPagePath } from "./../../../utilities/pathHelpers";
import { withRouter } from "react-router";

/**
 *
 * @description {remove/unmount component values}
 */
export const mapStateToProps = (state) => ({
  pageSize: state.ModelsListReducer.pageSize,
  modelsChekedLists: state.ModelsListReducer.excelModelParamsOnUpdate,
  isFetching: state.ModelsListReducer.isFetchingModels,
  modelsDeviceParams: state.ModelsListReducer.modelDeviceParams,
  modelsCheckedLists: state.ModelsListReducer.modelsCheckedLists
    ? state.ModelsListReducer.modelsCheckedLists.attributes
    : [],
  initialValues: {
    modelName: state.ModelsListReducer.modelsDataforReview.modelName
      ? state.ModelsListReducer.modelsDataforReview.modelName || ""
      : "",
    description: state.ModelsListReducer.modelsDataforReview.description
      ? state.ModelsListReducer.modelsDataforReview.description || ""
      : "",
  },
});

/**
 *
 * @description {onpage load API calls}
 */
export function componentDidMount() {
  this.props.fecthModelDeviceParams();
}

/**
 *
 * @description {remove/unmount component values}
 */
export function componentWillUnmount() {
  modelCancelButtonIsClicked();
  this.props.resetModelsPage();
}

/**
 *
 * @description {submit values}
 */
export const onSubmit = ({ createModel, history }) => async (values) => {
  createModel(values).then((payload) => {
    if (payload.type === "CREATE_MODEL_DATA_REQUEST_SUCCESS") {
      window.alert(payload.payload.message);
      history.push(modelsListPagePath);
    } else {
      window.alert(payload.payload.message);
    }
  });
};

/**
 *
 * @description {fetch excel sheet models params}
 */
export const fetchParams = ({ fecthExcelModelDeviceParams }) => async (
  values
) => {
  fecthExcelModelDeviceParams(values.target.value.toUpperCase());
};

/**
 *
 * @description {single checkbox calls}
 */
export const toggleRow = ({
  addAttributesToAttributesCheckedList,
  removeAttributesToAttributesCheckedList,
  addModelListOnUpdate,
  removeModelListOnUpdate,
}) => (event, mnemonic, description, displayName, dataType, unitsOfMeasure) => {
  let selectAllCheckbox = document.getElementsByName("selectAll");
  const val = event.target.checked;
  let assignList = {
    [mnemonic]: {
      displayName: displayName !== undefined ? displayName : "",
      dataType: dataType !== undefined ? dataType : "",
      mnemonic: mnemonic,
      description: description !== undefined ? description : "",
      unitsOfMeasure: unitsOfMeasure !== undefined ? unitsOfMeasure : "",
    },
  };
  if (val) {
    addModelListOnUpdate(mnemonic);
    addAttributesToAttributesCheckedList(assignList);
    selectAllCheckbox.disabled = true;
  } else {
    removeModelListOnUpdate(mnemonic);
    removeAttributesToAttributesCheckedList(assignList);
  }
};

/**
 *
 * @description {checkbox select all call}
 */
export const toggleSelectAll = ({
  addAttributesToAttributesCheckedList,
  removeAttributesToAttributesCheckedList,
  addModelListOnUpdate,
  removeModelListOnUpdate,
  addPageSize,
  removePageSize,
}) => (event, page) => {
  if (page === "") {
    page = 0;
  } else {
    page = page + 1;
  }
  if (document.getElementsByName("selectAll")[0].checked === true) {
    addPageSize(page);
  } else {
    removePageSize(page);
  }
  let checkboxes = document.getElementsByName("deviceParams");
  let assignList = {};
  var splitedCheckboxValue;
  if (event.target.checked) {
    for (var i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = true;

      splitedCheckboxValue = checkboxes[i].value.split(",");
      assignList = {
        [splitedCheckboxValue[0]]: {
          displayName:
            splitedCheckboxValue[2] !== undefined
              ? splitedCheckboxValue[2]
              : "",
          dataType:
            splitedCheckboxValue[3] !== undefined
              ? splitedCheckboxValue[3]
              : "",
          mnemonic:
            splitedCheckboxValue[0] !== undefined
              ? splitedCheckboxValue[0]
              : "",
          description:
            splitedCheckboxValue[1] !== undefined
              ? splitedCheckboxValue[1]
              : "",
          unitsOfMeasure:
            splitedCheckboxValue[4] !== undefined
              ? splitedCheckboxValue[4]
              : "",
        },
      };
      addModelListOnUpdate(splitedCheckboxValue[0]);
      addAttributesToAttributesCheckedList(assignList);
      //checkboxes.selected = false;
    }
  } else {
    for (i = 0; i < checkboxes.length; i++) {
      checkboxes[i].checked = false;
      splitedCheckboxValue = checkboxes[i].value.split(",");
      assignList = {
        [splitedCheckboxValue[0]]: {
          displayName:
            splitedCheckboxValue[2] !== undefined
              ? splitedCheckboxValue[2]
              : "",
          dataType:
            splitedCheckboxValue[3] !== undefined
              ? splitedCheckboxValue[3]
              : "",
          mnemonic:
            splitedCheckboxValue[0] !== undefined
              ? splitedCheckboxValue[0]
              : "",
          description:
            splitedCheckboxValue[1] !== undefined
              ? splitedCheckboxValue[1]
              : "",
          unitsOfMeasure:
            splitedCheckboxValue[4] !== undefined
              ? splitedCheckboxValue[4]
              : "",
        },
      };
      //checkboxes.selected = false;
      removeModelListOnUpdate(splitedCheckboxValue[0]);
      removeAttributesToAttributesCheckedList(assignList);
    }
  }
};

/**
 *
 * @description {back to modelslist page}
 */
export const backToListPage = ({ history }) => () => {
  history.push(modelsListPagePath);
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    saveModelsDataToStore,
    createModel,
    modelsNextPageRoute,
    modelCancelButtonIsClicked,
    fecthModelDeviceParams,
    fecthExcelModelDeviceParams,
    addModelListOnUpdate,
    resetModelsPage,
    addPageSize,
    removePageSize,
    removeModelListOnUpdate,
    addAttributesToAttributesCheckedList,
    removeAttributesToAttributesCheckedList,
  }),
  withHandlers({ toggleRow, toggleSelectAll, backToListPage, fetchParams }),
  withHandlers({ onSubmit }),
  lifecycle({ componentDidMount, componentWillUnmount }),
  reduxForm({
    form: "CreateModel",
    enableReinitialize: true,
  })
);
