import React from "react";
import {
  Form,
  Button,
  Divider,
  Dropdown,
  Table,
  Input,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { reportListPagePath } from "./../../../../utilities/pathHelpers";
import enhance from "./enhance";
import { Field } from "redux-form";
import {
  InputField,
  SelectField,
  TextAreaField,
} from "react-semantic-redux-form";
import JSONPretty from "react-json-pretty";
import { required, limitCharater } from "./../../../../utilities/validations";
import {
  commonLabels,
  CreateLabels,
  UpdateLabels,
} from "../../../../redux/constants/Values";

export const AddNewReport = (props) => {
  const {
    changeOfObject,
    addWhereClauseArray,
    deleteParameter,
    DisableSubmit,
    constructQuery,
    reportPageStatus,
    ReportReduxData,
    ReportConditionData,
    FinalObj,
    ReportArrayList,
    Query,
    handleSubmit,
    finalSubmit,
    changeEntity,
    getAddParams,
    getUpdateParams,
    history,
    isFetching,
    SqlQuery,
  } = props;

  const PageSelectOptions = [
    { key: 1, text: "Assets", value: "device_information" },
    { key: 2, text: "Rules", value: "rule" },
    { key: 3, text: "Models", value: "model" },
    { key: 4, text: "Events", value: "events" },
    { key: 5, text: "History Data", value: "timeseries" },
  ];

  const ParameterSelectOptions =
    reportPageStatus !== "UPDATE_PAGE" ? getAddParams : getUpdateParams;

  const logicalOperandSelectOptions = [
    { key: 1, text: "N/A", value: "N/A" },
    { key: 2, text: "AND", value: "AND" },
    { key: 3, text: "OR", value: "OR" },
  ];

  const OperandSelectOptions = [
    { key: 1, text: "N/A", value: "N/A" },
    { key: 2, text: "=", value: "=" },
    { key: 3, text: "<", value: "<" },
    { key: 4, text: ">", value: ">" },
    { key: 5, text: "<=", value: "<=" },
    { key: 6, text: ">=", value: ">=" },
    { key: 7, text: "matches", value: "matches" },
  ];

  return (
    <>
      {isFetching && (
        <Dimmer active inverted>
          <Loader size="large" inverted>
            {commonLabels.loading}
          </Loader>
        </Dimmer>
      )}
      <div className="containerStyle">
        <h4>
          {" "}
          {reportPageStatus === "CREATE_PAGE"
            ? CreateLabels.reports
            : UpdateLabels.reports}
        </h4>
        <Divider />
        <div>
          <Form onSubmit={handleSubmit}>
            <Form.Group widths="equal">
              <Field
                component={InputField}
                label="Report Name"
                placeholder="Report Name"
                name="reportName"
                validate={[required]}
                disabled={reportPageStatus === "UPDATE_PAGE" ? true : false}
                required
              />
              <Field
                component={TextAreaField}
                label="Report Description"
                placeholder="Enter Report Description"
                name="reportDescription"
                rows={2}
                validate={[required]}
                normalize={limitCharater}
                required
              />
            </Form.Group>

            <Form.Group widths="equal">
              <Field
                component={SelectField}
                fluid
                label="Select Entity"
                options={PageSelectOptions}
                placeholder="Select Entity"
                search
                name="entity"
                onChange={(e) => {
                  changeEntity(e, props);
                }}
                validate={[required]}
                disabled={reportPageStatus === "UPDATE_PAGE" ? true : false}
                required
              />
              <Field
                component={SelectField}
                fluid
                label="Select Parameter"
                multiple
                search
                name="SelectParameter"
                placeholder="Select Parameter"
                options={ParameterSelectOptions}
                validate={[required]}
                required
              />

              <Field
                component={InputField}
                type="Date"
                label="Select Start Date"
                placeholder="Select Start Date"
                name="startDate"
                validate={[required]}
                required
              />
              <Field
                component={InputField}
                type="Date"
                label="Select End Date"
                placeholder="Select End Date"
                name="endDate"
                validate={[required]}
                required
              />
            </Form.Group>
          </Form>
          <Button
            data-test="add-where-clause"
            type="button"
            floated="right"
            color="blue"
            onClick={(e) => addWhereClauseArray(ReportArrayList)}
          >
            {CreateLabels.addParameter}
          </Button>
          <Table celled compact>
            <Table.Header fullWidth>
              <Table.Row>
                <Table.HeaderCell>Parameter Name</Table.HeaderCell>
                <Table.HeaderCell>Operands</Table.HeaderCell>
                <Table.HeaderCell>Select Input Type</Table.HeaderCell>
                <Table.HeaderCell>Parameter Value</Table.HeaderCell>
                <Table.HeaderCell>Logical Operand</Table.HeaderCell>
                <Table.HeaderCell>Delete Action</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            {ReportArrayList.map((ReportArrayListObj, index) => (
              <Table.Body key={index}>
                <Table.Row>
                  <Table.Cell>
                    {" "}
                    <Dropdown
                      placeholder="Select Parameter"
                      fluid
                      search
                      value={ReportArrayListObj.parameterName}
                      options={ParameterSelectOptions}
                      onChange={
                        /* istanbul ignore next */ (e, data) =>
                          changeOfObject(
                            data.value,
                            index,
                            "parameter",
                            ReportArrayList
                          )
                      }
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Dropdown
                      placeholder="Select Operands"
                      fluid
                      search
                      value={ReportArrayListObj.operands}
                      options={OperandSelectOptions}
                      onChange={
                        /* istanbul ignore next */ (e, data) =>
                          changeOfObject(
                            data.value,
                            index,
                            "operand",
                            ReportArrayList
                          )
                      }
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <div className="radio">
                      <label>
                        <input
                          data-test="change-object"
                          type="radio"
                          value="radioInput"
                          checked={
                            ReportArrayListObj.parameterInputType.constant
                          }
                          onChange={(e) =>
                            changeOfObject(
                              e.target.value,
                              index,
                              "radioInput",
                              ReportArrayList
                            )
                          }
                        />
                        Select Input Field
                      </label>
                    </div>

                    <div className="radio">
                      <label>
                        <input
                          data-test="change-object-drop-down"
                          type="radio"
                          value="radioDropdown"
                          checked={
                            ReportArrayListObj.parameterInputType.dropdown
                          }
                          onChange={(e, data) =>
                            changeOfObject(
                              data,
                              index,
                              "radioDropdown",
                              ReportArrayList
                            )
                          }
                        />
                        Select DropDown
                      </label>
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    {ReportArrayListObj.parameterInputType.constant ? (
                      <Input
                        placeholder="Enter Value"
                        value={ReportArrayListObj.parameterValue}
                        onChange={
                          /* istanbul ignore next */ (e, data) =>
                            changeOfObject(
                              data.value,
                              index,
                              "parameterValue",
                              ReportArrayList
                            )
                        }
                      />
                    ) : (
                      <Dropdown
                        placeholder="Select Parameter"
                        fluid
                        search
                        value={ReportArrayListObj.parameterValue}
                        options={ParameterSelectOptions}
                        onChange={
                          /* istanbul ignore next */ (e, data) =>
                            changeOfObject(
                              data.value,
                              index,
                              "parameterValue",
                              ReportArrayList
                            )
                        }
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {
                      <Dropdown
                        placeholder="Select Logical Operand"
                        fluid
                        disabled={index === 0}
                        search
                        value={
                          index === 0
                            ? "N/A"
                            : ReportArrayListObj.logicalOperand
                        }
                        options={logicalOperandSelectOptions}
                        onChange={
                          /* istanbul ignore next */ (e, data) =>
                            changeOfObject(
                              data.value,
                              index,
                              "logicalOperand",
                              ReportArrayList
                            )
                        }
                      />
                    }
                  </Table.Cell>
                  <Table.Cell>
                    <Button
                      data-test="delete-params-btn"
                      type="button"
                      className={"Test" + index}
                      negative
                      onClick={() => deleteParameter(index, ReportArrayList)}
                    >
                      {commonLabels.delete}
                    </Button>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            ))}
          </Table>

          <JSONPretty
            id="json-pretty"
            className="Jsonformat"
            data={SqlQuery}
          ></JSONPretty>
          <Button.Group>
            <Button
              data-test="construct-query-btn"
              type="button"
              positive
              onClick={() =>
                constructQuery(
                  FinalObj,
                  reportPageStatus,
                  ReportReduxData,
                  ReportConditionData,
                  ReportArrayList
                )
              }
            >
              {CreateLabels.constructQuery}
            </Button>
            <Button.Or />

            <Button
              data-test="final-submit-btn"
              disabled={DisableSubmit}
              type="submit"
              color="blue"
              onClick={() => finalSubmit(Query, reportPageStatus)}
            >
              {CreateLabels.submitQuery}
            </Button>
            <Button.Or />

            <Button
              data-test="history-btn"
              type="button"
              negative
              onClick={() => history.push(reportListPagePath)}
            >
              {commonLabels.cancel}
            </Button>
          </Button.Group>
        </div>
      </div>
    </>
  );
};

export default enhance(AddNewReport);
