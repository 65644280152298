import { connect } from "react-redux";
import { compose, withHandlers, lifecycle, withState } from "recompose";
import { reduxForm, reset } from "redux-form";
import {
  fetchRulesOfRulesLists,
  saveRuleofRules,
  fetchRulesofRulesForUpdate,
  resetRulesPage,
  updateRuleofRules,
} from "../../../../redux/reducers/RulesListReducer";
import { rulesListPagePath } from "./../../../../utilities/pathHelpers";
import { withRouter } from "react-router";
/**
 *
 * @description {fetch values from store}
 */
export const mapStateToProps = (state) => ({
  rulesOfRulesCheckedList: state.rulesListreducer.rulesOfRulesCheckedList
    ? state.rulesListreducer.rulesOfRulesCheckedList
    : [],
  isFetching: state.rulesListreducer.isFetchingRules,
  initialValues: {
    ruleName: state.rulesListreducer.fetchRulesOfRulesForUpdateList.ruleName
      ? state.rulesListreducer.fetchRulesOfRulesForUpdateList.ruleName
      : "",
    description: state.rulesListreducer.fetchRulesOfRulesForUpdateList
      .description
      ? state.rulesListreducer.fetchRulesOfRulesForUpdateList.description
      : "",
    severity: state.rulesListreducer.fetchRulesOfRulesForUpdateList.severity
      ? state.rulesListreducer.fetchRulesOfRulesForUpdateList.severity
      : "",
    alarmTriggerWindowPeriod: state.rulesListreducer
      .fetchRulesOfRulesForUpdateList.alarmTriggerWindowPeriod
      ? state.rulesListreducer.fetchRulesOfRulesForUpdateList
        .alarmTriggerWindowPeriod / 60
      : "",
    timeFormat: state.rulesListreducer.fetchRulesOfRulesForUpdateList
      .alarmTriggerWindowPeriod
      ? state.rulesListreducer.fetchRulesOfRulesForUpdateList
        .alarmTriggerWindowPeriod && "Minutes"
      : "",
    deleted: state.rulesListreducer.fetchRulesOfRulesForUpdateList.isDeleted
      ? state.rulesListreducer.fetchRulesOfRulesForUpdateList.isDeleted ===
        "false"
        ? true
        : false
      : "",
  },
});

/**
 *
 * @description {onpage load call API}
 */
export function componentDidMount() {
  this.props.fetchRulesOfRulesLists();
  if (this.props.match.params.update === "update") {
    this.props.fetchRulesofRulesForUpdate(this.props.match.params.id);
  }
}

/**
 *
 * @description {save/update rule of rules data}
 */
export const onSubmit = ({
  saveRuleofRules,
  updateRuleofRules,
  updateProps,
  backToListPage,
}) => async (values) => {
  if (updateProps === "update") {
    updateRuleofRules(values).then((payload) => {
      /* istanbul ignore if */
      if (payload.type === "UPDATE_RULES_OF_RULES_REQUEST_SUCCESS") {
        window.alert(payload.payload.message);
        backToListPage();
      } else {
        window.alert(payload.payload.message);
      }
    });
  } else {
    saveRuleofRules(values).then((payload) => {
      /* istanbul ignore if */
      if (payload.type === "SAVE_RULES_OF_RULES_REQUEST_SUCCESS") {
        window.alert(payload.payload.message);
        backToListPage();
      } else {
        window.alert(payload.payload.message);
      }
    });
  }
};

/**
 *
 * @description {back to rulelist page}
 */
export const backToListPage = ({ history }) => () => {
  history.push(rulesListPagePath);
};

/**
 *
 * @description {remove/unmount component}
 */
export function componentWillUnmount() {
  this.props.resetRulesPage();
}

/**
 *
 * @description {fetch props on change of states}
 */
export function componentWillReceiveProps(prevProps) {
  if (
    this.props.match.params.id !== prevProps.match.params.id ||
    this.props.location.key !== prevProps.location.key
  ) {
    this.props.resetRulesPage();
    this.props.reset("RulesofRulesRootForm");
    this.props.setFormState(this.props.location.key);
  }
}

/**
 *
 * @description {clear form}
 */
export const setFormState = ({ setUniqueKey }) => (key) => {
  setUniqueKey(key);
};

/**
 *
 * @description {validate time interval fields}
 */
export const validate = (values) => {
  const errors = {};
  if (values.alarmTriggerWindowPeriod) {
    if (
      values.alarmTriggerWindowPeriod >= 8785 &&
      values.timeFormat === "Hour"
    ) {
      errors.alarmTriggerWindowPeriod = "Entered hours cannot exceed 8784";
    }

    if (
      values.alarmTriggerWindowPeriod >= 527041 &&
      values.timeFormat === "Minutes"
    ) {
      errors.alarmTriggerWindowPeriod = "Entered minutes cannot exceed 527040";
    }
  }
  return errors;
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    fetchRulesOfRulesLists,
    saveRuleofRules,
    fetchRulesofRulesForUpdate,
    resetRulesPage,
    reset,
    updateRuleofRules,
  }),
  withState("unique_key", "setUniqueKey", null),
  withHandlers({ setFormState }),
  withHandlers({ backToListPage }),
  withHandlers({ onSubmit }),
  lifecycle({
    componentDidMount,
    componentWillUnmount,
    componentWillReceiveProps,
  }),
  reduxForm({
    form: "RulesofRulesRootForm",
    enableReinitialize: true,
    validate,
  })
);
