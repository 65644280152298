import raiseError from "../../utilities/raiseError";
import api from "../config_api";
import { getHeaders } from "../../utilities/helpers";
import { commonLabels, pageSize } from "../../redux/constants/Values";

/**
 * @description {this calls updating onboarding status api of device}
 */

export const fetchupdateOnBoardingStatus = async ({ getState, values }) => {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(values.values2),
  };
  const response = await fetch(api.Update_onboarding_url, requestOptions);
  let responseBody;
  if (response.ok) {
    if (response.status === 204) {
      window.alert(commonLabels.noContent);
    }
    responseBody = await response.json();
    return responseBody;
  } else {
    responseBody = await response.json();
    window.alert(responseBody.message);
    raiseError({ response });
  }
};

/**
 * @description {this calls onboarding status list api}
 */

export const fetchOnBoardingLists = async ({ getState, values }) => {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      pageSize: pageSize.SIZE,
      offSet: 1,
    }),
  };

  const response = await fetch(api.assets_url, requestOptions);
  let responseBody;
  if (response.ok) {
    if (response.status === 204) {
      window.alert(commonLabels.noContent);
    }
    responseBody = await response.json();
    return responseBody;
  } else {
    responseBody = await response.json();
    window.alert(responseBody.message);
    raiseError({ response });
  }
};

/**
 * @description {this calls onboarding status list advance search api}
 */

export const fecthonBoardingListByAdvanceSearch = async ({
  getState,
  values,
}) => {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      deviceName: values.asset, //"06060606"
      modelName: values.model,
      deviceStatus: values.device_status,
      pageSize: pageSize.SIZE,
      offSet: 1,
    }),
  };
  const response = await fetch(api.assets_url, requestOptions);
  let responseBody;
  if (response.ok) {
    if (response.status === 204) {
      responseBody = {
        offSet: "1",
        pageSize: pageSize.SIZE,
        deviceList: [],
      };
      window.alert(commonLabels.noContent);
    } else {
      responseBody = await response.json();
    }
    return responseBody;
  } else {
    responseBody = await response.json();
    window.alert(responseBody.message);
    raiseError({ response });
  }
};

export const saveOnBoardingRemark = async ({ getState, values }) => {
  const requestOptions = {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      deviceName: values.obj.deviceName,
      remarks: values.obj.remarks,
      deviceModel: values.obj.deviceModel,
      updatedBy: values.obj.updatedBy,
    }),
  };
  const response = await fetch(api.Remark_onboarding_url, requestOptions);
  let responseBody;
  if (response.ok) {
    if (response.status === 204) {
      window.alert(commonLabels.noContent);
    }
    responseBody = await response.json();
    return responseBody;
  } else {
    responseBody = await response.json();
    return responseBody;
  }
};
