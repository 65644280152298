import { connect } from 'react-redux';
import { compose, lifecycle, withHandlers } from 'recompose';
import { reduxForm, reset } from 'redux-form';
import {
  resetHistoricalDataitemsData,
  detailsPageBackButtonIsClicked,
  fetchDeviceParameters,
  fetchHistoricalDataitems,
} from '../../../../redux/reducers/AssetListReducer';
import { withRouter } from 'react-router';
import moment from 'moment/min/moment-with-locales';
import { Alerts } from '../../../../redux/constants/Values';
import { exportCSVForHistoricalDataitems } from '../../../../apis/assetListAPI/normalizers';

export const mapStateToProps = (state) => ({
  isFetchingHistoricalDataitemsData:
    state.assetListReducer.isFetchingHistoricalDataitemsData,
  historicalDataitemsDetailsData:
    state.assetListReducer.historicalDataitemsDetailsData,
});

export const isDetailsPageBackButtonIsClicked = ({
  detailsPageBackButtonIsClicked,
}) => () => {
  detailsPageBackButtonIsClicked();
};

/* istanbul ignore next */
export const exportFile = () => {
  exportCSVForHistoricalDataitems();
};

/* istanbul ignore next */
export const onSubmit = ({ fetchHistoricalDataitems }) => async (
  values,
  dispatch,
  props
) => {
  let mnemonics = [];
  let Todaydate = moment(new Date().setHours(23, 59, 0, 0))
    .add(1, 'days')
    .utc()
    .format();
  mnemonics =
    values.dataitem !== undefined && values.dataitem.length !== 0
      ? values.dataitem.map((item) => {
          return item.value;
        })
      : [];
  if (Object.keys(values).length !== 0) {
    if (
      values.hasOwnProperty('startDate') &&
      values.hasOwnProperty('endDate') &&
      values.hasOwnProperty('dataitem')
    ) {
      let data = {
        startDate: moment(
          values.startDate + values.startTime,
          'YYYY-MM-DDTHH:mm:s'
        )
          .utc()
          .format(),
        endDate: moment(values.endDate + values.endTime, 'YYYY-MM-DDTHH:mm:s')
          .utc()
          .format(),
        mnemonics: mnemonics,
        deviceName: props.deviceName,
      };
      // if (data.startDate <= Todaydate && data.endDate <= Todaydate) {
      if (data.startDate <= Todaydate) {
        if (data.startDate <= data.endDate) {
          fetchHistoricalDataitems(data);
        } else {
          window.alert(Alerts.dateRange);
        }
      } else {
        window.alert(Alerts.validDate);
      }
    } else if (values.dataitem === undefined || values.dataitem.length === 0) {
      window.alert(Alerts.noDataitem);
    } else {
      window.alert(Alerts.invalidDate);
    }
  } else {
    window.alert(Alerts.invalidDate);
  }
};

export const getHistoricalDataitems = ({
  fetchHistoricalDataitems,
  resetHistoricalDataitemsData,
  reset,
}) => (props) => {
  let data = {
    mnemonics: [],
    deviceName: props.deviceName,
  };
  fetchHistoricalDataitems(data);
  resetHistoricalDataitemsData();
  reset('SearchHistoricalDataitemsForm');
};

export function componentWillUnmount() {
  this.props.resetHistoricalDataitemsData();
}

export default compose(
  withRouter,
  connect(mapStateToProps, {
    resetHistoricalDataitemsData,
    reset,
    detailsPageBackButtonIsClicked,
    fetchDeviceParameters,
    fetchHistoricalDataitems,
  }),
  lifecycle({ componentWillUnmount }),
  withHandlers({
    onSubmit,
    getHistoricalDataitems,
    isDetailsPageBackButtonIsClicked,
    exportFile,
  }),
  reduxForm({
    form: 'SearchHistoricalDataitemsForm',
    enableReinitialize: true,
  })
);
