import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import { fetchModelsLists,forceDeleteModel } from "../../../redux/reducers/ModelsListReducer";
import { reduxForm } from "redux-form";

export const mapStateToProps = (state) => ({
  modelsListsdata: state.ModelsListReducer.modelsListsdata,
  isFetching: state.ModelsListReducer.isFetchingModels,
});

export const deleteModel=({forceDeleteModel,fetchModelsLists})=>(modelName)=>{
  if(window.confirm("Are you sure want to Delete this Model?")){
    forceDeleteModel(modelName).then((payload)=>{
      if (payload.type === "DELETE_MODEL_REQUEST_SUCCESS") {
        fetchModelsLists().then(() => {
        //window.location.reload();
        window.alert(payload.payload.message);
      });
      }else{
        window.alert(payload.payload.message);
      }
    });
  }
};

export default compose(
  connect(mapStateToProps, { fetchModelsLists, forceDeleteModel }),
  withHandlers({deleteModel}),
  reduxForm({
    form: "ModelTableForm",
    enableReinitialize: true,
  })
);
