import React from "react";
import { Card } from "semantic-ui-react";

import matchSorter from "match-sorter";
import ReactTable from "react-table";
import styles from "./RulesOfRulesTable.module.scss";
import enhance from "./enhance";
import { CreateLabels, Alerts } from "../../../../redux/constants/Values";

export const RulesOfRulesTable = (props) => {
  const {
    rulesOfRulesListsdata,
    toggleRow,
    handleEnable,
    checkedArrayList,
  } = props;
  /* istanbul ignore next */
  return (
    <div className={styles.ruleOfRulesCards}>
      <Card fluid>
        <Card.Content header={CreateLabels.rulesList} className="cardHeader" />

        <Card.Content extra className={styles.rulesOfRulesCustomHeightFix}>
          <ReactTable
            data-test="react-table"
            data={rulesOfRulesListsdata}
            filterable
            loading={rulesOfRulesListsdata.length > 0 ? false : true}
            defaultFilterMethod={(filter, row) =>
              String(row[filter.id]) === filter.value
            }
            style={{ width: "100%", height: "450px" }}
            getTrProps={(state, rowInfo, column) => {
              return {
                style: {
                  background:
                    rulesOfRulesListsdata.length > 0 &&
                    rowInfo &&
                    checkedArrayList.includes(rowInfo.original.ruleName)
                      ? "lightgreen"
                      : "",
                },
              };
            }}
            columns={[
              {
                Header: "Select",
                id: "select",
                filterable: false,
                Cell: ({ original }) => {
                  return (
                    <input
                      component="input"
                      type="checkbox"
                      name="rulesListData"
                      onChange={(e) => toggleRow(e, original.ruleName)}
                      checked={
                        checkedArrayList.includes(original.ruleName)
                          ? true
                          : false
                      }
                    />
                  );
                },
              },
              {
                Header: "Name",
                id: "name",
                accessor: (d) => (d.ruleName ? d.ruleName : null),
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["name"] }),
                filterAll: true,
              },
              {
                Header: "Type",
                id: "type",
                accessor: (d) => (d.ruleType ? d.ruleType : null),
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["type"] }),
                filterAll: true,
              },
              {
                Header: "Description",
                id: "description",
                accessor: (d) => d.description,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["description"] }),
                filterAll: true,
              },
              {
                Header: "Rules",
                id: "rules",
                accessor: (d) => d.ruleExpression,
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["rules"] }),
                filterAll: true,
              },
              {
                Header: "Model",
                id: "models",
                accessor: (d) => (d.models ? d.models.toString() : null),
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["models"] }),
                filterAll: true,
              },
              {
                Header: "Severity",
                id: "severity",
                accessor: (d) => (d.severity ? parseInt(d.severity) : null),
                sortMethod: (a, b) => Number(a) - Number(b),
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["severity"] }),
                filterAll: true,
              },

              {
                Header: "Enable",
                id: "deleted",
                accessor: (d) =>
                  d.isDeleted === "false" ? "Enable" : "Disable",
                filterMethod: (filter, rows) =>
                  matchSorter(rows, filter.value, { keys: ["deleted"] }),
                filterAll: true,
                Filter: ({ filter, onChange }) => (
                  <select
                    className={styles.consecSelectWidth}
                    onChange={(event) => onChange(event.target.value)}
                    // value={filter ? filter.value : `'false':'true'`}
                    value={filter ? filter.value : ""}
                    defaultValue={filter ? filter.value : ""}
                  >
                    <option value="">All</option>
                    <option value="Enable">Enable</option>
                    <option value="Disable">Disable</option>
                  </select>
                ),
                Cell: (props) => handleEnable(props.value),
              },
            ]}
            defaultPageSize={10}
            minRows={3}
            noDataText={Alerts.noRows}
            className="-striped -highlight"
          />
        </Card.Content>
      </Card>
    </div>
  );
};
export default enhance(RulesOfRulesTable);
