import React from "react";
import { Field } from "redux-form";
import { Form } from "semantic-ui-react";
import {
  SelectField,
  TextAreaField,
  InputField,
  CheckboxField
} from "react-semantic-redux-form";
import { required, limitCharater } from "./../../../../utilities/validations";
import styles from "./DeviceCodeDetails.module.scss";
import enhance from "./enhance";

export const DeviceCodeDetails = (props) => {
  const { deviceCodeList, updateProps } = props;

  let options =
    deviceCodeList.length !== 0
      ? deviceCodeList.map((item) => ({
        key: item.attributes.deviceCode,
        value: item.attributes.deviceCode,
        text: item.attributes.deviceCode,
      }))
      : [{ key: "Loading...", value: "", text: "Loading..." }];

  return (
    <div>
      <Form.Group widths="equal">
        <label className={styles.codeFieldLable}>Error Code</label>
        {updateProps === "update" ? (
          <span>
            <Field
              name="ruleName"
              component={InputField}
              placeholder="Error Code"
              options={options}
              validate={[required]}
              disabled={updateProps ? true : false}
            />
          </span>
        ) : (
            <Field
              name="ruleName"
              component={SelectField}
              placeholder="Error Code"
              options={options}
              validate={[required]}
              disabled={updateProps ? true : false}
            />
          )}
        <label className={styles.codeFieldLable}>Description</label>
        <Field
          name="description"
          component={TextAreaField}
          placeholder="Error description"
          validate={[required]}
          normalize={limitCharater}
        />

        <Field name="deleted" component={CheckboxField} label="Enable" />
      </Form.Group>
    </div>
  );
};

export default enhance(DeviceCodeDetails);
