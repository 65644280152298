import React from "react";
import PropTypes from "prop-types";
import "bootstrap";
import AdvanceSearch from "../AdvanceSearch";
import AssetsTableData from "../AssetsTableData";
import { Dimmer, Loader, Divider } from "semantic-ui-react";
import enhance from "./enhance";
import { NavItems, commonLabels } from "../../../redux/constants/Values";

export const Assets = (props) => {
  const {
    btnHandler,
    assetsListsdata,
    modelsListsdata,
    isFetchingAssets,
    deviceParameters,
  } = props;
  return (
    <div className="containerStyle">
      <h4>
        {NavItems.assets + " " + commonLabels.list}
        <button className="exportButton" onClick={btnHandler}>
          {commonLabels.exportToCSV}
        </button>
      </h4>
      <Divider />
      <AdvanceSearch
        modelsListsdata={modelsListsdata}
        deviceParameters={deviceParameters}
      />
      <Divider />
      {isFetchingAssets ? (
        <Dimmer active inverted>
          <Loader size="large" inverted>
            {commonLabels.loading}
          </Loader>
        </Dimmer>
      ) : (
        <AssetsTableData assetsListsdata={assetsListsdata} />
      )}
    </div>
  );
};

Assets.propTypes = {
  assetsListsdata: PropTypes.array,
};

export default enhance(Assets);
