import React from "react";
import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";

export const mapStateToProps = (state) => ({
  isFetching: state.rulesListreducer.isFetchingRules,
});

/**
 *
 * @description {filter data based enable/disable}
 */
export const handleEnable = () => (enableInput) => {
  return (
    <span>
      <i
        className={
          "certificate  small icon marginRight " +
          (enableInput === "Disable" ? "black" : "green")
        }
      ></i>
      {enableInput}
    </span>
  );
};

export default compose(
  connect(mapStateToProps, null),
  withHandlers({ handleEnable })
);
