export const validateRegEXExpression = (ruleExpression) => {
  var validate = false;

  if (ruleExpression !== "") {
    if (ruleExpression.match(/\([^()]*\)/g)) {
      var checkExpLength = ruleExpression.match(/\([^()]*\)/g).length;
      var openBracecount = ruleExpression.match(/\([^(]*\)/g).length;
      var closeBracecount = ruleExpression.match(/\([^)]*\)/g).length;
      if (openBracecount !== closeBracecount) {
        validate = false;
        return;
      } else {
        var count = 0;
        for (var i = 0; i < ruleExpression.length; i++) {
          if (ruleExpression[i] === "(") {
            count++;
          } else if (ruleExpression[i] === ")") {
            if (count === 0) {
              validate = false;
              return;
            } else count--;
          }
        }

        if (count === 0) validate = true;
      }
    }

    try {
      if (ruleExpression.match(/'/g)) {
        var ooperandsLength = ruleExpression.match(/'/g).length;
        if (ooperandsLength % 2 === 0) {
          validate = true;
        } else {
          validate = false;
          return;
        }
      }

      // check and|or shoul not be there at beginning and ending
      if (
        ruleExpression.match(/.*(and|or|And|OR)$/g) ||
        ruleExpression.match(/^(and|or|And|OR)/g)
      ) {
        validate = false;
        return;
      } else {
        validate = true;
      }

      if (ruleExpression.match(/\)+[or|and|AND|OR]+\(/g)) {
        var ooperandsLength = ruleExpression.match(/\)+[or|and|AND|OR]+\(/g)
          .length;
        if (
          ooperandsLength !== null &&
          ooperandsLength !== undefined &&
          ooperandsLength !== ""
        ) {
          if (checkExpLength - 1 === ooperandsLength) {
            validate = true;
          } else {
            validate = false;
            return;
          }
        }
      } else {
        if (checkExpLength > 1) {
          validate = false;
          return;
        }
      }

      var count = 0;
      for (i = 0; i < ruleExpression.length; i++) {
        if (ruleExpression[i] === "(") count++;
        else if (ruleExpression[i] === ")")
          if (count === 0) return (validate = false);
          else count--;
      }
      if (count === 0) {
        var removedBraces = ruleExpression.replace(/["()]/g, ",");

        var splitByOperands = removedBraces.replace(/\b(AND|OR|and|or)\b/g, "");
        var splitByOperands1 = splitByOperands.split(",");

        for (i = 0; i < splitByOperands1.length; i++) {
          if (splitByOperands1[i] !== "") {
            if (
              /^[^+-\/*\[\]][A-Za-z0-9_-]+[<|>|<=|>=|=|!=|has|contains]+(['$A-Za-z_-|\d]+|\[['$A-Za-z_-|\d]+[+-\/*]+\d+(\.\d+)?\]|-?[0-9]\d*(\.\d+)?$)$/.test(
                splitByOperands1[i].trim()
              )
            ) {
              validate = true;
            } else {
              validate = false;
              break;
            }
          }
        }
      } else {
        return (validate = false);
      }
    } catch (e) {
      validate = false;
    }
  } else {
    validate = false;
  }
  return validate;
};
