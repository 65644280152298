import React from "react";
import {
  Table,
  Label,
  Segment,
  Button,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import { Grids, commonLabels } from "../../../../redux/constants/Values";

export const Rules = (props) => {
  const {
    detailsPageButtonIsClicked,
    rulesDetailsdata,
    isFetchingRulesData,
  } = props;
  return (
    <>
      <Segment padded>
        <Label attached="top" size="big" className="bgBlue">
          {Grids.rules}
          <Button
            primary
            onClick={detailsPageButtonIsClicked.bind(this, Grids.rules)}
            floated="right"
          >
            {commonLabels.viewAll}
          </Button>
        </Label>
        <Table
          className="displayTable"
          singleLine
          compact
          striped
          sortable
          celled
          textAlign="center"
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Rule Name</Table.HeaderCell>
              <Table.HeaderCell>Rule Expression</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {isFetchingRulesData ? (
            <Dimmer active inverted>
              <Loader size="mini">{commonLabels.fetching}</Loader>
            </Dimmer>
          ) : (
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  {rulesDetailsdata && rulesDetailsdata.length > 0
                    ? rulesDetailsdata[0].ruleName
                    : ""}
                </Table.Cell>
                <Table.Cell>
                  {rulesDetailsdata && rulesDetailsdata.length > 0
                    ? rulesDetailsdata[0].ruleExpression
                    : ""}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  {rulesDetailsdata && rulesDetailsdata.length > 1
                    ? rulesDetailsdata[1].ruleName
                    : ""}
                </Table.Cell>
                <Table.Cell>
                  {rulesDetailsdata && rulesDetailsdata.length > 1
                    ? rulesDetailsdata[1].ruleExpression
                    : ""}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  {rulesDetailsdata && rulesDetailsdata.length > 2
                    ? rulesDetailsdata[2].ruleName
                    : ""}
                </Table.Cell>
                <Table.Cell>
                  {rulesDetailsdata && rulesDetailsdata.length > 2
                    ? rulesDetailsdata[2].ruleExpression
                    : ""}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  {rulesDetailsdata && rulesDetailsdata.length > 3
                    ? rulesDetailsdata[3].ruleName
                    : ""}
                </Table.Cell>
                <Table.Cell>
                  {rulesDetailsdata && rulesDetailsdata.length > 3
                    ? rulesDetailsdata[3].ruleExpression
                    : ""}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  {rulesDetailsdata && rulesDetailsdata.length > 4
                    ? rulesDetailsdata[4].ruleName
                    : ""}
                </Table.Cell>
                <Table.Cell>
                  {rulesDetailsdata && rulesDetailsdata.length > 4
                    ? rulesDetailsdata[4].ruleExpression
                    : ""}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          )}
        </Table>
      </Segment>
    </>
  );
};

export default Rules;
