import React, { useState } from 'react';
import matchSorter from 'match-sorter';
import ReactTable from 'react-table';
import PropTypes from 'prop-types';
import moment from 'moment/min/moment-with-locales';
import { Field } from 'redux-form';
import enhance from './enhance';
import { Form, Input } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import {
  getDeviceStatusMappings,
  getStatusIndicator,
  getICStatus,
  getICStatusIndicator,
} from '../../../utilities/helpers';
import { commonLabels, Alerts } from '../../../redux/constants/Values';
import { fetchOnboardingFiltered } from '../../../redux/reducers/OnBoardingReducer';

export const OnBoardingTableData = (props) => {
  /* istanbul ignore next */
  const { updateStatus ,saveRemark } = props;
  /* istanbul ignore next */
  const roles = sessionStorage.getItem('roles').split(',');
  /* istanbul ignore next */
  const isAdmin = roles.indexOf('adminGroup') > -1 ? true : false;
  /* istanbul ignore next */
  const [UpdateStatusVal, setUpdateStatusVal] = useState({
    deviceName: '',
    deviceStatus: '',
  });
  /* istanbul ignore next */
  const [RemarkVal, setRemarkVal] = useState({
    remark:'',
  });
  /* istanbul ignore next */
  const [OnBoardingStateVal, setOnBoardingStateVal] = useState({
    RonFilterVal: '',
    ICFilterVal: '',
    LcFilterVal: '',
    filterDatePicker: false,
    afterfilter: [],
  });

  /* istanbul ignore next */
  const handleOnboardingRemark = (rowVal) => {
    let obj = {};
    obj.deviceName = rowVal.deviceName;
    obj.remarks = RemarkVal.remark;
    obj.deviceModel = rowVal.modelName;
    obj.updatedBy = sessionStorage.getItem('username');
    
    return (
      <>
        <div style={{ width: '100%' }}>
        <Form>
        <Input
          type='text'
          value={obj.deviceRemark}
          //placeholder={rowVal.remarks}
          onChange={(e)=> {setRemarkVal({
            ...RemarkVal,
            remark: e.target.value,

          })}}
          
          style={{ width: '55%' , marginRight: '3px', height: '2px',}}
          >

          </Input> 
           <button
            style={{ width: '45%', fontWeight: '100', fontSize: 'smaller',}}
            onClick={() => {
              saveRemark(obj);
            }}
            >
            {commonLabels.update}
          </button>
        </Form>
        
        </div>
      </>
    );
  };

  /* istanbul ignore next */
  const handleOnboardingStatus = (rowVal) => {
    const input = rowVal.deviceStatus;
    let obj = {};
    obj.deviceName = rowVal.deviceName;
    obj.status = UpdateStatusVal.deviceStatus;
    obj.selectedDevice4Status = UpdateStatusVal.deviceName;
    obj.deviceModel = rowVal.modelName;
    obj.updatedBy = sessionStorage.getItem('username');
    return (
      <>
        <div style={{ width: '100%' }}>
          <select
            disabled={!isAdmin}
            className='OK'
            style={{ width: '55%', marginRight: '6px' }}
            onChange={(e) => {
              setUpdateStatusVal({
                ...UpdateStatusVal,
                deviceName: obj.deviceName,
                deviceStatus: e.target.value,
              });
            }}
          >
            <option value='OK' selected={input === 'OK'}>
              OK
            </option>
            <option value='PENDING' selected={input === 'PENDING'}>
              PENDING
            </option>
            <option value='DENIED' selected={input === 'DENIED'}>
              DENIED
            </option>
            <option value='DORMANT' selected={input === 'DORMANT'}>
              DORMANT
            </option>
          </select>

          <button
            disabled={!isAdmin}
            style={{ width: '44%',fontSize: 'small', }}
            onClick={() => {
              updateStatus(obj);
            }}
          >
            {commonLabels.update}
          </button>
        </div>
      </>
    );
  };

  /* istanbul ignore next */
  const handleDatepicker = (input, datetype) => {
    const value = input.currentTarget.valueAsDate;
    setOnBoardingStateVal((prevState) => {
      return {
        ...prevState,
        filterDatePicker: value === null ? false : true,
      };
    });
    const dateinput = moment(value).format('YYYY-MM-DD');
    const beforefilter = props.onBoardingListsdata;
    if (datetype === 'registerdOn' && value !== null) {
      const filterresult = beforefilter.filter(
        (data) => data.registerdOn.slice(0, 10) === dateinput
      );

      setOnBoardingStateVal((prevState) => {
        return {
          ...prevState,
          filterDatePicker: true,
          afterfilter: filterresult,
          RonFilterVal: input,
        };
      });
    } else if (datetype === 'icLastContacted' && value !== null) {
      const filterresult = beforefilter.filter(
        (data) =>
          data.ICLastContactedTime &&
          data.ICLastContactedTime.slice(0, 10) === dateinput
      );
      setOnBoardingStateVal((prevState) => {
        return {
          ...prevState,
          filterDatePicker: true,
          afterfilter: filterresult,
          ICFilterVal: input,
        };
      });
    } else if (datetype === 'lastContacted' && value !== null) {
      const filterresult = beforefilter.filter(
        (data) => data.lastContacted.slice(0, 10) === dateinput
      );
      setOnBoardingStateVal((prevState) => {
        return {
          ...prevState,
          filterDatePicker: true,
          afterfilter: filterresult,
          LcFilterVal: input,
        };
      });
    }
  };
  /* istanbul ignore next */
  return (
    <div>
      <ReactTable
        data={
          OnBoardingStateVal.filterDatePicker
            ? OnBoardingStateVal.afterfilter
            : props.onBoardingListsdata
        }
        getTheadFilterThProps={(e) => {
          let filtredData = e.sortedData.map((eachVal) => {
            return eachVal._original;
          });
          props.dispatch(fetchOnboardingFiltered(filtredData));
          return {
            style: { position: 'inherit', overflow: 'inherit' },
          };
        }}
        filterable
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id]) === filter.value
        }
        columns={[
          {
            Header: 'Instrument Status',
            id: 'groupStatus',
            accessor: (d) => getDeviceStatusMappings(d.groupStatus),
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ['groupStatus'] }),
            filterAll: true,
            Filter: ({ filter, onChange }) => (
              <select
                onChange={(event) => onChange(event.target.value)}
                style={{ width: '100%', height: '37px' }}
                value={filter ? filter.value : `'Active':'Inactive'`}
              >
                <option value=''>ALL</option>
                <option value='READY'>READY</option>
                <option value='FAULT'>FAULT</option>
                <option value='RUNNING'>RUNNING</option>
                <option value='UNKNOWN'>UNKNOWN</option>
                <option value='DISCONNECTED'>DISCONNECTED</option>
              </select>
            ),
            Cell: (props) => getStatusIndicator(props.value),
          },
          {
            Header: 'Serial Number',
            id: 'deviceName',
            width: 150,
            accessor: (d) => d.deviceName,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ['deviceName'] }),
            filterAll: true,
            Cell: (e) => ([
              <Link
                to={`/assets/${e.original.deviceName}/${e.original.modelName}`}
              >
                {e.original.deviceName}
              </Link>,
              handleOnboardingRemark(e.original)]
            ),
              
          },

          {
            Header: 'Model',
            id: 'modelName',
            accessor: (d) => d.modelName,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ['modelName'] }),
            filterAll: true,
          },
          {
            Header: 'IC Status',
            id: 'icStatus',
            width: 96,
            accessor: (d) =>
              d.ICStatus !== undefined ? getICStatus(d.ICStatus) : 'N/A',
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ['icStatus'] }),
            filterAll: true,
            Filter: ({ filter, onChange }) => (
              <select
                onChange={(event) => onChange(event.target.value)}
                style={{ width: '100%', height: '37px' }}
                value={filter ? filter.value : `'Active':'Inactive'`}
              >
                <option value=''>ALL</option>
                <option value='ON'>ON</option>
                <option value='OFF'>OFF</option>
              </select>
            ),
            Cell: (props) => getICStatusIndicator(props.value),
          },
          {
            Header: 'IC Last Contacted',
            id: 'icLastContacted',
            accessor: (d) =>
              d.ICLastContactedTime !== undefined
                ? d.ICLastContactedTime
                : 'N/A',
            filterAll: true,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ['icLastContacted'] }),
            Filter: ({ filter, onChange }) => (
              <Form>
                <Field
                  component={Form.Input}
                  type='Date'
                  name='icLastContactedDate'
                  value={OnBoardingStateVal.ICFilterVal}
                  onChange={(event) => {
                    if (event != null) {
                      handleDatepicker(event, 'icLastContacted');
                    } else {
                      setOnBoardingStateVal({
                        filterDatePicker: false,
                        ICFilterVal: '',
                      });
                    }
                  }}
                />
              </Form>
            ),
          },
          {
            Header: 'Registered On',
            id: 'registerdOn',
            accessor: (d) => d.registerdOn,
            Filter: ({ filter, onChange }) => (
              <Form>
                <Field
                  component={Form.Input}
                  type='Date'
                  name='registeredOnDate'
                  value={OnBoardingStateVal.RonFilterVal}
                  onChange={(event) => {
                    if (event != null) {
                      handleDatepicker(event, 'registerdOn');
                    } else {
                      setOnBoardingStateVal({
                        filterDatePicker: false,
                        RonFilterVal: '',
                      });
                    }
                  }}
                />
              </Form>
            ),
            filterAll: true,
          },
          {
            Header: 'Last Data Item Date',
            id: 'lastContacted',
            accessor: (d) => d.lastContacted,
            filterAll: true,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ['lastContacted'] }),
            Filter: ({ filter, onChange }) => (
              <Form>
                <Field
                  component={Form.Input}
                  type='Date'
                  name='lastContactedDate'
                  value={OnBoardingStateVal.LcFilterVal}
                  onChange={(event) => {
                    if (event != null) {
                      handleDatepicker(event, 'lastContacted');
                    } else {
                      setOnBoardingStateVal({
                        filterDatePicker: false,
                        LcFilterVal: '',
                      });
                    }
                  }}
                />
              </Form>
            ),
          },
          {
            Header: 'Current Status',
            id: 'deviceStatus',
            accessor: (d) => d.deviceStatus,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: ['deviceStatus'],
              }),
            filterAll: true,
            Filter: ({ filter, onChange }) => (
              <select
                onChange={(event) => onChange(event.target.value)}
                style={{ width: '100%', height: '37px' }}
                value={filter ? filter.value : ''}
                defaultValue={filter ? filter.value : ''}
              >
                <option value=''>Status</option>
                <option value='OK'>OK</option>
                <option value='PENDING'>PENDING</option>
                <option value='DENIED'>DENIED</option>
                <option value='DORMANT'>DORMANT</option>
              </select>
            ),

            Cell: (e) => handleOnboardingStatus(e.original),
          },
          {
            Header: 'Desired Status',
            id: 'deviceDesiredStatus',
            accessor: (d) => d.desiredStatus,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: ['deviceDesiredStatus'],
              }),
            filterAll: true,
          },
          {
            Header: 'Remark',
            id: 'remarks',
            width: 96,
            accessor: (d) => d.remarks,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, {
                keys: ['remarks'],
              }),
            filterAll: true,
          },
        ]}
        defaultPageSize={10}
        minRows={3}
        noDataText={Alerts.noRows}
        className='-striped -highlight'
      />
    </div>
  );
};

OnBoardingTableData.propTypes = {
  data: PropTypes.array,
  filterable: PropTypes.bool,
};

export default enhance(OnBoardingTableData);
