import React from "react";
import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";
import {
  addRulesOfRulesToRulesCheckedList,
  removeRulesOfRulesToRulesCheckedList,
} from "../../../../redux/reducers/RulesListReducer";
import { Alerts } from "../../../../redux/constants/Values";

/**
 *
 * @description {fetch values from store}
 */
export const mapStateToProps = (state) => ({
  rulesOfRulesListsdata: state.rulesListreducer.rulesOfRulesListsdata,
  checkedArrayList: state.rulesListreducer.rulesOfRulesCheckedList
    ? state.rulesListreducer.rulesOfRulesCheckedList.map(
        (item) => item.ruleName
      )
    : [],

  rulesOfRulesCheckedList: state.rulesListreducer.rulesOfRulesCheckedList
    ? state.rulesListreducer.rulesOfRulesCheckedList
    : [],
});

/**
 *
 * @description {filter values based on enable\disable}
 */
export const handleEnable = () => (enableInput) => {
  return (
    <span>
      <i
        className={
          "certificate  small icon marginRight " +
          (enableInput === "Disable" ? "black" : "green")
        }
      ></i>
      {enableInput}
    </span>
  );
};

/**
 *
 * @description {checkbox checked calls}
 */
export const toggleRow = ({
  addRulesOfRulesToRulesCheckedList,
  removeRulesOfRulesToRulesCheckedList,
  rulesOfRulesCheckedList,
}) => (event, ruleName) => {
  const val = event.target.checked;
  let assignList = {
    ruleName: ruleName,
  };
  if (val) {
    if (rulesOfRulesCheckedList.length > 1) {
      window.alert(Alerts.maxRules);
      event.target.checked = false;
    } else {
      addRulesOfRulesToRulesCheckedList(assignList);
    }
  } else {
    removeRulesOfRulesToRulesCheckedList(assignList);
  }
};

export default compose(
  connect(mapStateToProps, {
    addRulesOfRulesToRulesCheckedList,
    removeRulesOfRulesToRulesCheckedList,
  }),
  withHandlers({ toggleRow, handleEnable })
);
