import React from "react";
import enhance from "./enhance";
import { Form, Button, Divider } from "semantic-ui-react";
import { Field } from "redux-form";
import { Dimmer, Loader } from "semantic-ui-react";
import { InputField, SelectField } from "react-semantic-redux-form";
import "react-phone-number-input/style.css";
// import { isValidPhoneNumber } from "react-phone-number-input";
// import RenderPhoneInput from "../PhoneInput";
import { required, emailFormat } from "./../../../utilities/validations";
import { commonLabels, UpdateLabels } from "../../../redux/constants/Values";

export const UpdateUser = (props) => {
  const { handleSubmit, backToListPage, isFetching } = props;
  // const [phonevalue, setPhonevalue] = useState();
  const userGroupsOptions = [
    { key: 1, text: "Admin", value: "adminGroup" },
    { key: 2, text: "Read Only User", value: "readOnlyUser" },
  ];
  return (
    <>
      {isFetching ? (
        <Dimmer active inverted>
          <Loader size="large" inverted>
            {commonLabels.loading}
          </Loader>
        </Dimmer>
      ) : (
        <div className="containerStyle">
          <h4>{UpdateLabels.users}</h4> <Divider />
          <div className="toggleContent">
            <Form onSubmit={handleSubmit}>
              <Field
                component={InputField}
                label="Username"
                placeholder="Username"
                name="userName"
                validate={[required]}
                disabled={true}
              />
              <Field
                component={SelectField}
                label="User groups"
                options={userGroupsOptions}
                value={userGroupsOptions}
                placeholder="User Groups"
                name="usergroups"
                validate={[required]}
              />
              <Field
                component={InputField}
                label="Email"
                placeholder="Email"
                name="email"
                validate={[required, emailFormat]}
              />
              {/* <Field
                value={phonevalue}
                name="phone_number"
                onChange={setPhonevalue}
                component={RenderPhoneInput}
                validate={[required]}
              /> */}
              <Button.Group>
                <Button positive>{commonLabels.update}</Button>
                <Button.Or />
                <Button negative onClick={backToListPage}>
                  {commonLabels.cancel}
                </Button>
              </Button.Group>
            </Form>
          </div>
        </div>
      )}
    </>
  );
};

export default enhance(UpdateUser);
