import React from "react";
import {
  Table,
  Label,
  Segment,
  Button,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import "../Root/DeviceDetail.module.scss";
import { Grids, commonLabels } from "../../../../redux/constants/Values";

export const WorkstationData = (props) => {
  const {
    detailsPageButtonIsClicked,
    workstationDetailsData,
    isFetchingWorkstationData,
  } = props;
  return (
    <>
      <Segment padded>
        <Label attached="top" size="big" className="bgBlue">
          {Grids.workstation + " " + commonLabels.data}
          <Button
            primary
            onClick={detailsPageButtonIsClicked.bind(this, Grids.workstation)}
            floated="right"
          >
            {commonLabels.viewAll}
          </Button>
        </Label>
        <Table
          className="displayTable"
          singleLine
          compact
          striped
          sortable
          celled
          textAlign="center"
        >
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Display Name</Table.HeaderCell>
              <Table.HeaderCell>Value</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          {isFetchingWorkstationData ? (
            <Dimmer active inverted>
              <Loader size="mini">{commonLabels.fetching}</Loader>
            </Dimmer>
          ) : (
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  {workstationDetailsData &&
                  workstationDetailsData.length > 0 &&
                  workstationDetailsData[0].mnemonicDisplayName !== "N/A"
                    ? workstationDetailsData[0].mnemonicDisplayName
                    : workstationDetailsData &&
                      workstationDetailsData.length > 0
                    ? workstationDetailsData[0].mnemonicName
                    : ""}
                </Table.Cell>
                <Table.Cell>
                  {workstationDetailsData &&
                    workstationDetailsData.length > 0 &&
                    workstationDetailsData[0].mnemonicValue}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  {workstationDetailsData &&
                  workstationDetailsData.length > 1 &&
                  workstationDetailsData[1].mnemonicDisplayName !== "N/A"
                    ? workstationDetailsData[1].mnemonicDisplayName
                    : workstationDetailsData &&
                      workstationDetailsData.length > 1
                    ? workstationDetailsData[1].mnemonicName
                    : ""}
                </Table.Cell>
                <Table.Cell>
                  {workstationDetailsData &&
                    workstationDetailsData.length > 1 &&
                    workstationDetailsData[1].mnemonicValue}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  {workstationDetailsData &&
                  workstationDetailsData.length > 2 &&
                  workstationDetailsData[2].mnemonicDisplayName !== "N/A"
                    ? workstationDetailsData[2].mnemonicDisplayName
                    : workstationDetailsData &&
                      workstationDetailsData.length > 2
                    ? workstationDetailsData[2].mnemonicName
                    : ""}
                </Table.Cell>
                <Table.Cell>
                  {workstationDetailsData &&
                    workstationDetailsData.length > 2 &&
                    workstationDetailsData[2].mnemonicValue}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  {workstationDetailsData &&
                  workstationDetailsData.length > 3 &&
                  workstationDetailsData[3].mnemonicDisplayName !== "N/A"
                    ? workstationDetailsData[3].mnemonicDisplayName
                    : workstationDetailsData &&
                      workstationDetailsData.length > 3
                    ? workstationDetailsData[3].mnemonicName
                    : ""}
                </Table.Cell>
                <Table.Cell>
                  {workstationDetailsData &&
                    workstationDetailsData.length > 3 &&
                    workstationDetailsData[3].mnemonicValue}
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell>
                  {workstationDetailsData &&
                  workstationDetailsData.length > 4 &&
                  workstationDetailsData[4].mnemonicDisplayName !== "N/A"
                    ? workstationDetailsData[4].mnemonicDisplayName
                    : workstationDetailsData &&
                      workstationDetailsData.length > 4
                    ? workstationDetailsData[4].mnemonicName
                    : ""}
                </Table.Cell>
                <Table.Cell>
                  {workstationDetailsData &&
                    workstationDetailsData.length > 4 &&
                    workstationDetailsData[4].mnemonicValue}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          )}
        </Table>
      </Segment>
    </>
  );
};

export default WorkstationData;
