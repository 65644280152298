import React from "react";
import { Field } from "redux-form";
import { Form, Button } from "semantic-ui-react";
import enhance from "./enhance";
import { commonLabels } from "../../../../../redux/constants/Values";

export const ModalForm = (props) => {
  const { handleSubmit, getHistoryData } = props;
  return (
    <div style={{ marginTop: "16px" }}>
      <Form onSubmit={handleSubmit.bind(this, props)}>
        <Form.Group widths="equal">
          <Field
            component={Form.Input}
            type="Date"
            label="Start Date"
            name="startDate"
          />
          <Field
            component={Form.Input}
            type="Date"
            label="End Date"
            placeholder="End date"
            name="endDate"
          />
        </Form.Group>
      </Form>
      <Button.Group>
        <Button positive onClick={handleSubmit}>
          {commonLabels.search}
        </Button>
        <Button.Or />
        <Button negative onClick={getHistoryData.bind(this, props)}>
          {commonLabels.clear}
        </Button>
      </Button.Group>
      <div style={{ float: "right" }}>
        <h5>
          <button className="exportButton" onClick={props.exportFile}>
            {commonLabels.exportToCSV}
          </button>
        </h5>
      </div>
    </div>
  );
};

export default enhance(ModalForm);
