import React from "react";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import "react-table/react-table.css";
import enhance from "./enhance";
import { Alerts } from "../../../../redux/constants/Values";

export const ErrorTableData = (props) => {
  const { getMnemonicsData } = props;
  /* istanbul ignore next */
  return (
    <div>
      <ReactTable
        data={props.data}
        getTheadFilterThProps={(e) => {
          let filtredData = e.sortedData.map((eachVal) => {
            return eachVal._original;
          });
          getMnemonicsData(filtredData.length > 0 ? filtredData : []);
          return {
            style: { position: "inherit", overflow: "inherit" },
          };
        }}
        filterable
        defaultFilterMethod={(filter, row) =>
          String(row[filter.id]) === filter.value
        }
        columns={[
          {
            Header: "Error Type",
            id: "errorType",
            accessor: (d) => d.errorType,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["errorType"] }),
            filterAll: true,
          },
          {
            Header: "Error Code",
            id: "errorCode",
            accessor: (d) => d.errorCode,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["errorCode"] }),
            filterAll: true,
          },
          {
            Header: "Error Timestamp",
            id: "errorTimeStamp",
            accessor: (d) => d.errorTimeStamp,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["errorTimeStamp"] }),
            filterAll: true,
          },
          {
            Header: "Error Info",
            id: "errorInfo",
            accessor: (d) => d.errorInfo,
            filterMethod: (filter, rows) =>
              matchSorter(rows, filter.value, { keys: ["errorInfo"] }),
            filterAll: true,
          },
        ]}
        defaultPageSize={10}
        minRows={3}
        noDataText={Alerts.noRows}
        className="-striped -highlight"
      />
    </div>
  );
};
export default enhance(ErrorTableData);
