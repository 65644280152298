import React from 'react';
import { Button, Form, FormGroup, FormControl } from 'react-bootstrap';
import styles from './SignIn.module.scss';
import enhance from './enhance';
import logo from '../../images/Sciex.jpg';
import { Dimmer, Loader } from 'semantic-ui-react';
import { commonLabels, CreateLabels } from '../../redux/constants/Values';

export const Login = (props) => {
  const { handleSubmit, loader, queryChange } = props;
  /* istanbul ignore next */
  const enablePassword = () => {
    var x = document.getElementById('showPassword');
    if (x.type === 'password') {
      x.type = 'text';
    } else {
      x.type = 'password';
    }
  };
  return (
    <>
      {loader ? (
        <Dimmer active inverted>
          <Loader size='large' inverted>
            {commonLabels.loading}
          </Loader>
        </Dimmer>
      ) : (
        <div className={styles.login}>
          <div>
            <form className='alignlogin shadow' onSubmit={handleSubmit}>
              <div className={styles.loginLogo}>
                {' '}
                  
                <img src={logo} alt='Sciex' className='brand-logo' />{' '}
              </div>

              <FormGroup controlId='email'>
                <h5> User Name</h5>
                <FormControl
                  required
                  autoFocus
                  // value={LoginDetails.email}
                  type='text'
                  name='email'
                  onChange={(e) => queryChange(e)}
                />
              </FormGroup>
              <FormGroup controlId='password'>
                <h5> Password </h5>
                <FormControl
                  required
                  // value={LoginDetails.password}
                  id='showPassword'
                  name='password'
                  type='password'
                  onChange={(e) => queryChange(e)}
                />
                <div style={{ marginTop: '10px' }}>
                  <Form.Check
                    type='checkbox'
                    onClick={enablePassword}
                    label='Show Password'
                  />
                </div>
              </FormGroup>
              <Button block className={styles.btnColor} type='submit'>
                {CreateLabels.login}
              </Button>
              <a className={styles.forgotPW} href='/forgotPassword'>
                {CreateLabels.forgotPassword}
              </a>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default enhance(Login);
