import React from 'react';
import 'react-table/react-table.css';
import enhance from './enhance';
import { Grid, Button, Divider } from 'semantic-ui-react';
import { Grids, commonLabels } from '../../../../redux/constants/Values';

//File Imports
import AlarmTableData from './AlarmsTableData';
import MassSpecTableData from './MassSpecTableData';
import QueueListTableData from './QueueListTableData';
import RulesTableData from './RulesTableData';
import WorkstationTableData from './WorkstationTableData';
import LCTableData from './LCTableData';
import ErrorTableData from './ErrorTableData';

const getTableData = (
  data,
  customWorkstationData,
  customMassSpecData,
  customLCData
) => {
  switch (data.tableType) {
    case Grids.alarms:
      return <AlarmTableData data={data.alarmDetailsdata} />;
    case Grids.massSpec:
      return <MassSpecTableData data={customMassSpecData} />;
    case Grids.sampleItem:
      return <QueueListTableData data={data.sampleQueueListDetailsData} />;
    case Grids.rules:
      return <RulesTableData data={data.rulesDetailsdata} />;
    case Grids.workstation:
      return <WorkstationTableData data={customWorkstationData} />;
    case Grids.lc:
      return <LCTableData data={customLCData} />;
    case Grids.error:
      return <ErrorTableData data={data.errorDataDetailsData.errorDetails} />;
    default:
      return null;
  }
};

export const TableData = (props) => {
  const { handleSubmit } = props;
  return (
    <div>
      <h5>
        {props.tableType} {commonLabels.data} - {props.deviceName}
        <button className='exportButton' onClick={props.exportFile}>
          {commonLabels.exportToCSV}
        </button>
        <button className='refreshButton' onClick={handleSubmit}>
          {commonLabels.fetchlatestdata}
        </button>
      </h5>
      <Divider />
      {getTableData(
        props,
        props.workstationDetailsData.deviceListValues,
        props.massSpecDetailsData.deviceListValues,
        props.lcDataDetailsData.deviceListValues
      )}
      <Divider />
      <Grid>
        <Grid.Column>
          <Button primary onClick={props.isDetailsPageBackButtonIsClicked}>
            {commonLabels.back}
          </Button>
        </Grid.Column>
      </Grid>
    </div>
  );
};
export default enhance(TableData);
