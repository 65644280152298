import React from 'react';

export const useAppendValuesState = () => {
  const [deviceParameter, setDeviceParameter] = React.useState('');
  const [rows, setRows] = React.useState(0);
  const [operator, setOperator] = React.useState('');
  const [booleanValue, setBooleanValue] = React.useState('');
  const [deviceValue, setDeviceValue] = React.useState('');
  const [deviceDropdownValue, setDeviceDropdownValue] = React.useState('');
  const [topOperator, setTopOperator] = React.useState('');
  const [resetExpression, setResetExpressionParamaters] = React.useState('');
  const [radioValue, setRadioValue] = React.useState('radio_value');

  const radioChange = (value) => {
    setRadioValue(value);
    if (value === 'radio_expression') {
      setDeviceValue('');
    } else {
      setDeviceDropdownValue('');
    }
  };

  const [dropdownParameterValue, setDropdownParameterValue] = React.useState(
    null
  );

  const [dropdownOperatorValue, setDropdownOperatorValue] = React.useState(
    null
  );

  const [dropdownBooleanValue, setDropdownBooleanValue] = React.useState(null);

  const [dropdownInputValue, setDropdownInputValue] = React.useState(null);

  const [dropdownListValue, setDropdownListValue] = React.useState(null);

  const [keyChangeValue, setkeyChangeValue] = React.useState('');

  const [keyPressBoolean, setkeyPressBoolean] = React.useState('');

  const [
    dropdownTopOperatorValue,
    setDropdownTopOperatorValue,
  ] = React.useState(null);

  const setParameterDropdown = (val) => {
    setDeviceParameter('(' + val);
    // setDeviceParameter(val);
    setDropdownParameterValue(val);
  };

  const setOperatorDropdown = (val) => {
    setOperator(val);
    setDropdownOperatorValue(val);
  };

  const setBooleanDropdown = (val) => {
    setBooleanValue(val + ')');
    setDropdownBooleanValue(val);
  };

  const setInputDropdown = (val) => {
    if (
      !/[0-9|(?<=^| )\d+(\d+)?(?=$| )|(?<=^| )\d+(?=$| )]+$/.test(val.trim())
    ) {
      // if (val.toLowerCase() === "true" || val.toLowerCase() === "false") {
      //   setDeviceValue("'" + val.toUpperCase() + "')");
      // } else {
      setDeviceValue("'" + val + "')");
      // }
    } else {
      setDeviceValue(val + ')');
    }
    //setDeviceValue(val);
    setDropdownInputValue(val);
  };

  const setDropdownList = (val) => {
    setDeviceDropdownValue(val + ')');
    // setDeviceDropdownValue(val + ")");
    //setDeviceDropdownValue(val);
    setDropdownListValue(val);
  };

  const setTopOperatorDropdown = (val) => {
    setTopOperator(val);
    setDropdownTopOperatorValue(val);
  };

  const ClearDropdowns = () => {
    setDeviceParameter('');
    setDropdownParameterValue('');
    setDropdownOperatorValue('');
    setDropdownBooleanValue('');
    setOperator('');
    setBooleanValue('');
    setDropdownInputValue('');
    setDeviceValue('');
    setDropdownTopOperatorValue('');
    setTopOperator('');
    setDeviceDropdownValue('');
    setDropdownListValue('');
  };

  return {
    setDeviceParameter,
    deviceParameter,
    setRows,
    rows,
    setOperator,
    setBooleanValue,
    operator,
    booleanValue,
    setDeviceValue,
    deviceValue,
    resetExpression,
    //handleClick,
    setResetExpressionParamaters,
    setTopOperator,
    topOperator,
    radioValue,
    setRadioValue,
    radioChange,

    dropdownParameterValue,
    setDropdownParameterValue,
    setParameterDropdown,

    dropdownOperatorValue,
    setOperatorDropdown,

    dropdownBooleanValue,
    setBooleanDropdown,

    dropdownInputValue,
    setInputDropdown,

    dropdownTopOperatorValue,
    setTopOperatorDropdown,

    dropdownListValue,
    setDropdownList,
    deviceDropdownValue,

    ClearDropdowns,

    keyChangeValue,
    setkeyChangeValue,
    keyPressBoolean,
    setkeyPressBoolean,
  };
};
