import React from 'react';
import {
  Button,
  Card,
  Radio,
  Grid,
  Input,
  Select,
  Form,
  Dropdown,
  Popup,
  Icon,
} from 'semantic-ui-react';
import { Field } from 'redux-form';
import { InputField, SelectField } from 'react-semantic-redux-form';
import { TextArea } from './../../TextArea/index';
import { useAppendValuesState } from './useAppendValuesState';
import styles from './ExpressionsRuleCard.module.scss';
import enhance from './enhance';
import {
  OnlyNumberAllowed,
  limitTwoDigit,
} from './../../../../utilities/validations';
import {
  commonLabels,
  CreateLabels,
  Alerts,
} from '../../../../redux/constants/Values';

const operator_options = [
  { key: 'select', value: '', text: 'Choose One' },
  { key: '=', value: '=', text: '=' },
  { key: '> ', value: '>', text: '>' },
  { key: '>= ', value: '>=', text: '>=' },
  { key: '< ', value: '<', text: '<' },
  { key: '<= ', value: '<=', text: '<=' },
  { key: '<> ', value: '<>', text: '!=' },
  { key: 'has ', value: 'has', text: 'has' },
  //{ key: "contains ", value: "has", text: "contains" },
];

const boolean_values = [
  { key: 'TRUE', value: 'TRUE', text: 'TRUE' },
  { key: 'FALSE', value: 'FALSE', text: 'FALSE' },
];

const top_operator_options = [
  { key: 'select', value: '', text: 'Choose One' },
  { key: 'or ', value: 'or', text: 'OR' },
  { key: 'and ', value: 'and', text: 'AND' },
];

const time_options = [
  { key: 'select', value: '', text: 'Choose One' },
  { key: 'Hour', value: 'Hour', text: 'Hour' },
  { key: 'Minutes', value: 'Minutes', text: 'Minutes' },
];

export const ExpressionRulesCard = ({
  validateExpression,
  expressionParamaters,
  initialValues,
  appendValuesToTextAreaEvent,
  clearExpressionEvent,
  IsExpressionValidated,
  paramterListForDropdown,
  textAreaValueToStore,
  onChangeTextarea,
  onKeyDown,
  keyboardEvent,
  isFetching,
}) => {
  const {
    deviceParameter,
    setRows,
    rows,
    operator,
    deviceValue,
    booleanValue,
    topOperator,
    radioValue,
    radioChange,
    dropdownParameterValue,
    setParameterDropdown,
    dropdownOperatorValue,
    dropdownBooleanValue,
    setOperatorDropdown,
    setBooleanDropdown,
    dropdownInputValue,
    setInputDropdown,
    dropdownTopOperatorValue,
    setTopOperatorDropdown,
    dropdownListValue,
    setDropdownList,
    deviceDropdownValue,
    ClearDropdowns,
    setkeyPressBoolean,
  } = useAppendValuesState();

  let options =
    paramterListForDropdown.length > 0
      ? paramterListForDropdown.map((item) => ({
          key: item.displayText,
          value: item.value,
          text: item.displayText,
        }))
      : [];
  return (
    <div className={` "cards" + " " + ${styles.customWidthFix}`}>
      <Card fluid>
        <Card.Content
          header={CreateLabels.configureRules}
          className='cardHeader'
        />

        <Card.Content extra className={styles.customHeightFix}>
          <Grid>
            <Grid.Row columns={3} className={styles.gridCustomRowPadding}>
              <Grid.Column>
                <Form.Field>
                  <label>Device Parameter</label>
                  <Dropdown
                    search
                    selection
                    loading={isFetching ? true : false}
                    placeholder='Device Parameter'
                    fluid
                    options={options}
                    onChange={(e, { value }) => setParameterDropdown(value)}
                    clearable
                    value={dropdownParameterValue}
                    searchInput={{ autoFocus: false }}
                  />
                </Form.Field>
              </Grid.Column>

              <Grid.Column>
                <Form.Field>
                  <label>Operator</label>

                  <Dropdown
                    fluid
                    search
                    selection
                    options={operator_options}
                    onChange={(e, { value }) => setOperatorDropdown(value)}
                    value={dropdownOperatorValue}
                    placeholder='Operator'
                    clearable
                  />
                </Form.Field>
              </Grid.Column>

              <Grid.Column>
                <Form.Field>
                  <label>Value</label>
                </Form.Field>
                <Radio
                  //label="Choose this"
                  name='radioGroup'
                  value='radio_expression'
                  checked={radioValue === 'radio_expression'}
                  onChange={(e, { value }) => radioChange(value)}
                />

                <Dropdown
                  fluid
                  search
                  selection
                  options={options}
                  onChange={(e, { value }) => setDropdownList(value)}
                  value={dropdownListValue}
                  placeholder='Device Parameter'
                  name='radioGroup'
                  className={styles.customSelectWidth}
                  disabled={
                    radioValue === 'radio_value' ||
                    radioValue === 'radio_boolean'
                  }
                  clearable={radioValue === 'radio_value' ? false : true}
                  // onClick={getParametersListOnClick}
                />

                <Radio
                  //label="Choose this"
                  name='radioGroup'
                  value='radio_value'
                  checked={radioValue === 'radio_value'}
                  onChange={(e, { value }) => radioChange(value)}
                />
                <Input
                  className={styles.customInputWidth}
                  name='device_value'
                  onChange={(e, { value }) => setInputDropdown(value)}
                  value={dropdownInputValue}
                  placeholder='Value'
                  disabled={
                    radioValue === 'radio_expression' ||
                    radioValue === 'radio_boolean'
                  }
                />
                <Dropdown
                  fluid
                  search
                  selection
                  options={boolean_values}
                  onChange={(e, { value }) => setBooleanDropdown(value)}
                  value={dropdownBooleanValue}
                  placeholder='Boolean Values'
                  name='radioGroup'
                  className={styles.customBooleanWidth}
                  disabled={
                    radioValue === 'radio_value' ||
                    radioValue === 'radio_expression'
                  }
                  clearable={radioValue === 'radio_value' ? false : true}
                />
                <Radio
                  className={styles.customRadioButton}
                  name='radioGroup'
                  value='radio_boolean'
                  checked={radioValue === 'radio_boolean'}
                  onChange={(e, { value }) => radioChange(value)}
                />
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={3} className={styles.gridCustomRowPadding}>
              {/* <Grid> */}
              <Grid.Column textAlign='center'>
                <Button
                  primary
                  className={
                    paramterListForDropdown.length !== 0
                      ? ''
                      : styles.addDisabled
                  }
                  onClick={(e) => {
                    deviceParameter !== '' ||
                    operator !== '' ||
                    (deviceValue !== '' && topOperator)
                      ? appendValuesToTextAreaEvent(
                          e,
                          topOperator +
                            deviceParameter +
                            operator +
                            deviceValue +
                            deviceDropdownValue +
                            booleanValue,
                          rows
                        )
                      : appendValuesToTextAreaEvent(
                          e,
                          deviceParameter +
                            operator +
                            deviceValue +
                            deviceDropdownValue +
                            booleanValue,
                          rows
                        );

                    setRows(rows + 1);
                    deviceParameter !== '' ||
                    operator !== '' ||
                    (deviceValue !== '' && topOperator)
                      ? setkeyPressBoolean(
                          topOperator +
                            deviceParameter +
                            operator +
                            deviceValue +
                            deviceDropdownValue +
                            booleanValue
                        )
                      : setkeyPressBoolean(
                          deviceParameter +
                            operator +
                            deviceValue +
                            deviceDropdownValue +
                            booleanValue
                        );

                    deviceParameter !== '' ||
                    operator !== '' ||
                    (deviceValue !== '' && topOperator)
                      ? onChangeTextarea(
                          e,
                          topOperator +
                            deviceParameter +
                            operator +
                            deviceValue +
                            deviceDropdownValue +
                            booleanValue,
                          true,
                          keyboardEvent
                        )
                      : onChangeTextarea(
                          e,
                          deviceParameter +
                            operator +
                            deviceValue +
                            deviceDropdownValue +
                            booleanValue,
                          true,
                          keyboardEvent
                        );
                    ClearDropdowns();
                  }}
                >
                  {commonLabels.addRow}
                </Button>
              </Grid.Column>
            </Grid.Row>

            {(textAreaValueToStore.toString() !== '' ||
              initialValues.ruleExpression !== '') && (
              <Grid.Row columns={3} className={styles.gridCustomRowPadding}>
                <Grid.Column>
                  <Form.Field>
                    <label>Operator</label>
                    <Select
                      name='device_operator'
                      options={top_operator_options}
                      onChange={(e, { value }) => setTopOperatorDropdown(value)}
                      value={dropdownTopOperatorValue}
                      placeholder='Operator'
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid.Row>
            )}

            <Grid.Row columns={3}>
              <Grid.Column>
                <label className={styles.fieldLable}>Data Expression</label>
              </Grid.Column>
              <Grid.Column>
                <Popup
                  trigger={
                    <Icon
                      className={styles.adjustLeftMargin}
                      name='exclamation circle'
                    />
                  }
                  position='top left'
                  wide
                >
                  {commonLabels.instructions} <br></br>
                  <ul>
                    <li>
                      {Alerts.ruleInstruction1}
                      <br></br>
                      {commonLabels.example}
                    </li>
                    <li>
                      {Alerts.ruleInstruction2}
                      <br></br>
                      {commonLabels.textarea}
                    </li>
                    <li>{Alerts.ruleInstruction3}</li>
                    <li>{Alerts.ruleInstruction4}</li>
                    <li>{Alerts.ruleInstruction5}</li>
                  </ul>
                </Popup>

                <Field
                  name='ruleExpression'
                  component={TextArea}
                  placeholder='Data Expression'
                  content={
                    textAreaValueToStore === ''
                      ? expressionParamaters
                      : textAreaValueToStore
                  }
                  rows='5'
                  cols='40'
                  controlFunc={(e) =>
                    onChangeTextarea(e, e.target.value, false, keyboardEvent)
                  }
                  //validate={[required]}
                  isDisabled={textAreaValueToStore === '' ? true : false}
                  className={styles.customTextArea}
                  onKeyDown={onKeyDown}
                />

                {IsExpressionValidated === false && (
                  <span className={styles.error}>
                    {Alerts.invalidExpression}
                  </span>
                )}

                {IsExpressionValidated === true && (
                  <span className={styles.successMsg}>
                    {Alerts.validExpression}
                  </span>
                )}
              </Grid.Column>
            </Grid.Row>

            <Grid.Row columns={2} className={styles.gridCustomRowPadding}>
              <Grid.Column textAlign='center'>
                <Button
                  primary
                  onClick={(e) => {
                    validateExpression(
                      rows,
                      document.getElementsByName('ruleExpression')[0].value
                        ? document.getElementsByName('ruleExpression')[0].value
                        : '',
                      e
                    );
                  }}
                >
                  {commonLabels.validateExp}
                </Button>
              </Grid.Column>

              <Grid.Column textAlign='center'>
                <Button
                  primary
                  onClick={(e) => {
                    clearExpressionEvent(e);
                    ClearDropdowns();
                    setRows(0);
                  }}
                >
                  {commonLabels.clearExp}
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid>
            <Grid.Row columns={6}>
              <Grid.Column>
                <label className={styles.fieldLable}>
                  Must occur more than
                </label>
              </Grid.Column>
              <Grid.Column>
                <Field
                  name='alarmTriggerCount'
                  component={InputField}
                  placeholder='Must occur'
                  normalize={limitTwoDigit}
                />
              </Grid.Column>

              <Grid.Column>
                <label className={styles.fieldLable}>Time in </label>
              </Grid.Column>
              <Grid.Column>
                <Field
                  name='alarmTriggerWindowPeriod'
                  component={InputField}
                  placeholder='Times in'
                  normalize={OnlyNumberAllowed}
                />
              </Grid.Column>
              <Grid.Column>
                <Field
                  name='timeFormat'
                  component={SelectField}
                  placeholder='Hrs/Mins'
                  options={time_options}
                />
              </Grid.Column>
            </Grid.Row>
            </Grid>          
        </Card.Content>
        <div className={styles.noteText}>{Alerts.mandatoryValidation}</div>
      </Card>
    </div>
  );
};

export default enhance(ExpressionRulesCard);
