import React from "react";
import PropTypes from "prop-types";
import { Form, Grid, Button, Table } from "semantic-ui-react";
import { Dimmer, Loader } from "semantic-ui-react";
import enhance from "./enhance";
import { commonLabels } from "../../../../redux/constants/Values";

export const DeviceCodeReview = (props) => {
  const {
    returnToAddPage,
    deviceCodeDetailsForReview,
    //modelsForDeviceCodeData,
    saveDeviceCodeEvent,
    updateProps,
    isFetching,
    cancelEvent,
  } = props;
  return (
    <div>
      {isFetching && (
        <Dimmer active inverted>
          <Loader size="large" inverted>
            {commonLabels.loading}
          </Loader>
        </Dimmer>
      )}
      <Grid columns="equal">
        <Grid.Row centered>
          <Grid.Column width={8}>
            <Form>
              <Table size="large">
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell width={10}>
                      Review Changes of Device Code Rule
                    </Table.HeaderCell>
                    <Table.HeaderCell width="six"></Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell>Error Code</Table.Cell>
                    <Table.Cell>
                      {deviceCodeDetailsForReview.ruleName}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Error Description</Table.Cell>
                    <Table.Cell>
                      {deviceCodeDetailsForReview.description}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Severity</Table.Cell>
                    <Table.Cell>
                      {deviceCodeDetailsForReview.severity}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Model(s)</Table.Cell>
                    <Table.Cell>
                      {deviceCodeDetailsForReview.models.map((label, index) => (
                        <span key={`models_${index}`}>
                          {(index ? ", " : "") + label.label}
                        </span>
                      ))}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell>Enabled</Table.Cell>
                    <Table.Cell>
                      {deviceCodeDetailsForReview.deleted.toString() ===
                        "true"
                        ? "Yes"
                        : "No"}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid>
        <Grid.Column textAlign="center">
          <Button primary onClick={returnToAddPage}>
            {commonLabels.back}
          </Button>
          <Button primary onClick={saveDeviceCodeEvent}>
            {updateProps === "update" ? (
              <span>{commonLabels.update}</span>
            ) : (
                <span>{commonLabels.finish}</span>
              )}
          </Button>
          <Button primary onClick={cancelEvent}>
            {commonLabels.cancel}
          </Button>
        </Grid.Column>
      </Grid>
    </div>
  );
};

DeviceCodeReview.propTypes = {
  returnToAddPage: PropTypes.func.isRequired,
  deviceCodeDetailsForReview: PropTypes.object.isRequired,
};

export default enhance(DeviceCodeReview);
