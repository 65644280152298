import { getDeviceStatusMappings, getICStatus } from '../../utilities/helpers';

export const exportCSVFile = (responseBody) => {
  const jsonData = responseBody.map((row) => ({
    'Instrument Status': getDeviceStatusMappings(row.groupStatus),
    'Serial Number': row.deviceName,
    Model: row.modelName,
    'IC Status': row.ICStatus !== undefined ? getICStatus(row.ICStatus) : 'N/A',
    'IC Last Contacted':
      row.ICLastContactedTime !== undefined ? row.ICLastContactedTime : 'N/A',
    'Registered On': row.registerdOn,
    'Last Data Item Date': row.lastContacted,
    'Current Status': row.deviceStatus,
    'Desired Status':
      row.desiredStatus !== undefined ? row.desiredStatus : 'N/A',
    'Remark': row.remarks !== undefined? row.remarks: 'N/A',
  }));
  if (jsonData.length !== 0) {
    const csvData = objectToCsv(jsonData);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'Onboarding_Status_Data.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    window.alert('There is No data to Export');
  }
};

export const objectToCsv = (jsonData) => {
  const csvRows = [];
  //get the Headers
  const headers = Object.keys(jsonData[0]);
  csvRows.push(headers.join(','));
  //loop over the rows
  for (const row of jsonData) {
    const values = headers.map((header) => {
      const escaped = ('' + row[header]).replace(/"/g, '\\"');
      return `"${escaped}"`;
    });
    csvRows.push(values.join(','));
  }
  return csvRows.join('\n');
};
