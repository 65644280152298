import React from "react";
import {
  Form,
  Button,
  Divider,
  Radio,
  Grid,
  Input,
  GridColumn,
} from "semantic-ui-react";
import { Field } from "redux-form";
import {
  InputField,
  SelectField,
  TextAreaField,
  CheckboxField,
} from "react-semantic-redux-form";
import {
  required,
  limitDescriptionChar,
  OnlyNumberAllowed,
  omitWhitespaces,
} from "../../../utilities/validations";
import RenderSelectInput from "../ReactMultiSelectCheckboxes";
import { Dimmer, Loader } from "semantic-ui-react";
import { useSelectedRadioValue } from "./useSelectedRadioValue";
import renderTextArea from "../TextArea/index";
import styles from "./AddNewContent.module.scss";
import enhance from "./enhance";
import {
  commonLabels,
  CreateLabels,
  UpdateLabels,
} from "../../../redux/constants/Values";

export const CreateContent = (props) => {
  const {
    handleSubmit,
    modelsListsdata,
    onAttachmentChange,
    isFetching,
    checkSerialIsPresent,
    matchedSerialNumbers,
    match,
    initialValues,
    isSerialMatched,
    backToListPage,
    clearSerialNumbers,
    unique_key,
    reSetModelValue,
    fileError,
  } = props;
  const {
    radioValue,
    radioChange,
    serialTextValue,
    serialFieldValue,
    ClearField,
    setSerialNumberValue,
  } = useSelectedRadioValue();

  const operationParams = [
    { value: "install", text: "install" },
    { value: "update", text: "update" },
  ];
  const model_params =
    modelsListsdata.length !== 0
      ? modelsListsdata.map((item) => ({
          label: item.attributes.modelName,
          value: item.attributes.modelName,
          text: item.attributes.modelName,
        }))
      : [{ key: "Loading...", value: "", text: "Loading..." }];

  return (
    <div>
      {isFetching && (
        <Dimmer active inverted>
          <Loader size="large" inverted>
            {commonLabels.loading}
          </Loader>
        </Dimmer>
      )}
      <div className={styles.containerStyleMain} id="addNewContentContainer">
        <Form onSubmit={handleSubmit} key={unique_key}>
          <h4>
            {match.params.update === "update"
              ? UpdateLabels.contents
              : CreateLabels.contents}
          </h4>
          <Divider />
          <div className={styles.toggleContent}>
            {match.params.update !== "update" ? (
              <Field
                component={InputField}
                label="Name"
                placeholder="Name"
                name="jobName"
                // disabled={match.params.update === "update" ? true : false}
                validate={[required]}
                normalize={omitWhitespaces}
              />
            ) : (
              <Form.Field>
                <label>Name</label>
                <span>{initialValues.jobName}</span>
              </Form.Field>
            )}
            <Form.Field>
              <label>Target</label>
              {match.params.update !== "update" ? (
                <Grid>
                  <Grid.Row columns={8} className={styles.customGridRow}>
                    <Grid.Column>
                      <Radio
                        name="radioGroup"
                        value="model_content"
                        label="Select Model"
                        checked={radioValue === "model_content"}
                        onChange={radioChange}
                        onClick={(event) => {
                          clearSerialNumbers(event);
                        }}
                        disabled={
                          match.params.update === "update" ? true : false
                        }
                      />
                    </Grid.Column>
                    <Grid.Column
                      className={
                        radioValue === "serial_number" ? styles.addDisabled : ""
                      }
                    >
                      <Field
                        component={
                          radioValue === "model_content"
                            ? RenderSelectInput
                            : InputField
                        }
                        name="models"
                        label="Select..."
                        options={model_params}
                        placeholder="Select..."
                        className="multiCheckboxFix"
                        disabled={radioValue === "serial_number" ? true : false}
                        clearable={
                          radioValue === "serial_number" ? false : true
                        }
                        validate={
                          radioValue === "model_content" &&
                          isSerialMatched === "" && [required]
                        }
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Radio
                        name="radioGroup"
                        value="serial_number"
                        label="Enter Serial Number"
                        checked={radioValue === "serial_number"}
                        onChange={radioChange}
                        onClick={reSetModelValue}
                        disabled={
                          match.params.update === "update" ? true : false
                        }
                      />
                    </Grid.Column>
                    <Grid.Column>
                      <Input
                        name="serial_numbers"
                        onChange={
                          /* istanbul ignore next */ (e, value) => {
                            setSerialNumberValue(value.value);
                          }
                        }
                        placeholder="Asset Serial Number"
                        disabled={radioValue === "model_content"}
                        className={
                          match.params.update === "update"
                            ? styles.addDisabled
                            : ""
                        }
                        value={serialFieldValue}
                      />
                    </Grid.Column>
                    <GridColumn>
                      <div>
                        <Button
                          attached="left"
                          onClick={(e) => {
                            checkSerialIsPresent(e, serialTextValue);
                            ClearField();
                          }}
                          disabled={radioValue === "model_content"}
                        >
                          {commonLabels.add}
                        </Button>
                        <Button
                          attached="right"
                          onClick={(event) => {
                            clearSerialNumbers(event);
                            ClearField();
                          }}
                          disabled={radioValue === "model_content"}
                        >
                          {commonLabels.clear}
                        </Button>
                      </div>
                    </GridColumn>
                    <GridColumn>
                      <Field
                        component={renderTextArea}
                        name="deviceSerialNumbers"
                        placeholder="Entered Serial Numbers"
                        content={matchedSerialNumbers}
                        disabled={radioValue === "model_content"}
                        className={
                          radioValue === "model_content"
                            ? styles.addDisabled
                            : ""
                        }
                        validate={
                          radioValue === "serial_number" &&
                          isSerialMatched === "" && [required]
                        }
                      />
                    </GridColumn>

                    {isSerialMatched === true && (
                      <span className={styles.matched}>
                        Serial number is found
                      </span>
                    )}
                    {isSerialMatched === false && (
                      <span className={styles.notMatched}>
                        Serial number is not found
                      </span>
                    )}
                  </Grid.Row>
                </Grid>
              ) : (
                <span>
                  {initialValues.models
                    ? initialValues.models
                    : initialValues.serialNumbers}
                </span>
              )}
            </Form.Field>
            {match.params.update !== "update" ? (
              <Field
                component={InputField}
                label="Version"
                placeholder="Version"
                name="version"
                className={styles.versionCssContentStyling}
                validate={[required]}
                disabled={match.params.update === "update" ? true : false}
                normalize={OnlyNumberAllowed}
              />
            ) : (
              <Form.Field>
                <label>Version</label>
                <span>{initialValues.version}</span>
              </Form.Field>
            )}
            <Field
              name="description"
              label="Description"
              component={TextAreaField}
              placeholder="Description"
              validate={[required]}
              normalize={limitDescriptionChar}
            />
            {match.params.update !== "update" ? (
              <div>
                {" "}
                <div className={styles.customFileFix}>
                  <Input
                    name="file"
                    label="Browse File"
                    type="file"
                    onChange={(e) => {
                      onAttachmentChange(e);
                    }}
                    placeholder="file"
                    validate={[required]}
                    disabled={match.params.update === "update" ? true : false}
                    id="myFileInput"
                  />
                </div>
                {fileError && (
                  <div className="ui red pointing basic label">Required</div>
                )}
              </div>
            ) : (
              <Form.Field>
                <label>FileName</label>
                <span>{initialValues.fileName}</span>
              </Form.Field>
            )}
            {match.params.update !== "update" ? (
              <Field
                component={SelectField}
                className="contentOperationStyling"
                name="operation"
                label="Operation"
                options={operationParams}
                validate={[required]}
                disabled={match.params.update === "update" ? true : false}
              />
            ) : (
              <Form.Field></Form.Field>
            )}

            {match.params.update !== "update" ? (
              <Field
                name="mandatoryUpdate"
                label="Mandatory Update"
                component={CheckboxField}
                type="checkbox"
                className="manadateUpdateStyling"
                disabled={match.params.update === "update" ? true : false}
              />
            ) : (
              <Form.Field>
                <label>Mandatory Update</label>
                <span>{initialValues.mandatoryUpdate}</span>
              </Form.Field>
            )}
            <Button.Group>
              <Button positive>
                {match.params.update === "update"
                  ? commonLabels.update
                  : commonLabels.add}
              </Button>
              <Button.Or />
              <Button negative onClick={backToListPage}>
                {commonLabels.cancel}
              </Button>
            </Button.Group>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default enhance(CreateContent);
